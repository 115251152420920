import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import CurrencyTextField from "components/CurrencyTextField/CurrencyTextField"
import NumericInput from "components/NumericInput/NumericInput"
import { datePickerLocaleDEde } from "constants/datePickerLocale"
import dayjs from "dayjs"
import { ArticleDetail, ArticleSupplier, PlantType } from "generated/graphql"
import { useEffect, useState } from "react"

export interface ArticleBaseDataProps {
  articleDetail: ArticleDetail
  articleSuppliers: ArticleSupplier[]
  setArticleDetail: (articleDetail: ArticleDetail | null) => void
  handleArticleDetailsSaved: (articleDetail: ArticleDetail) => Promise<void>
  plantTypes: PlantType[]
  hasEditPermission: boolean
}

const zeroDate = "1970-01-01"

const ArticleEditPageBaseData = (props: ArticleBaseDataProps): JSX.Element => {
  const {
    articleDetail,
    articleSuppliers,
    setArticleDetail,
    handleArticleDetailsSaved,
    plantTypes,
    hasEditPermission,
  } = props
  const [isPriceValid, setIsPriceValid] = useState<boolean>(true)

  const [saleFrom, setSaleFrom] = useState([
    { value: 0, label: "Sofort" },
    { value: 666, label: "Ausverkauft" },
    { value: 201, label: "Anfang Januar" },
    { value: 101, label: "Mitte Januar" },
    { value: 202, label: "Anfang Februar" },
    { value: 102, label: "Mitte Februar" },
    { value: 203, label: "Anfang März" },
    { value: 103, label: "Mitte März" },
    { value: 204, label: "Anfang April" },
    { value: 104, label: "Mitte April" },
    { value: 205, label: "Anfang Mai" },
    { value: 105, label: "Mitte Mai" },
    { value: 206, label: "Anfang Juni" },
    { value: 106, label: "Mitte Juni" },
    { value: 207, label: "Anfang Juli" },
    { value: 107, label: "Mitte Juli" },
    { value: 208, label: "Anfang August" },
    { value: 108, label: "Mitte August" },
    { value: 209, label: "Anfang September" },
    { value: 109, label: "Mitte September" },
    { value: 210, label: "Anfang Oktober" },
    { value: 110, label: "Mitte Oktober" },
    { value: 211, label: "Anfang November" },
    { value: 111, label: "Mitte November" },
    { value: 212, label: "Anfang Dezember" },
    { value: 112, label: "Mitte Dezember" },
  ])
  const [isReducedPriceValid, setIsReducedPriceValid] = useState<boolean>(true)
  const [isSizeFromValid, setIsSizeFromValid] = useState<boolean>(true)
  const [isSizeToValid, setIsSizeToValid] = useState<boolean>(true)
  const [isTrunkSizeFromValid, setIsTrunkSizeFromValid] =
    useState<boolean>(true)
  const [isTrunkSizeToValid, setIsTrunkSizeToValid] = useState<boolean>(true)
  const [isTrunkHeightFromValid, setIsTrunkHeightFromValid] =
    useState<boolean>(true)

  useEffect(() => {
    // check if list includes the value if not add it
    if (
      !saleFrom.some((e) => e.value.toString() === articleDetail.availableFrom)
    ) {
      setSaleFrom([
        ...saleFrom,
        {
          value: Number(articleDetail.availableFrom),
          label: articleDetail.availableFrom.toString() + " Unbekannt",
        },
      ])
    }
  }, [articleDetail.availableFrom, setSaleFrom, saleFrom])

  const areAnyInvalid = (): boolean => {
    return (
      !isPriceValid ||
      !isReducedPriceValid ||
      !isSizeFromValid ||
      !isSizeToValid ||
      !isTrunkSizeFromValid ||
      !isTrunkSizeToValid ||
      !isTrunkHeightFromValid
    )
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={3}>
        <Typography variant="h6" component="div" gutterBottom>
          Stammdaten
        </Typography>
        <TextField
          label="BDB"
          value={articleDetail.bdb}
          disabled
          onChange={(e) => {
            setArticleDetail({
              ...articleDetail,
              bdb: e.target.value,
            })
          }}
          fullWidth
          sx={{ marginBottom: 1 }}
        />
        <TextField
          label="PflNR"
          disabled={!hasEditPermission}
          value={articleDetail.pflNR}
          onChange={(e) => {
            setArticleDetail({
              ...articleDetail,
              pflNR: e.target.value,
            })
          }}
          fullWidth
          sx={{ marginBottom: 1 }}
        />
        <TextField
          label="Botanischer Name"
          value={articleDetail.botname}
          disabled={!hasEditPermission}
          onChange={(e) => {
            setArticleDetail({
              ...articleDetail,
              botname: e.target.value,
            })
          }}
          fullWidth
          sx={{ marginBottom: 1 }}
        />
        <TextField
          label="Deutscher Name"
          disabled={!hasEditPermission}
          value={articleDetail.gername}
          onChange={(e) => {
            setArticleDetail({
              ...articleDetail,
              gername: e.target.value,
            })
          }}
          fullWidth
          sx={{ marginBottom: 1 }}
        />
        {/*
        <Autocomplete
          multiple
          fullWidth
          value={articleDetail.plantTypes}
          options={plantTypes}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(_, newValue) => {
            setArticleDetail({
              ...articleDetail,
              plantTypes: newValue,
            })
          }}
          renderInput={(params) => <TextField {...params} label="Typ" />}
        />
        */}
      </Grid>

      <Grid item xs={12} md={3}>
        <Typography variant="h6" component="div" gutterBottom>
          Verkaufsdaten
        </Typography>
        <CurrencyTextField
          label="Preis"
          value={articleDetail.price}
          disabled={!hasEditPermission}
          nullAllowed={false}
          setValueCallback={(e) => {
            setArticleDetail({
              ...articleDetail,
              price: e,
            })
          }}
          setIsValidCallback={(v) => {
            setIsPriceValid(v)
          }}
        />
        <FormControl fullWidth>
          <InputLabel id="available-from-select-label">Verkauf Ab:</InputLabel>
          <Select
            labelId="available-from-select-label"
            id="available-from-select"
            value={articleDetail.availableFrom}
            disabled={!hasEditPermission}
            label="Verkauf Ab:"
            onChange={(e) => {
              setArticleDetail({
                ...articleDetail,
                availableFrom: "" + e.target.value,
              })
            }}
            fullWidth
            sx={{ marginBottom: 1 }}
          >
            {saleFrom.map((s) => (
              <MenuItem key={s.value} value={s.value}>
                {s.value} - {s.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="sold-status-select-label">
            Status bei Sold-Out
          </InputLabel>
          <Select
            labelId="sold-status-select-label"
            id="sold-status-select"
            value={articleDetail.statusAfterSoldOut}
            disabled={!hasEditPermission}
            label="Status bei Sold-Out"
            onChange={(e) => {
              setArticleDetail({
                ...articleDetail,
                statusAfterSoldOut: "" + e.target.value,
              })
            }}
            fullWidth
            sx={{ marginBottom: 1 }}
          >
            <MenuItem value={0}>0 - Deaktiviert</MenuItem>
            <MenuItem value={1}>1 - Ausverkauft</MenuItem>
            <MenuItem value={2}>2 - Aktiv</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="sold-suppler-select-label">
            Lieferant bei Sold-Out
          </InputLabel>
          <Select
            labelId="sold-supplier-select-label"
            id="sold-supplier-select"
            value={articleDetail.supplierAfterSoldOut}
            label="Lieferant bei Sold-Out"
            onChange={(e) => {
              setArticleDetail({
                ...articleDetail,
                supplierAfterSoldOut: Number(e.target.value),
              })
            }}
            fullWidth
            sx={{ marginBottom: 1 }}
            disabled={articleSuppliers.length === 0 || !hasEditPermission}
          >
            <MenuItem value={0}>0 - Kein Lieferant</MenuItem>
            {articleSuppliers.map((s) => (
              <MenuItem key={s.priority} value={s.priority}>
                {s.priority} - {s.supplierName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={3} sx={{ marginTop: { md: "39px" } }}>
        <CurrencyTextField
          label="Aktionspreis"
          value={articleDetail.reducedPrice}
          disabled={!hasEditPermission}
          nullAllowed={true}
          setValueCallback={(e) => {
            setArticleDetail({
              ...articleDetail,
              reducedPrice: e,
            })
          }}
          setIsValidCallback={(v) => {
            setIsReducedPriceValid(v)
          }}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="deDE">
          <DatePicker
            label="Aktionspreis bis"
            value={dayjs(articleDetail.reducedPriceUntil)}
            disabled={!hasEditPermission}
            localeText={datePickerLocaleDEde}
            format="DD.MM.YYYY"
            onChange={(e) => {
              setArticleDetail({
                ...articleDetail,
                reducedPriceUntil: e?.format("YYYY-MM-DD") ?? zeroDate,
              })
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                sx: { marginBottom: 1 },
              },
            }}
          />
        </LocalizationProvider>
        <NumericInput
          label="Sale minimum"
          value={articleDetail.saleMinCount}
          disabled={!hasEditPermission}
          minValue={0}
          setValueCallback={(e) => {
            setArticleDetail({
              ...articleDetail,
              saleMinCount: e,
            })
          }}
          setIsValidCallback={(_) => {}}
          onFocusLostCallback={(e) => {
            if (isNaN(Number(e)) || !e) {
              setArticleDetail({
                ...articleDetail,
                saleMinCount: "0",
              })
            }
          }}
        />
        <NumericInput
          label="Sale %"
          value={articleDetail.salePct}
          minValue={0}
          maxValue={100}
          disabled={
            !hasEditPermission ||
            articleDetail.saleMinCount === "" ||
            articleDetail.saleMinCount === "0"
          }
          setValueCallback={(e) => {
            setArticleDetail({
              ...articleDetail,
              salePct: e,
            })
          }}
          setIsValidCallback={(e) => {}}
          onFocusLostCallback={(e) => {
            if (isNaN(Number(e)) || !e) {
              setArticleDetail({
                ...articleDetail,
                salePct: "0",
              })
            }
          }}
        />
      </Grid>

      <Grid item xs={12} md={3} sx={{ marginTop: { md: "39px" } }}>
        <FormControl fullWidth>
          <InputLabel id="variant-select-label">Variante</InputLabel>
          <Select
            labelId="variant-select-label"
            id="variant-select"
            value={articleDetail.variant}
            disabled={!hasEditPermission}
            label="Variante"
            onChange={(e) => {
              setArticleDetail({
                ...articleDetail,
                variant: "" + e.target.value,
              })
            }}
            fullWidth
            sx={{ marginBottom: 1 }}
          >
            <MenuItem value={0}>0 - Normal</MenuItem>
            <MenuItem value={1}>1 - Fundgrube</MenuItem>
            <MenuItem value={2}>2 - Aktion</MenuItem>
            <MenuItem value={3}>3 - Fotomodell</MenuItem>
            <MenuItem value={5}>5 - Gratis </MenuItem>
          </Select>
        </FormControl>
        <NumericInput
          label="Gruppe"
          value={articleDetail.articleGroup}
          minValue={0}
          disabled={!hasEditPermission}
          setValueCallback={(e) => {
            setArticleDetail({
              ...articleDetail,
              articleGroup: Number(e),
            })
          }}
          setIsValidCallback={(e) => {}}
          onFocusLostCallback={(e) => {}}
        />
        <FormControl fullWidth>
          <InputLabel id="h24-select-label">H24</InputLabel>
          <Select
            labelId="h24-select-label"
            id="h24-select"
            value={articleDetail.h24}
            disabled={!hasEditPermission}
            label="H24"
            onChange={(e) => {
              setArticleDetail({
                ...articleDetail,
                h24: e.target.value,
              })
            }}
            fullWidth
            sx={{ marginBottom: 1 }}
          >
            <MenuItem value={0}>Normal</MenuItem>
            <MenuItem value={1}>Express</MenuItem>
            <MenuItem value={2}>Super Express</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={3}>
        <Typography variant="h6" component="div" gutterBottom>
          Logistikdaten
        </Typography>
        <TextField
          label="Standort"
          fullWidth
          value={articleDetail.location}
          disabled={!hasEditPermission}
          onChange={(e) => {
            setArticleDetail({
              ...articleDetail,
              location: e.target.value,
            })
          }}
          sx={{ marginBottom: 1 }}
        />
        <NumericInput
          label="Bestand"
          value={articleDetail.stock}
          disabled={!hasEditPermission}
          minValue={0}
          setValueCallback={(e) => {
            setArticleDetail({
              ...articleDetail,
              stock: e,
            })
          }}
          setIsValidCallback={(e) => {}}
          onFocusLostCallback={(e) => {
            if (isNaN(Number(e)) || !e) {
              setArticleDetail({
                ...articleDetail,
                stock: "0",
              })
            }
          }}
        />
        <NumericInput
          label="Lokaler Bestand"
          value={articleDetail.localStock}
          disabled={!hasEditPermission}
          minValue={0}
          setValueCallback={(e) => {
            setArticleDetail({
              ...articleDetail,
              localStock: e,
            })
          }}
          setIsValidCallback={(e) => {}}
          onFocusLostCallback={(e) => {
            if (isNaN(Number(e)) || !e) {
              setArticleDetail({
                ...articleDetail,
                localStock: "0",
              })
            }
          }}
        />
      </Grid>

      <Grid item xs={12} md={3} sx={{ marginTop: { md: "39px" } }}>
        <TextField
          label="Quali & Sorte"
          value={articleDetail.quali}
          disabled={!hasEditPermission}
          onChange={(e) => {
            setArticleDetail({
              ...articleDetail,
              quali: e.target.value,
              sorte: e.target.value,
            })
          }}
          fullWidth
          sx={{ marginBottom: 1 }}
        />
        <TextField
          label="Containergröße - Kunde"
          value={articleDetail.customerContainerSize}
          disabled={!hasEditPermission}
          onChange={(e) => {
            setArticleDetail({
              ...articleDetail,
              customerContainerSize: e.target.value,
            })
          }}
          fullWidth
          sx={{ marginBottom: 1 }}
        />
        <TextField
          label="Containergröße - Lieferant"
          value={articleDetail.supplierContainerSize}
          disabled={!hasEditPermission}
          onChange={(e) => {
            setArticleDetail({
              ...articleDetail,
              supplierContainerSize: e.target.value,
            })
          }}
          fullWidth
          sx={{ marginBottom: 1 }}
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <Typography variant="h6" component="div" gutterBottom>
          Messdaten
        </Typography>
        <NumericInput
          label="Größe: Von"
          value={articleDetail.sizeFrom}
          disabled={!hasEditPermission}
          minValue={0}
          maxValue={Number(articleDetail.sizeTo) ?? 0}
          setValueCallback={(e) => {
            setArticleDetail({
              ...articleDetail,
              sizeFrom: e,
            })
          }}
          setIsValidCallback={(e) => {
            setIsSizeFromValid(e)
          }}
          onFocusLostCallback={(e) => {}}
        />
        <NumericInput
          label="Größe: Bis"
          minValue={Number(articleDetail.sizeFrom) ?? 0}
          value={articleDetail.sizeTo}
          disabled={!hasEditPermission}
          setValueCallback={(e) => {
            setArticleDetail({
              ...articleDetail,
              sizeTo: e,
            })
          }}
          setIsValidCallback={(e) => {
            setIsSizeToValid(e)
          }}
          onFocusLostCallback={(e) => {}}
        />
      </Grid>

      <Grid item xs={12} md={3} sx={{ marginTop: { md: "39px" } }}>
        <NumericInput
          label="Stammumfang: Von"
          value={articleDetail.trunkSizeFrom}
          disabled={!hasEditPermission}
          minValue={0}
          maxValue={Number(articleDetail.trunkSizeTo) ?? 0}
          setValueCallback={(e) => {
            setArticleDetail({
              ...articleDetail,
              trunkSizeFrom: e,
            })
          }}
          setIsValidCallback={(e) => {
            setIsTrunkSizeFromValid(e)
          }}
          onFocusLostCallback={(e) => {}}
        />
        <NumericInput
          label="Stammumfang: Bis"
          minValue={0}
          value={articleDetail.trunkSizeTo}
          disabled={!hasEditPermission}
          setValueCallback={(e) => {
            setArticleDetail({
              ...articleDetail,
              trunkSizeTo: e,
            })
          }}
          setIsValidCallback={(e) => {
            setIsTrunkSizeToValid(e)
          }}
          onFocusLostCallback={(e) => {}}
        />
        <NumericInput
          label="Stammhöhe"
          minValue={0}
          value={articleDetail.trunkHeight}
          disabled={!hasEditPermission}
          setValueCallback={(e) => {
            setArticleDetail({
              ...articleDetail,
              trunkHeight: e,
            })
          }}
          setIsValidCallback={(e) => {
            setIsTrunkHeightFromValid(e)
          }}
          onFocusLostCallback={(e) => {}}
        />
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleArticleDetailsSaved(articleDetail)
            }}
            disabled={!hasEditPermission || areAnyInvalid()}
          >
            Daten Speichern
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}

export default ArticleEditPageBaseData
