import { fetchGQL } from "app/api"
import {
  OrderItemsCancelMutation,
  OrderItemsCancelMutationVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"

const ORDER_ITEM_CANCEL = gql`
  mutation orderItemsCancel($in: OrderItemsCancelInput!) {
    orderItemsCancel(in: $in)
  }
`

export const orderItemsCancel = async (
  vars: OrderItemsCancelMutationVariables
): Promise<OrderItemsCancelMutation | undefined> => {
  const data = await fetchGQL<
    OrderItemsCancelMutation,
    OrderItemsCancelMutationVariables
  >(ORDER_ITEM_CANCEL, vars)
  return data
}
