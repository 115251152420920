import LoadingButton from "@mui/lab/LoadingButton"
import {
  Autocomplete,
  Box,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"
import {
  DataGridPro,
  GridColDef,
  getGridNumericOperators,
} from "@mui/x-data-grid-pro"
import { useAppSelector } from "app/hooks"
import { selectCurrentRound } from "app/roundSlice"
import { dataGridDEde } from "constants/dataGridLocale"
import { EtikettenData, Printer } from "generated/graphql"
import { uniq, uniqBy } from "lodash"
import { enqueueSnackbar } from "notistack"
import { valueBetweenOperator } from "pages/PrintKistenzettelPage/PrintKistenzettelPage"
import { etikettenData } from "queries/etikettenData"
import { etikettenPrint } from "queries/etikettenPrint"
import { fetchPrinters } from "queries/fetchPrinters"
import { useEffect, useState } from "react"

const PrintLabelPage = (): JSX.Element => {
  const round = useAppSelector(selectCurrentRound)
  const [isLoading, setIsLoading] = useState(false)
  const [gridData, setGridData] = useState<EtikettenData[]>([])
  const [selection, setSelection] = useState<EtikettenData[]>([])

  const handleSelectionModelChange = (selectionModel: any) => {
    //selection model is an array of selected row ids here routeIDs
    // e.g [1,2,3] this comes from the database
    const selectedOrderList = selectionModel.map((id: any) =>
      gridData.find((item) => item.ewid === id)
    )
    setSelection(selectedOrderList)
  }

  const cols: GridColDef<EtikettenData>[] = [
    {
      field: "etiNum",
      headerName: "Nr.",
      width: 60,
      sortable: false,
      type: "number",
      filterOperators: valueBetweenOperator.concat(getGridNumericOperators()),
    },
    {
      field: "posten",
      headerName: "Posten",
      width: 60,
      sortable: false,
      type: "number",
      filterOperators: valueBetweenOperator.concat(getGridNumericOperators()),
    },
    {
      field: "sh",
      headerName: "SH",
      width: 60,
      type: "number",
      sortable: false,
      filterOperators: valueBetweenOperator.concat(getGridNumericOperators()),
    },
    {
      field: "orderID",
      headerName: "Bestellnr.",
      width: 150,
      sortable: false,
    },
    {
      field: "ewid",
      headerName: "Ewid",
      width: 125,
      sortable: false,
    },
    {
      field: "botname",
      headerName: "Botanischer Name",
      width: 200,
      sortable: false,
    },
    {
      field: "sorte",
      headerName: "Sorte",
      width: 150,
      sortable: false,
    },
    {
      field: "size",
      headerName: "Größe",
      width: 150,
      sortable: false,
      valueGetter: (params) => {
        if (!params.row.von || !params.row.bis) {
          return ""
        }
        return `${params.row.von} - ${params.row.bis}`
      },
    },
    {
      field: "quantity",
      headerName: "Menge",
      width: 150,
      sortable: false,
    },
    {
      field: "totalBasketItems",
      headerName: "Bestell Positionen",
      width: 150,
      sortable: false,
    },
    {
      field: "supplierName",
      headerName: "Lieferant",
      width: 200,
      sortable: false,
    },
  ]

  useEffect(() => {
    if (!round) {
      return
    }
    setIsLoading(true)
    etikettenData(round.round)
      .then((res) => {
        if (res?.getEtikettenRound) {
          setGridData(res.getEtikettenRound)
          let supplier = uniqBy(res.getEtikettenRound, "supplierName").map(
            (item) => item.supplierName
          )
          supplier = supplier.sort((a, b) => a.localeCompare(b))
          setAllSuppliers(["Alle", ...supplier])
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [round])

  const [supplier, setSupplier] = useState<string>("Alle")
  const [allSuppliers, setAllSuppliers] = useState<string[]>(["Alle"])
  const [steckEtiPrinter, setSteckEtiPrinter] = useState<Printer | null>(null)
  const [schlaufeEtiPrinter, setSchlaufenEtiPrinter] = useState<Printer | null>(
    null
  )

  const [printers, setPrinters] = useState<Printer[]>([])

  useEffect(() => {
    // load printer from server
    fetchPrinters().then((data) => {
      if (data?.printers) {
        setPrinters(data.printers)
        const steckEti = localStorage.getItem(
          "selectedPrinter-SteckEtiPrinting"
        )
        const schlaufenEti = localStorage.getItem(
          "selectedPrinter-SchlaufenEtiPrinting"
        )
        if (schlaufenEti) {
          const schlaufen = data.printers.find(
            (printer) => printer.printerID === Number(schlaufenEti)
          )
          if (schlaufen) {
            setSchlaufenEtiPrinter(schlaufen)
          }
        }
        if (steckEti) {
          const se = data.printers.find(
            (printer) => printer.printerID === Number(steckEti)
          )
          if (se) {
            setSteckEtiPrinter(se)
          }
        }
      }
    })
  }, [])

  const filterBySupplier = (data: EtikettenData[]): EtikettenData[] => {
    if (supplier === "Alle") {
      return data
    }
    return data.filter((item) => item.supplierName === supplier)
  }

  const handlePrintSteckEtiketten = () => {
    const supplierIds = uniq(selection.map((item) => item.supplierID))
    const roundId = round?.round
    const printerIpPort =
      steckEtiPrinter?.printerIP + ":" + steckEtiPrinter?.printerPort
    const ewIds = selection.map((item) => item.ewid)
    if (ewIds.length === 0) {
      enqueueSnackbar("Keine Etiketten ausgewählt", { variant: "info" })
      return
    }
    if (
      supplierIds.length > 0 &&
      roundId !== undefined &&
      printerIpPort !== undefined
    ) {
      setIsLoading(true)
      etikettenPrint(roundId, supplierIds, printerIpPort, true, ewIds)
        .then((data) => {
          if (data?.printEtiketten) {
            enqueueSnackbar(
              `Etiketten wurden zum Drucker ${printerIpPort} geschickt`,
              { variant: "success" }
            )
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const handlePrintSchlaufenEtiketten = () => {
    const supplierIds = uniq(selection.map((item) => item.supplierID))

    const roundId = round?.round
    const printerIpPort =
      schlaufeEtiPrinter?.printerIP + ":" + schlaufeEtiPrinter?.printerPort

    const ewIds = selection.map((item) => item.ewid)
    if (ewIds.length === 0) {
      enqueueSnackbar("Keine Etiketten ausgewählt", { variant: "info" })
      return
    }
    if (
      supplierIds.length > 0 &&
      roundId !== undefined &&
      printerIpPort !== undefined
    ) {
      setIsLoading(true)
      etikettenPrint(roundId, supplierIds, printerIpPort, false, ewIds)
        .then((data) => {
          if (data?.printEtiketten) {
            enqueueSnackbar(
              `Etiketten wurden zum Drucker ${printerIpPort} geschickt`,
              { variant: "success" }
            )
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  return (
    <Container maxWidth="xl">
      <Grid container spacing={1}>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <InputLabel id="printer-select-label-steck">
              Steck Etiketten Drucker
            </InputLabel>
            <Select
              labelId="printer-select-label-steck"
              id="printer-select-steck"
              value={steckEtiPrinter?.printerID || ""}
              label="Steck Etiketten Drucker"
              onChange={(event) => {
                const printerID = event.target.value as number
                const printer = printers.find(
                  (printer) => printer.printerID === printerID
                )
                setSteckEtiPrinter(printer || null)
                localStorage.setItem(
                  "selectedPrinter-SteckEtiPrinting",
                  printerID.toString()
                )
              }}
            >
              {printers.map((printer) => (
                <MenuItem key={printer.printerID} value={printer.printerID}>
                  {printer.printerID} - {printer.printerIP}:
                  {printer.printerPort} - {printer.printerName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            sx={{ height: "100%" }}
            onClick={handlePrintSteckEtiketten}
            loading={isLoading}
            disabled={selection.length === 0 || gridData.length === 0}
          >
            ({selection.length}) Steck-Etiketten drucken
          </LoadingButton>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <InputLabel id="printer-select-label-schlaufen">
              Schlaufen Etiketten Drucker
            </InputLabel>
            <Select
              labelId="printer-select-label-schlaufen"
              id="printer-select-schlaufen"
              value={schlaufeEtiPrinter?.printerID || ""}
              label="Schlaufen Etiketten Drucker"
              onChange={(event) => {
                const printerID = event.target.value as number
                const printer = printers.find(
                  (printer) => printer.printerID === printerID
                )
                setSchlaufenEtiPrinter(printer || null)
                localStorage.setItem(
                  "selectedPrinter-SchlaufenEtiPrinting",
                  printerID.toString()
                )
              }}
            >
              {printers.map((printer) => (
                <MenuItem key={printer.printerID} value={printer.printerID}>
                  {printer.printerID} - {printer.printerIP}:
                  {printer.printerPort} - {printer.printerName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            sx={{ height: "100%" }}
            onClick={handlePrintSchlaufenEtiketten}
            loading={isLoading}
            disabled={selection.length === 0 || gridData.length === 0}
          >
            ({selection.length}) Schlaufen-Etiketten drucken
          </LoadingButton>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            disablePortal
            options={allSuppliers}
            renderInput={(params) => (
              <TextField {...params} label="Lieferant" variant="outlined" />
            )}
            value={supplier}
            onChange={(_, newValue) => {
              if (newValue) setSupplier(newValue)
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ height: "70vh", width: "100%" }}>
            <DataGridPro
              rows={filterBySupplier(gridData)}
              getRowHeight={() => "auto"}
              checkboxSelection
              disableColumnReorder
              getRowId={(row) => row.ewid}
              disableRowSelectionOnClick
              localeText={dataGridDEde}
              columns={cols}
              loading={isLoading}
              onRowSelectionModelChange={handleSelectionModelChange}
              onFilterModelChange={(_) => setSelection([])}
              rowSelectionModel={selection.map((item) => item.ewid)}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default PrintLabelPage
