import {
  Autocomplete,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { AskDanteUser } from "generated/graphql"
import { enqueueSnackbar } from "notistack"
import {
  fetchAskDanteUsers,
  saveAskDanteUserTags,
} from "queries/fetchAskDanteUser"
import * as React from "react"
import { useEffect, useState } from "react"

interface EmployeeStatsEditTagDialogProps {
  isOpen: boolean
  setOpen: (value: boolean) => void
}

export default function EmployeeStatsEditTagDialog(
  props: EmployeeStatsEditTagDialogProps
) {
  const { isOpen, setOpen } = props

  const [users, setUsers] = useState<AskDanteUser[]>([])
  const [selectedUser, setSelectedUser] = useState<AskDanteUser | null>(null)

  const handleClose = () => {
    setSelectedUser(null)
    setOpen(false)
  }

  const handleSave = () => {
    if (selectedUser) {
      saveAskDanteUserTags(selectedUser.id, selectedUser.tags).then((data) => {
        if (data) {
          enqueueSnackbar("Tags gespeichert!", { variant: "success" })
        }
      })
    }
  }

  useEffect(() => {
    fetchAskDanteUsers().then((data) => {
      if (data) {
        setUsers(data.askDanteUsers)
      }
    })
  }, [])

  return (
    <React.Fragment>
      <Dialog maxWidth={"lg"} open={isOpen} onClose={handleClose} fullWidth>
        <DialogTitle>Mitarbeiter Tags</DialogTitle>
        <DialogContent sx={{ height: "70vh" }}>
          <DialogContentText>Editiere Tags</DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                id="askdanteUser-autocomplete"
                options={users}
                getOptionLabel={(option) =>
                  option.personnelNumber +
                  " " +
                  option.firstName +
                  " " +
                  option.lastName
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(_, value) => {
                  setSelectedUser(value)
                }}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Mitarbeiter" />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              {selectedUser && (
                <>
                  <TextField
                    fullWidth
                    label="Tags"
                    onKeyUp={(event) => {
                      if (event.key === "Enter") {
                        const target = event.target as HTMLInputElement
                        // check if tag is already in list
                        if (selectedUser.tags.includes(target.value)) {
                          enqueueSnackbar(
                            `Nutzer hat bereits den Tag ${target.value}!`,
                            { variant: "info" }
                          )
                          target.value = ""
                          return
                        }
                        setSelectedUser({
                          ...selectedUser,
                          tags: [...selectedUser.tags, target.value],
                        })
                        target.value = ""
                      }
                    }}
                    variant="outlined"
                  />
                  <List>
                    {selectedUser.tags.map((tag, index) => (
                      <ListItem
                        key={index}
                        secondaryAction={
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => {
                              setSelectedUser({
                                ...selectedUser,
                                tags: selectedUser.tags.filter(
                                  (t) => t !== tag
                                ),
                              })
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        }
                      >
                        <ListItemText>{tag}</ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={handleClose}>
            Abbrechen
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
