import { fetchGQL } from "app/api"
import {
  RoundItemAddInput,
  RoundItemAddMutation,
  RoundItemAddMutationVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"

const ROUND_ITEMS_ADD = gql`
  mutation roundItemAdd($in: [RoundItemAddInput!]!) {
    roundItemsAdd(in: $in)
  }
`

export const roundItemsAdd = async (
  _in: RoundItemAddInput[]
): Promise<RoundItemAddMutation | undefined> => {
  var vars: RoundItemAddMutationVariables = {
    in: _in,
  }
  const data = await fetchGQL<
    RoundItemAddMutation,
    RoundItemAddMutationVariables
  >(ROUND_ITEMS_ADD, vars)
  return data
}
