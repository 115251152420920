import { Box, TextField, Typography } from "@mui/material"
import {
  DataGridPro,
  GridColDef,
  GridSlotsComponentsProps,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro"
import { OrderItem, OrderItemsCancelInput } from "generated/graphql"
import { useEffect, useState } from "react"

type CancelOrderItem = {
  ewID: string
  articleID: string
  amount: number
}

type CancelOrderItemProps = {
  orderItems: OrderItem[]
  orderItemsCancelInput: OrderItemsCancelInput
  setOrderItemsCancelInput: (arg: OrderItemsCancelInput) => void
}

export const CancelOrderItemsDialog = (props: CancelOrderItemProps) => {
  const { orderItems, orderItemsCancelInput, setOrderItemsCancelInput } = {
    ...props,
  }
  const [cancelOrderItems, setCancelOrderItems] = useState<CancelOrderItem[]>(
    []
  )
  const [quantities, setQuantities] = useState<{ [key: string]: number }>({})

  useEffect(() => {
    const newQuantities = orderItems.reduce((acc, oi) => {
      const orderItem = orderItems.find((a) => a.ewID === oi.ewID)
      if (orderItem) {
        acc[orderItem.artnum] = orderItem.anzahl
      }
      return acc
    }, {} as { [key: string]: number })
    setQuantities(newQuantities)
  }, [orderItems])

  const handleAmountChange = (amount: number, artnum: string, ewID: string) => {
    let tmp = []
    if (amount <= 0) {
      tmp = cancelOrderItems.filter((item) => item.ewID !== ewID)
    } else {
      if (cancelOrderItems?.find((item) => item.ewID === ewID)) {
        // update an existing item
        tmp = cancelOrderItems.map((item) => {
          if (item.ewID === ewID)
            return { ewID: ewID, articleID: artnum, amount: amount }
          return item
        })
      } else {
        tmp = [
          ...cancelOrderItems,
          { ewID: ewID, amount: amount, articleID: artnum },
        ]
      }
    }
    setCancelOrderItems(tmp)
    setOrderItemsCancelInput({
      orderID: orderItemsCancelInput.orderID,
      action: orderItemsCancelInput.action,
      orderItems: tmp
        .filter((item) => item.amount > 0)
        .map((item) => {
          return {
            articleId: item.articleID,
            count: item.amount,
          }
        }),
    })
  }

  const cancelItemsColumns: GridColDef<OrderItem>[] = [
    {
      field: "artnum",
      headerName: "BDB",
      width: 80,
      renderCell: (params) => {
        return (
          <Typography
            variant="body2"
            sx={{
              cursor: "pointer",
              textDecoration: "&&erline",
              color: "primary.main",
            }}
            component="div"
            onClick={() =>
              window.open(
                `${window.location.origin}/#/editArticle/${params.row.artnum}`,
                "_blank",
                "noopener,noreferrer"
              )
            }
          >
            {params.row.artnum}
          </Typography>
        )
      },
    },
    {
      field: "quantity",
      headerName: "Storno-menge",
      width: 120,
      renderCell: (params) => {
        const isSelected = cancelOrderItems.find(
          (item) => item.ewID === params.row.ewID.toString()
        )
        const amount = isSelected
          ? isSelected.amount
          : quantities[params.row.artnum]
        if (!isSelected) {
          return <div />
        }

        return (
          <TextField
            type="number"
            value={amount}
            onChange={(e) =>
              handleAmountChange(
                parseInt(e.target.value),
                params.row.artnum.toString(),
                params.row.ewID.toString()
              )
            }
            inputProps={{
              min: 0,
              max: params.row.anzahl,
            }}
            size="small"
          />
        )
      },
    },
    {
      field: "botname",
      headerName: "Botname",
      width: 280,
    },
    {
      field: "size",
      headerName: "Größe",
      renderCell: (params) => {
        let vonBis = params.row.von + " - " + params.row.bis
        if (params.row.von === params.row.bis) vonBis = params.row.von + ""
        if (params.row.von === 0 && params.row.bis === 0) vonBis = ""

        let stuVonBis = params.row.stuvon + " - " + params.row.stubis
        if (params.row.stuvon === params.row.stubis)
          stuVonBis = params.row.stuvon + ""
        if (params.row.stuvon === 0 && params.row.stubis === 0) stuVonBis = ""
        return (
          <Box>
            <Typography variant="body2" component="div">
              {vonBis}
            </Typography>

            {params.row.stahoeh > 0 && (
              <Typography
                variant="body2"
                color="text.secondary"
                component="div"
              >
                Stahoeh: {params.row.stahoeh}
              </Typography>
            )}

            {stuVonBis !== "" && (
              <Typography
                variant="body2"
                color="text.secondary"
                component="div"
              >
                Stu: {stuVonBis}
              </Typography>
            )}
          </Box>
        )
      },
      width: 140,
    },
    {
      field: "anzahl",
      headerName: "Anzahl",
      width: 80,
    },
  ]

  const CustomToolbar = (
    props: NonNullable<GridSlotsComponentsProps["toolbar"]>
  ) => {
    return (
      <GridToolbarContainer
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    )
  }

  return (
    <Box sx={{ maxHeight: 600 }}>
      <DataGridPro
        getRowId={(rows) => rows.ewID}
        rows={orderItems}
        getRowHeight={() => "auto"}
        columns={cancelItemsColumns}
        slots={{
          toolbar: CustomToolbar,
        }}
        checkboxSelection
        disableRowSelectionOnClick
        onRowSelectionModelChange={(rows) => {
          const newCancelOrderItems: CancelOrderItem[] = []
          rows.forEach((row) => {
            const oldCancelOrderItem = cancelOrderItems.find(
              (item) => item.articleID.toString() === row.toString()
            )
            newCancelOrderItems.push({
              ewID: row.toString(),
              amount: oldCancelOrderItem
                ? oldCancelOrderItem.amount
                : quantities[row],
              articleID: oldCancelOrderItem ? oldCancelOrderItem.articleID : "",
            })
          })
          setCancelOrderItems(newCancelOrderItems)
        }}
      />
    </Box>
  )
}
