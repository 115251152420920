import {
  RoundSuppliersQuery,
  RoundSuppliersQueryVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"
import { fetchGQL } from "app/api"

const GET_ROUND_SUPPLIERS = gql`
  query roundSuppliers($in: Int!) {
    roundSuppliers(in: $in) {
      supplierID
      supplier
      expected
      tagged
      rest
      taggedbybot
    }
  }
`

export const fetchRoundSuppliers = async (
  round: number
): Promise<RoundSuppliersQuery | undefined> => {
  const variables: RoundSuppliersQueryVariables = { in: round }
  const data = await fetchGQL<
    RoundSuppliersQuery,
    RoundSuppliersQueryVariables
  >(GET_ROUND_SUPPLIERS, variables)
  return data
}
