import { Container } from "@mui/material"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro"
import { DateRange, DateRangePicker } from "@mui/x-date-pickers-pro"
import { dataGridDEde } from "constants/dataGridLocale"
import { datePickerLocaleDEde } from "constants/datePickerLocale"
import dayjs, { Dayjs } from "dayjs"
import { Performance } from "generated/graphql"
import { fetchPerformance } from "queries/fetchPerformance"
import { useEffect, useState } from "react"
import { formatDateFromDate } from "utils/formatDate"
import { shortcutsItems } from "utils/shortcutItems"

const columns: GridColDef[] = [
  { field: "worker", headerName: "Mitarbeiter", width: 150, editable: false },
  {
    field: "itemsTagged",
    headerName: "Etikettiert",
    width: 150,
    editable: false,
  },
  {
    field: "itemsDistributed",
    headerName: "Verteilt",
    width: 150,
    editable: false,
  },
  {
    field: "itemsDispatched",
    headerName: "Verpackt",
    width: 150,
    editable: false,
  },
]

const PerformancePage = () => {
  const [loading, setloading] = useState(false)

  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([
    dayjs(),
    dayjs(),
  ])
  const [performanceData, setPerformanceData] = useState<Performance[]>([])

  useEffect(() => {
    setloading(true)
    const fromDateString = dateRange[0]
      ? formatDateFromDate(dateRange[0].toDate())
      : ""
    const toDateString = dateRange[1]
      ? formatDateFromDate(dateRange[1].toDate())
      : ""

    if (!fromDateString || !toDateString) return
    if (fromDateString === "" || toDateString === "") return
    fetchPerformance(fromDateString, toDateString).then((res) => {
      if (res) {
        setloading(false)
        const performanceSum: Performance = {
          worker: "Gesamt",
          itemsTagged: 0,
          itemsDistributed: 0,
          itemsDispatched: 0,
        }
        for (let i = 0; i < res.performance.length; i++) {
          performanceSum.itemsTagged += res.performance[i].itemsTagged
          performanceSum.itemsDistributed += res.performance[i].itemsDistributed
          performanceSum.itemsDispatched += res.performance[i].itemsDispatched
        }

        setPerformanceData([performanceSum, ...res.performance])
      }
    })
  }, [dateRange, setloading, setPerformanceData])

  return (
    <Container maxWidth="xl" sx={{ mt: "2.5%" }}>
      <Grid container spacing={1} sx={{ marginTop: 1 }}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: 500 }}>
              <DateRangePicker
                slotProps={{
                  shortcuts: {
                    items: shortcutsItems,
                  },
                }}
                localeText={datePickerLocaleDEde}
                value={dateRange}
                onChange={(newValue) => setDateRange(newValue)}
                disableFuture={true}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                height: "calc(100vh - 30vh)",
                width: 700,
              }}
            >
              <DataGridPro
                rows={performanceData}
                columns={columns}
                localeText={dataGridDEde}
                getRowId={(item) => item.worker}
                loading={loading}
                sortingOrder={["desc", "asc"]}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default PerformancePage
