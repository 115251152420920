import { fetchGQL } from "app/api"
import { gql } from "graphql-tag"
import {
  ArticleDetailsMultiQuery,
  ArticleDetailsMultiQueryVariables,
} from "../generated/graphql"

const ARTICLE_DETAILS_MULTI = gql`
  query articleDetailsMulti($bdb: String!) {
    articleDetails(bdb: $bdb) {
      bdb
      pflNR
      botname
      gername
      price
      reducedPrice
      reducedPriceUntil
      location
      stock
      localStock
      h24
      sizeFrom
      sizeTo
      trunkSizeFrom
      trunkSizeTo
      trunkHeight
      customerContainerSize
      supplierContainerSize
      sorte
      quali
      variant
      saleMinCount
      salePct
      availableFrom
      statusAfterSoldOut
      supplierAfterSoldOut
      articleGroup
      plantTypes {
        id
        name
      }
    }
    articleSupplier(bdb: $bdb) {
      id
      articleID
      supplierID
      supplierName
      price
      createdAt
      updatedAt
      createdBy
      note
      quality
      priority
    }
    articleInventoryReports(bdb: $bdb) {
      id
      pmbdb
      realbdb
      supplier
      supplierID
      quality
      bestellcode
      sticky
      price
      quantity
      saugtxt
      createdAt
    }
    articleChangelog(bdb: $bdb) {
      id
      bdb
      changeDate
      userName
      computerName
      action
    }
    articlePriceHistory(bdb: $bdb) {
      id
      bdb
      supplierID
      supplierName
      price
      createdAt
      action
    }
    plantTypes {
      id
      name
    }
  }
`

export const articleDetailsMulti = async (
  bdb: string
): Promise<ArticleDetailsMultiQuery | undefined> => {
  const variables: ArticleDetailsMultiQueryVariables = { bdb }
  const data = await fetchGQL<
    ArticleDetailsMultiQuery,
    ArticleDetailsMultiQueryVariables
  >(ARTICLE_DETAILS_MULTI, variables)
  return data
}
