import { gql } from "graphql-tag"
import { fetchGQL } from "app/api"
import { CarriersQuery, CarriersQueryVariables } from "generated/graphql"

const GET_CARRIERS = gql`
  query carriers {
    carriers
  }
`

export const carriersGet = async (): Promise<CarriersQuery | undefined> => {
  const variables: CarriersQueryVariables = {}
  const data = await fetchGQL<CarriersQuery, CarriersQueryVariables>(
    GET_CARRIERS,
    variables
  )
  return data
}
