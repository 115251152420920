import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material"
import { ADMIN, hasPrivilege } from "constants/privileges"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { selectUser } from "app/userSlice"
import { useLocation, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import {
  selectCurrentRound,
  selectRounds,
  setRounds,
  setSelectedRound,
} from "app/roundSlice"
import RoundSelector from "components/RoundSelector/RoundSelector"
import { fetchRounds } from "queries/fetchRounds"
import { OrderIdSearch } from "components/OrderIdSearch/OrderIdSearch"
import { NavItem, navigationPages, ALL } from "./navigationPages"

export const TopNavigation = () => {
  const [activeMenu, setActiveMenu] = useState<string | null>(null)
  const [activeSubMenu, setActiveSubMenu] = useState<string | null>(null)
  const navigate = useNavigate()
  const loc = useLocation()
  const us = useAppSelector(selectUser)
  const selectedRound = useAppSelector(selectCurrentRound)
  const rounds = useAppSelector(selectRounds)
  const dispatch = useAppDispatch()

  useEffect(() => {
    fetchRounds().then((roundsGetResponse) => {
      if (roundsGetResponse?.roundsGet) {
        if (roundsGetResponse.roundsGet.rounds.length > 0) {
          dispatch(setRounds(roundsGetResponse.roundsGet.rounds))
          dispatch(setSelectedRound(roundsGetResponse.roundsGet.rounds[0]))
        }
      }
    })
  }, [dispatch])

  const handleMenuClick = (item: NavItem) => {
    if (item.children) {
      setActiveMenu(item.id)
      setActiveSubMenu(null)
      if (item.navTo !== undefined && item.navTo !== "") {
        window.scrollTo(0, 0)
        navigate(item.navTo)
      }
    } else {
      setActiveMenu(null)
      setActiveSubMenu(item.id)
      if (item.navTo !== undefined && item.navTo !== "") {
        window.scrollTo(0, 0)
        navigate(item.navTo)
      }
    }
  }

  const handleSubMenuClick = (subItem: NavItem) => {
    setActiveSubMenu(subItem.id)
    if (subItem.navTo !== undefined && subItem.navTo !== "") {
      window.scrollTo(0, 0)
      navigate(subItem.navTo)
    }
  }

  // const pages = useMemo(() => {
  //   let pages = [...navigationPages]

  //   if (hasPrivilege(us.user?.privileges, ADMIN)) {
  //     if (!pages.find((p) => p.id === "99")) {
  //       pages.splice(0, 0, {
  //         id: "99",
  //         label: "Benutzer verwalten",
  //         Icon: SupervisorAccountIcon,
  //         navTo: "/userAdministration",
  //       })
  //     }

  //     if (!pages.find((p) => p.id === "Mitarbeiterstatistik")) {
  //       pages.splice(3, 0, {
  //         id: "Mitarbeiterstatistik",
  //         label: "Mitarbeiterstatistik",
  //         Icon: Assessment,
  //         navTo: "/employeeStats",
  //       })
  //     }
  //   }

  //   return pages
  // }, [us])

  useEffect(() => {
    // find the current path in the pages array
    if (loc.pathname === "/") {
      setActiveMenu("1")
      setActiveSubMenu(null)
      return
    }
    if (loc.pathname.includes("/editArticle")) {
      setActiveMenu("Stammdaten")
      setActiveSubMenu("searchArticle")
      return
    }
    if (
      loc.pathname.includes("/editCustomer") ||
      loc.pathname.includes("/orderDetails")
    ) {
      setActiveMenu("serviceWorker")
      setActiveSubMenu("searchCustomer")
      return
    }
    for (const page of navigationPages) {
      if (page.navTo === loc.pathname) {
        setActiveMenu(page.id)

        break
      }
      if (page.children) {
        for (const child of page.children) {
          if (child.navTo === loc.pathname) {
            setActiveMenu(page.id)
            setActiveSubMenu(child.id)
            break
          }
        }
      }
    }
  }, [loc])

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginRight: 5,
        mt: 1,
      }}
    >
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {navigationPages.map(
              (item) =>
                (hasPrivilege(us.user?.privileges, item.userPrivilege) ||
                  item.userPrivilege === ALL ||
                  hasPrivilege(us.user?.privileges, ADMIN)) && (
                  <Tooltip title={item.label} key={item.id}>
                    <IconButton
                      color={"primary"}
                      onClick={() => handleMenuClick(item)}
                      sx={{
                        backgroundColor:
                          activeMenu === item.id ? "#7fb962" : "none",
                        color: activeMenu === item.id ? "#fff" : "#7fb962",
                        "&:hover": {
                          color: "#fff",
                          backgroundColor: "#7fb962",
                        },
                      }}
                    >
                      <item.Icon />
                    </IconButton>
                  </Tooltip>
                )
            )}
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {selectedRound &&
              (activeMenu === "1" || activeMenu === "rounds") && (
                <Box sx={{ width: "600px" }}>
                  <RoundSelector
                    rounds={rounds}
                    handleRoundSelectionChange={(round) => {
                      dispatch(setSelectedRound(round))
                    }}
                    selectedRound={selectedRound}
                  />
                </Box>
              )}

            {activeMenu === "serviceWorker" && (
              <Box>
                <OrderIdSearch size="small" />
              </Box>
            )}
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", minHeight: 48 }}>
          {navigationPages.map((item) =>
            item.id === activeMenu && item.children
              ? item.children.map(
                  (subItem) =>
                    (hasPrivilege(us.user?.privileges, subItem.userPrivilege) ||
                      subItem.userPrivilege === ALL ||
                      hasPrivilege(us.user?.privileges, ADMIN)) && (
                      <Button
                        key={subItem.id}
                        onClick={() => handleSubMenuClick(subItem)}
                        startIcon={<subItem.Icon />}
                      >
                        <Typography
                          variant="button"
                          sx={{
                            borderBottom:
                              activeSubMenu === subItem.id
                                ? "2px solid"
                                : "none",
                          }}
                        >
                          {subItem.label}
                        </Typography>
                      </Button>
                    )
                )
              : null
          )}
          {!activeMenu && <Box sx={{ minHeight: 48, width: "100%" }}></Box>}
        </Box>
      </Box>
    </Box>
  )
}
