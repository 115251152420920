import { useEffect, useState } from "react"
import Grid from "@mui/material/Grid"
import LogisticStatBox from "components/LogisticStatsBox/LogisticStatsBox"
import { fetchRoundLogisticStat } from "queries/fetchRoundLogisticStat"
import { fetchRoundSuppliers } from "queries/fetchRoundSuppliers"
import { fetchRoundStats } from "queries/fetchRoundStats"
import { LogisticStat, RoundStat, RoundSupplier } from "generated/graphql"
import RoundStatBox from "components/RoundStatBox/RoundStatBox"
import Container from "@mui/material/Container"
import DialogRoundSupplier from "components/DialogRoundSupplier/DialogRoundSupplier"
import DialogRoundStateChange from "components/DialogRoundStateChange/DialogRoundChange"
import { useMediaQuery, Skeleton, useTheme } from "@mui/material"

export interface RoundSupplierDialogData {
  isOpen: boolean
  isLoading: boolean
  roundID: number
  suppliers: RoundSupplier[]
}

const RoundsStatisticPage = () => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"))
  const [loading, setloading] = useState(true)
  const [roundSupplierDialogData, setRoundSupplierDialogData] =
    useState<RoundSupplierDialogData>({
      isOpen: false,
      isLoading: false,
      roundID: 0,
      suppliers: [],
    })
  const [isRoundChangeDialogOpen, setIsRoundChangeDialogOpen] = useState(false)
  const [roundStat, setRoundStat] = useState<RoundStat[]>([])
  const [logisticStat, setLogisticStat] = useState<LogisticStat>({
    distributed: 0,
    tagged: 0,
    packagesSent: 0,
  })
  const [roundStatList, setRoundStatList] = useState<string[]>([])

  useEffect(() => {
    fetchRoundLogisticStat({ rounds: [] }).then((data) => {
      setloading(false)
      if (data) {
        setRoundStat(data.roundStats)
        setLogisticStat(data.logisticStat)
      }
    })
  }, [])

  const onLoadRoundStat = () => {
    fetchRoundStats({ rounds: roundStatList }).then((data) => {
      setIsRoundChangeDialogOpen(false)
      if (data) {
        setRoundStat(data.roundStats)
      }
    })
  }

  const handleOpenSupplierDialog = (roundID: number) => {
    setRoundSupplierDialogData({
      isOpen: true,
      isLoading: true,
      roundID,
      suppliers: [],
    })
    fetchRoundSuppliers(roundID).then((data) => {
      let roundSuppliers: RoundSupplier[] = []
      if (data) {
        roundSuppliers = data.roundSuppliers
      }
      setRoundSupplierDialogData({
        isOpen: true,
        isLoading: false,
        roundID,
        suppliers: roundSuppliers,
      })
    })
  }

  const handleOpenRoundChangeDialog = () => {
    setRoundStatList(roundStat.map((round) => round.roundID.toString()))
    setIsRoundChangeDialogOpen(true)
  }

  const handleCloseRoundSupplierDialog = () => {
    setRoundSupplierDialogData({
      ...roundSupplierDialogData,
      isOpen: false,
    })
  }

  return (
    <Container maxWidth="xl">
      {loading && (
        <>
          <Grid container justifyContent={"center"}>
            <Grid item xs={12}>
              <Skeleton
                variant="rectangular"
                height={isSmallScreen ? "270px" : "100px"}
                sx={{
                  borderRadius: "10px",
                }}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2} marginTop={4}>
            <Grid item xs={12} md={6} lg={3}>
              <Skeleton
                variant="rectangular"
                height={"600px"}
                sx={{
                  borderRadius: "10px",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Skeleton
                variant="rectangular"
                height={"600px"}
                sx={{
                  borderRadius: "10px",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Skeleton
                variant="rectangular"
                height={"600px"}
                sx={{
                  borderRadius: "10px",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Skeleton
                variant="rectangular"
                height={"600px"}
                sx={{
                  borderRadius: "10px",
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
      {!loading && (
        <>
          <Grid container justifyContent={"center"}>
            <Grid item xs={12}>
              <LogisticStatBox
                distributed={logisticStat.distributed}
                tagged={logisticStat.tagged}
                packagesSent={logisticStat.packagesSent}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" marginTop={4}>
            {roundStat.map((round, roundIndex) => {
              return (
                <Grid
                  key={round.roundID.toString() + roundIndex.toString()}
                  item
                  xs={12}
                  md={6}
                  lg={3}
                >
                  <RoundStatBox
                    data={round}
                    onOpenChangeRoundDialog={handleOpenRoundChangeDialog}
                    onOpenSupplierDialog={handleOpenSupplierDialog}
                  />
                </Grid>
              )
            })}
          </Grid>
        </>
      )}
      <DialogRoundSupplier
        data={roundSupplierDialogData}
        onClose={handleCloseRoundSupplierDialog}
      />
      <DialogRoundStateChange
        isOpen={isRoundChangeDialogOpen}
        onClose={() => setIsRoundChangeDialogOpen(false)}
        roundStatList={roundStatList}
        setRoundStatList={setRoundStatList}
        onLoadRoundStat={onLoadRoundStat}
      />
    </Container>
  )
}

export default RoundsStatisticPage
