import { fetchGQL } from "app/api"
import {
  AskDanteUsersQuery,
  EditAskDanteUserTagsMutation,
  EditAskDanteUserTagsMutationVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"

const ASKDANTE_USER_GET = gql`
  query askDanteUsers {
    askDanteUsers {
      id
      firstName
      lastName
      personnelNumber
      email
      tags
    }
  }
`

export const fetchAskDanteUsers = async (): Promise<
  AskDanteUsersQuery | undefined
> => {
  const data = await fetchGQL<AskDanteUsersQuery, undefined>(
    ASKDANTE_USER_GET,
    undefined
  )
  return data
}

const ASKDANTE_USER_SAVE_TAGS = gql`
  mutation editAskDanteUserTags($userId: String!, $tags: [String!]!) {
    editAskDanteUserTags(userId: $userId, tags: $tags)
  }
`

export const saveAskDanteUserTags = async (
  userId: string,
  tags: string[]
): Promise<EditAskDanteUserTagsMutation | undefined> => {
  const vars: EditAskDanteUserTagsMutationVariables = {
    userId,
    tags,
  }
  const data = await fetchGQL<
    EditAskDanteUserTagsMutation,
    EditAskDanteUserTagsMutationVariables
  >(ASKDANTE_USER_SAVE_TAGS, vars)
  return data
}
