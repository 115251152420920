import React, { useEffect, useMemo, useRef, useState } from "react"
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material"
import { DataGridPro } from "@mui/x-data-grid-pro/DataGridPro"
import { GridColDef } from "@mui/x-data-grid-pro"
import { dataGridDEde } from "constants/dataGridLocale"
import { PlantOrderSum, PlantType, SortOrderBaseQuery } from "generated/graphql"
import { fetchPlantTypesAndSupplier } from "queries/fetchSortOrderBase"
import { fetchOrderAggregateByStatus } from "queries/fetchOrderWithStatus"
import { fetchSortOrder } from "queries/fetchSortOrder"
import { enqueueSnackbar } from "notistack"
import { getGrpName, grpList } from "pages/OrderList/OrderList"

const cols: GridColDef<PlantOrderSum>[] = [
  {
    field: "bdb",
    headerName: "BDB",
    width: 100,
    renderCell: (params) => {
      return (
        <Link href={`/#/editArticle/${params.row.bdb}`}>
          <Typography variant="body2" color="secondary" component="div">
            {params.row.bdb}
          </Typography>
        </Link>
      )
    },
  },
  {
    field: "botname",
    headerName: "Pflanze",
    width: 200,
  },
  {
    field: "quantity",
    headerName: "Anzahl",
    width: 100,
    type: "number",
  },
  {
    field: "supplier",
    headerName: "Lieferant",
    width: 150,
  },
  {
    field: "soldStatus",
    headerName: "Verfügbar",
    width: 75,
  },
]

const SortOrderPage: React.FC = () => {
  const statusRef = useRef<HTMLInputElement>(null)
  const [baseData, setBaseData] = useState<SortOrderBaseQuery | undefined>(
    undefined
  )
  const [allSupplierOptions, setAllSupplierOptions] = useState<any[]>([])
  const [allOrders, setAllOrders] = useState<PlantOrderSum[]>([])
  const [previewOrders, setPreviewOrders] = useState<PlantOrderSum[]>([])
  const [fetchStatus, setFetchStatus] = useState<string>("")
  const [loadingAllOrders, setLoadingAllOrders] = useState<boolean>(false)
  const [previewIsLoading, setPreviewIsLoading] = useState<boolean>(false)
  const [selectedGroups, setSelectedGroups] = useState<string[]>([])
  const [selectedPlantTypeIds, setSelectedPlantTypeIds] = useState<number[]>([])
  const [splitSoldOut, setSplitSoldOut] = useState<boolean>(true)
  const [onlySingle, setOnlySingle] = useState<boolean>(false)
  const [createNewRound, setCreateNewRound] = useState<boolean>(false)

  useEffect(() => {
    statusRef.current?.focus()
    fetchPlantTypesAndSupplier().then((data) => {
      setBaseData(data)
      if (data !== undefined) {
        const supOpts: any[] = []
        for (let i = 0; i < data.suppliers.length; i++) {
          supOpts.push({
            id: data.suppliers[i].id,
            label: data.suppliers[i].name,
            selected: false,
          })
        }
        setAllSupplierOptions(supOpts)
      }
    })
  }, [])

  const selectedSuppliers = useMemo(
    () => allSupplierOptions.filter((v) => v.selected),
    [allSupplierOptions]
  )

  const handleGroupChange = (newGroups: string[]) => {
    setSelectedGroups(newGroups)
    // find supplier with group name in selected groups
    const newSupplierIds: number[] = []
    if (newGroups.includes("Alle")) {
      const allSupplierOptionC = [...allSupplierOptions]
      for (let i = 0; i < allSupplierOptionC.length; i++) {
        allSupplierOptionC[i].selected = true
      }
      setAllSupplierOptions(allSupplierOptionC)
      return
    }
    if (baseData?.suppliers) {
      baseData.suppliers.forEach((supplier) => {
        const supGroups = getGrpName(false, supplier.G24, supplier.flavour)
        for (let i = 0; i < supGroups.length; i++) {
          for (let j = 0; j < newGroups.length; j++) {
            if (supGroups[i] === newGroups[j]) {
              newSupplierIds.push(supplier.id)
              break
            }
          }
        }
      })
      const allSupplierOptionC = [...allSupplierOptions]
      for (let i = 0; i < allSupplierOptionC.length; i++) {
        allSupplierOptionC[i].selected = newSupplierIds.includes(
          allSupplierOptionC[i].id
        )
      }
      setAllSupplierOptions(allSupplierOptionC)
    }
  }

  const handleSortOrder = () => {
    const selectedSupplierIds = allSupplierOptions
      .filter((v) => v.selected)
      .map((v) => v.id)
    setPreviewIsLoading(true)
    setLoadingAllOrders(true)
    fetchSortOrder(
      fetchStatus,
      "42",
      false,
      onlySingle,
      createNewRound,
      splitSoldOut,
      selectedPlantTypeIds,
      selectedSupplierIds
    )
      .then((data) => {
        if (data !== undefined) {
          if (data.sortOrder.message.length > 0) {
            enqueueSnackbar(data.sortOrder.message, { variant: "success" })
          }
        }
      })
      .finally(() => {
        setPreviewOrders([])
        setAllOrders([])
        setFetchStatus("")
        setPreviewIsLoading(false)
        setLoadingAllOrders(false)
        if (statusRef.current !== null) {
          statusRef.current.focus()
        }
      })
  }

  const loadPreview = () => {
    const selectedSupplierIds = allSupplierOptions
      .filter((v) => v.selected)
      .map((v) => v.id)
    setPreviewIsLoading(true)
    fetchSortOrder(
      fetchStatus,
      "",
      true,
      onlySingle,
      createNewRound,
      splitSoldOut,
      selectedPlantTypeIds,
      selectedSupplierIds
    )
      .then((data) => {
        if (data !== undefined) {
          if (data.sortOrder.preview.length === 0) {
            enqueueSnackbar("Keine Artikel gefunden", { variant: "info" })
          }
          setPreviewOrders(data.sortOrder.preview)
        }
      })
      .finally(() => {
        setPreviewIsLoading(false)
      })
  }

  const handleStatusRef = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      const val = statusRef.current?.value
      if (val !== undefined && val !== null && val !== "") {
        setPreviewOrders([])
        setLoadingAllOrders(true)
        fetchOrderAggregateByStatus(val)
          .then((data) => {
            if (data !== undefined) {
              setAllOrders(data.newOrdersByStatus.rows)
              setFetchStatus(val)
            }
          })
          .finally(() => {
            setLoadingAllOrders(false)
          })
      }
    }
  }
  const [template, setTemplate] = React.useState<number>(1)
  const handleChange = (event: SelectChangeEvent<number>) => {
    setTemplate(event.target.value as number)
  }

  return (
    <Container maxWidth="xl">
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <TextField
            label="Status"
            variant="outlined"
            onKeyUp={handleStatusRef}
            inputRef={statusRef}
          />
        </Grid>
        <Grid item xs={5}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Filter Template
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={template}
              label="Filter Template"
              onChange={handleChange}
            >
              <MenuItem value={1}>Beispiel 1</MenuItem>
              <MenuItem value={2}>Beispiel 2</MenuItem>
              <MenuItem value={3}>Beispiel 3</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            sx={{ height: "100%" }}
          >
            Filter Template speichern
          </Button>
        </Grid>
        <Grid item xs={3}>
          {baseData && (
            <Autocomplete
              disablePortal
              id="article-groups-autocomplete"
              multiple
              options={baseData.plantTypes.map((plantType: PlantType) => {
                return { id: plantType.id, label: plantType.name }
              })}
              fullWidth
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_, newValue) => {
                setSelectedPlantTypeIds(newValue.map((val) => val.id))
              }}
              renderInput={(params) => <TextField {...params} label="Typ" />}
            />
          )}
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            disablePortal
            id="lieferanten-groups-autocomplete"
            multiple
            value={selectedGroups}
            onChange={(_, newValue) => {
              handleGroupChange(newValue)
            }}
            options={grpList}
            isOptionEqualToValue={(option, value) => option === value}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} label="Lieferanten Gruppe" />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          {baseData && (
            <Autocomplete
              id="lieferanten-single-autocomplete"
              multiple
              fullWidth
              value={selectedSuppliers}
              options={allSupplierOptions}
              onChange={(_, newValue) => {
                setAllSupplierOptions(
                  allSupplierOptions.map((option) => {
                    return {
                      ...option,
                      selected: newValue.some((v) => v.id === option.id),
                    }
                  })
                )
              }}
              getOptionLabel={(option) => option.id + " - " + option.label}
              renderInput={(params) => (
                <TextField {...params} label="Lieferanten" />
              )}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <FormGroup sx={{ display: "inline" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={onlySingle}
                  onChange={(_, checked) => {
                    setOnlySingle(checked)
                  }}
                />
              }
              label="Nur Singles"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={splitSoldOut}
                  onChange={(_, checked) => {
                    setSplitSoldOut(checked)
                  }}
                />
              }
              label="Ausverkaufte Artikel aufsplitten"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={createNewRound}
                  onChange={(_, checked) => {
                    setCreateNewRound(checked)
                  }}
                />
              }
              label="Neue Runde miterstellen"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={3}>
          <Button
            fullWidth
            sx={{ height: "100%" }}
            variant="contained"
            color="primary"
            disabled={
              allOrders.length === 0 || previewIsLoading || loadingAllOrders
            }
            onClick={loadPreview}
          >
            Preview laden
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            fullWidth
            sx={{ height: "100%" }}
            variant="contained"
            color="primary"
            onClick={handleSortOrder}
            disabled={
              allOrders.length === 0 || previewIsLoading || loadingAllOrders
            }
          >
            Sortieren
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ height: "70vh", width: "100%" }}>
            <Typography variant="h6">
              Bestellungen mit Status: {fetchStatus}
            </Typography>
            <DataGridPro
              rows={allOrders}
              getRowId={(row) => row.bdb}
              columns={cols}
              localeText={dataGridDEde}
              loading={loadingAllOrders}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ height: "70vh", width: "100%" }}>
            <Typography variant="h6">Preview</Typography>
            <DataGridPro
              rows={previewOrders}
              loading={previewIsLoading}
              getRowId={(row) => row.bdb}
              columns={cols}
              localeText={dataGridDEde}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default SortOrderPage
