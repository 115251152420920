import { gql } from "graphql-tag"
import { fetchGQL } from "app/api"
import { DownloadPdfQuery, DownloadPdfQueryVariables } from "generated/graphql"

const GET_PDF_BASE64 = gql`
  query downloadPDF($url: String!, $carrier: String!) {
    downloadPdf(url: $url, carrier: $carrier)
  }
`

export const fetchPDFBase64 = async (
  url: string,
  carrier: string
): Promise<DownloadPdfQuery | undefined> => {
  const variables: DownloadPdfQueryVariables = { url, carrier }
  const data = await fetchGQL<DownloadPdfQuery, DownloadPdfQueryVariables>(
    GET_PDF_BASE64,
    variables
  )
  return data
}
