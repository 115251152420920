import { LoadingButton } from "@mui/lab"
import { DialogActions, DialogContent, Grid, TextField } from "@mui/material"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import Typography from "@mui/material/Typography"
import { DatePicker } from "@mui/x-date-pickers"
import { useAppDispatch } from "app/hooks"
import { setRounds, setSelectedRound } from "app/roundSlice"
import { qSnack } from "app/snackSlice"
import dayjs, { Dayjs } from "dayjs"
import { fetchRounds } from "queries/fetchRounds"
import { roundCreate } from "queries/roundCreate"
import { useEffect, useState } from "react"
var weekOfYear = require("dayjs/plugin/weekOfYear")
dayjs.extend(weekOfYear)

export interface RoundCreateDialogProps {
  open: boolean
  statusOrder: number
  nextRoundNumber: string | null
  onClose: () => void
}

const translateEnglishToGermanWeekday = (weekday: string) => {
  // weekday will come as "Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"
  // but we want "Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"

  switch (weekday) {
    case "Mo":
      return "Mo"
    case "Tu":
      return "Di"
    case "We":
      return "Mi"
    case "Th":
      return "Do"
    case "Fr":
      return "Fr"
    case "Sa":
      return "Sa"
    case "Su":
      return "So"
    default:
      return weekday
  }
}

const getCurrentCalendarWeek = (): string => {
  const d = new Date()
  d.setHours(0, 0, 0, 0)
  d.setDate(d.getDate() + 4 - (d.getDay() || 7))
  const yearStart = new Date(d.getFullYear(), 0, 1)
  const weekNo = Math.ceil(
    ((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7
  )
  return weekNo.toString()
}

const gridTileCss = {
  textAlign: "center",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
}

const RoundCreateDialog = (props: RoundCreateDialogProps) => {
  const { open, nextRoundNumber, onClose, statusOrder } = props
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs())
  const [roundNumber, setRoundNumber] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [endDate, setEndDate] = useState<Dayjs | null>(null)
  const [roundName, setRoundName] = useState(
    translateEnglishToGermanWeekday(dayjs().format("dd")) +
      "KW" +
      getCurrentCalendarWeek()
  )
  const dispatch = useAppDispatch()

  const updateRounds = () => {
    fetchRounds().then((roundsGetResponse) => {
      if (roundsGetResponse?.roundsGet) {
        if (roundsGetResponse.roundsGet.rounds.length > 0) {
          dispatch(setRounds(roundsGetResponse.roundsGet.rounds))
          dispatch(setSelectedRound(roundsGetResponse.roundsGet.rounds[0]))
        }
      }
    })
  }
  useEffect(() => {
    if (nextRoundNumber != null && open && roundNumber === null) {
      setRoundNumber(nextRoundNumber)
    }
  }, [nextRoundNumber, open, roundNumber])

  const roundCreateClick = () => {
    //fetchRoundCreate
    const startDateString = startDate?.format("YYYY-MM-DD")
    const endDateString = endDate?.format("YYYY-MM-DD")
    if (nextRoundNumber == null) {
      dispatch(
        qSnack({ msg: "Runden Nummer nicht gefunden", severity: "error" })
      )
      return
    }
    if (startDateString == null || endDateString == null) {
      dispatch(
        qSnack({ msg: "Runden Start und End Datum angeben", severity: "error" })
      )
      return
    }

    setIsLoading(true)
    const parsedrn = parseInt(roundNumber ?? "")
    if (isNaN(parsedrn)) {
      dispatch(
        qSnack({
          msg: "Runden Nummer konnte nicht in eine Zahl umgewandelt werden",
          severity: "error",
        })
      )
      return
    }
    roundCreate(
      parsedrn,
      roundName,
      startDateString,
      endDateString,
      statusOrder
    )
      .then((data) => {
        const msg = data?.roundCreate?.msg
        if (msg === "beep boop") {
          dispatch(
            qSnack({
              msg: "Runde erfolgreich angelegt",
              severity: "success",
            })
          )
        } else {
          dispatch(
            qSnack({
              msg: "Runde konnte nicht angelegt werden: " + msg,
              severity: "error",
            })
          )
        }
      })
      .finally(() => {
        updateRounds()
        onClose()
        setIsLoading(false)
      })
  }

  return (
    <Dialog open={open}>
      <DialogTitle variant="h4">
        Runde Nr. {nextRoundNumber ?? ""} anlegen
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={6} sx={{ marginTop: "1%" }}>
          <Grid item xs={12} md={6} sx={gridTileCss}>
            <Typography variant="h6" component="div">
              Rundennummer
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} sx={gridTileCss}>
            <TextField
              value={roundNumber}
              fullWidth
              onChange={(e) => {
                setRoundNumber(e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={gridTileCss}>
            <Typography variant="h6" component="div">
              Rundenname
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              value={roundName}
              fullWidth
              onChange={(e) => {
                setRoundName(e.target.value)
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} sx={gridTileCss}>
            <Typography variant="h6" component="div">
              Startdatum
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <DatePicker
              value={startDate}
              disablePast
              onChange={(newValue) => {
                setStartDate(newValue)
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} sx={gridTileCss}>
            <Typography variant="h6" component="div">
              Enddatum
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <DatePicker
              value={endDate}
              disablePast
              onChange={(newValue) => {
                setEndDate(newValue)
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="error" fullWidth onClick={onClose}>
          Abbrechen
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          fullWidth
          loading={isLoading}
          onClick={roundCreateClick}
        >
          Runde Anlegen
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default RoundCreateDialog
