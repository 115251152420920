import { gql } from "graphql-tag"
import { fetchGQL } from "app/api"
import { SalesStatsQuery, SalesStatsQueryVariables } from "generated/graphql"

const SALE_STATS_GET = gql`
  query salesStats($from: String!, $to: String!) {
    salesStats(from: $from, to: $to) {
      botname
      gername
      from
      to
      pflNR
      artnum
      count
      price
    }
  }
`

export const fetchSaleStats = async (
  from: string,
  to: string
): Promise<SalesStatsQuery | undefined> => {
  const variables: SalesStatsQueryVariables = { from, to }
  const data = await fetchGQL<SalesStatsQuery, SalesStatsQueryVariables>(
    SALE_STATS_GET,
    variables
  )
  return data
}
