import { fetchGQL } from "app/api"
import {
  PrintEtikettenQuery,
  PrintEtikettenQueryVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"

const ETIKETTEN_PRINT = gql`
  query printEtiketten(
    $round: Int!
    $supplierIds: [Int!]!
    $printerIpPort: String!
    $isSteck: Boolean!
    $selectedEwIds: [Int!]!
  ) {
    printEtiketten(
      round: $round
      supplierIDs: $supplierIds
      printerIpPort: $printerIpPort
      isSteck: $isSteck
      selectedEwIds: $selectedEwIds
    )
  }
`

export const etikettenPrint = async (
  round: number,
  supplierIds: number[],
  printerIpPort: string,
  isSteck: boolean,
  selectedEwIds: number[]
): Promise<PrintEtikettenQuery | undefined> => {
  const variables: PrintEtikettenQueryVariables = {
    round,
    supplierIds,
    printerIpPort,
    isSteck,
    selectedEwIds,
  }
  const data = await fetchGQL<
    PrintEtikettenQuery,
    PrintEtikettenQueryVariables
  >(ETIKETTEN_PRINT, variables)
  return data
}
