import {
  BoxStatisticsQuery,
  BoxStatisticsQueryVariables,
} from "./../generated/graphql"
import { gql } from "graphql-tag"
import { fetchGQL } from "app/api"

const GET_BOX_STATISTICS = gql`
  query boxStatistics(
    $start: String!
    $end: String!
    $carrier: String!
    $orderId: String!
  ) {
    boxStatistics(
      start: $start
      end: $end
      carrier: $carrier
      orderId: $orderId
    ) {
      carton {
        id
        barcode
        description
        lengthMM
        widthMM
        heightMM
      }
      count
      countPercent
    }
  }
`

export const boxStatisticGet = async (
  start: string,
  end: string,
  carrier: string,
  orderId: string
): Promise<BoxStatisticsQuery | undefined> => {
  const variables: BoxStatisticsQueryVariables = {
    start,
    end,
    carrier,
    orderId,
  }
  const data = await fetchGQL<BoxStatisticsQuery, BoxStatisticsQueryVariables>(
    GET_BOX_STATISTICS,
    variables
  )
  return data
}
