import { fetchGQL } from "app/api"
import {
  CustomerDetailsSaveMutation,
  CustomerDetailsSaveMutationVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"

const CUSTOMER_DETAILS_SAVE = gql`
  mutation customerDetailsSave($in: CustomerDetailInput!) {
    customerDetailsSave(in: $in) {
      id
    }
  }
`

export const customerDetailsSave = async (
  vars: CustomerDetailsSaveMutationVariables
): Promise<CustomerDetailsSaveMutation | undefined> => {
  const data = await fetchGQL<
    CustomerDetailsSaveMutation,
    CustomerDetailsSaveMutationVariables
  >(CUSTOMER_DETAILS_SAVE, vars)
  return data
}
