import { fetchGQL } from "app/api"
import {
  RoundItemsSplitMutation,
  RoundItemsSplitMutationVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"

const ROUND_ITEMS_SPLIT = gql`
  mutation roundItemsSplit($in: RoundItemsSplitInput!) {
    roundItemsSplit(in: $in) {
      message
    }
  }
`

export const roundItemsSplit = async (
  vars: RoundItemsSplitMutationVariables
): Promise<RoundItemsSplitMutation | undefined> => {
  const data = await fetchGQL<
    RoundItemsSplitMutation,
    RoundItemsSplitMutationVariables
  >(ROUND_ITEMS_SPLIT, vars)
  return data
}
