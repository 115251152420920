import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { Collapse, IconButton, Typography } from "@mui/material"
import { useState } from "react"

type CollapsibleTableProps = {
  defaultOpen: boolean
  title: string
  children: React.ReactNode
}

export const CollapsibleTable = (props: CollapsibleTableProps) => {
  const { defaultOpen, title, children } = { ...props }
  const [open, setOpen] = useState(defaultOpen)

  return (
    <>
      <Typography
        variant="h6"
        component="div"
        gutterBottom
        sx={{
          cursor: "pointer",
        }}
        onClick={() => setOpen(!open)}
      >
        {title}
        <IconButton onClick={() => setOpen(!open)}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Typography>
      <Collapse in={open}>{children}</Collapse>
    </>
  )
}
