import {
  BoxUpdateMutation,
  BoxUpdateMutationVariables,
  CartonInput,
} from "../generated/graphql"
import { gql } from "graphql-tag"
import { fetchGQL } from "app/api"

const UPDATE_BOX = gql`
  mutation boxUpdate($in: CartonInput!) {
    boxUpdate(in: $in) {
      id
      barcode
      description
      lengthMM
      widthMM
      heightMM
    }
  }
`

export const boxUpdate = async (
  input: CartonInput
): Promise<BoxUpdateMutation | undefined> => {
  const variables: BoxUpdateMutationVariables = {
    in: input,
  }
  const data = await fetchGQL<BoxUpdateMutation, BoxUpdateMutationVariables>(
    UPDATE_BOX,
    variables
  )
  return data
}
