import {
  BoxCreateMutation,
  BoxCreateMutationVariables,
  CartonInput,
} from "../generated/graphql"
import { gql } from "graphql-tag"
import { fetchGQL } from "app/api"

const CREATE_BOX = gql`
  mutation boxCreate($in: CartonInput!) {
    boxCreate(in: $in) {
      id
      barcode
      description
      lengthMM
      widthMM
      heightMM
    }
  }
`

export const boxCreate = async (
  input: CartonInput
): Promise<BoxCreateMutation | undefined> => {
  const variables: BoxCreateMutationVariables = {
    in: input,
  }
  const data = await fetchGQL<BoxCreateMutation, BoxCreateMutationVariables>(
    CREATE_BOX,
    variables
  )
  return data
}
