import { Typography } from "@mui/material"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import { LogisticStat } from "generated/graphql"

const LogisticStatBox = (props: LogisticStat): JSX.Element => {
  return (
    <Box
      sx={{
        color: "primary.contrastText",
        borderRadius: "10px",
        padding: "20px",
        margin: "4px",
        bgcolor: "primary.main",
        boxShadow: "2px 2px 8px 0 rgba(0,0,0,0.2)",
      }}
    >
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        direction={"row"}
      >
        <Grid item xs={12} md={3}>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h5">Logistik</Typography>
            <Typography variant="caption" marginLeft={"5px"}>
              Heute
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={3}>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="subtitle1">Ettikettiert</Typography>
            <Typography variant="subtitle1">{props.tagged}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="subtitle1">Verteilt</Typography>
            <Typography variant="subtitle1">{props.distributed}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="subtitle1">Pakete</Typography>
            <Typography variant="subtitle1">{props.packagesSent}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default LogisticStatBox
