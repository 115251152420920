import React from "react"
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material"

export interface Hint {
  hintID: string
  hint: string
  hintLabel: string
  hasAdditionalInfo: boolean
  additionalInfo: string
}

export interface HintSelectorProps {
  hint: Hint | null
  label: string
  hintIndex: number
  hintOptions: Hint[]
  setSelectedHint: (hint: Hint | null, index: number) => void
}

const HintSelector = (props: HintSelectorProps): JSX.Element => {
  const { hint, setSelectedHint, label, hintIndex, hintOptions } = props

  const handleHintChange = (event: SelectChangeEvent<string>) => {
    if (event.target.value === "DEL") {
      setSelectedHint(null, hintIndex)
      return
    }
    const hintItem = hintOptions.find(
      (item) => item.hintID === event.target.value
    )
    if (hintItem) setSelectedHint(hintItem, hintIndex)
  }

  const itemHasAdditionalInfo = (): boolean | undefined => {
    return hintOptions.find((item) => {
      if (hint) {
        return item.hintID === hint.hintID
      } else {
        return false
      }
    })?.hasAdditionalInfo
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl sx={{ width: "100%" }} variant="outlined">
          <InputLabel id={label}>{label}</InputLabel>
          <Select
            labelId={label}
            id="ticket-category"
            label={label}
            value={hint ? hint.hintID : ""}
            disabled={hintIndex === 0}
            onChange={handleHintChange}
            fullWidth
          >
            {hintOptions.map((item, index) => {
              return (
                <MenuItem
                  key={item.hintID}
                  value={item.hintID}
                  disabled={index === 0}
                >
                  {item.hint}
                </MenuItem>
              )
            })}
            <MenuItem value="DEL">Entfernen</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {itemHasAdditionalInfo() && (
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Zusatz"
            value={hint?.additionalInfo}
            onChange={(e) => {
              if (hint) {
                setSelectedHint(
                  { ...hint, additionalInfo: e.target.value },
                  hintIndex
                )
              }
            }}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default HintSelector
