import { LoadingButton } from "@mui/lab"
import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro"
import { dataGridDEde } from "constants/dataGridLocale"
import {
  ArticleSupplierCrossRef,
  RoundOrderArticle,
  SupplierOrderItem,
} from "generated/graphql"
import { useState } from "react"

export interface RoundOrderMoveItemDialogProps {
  open: boolean
  onClose: () => void
  supplierName: string
  selection: RoundOrderArticle[]
  supplierOrderInfo: SupplierOrderItem[]
  supplierItemCrossRefs: ArticleSupplierCrossRef[]
  onMove: (data: EditSup[], callback: () => void) => void
  isLoading: boolean
}

export type EditSup = {
  roundlistId: number
  newSupplierId: number
  quantity: number
}

export const RoundOrderMoveItemDialog = (
  props: RoundOrderMoveItemDialogProps
): JSX.Element => {
  const {
    open,
    onClose,
    selection,
    supplierOrderInfo,
    supplierItemCrossRefs,
    supplierName,
    onMove,
    isLoading,
  } = props
  const [editData, setEditData] = useState<EditSup[]>([])

  const handleEditDataSave = (newEntry: EditSup): void => {
    const c = [...editData]
    // check if entry already exists
    let found = false
    for (let i = 0; i < c.length; i++) {
      if (c[i].roundlistId === newEntry.roundlistId) {
        c[i].newSupplierId = newEntry.newSupplierId
        c[i].quantity = newEntry.quantity
        found = true
        break
      }
    }
    if (!found) {
      c.push(newEntry)
    }
    setEditData(c)
  }

  const handleAllSameSupplier = (supplierID: number): void => {
    const c: EditSup[] = []
    const selectedData = filterSelctedData(supplierOrderInfo)
    for (let i = 0; i < selectedData.length; i++) {
      c.push({
        roundlistId: selectedData[i].id,
        newSupplierId: supplierID,
        quantity: selectedData[i].quantity,
      })
    }
    setEditData(c)
  }

  const getEditData = (roundlistId: number, defaultValue: EditSup): EditSup => {
    for (let i = 0; i < editData.length; i++) {
      if (editData[i].roundlistId === roundlistId) {
        return editData[i]
      }
    }
    return defaultValue
  }

  const filterSelctedData = (
    data: SupplierOrderItem[]
  ): SupplierOrderItem[] => {
    const l: SupplierOrderItem[] = []
    for (let i = 0; i < selection.length; i++) {
      for (let j = 0; j < data.length; j++) {
        if (selection[i].articleId === data[j].bdb) {
          l.push(data[j])
        }
      }
    }
    return l
  }

  const cols: GridColDef<SupplierOrderItem>[] = [
    { field: "id", headerName: "Lieferant Bestellnr.", width: 150 },
    { field: "orderID", headerName: "Kunde Bestellnr.", width: 150 },
    { field: "ewID", headerName: "EwID", width: 100 },
    { field: "bdb", headerName: "BDB", width: 120 },
    {
      field: "botname",
      headerName: "Artikel",
      renderCell: (params) => {
        return (
          <Box>
            <Typography variant="body1" component="div">
              {params.row.botname}
            </Typography>
            <Typography variant="body2" color="text.secondary" component="div">
              {params.row.sorte} - {params.row.size}
            </Typography>
          </Box>
        )
      },
      width: 300,
    },
    { field: "quantity", headerName: "Anzahl", width: 100 },
    {
      field: "Verschieben zu Lieferant",
      headerName: "Verschieben zu Lieferant",
      width: 450,
      renderCell: (params) => {
        return (
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <TextField
                value={
                  getEditData(params.row.id, {
                    roundlistId: params.row.id,
                    newSupplierId: params.row.supplierID,
                    quantity: params.row.quantity,
                  }).quantity
                }
                onChange={(e) => {
                  handleEditDataSave({
                    roundlistId: params.row.id,
                    newSupplierId: params.row.supplierID,
                    quantity: Number(e.target.value),
                  })
                }}
                type="number"
                placeholder="Anzahl verschieben"
              />
            </Grid>
            <Grid item xs={9}>
              <Autocomplete
                disablePortal
                isOptionEqualToValue={(option, value) => {
                  return option.supplierID === value.supplierID
                }}
                onChange={(_, newValue) => {
                  handleEditDataSave({
                    roundlistId: params.row.id,
                    newSupplierId: newValue!.supplierID,
                    quantity: getEditData(params.row.id, {
                      roundlistId: params.row.id,
                      newSupplierId: newValue!.supplierID,
                      quantity: params.row.quantity,
                    }).quantity,
                  })
                }}
                value={supplierItemCrossRefs.find(
                  (x) =>
                    x.supplierID ===
                    getEditData(params.row.id, {
                      roundlistId: params.row.id,
                      newSupplierId: params.row.supplierID,
                      quantity: params.row.quantity,
                    }).newSupplierId
                )}
                getOptionLabel={(o) => o.supplierID + " - " + o.supplierName}
                options={supplierItemCrossRefs.filter((x) => {
                  return x.articleID.toString() === params.row.bdb
                })}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Lieferant" />
                )}
              />
            </Grid>
          </Grid>
        )
      },
    },
  ]

  const handleMove = (): void => {
    onMove(editData, () => setEditData([]))
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl">
      <DialogTitle>Artikel-verschieben von {supplierName}</DialogTitle>
      <DialogContent sx={{ height: "70vh", width: "100%" }}>
        <Box sx={{ height: "60vh", width: "100%" }}>
          <DataGridPro
            rowHeight={80}
            rows={filterSelctedData(supplierOrderInfo)}
            columns={cols}
            disableRowSelectionOnClick
            localeText={dataGridDEde}
            loading={isLoading}
          />
        </Box>

        <Box sx={{ height: "10vh", width: "100%", paddingTop: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Lieferant</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={undefined}
              label="Lieferant auswählen"
              onChange={(e) => {
                if (e.target.value === undefined) {
                  return
                }
                const supplierID = Number(e.target.value)
                if (supplierID) {
                  handleAllSameSupplier(supplierID)
                }
              }}
            >
              <MenuItem value={undefined}></MenuItem>
              {supplierItemCrossRefs
                .filter(
                  (x) => x.articleID.toString() === selection[0]?.articleId
                )
                .map((x) => {
                  return (
                    <MenuItem key={x.supplierID} value={x.supplierID}>
                      {x.supplierID} - {x.supplierName}
                    </MenuItem>
                  )
                })}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setEditData([])
            onClose()
          }}
          fullWidth
          variant="contained"
          color="secondary"
        >
          Abbrechen
        </Button>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          fullWidth
          onClick={handleMove}
          color="primary"
        >
          Verschieben
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
