import { fetchGQL } from "app/api"
import {
  TimelineInsertMutation,
  TimelineInsertMutationVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"

//CREATE REFUND
const TIMELINE_INSERT = gql`
  mutation timelineInsert($in: TimelineInput!) {
    timelineInsert(in: $in) {
      id
      zeitpunkt
      action
      username
      computername
      orderID
      flavour
    }
  }
`

export const timelineInsert = async (
  vars: TimelineInsertMutationVariables
): Promise<TimelineInsertMutation | undefined> => {
  const data = await fetchGQL<
    TimelineInsertMutation,
    TimelineInsertMutationVariables
  >(TIMELINE_INSERT, vars)
  return data
}
