import logo from "assets/logo.svg"

const PmLogo = () => {
  return (
    <div className="pm-logo">
      <img src={logo} alt="logo" />
    </div>
  )
}

export default PmLogo
