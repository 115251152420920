import { IconButton, InputBase } from "@mui/material"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import SendIcon from "@mui/icons-material/Send"
import Typography from "@mui/material/Typography"

import { Timeline } from "generated/graphql"

import { formatDate } from "utils/formatDate"
import { useState } from "react"
import { timelineInsert } from "queries/timelineInsert"

type OrderTimelineProps = {
  timeline: Timeline[]
  setTimeline: (timeline: Timeline[]) => void
  orderId: number
}

const sortTimeLineByDateDesc = (timeline: Timeline[]): Timeline[] => {
  return timeline.sort((a, b) => {
    return new Date(b.zeitpunkt).getTime() - new Date(a.zeitpunkt).getTime()
  })
}

export const OrderTimeline = (props: OrderTimelineProps) => {
  const { timeline, orderId, setTimeline } = { ...props }
  const [comment, setComment] = useState("")

  const handleSubmit = async () => {
    if (comment) {
      const res = await timelineInsert({
        in: {
          action: comment,
          orderId: orderId,
        },
      })
      if (res?.timelineInsert) {
        setTimeline([res.timelineInsert, ...timeline])
      }
    }
  }

  return (
    <Box
      sx={{
        borderLeft: "2px solid",
        borderColor: "divider",
        pl: 1,
        height: "100%",
      }}
    >
      <Typography variant="h6">Timeline</Typography>

      <Box
        component="form"
        sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}
        onSubmit={(e) => e.preventDefault()}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          multiline
          placeholder="Kommentar"
          value={comment}
          onChange={(event) => setComment(event.target.value)}
        />
        <IconButton type="button" onClick={handleSubmit}>
          <SendIcon />
        </IconButton>
      </Box>

      <Divider />

      {sortTimeLineByDateDesc(timeline).map((item) => (
        <Box key={item.id}>
          <Box sx={{ m: 1 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="caption">
                {formatDate(item.zeitpunkt)}
              </Typography>
              <Typography variant="caption">{item.username}</Typography>
            </Box>
            <Typography variant="body2">{item.action}</Typography>
          </Box>
          <Divider />
        </Box>
      ))}
    </Box>
  )
}
