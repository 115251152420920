import { fetchGQL } from "app/api"
import {
  OrderListGetPrintItemsQuery,
  OrderListGetPrintItemsQueryVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"

const ORDER_LIST_GET_PRINT_ITEMS = gql`
  query orderListGetPrintItems($round: Int!) {
    orderListGetPrintItems(round: $round) {
      supplierID
      supplierName
      emailSentAt
      faxSentAt
    }
  }
`

export const orderListGetPrintItems = async (
  round: number
): Promise<OrderListGetPrintItemsQuery | undefined> => {
  const variables: OrderListGetPrintItemsQueryVariables = { round }
  const data = await fetchGQL<
    OrderListGetPrintItemsQuery,
    OrderListGetPrintItemsQueryVariables
  >(ORDER_LIST_GET_PRINT_ITEMS, variables)
  return data
}
