import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  TextField,
  Tooltip,
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import AddIcon from "@mui/icons-material/Add"

import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridRowSelectionModel,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro"
import { dataGridDEde } from "constants/dataGridLocale"
import {
  couponsByKey,
  couponCreate,
  couponDelete,
  couponByActiveDate,
  couponUpdate,
} from "queries/coupons"
import React, { useCallback, useEffect, useState } from "react"
import { Coupon, CouponUpdateInput } from "generated/graphql"
import { DeleteDialog } from "pages/CustomerEditPage/DeleteDialog"
import CouponAddDialog from "./CouponAddDialog"
import { datePickerLocaleDEde } from "constants/datePickerLocale"
import dayjs from "dayjs"
import { DatePicker } from "@mui/x-date-pickers"
import { qSnack } from "app/snackSlice"
import { useAppDispatch } from "app/hooks"

interface EditToolbarProps {
  selectedRows: []
  deleteClick: () => void
  addClick: () => void
  titel: string
}

export const CouponListPage = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const [couponData, setCouponData] = useState<Coupon[]>([])
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([])
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [openAddDialog, setOpenAddDialog] = useState(false)
  const [couponToUpdate, setCouponToUpdate] = useState<CouponUpdateInput>()
  const [searchDate, setSearchDate] = useState<string>(
    dayjs().format("YYYY-MM-DD")
  )
  const [searchKey, setSearchKey] = useState<string>("")
  const [debouncedSearchKey, setDebouncedSearchKey] = useState<string>("")
  const [hideSOV, setHideSOV] = useState<boolean>(true)
  const [searchField, setSearchField] = useState<string>("date")

  const fetchCouponData = useCallback(async () => {
    const updateCouponData = (couponData: Coupon[]) => {
      if (couponData.length >= 100) {
        dispatch(
          qSnack({
            msg: "Zu viele passende Coupons. Es werden nur die jungsten 100 angezeigt",
            severity: "info",
          })
        )
      }
      setCouponData(couponData)
      setLoading(false)
    }

    setLoading(true)
    switch (searchField) {
      case "key":
        if (debouncedSearchKey) {
          couponsByKey({
            key: debouncedSearchKey,
            excludeSov: hideSOV,
          }).then((res) => {
            if (res) {
              updateCouponData(res.couponsByKey)
            }
          })
        } else {
          setLoading(false)
        }
        break
      case "date":
        if (searchDate) {
          couponByActiveDate({
            activeDate: searchDate,
            excludeSov: hideSOV,
          }).then((res) => {
            if (res) {
              updateCouponData(res.couponsByActiveDate)
            }
          })
        }
        break
    }
  }, [searchDate, debouncedSearchKey, searchField, hideSOV, dispatch])

  useEffect(() => {
    fetchCouponData()
  }, [fetchCouponData])

  const handleDeleteCouponClick = (id: GridRowId) => () => {
    setSelectedRows([id])
    setOpenDeleteDialog(true)
  }

  const handleDeleteCouponsClick = () => {
    setOpenDeleteDialog(true)
  }

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedSearchKey(searchKey)
      setSearchField("key")
    }, 300)
    return () => clearTimeout(delayInputTimeoutId)
  }, [searchKey])

  const handleSOVChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHideSOV(e.target.checked)
  }

  const handleDeleteClose = () => {
    setOpenDeleteDialog(false)
    setSelectedRows([])
  }

  const deleteCoupons = () => {
    couponDelete({ id: selectedRows.map((r) => parseInt(r.toString())) })
    setCouponData((item) =>
      item.filter((item) => !selectedRows.includes(item.id))
    )
    setOpenDeleteDialog(false)
    setSelectedRows([])
  }

  const handleEditClick = (id: GridRowId) => () => {
    const c = couponData.find((item) => item.id === id)
    if (c) {
      // doing copy because unwanted fields were not removed by cast
      const cu: CouponUpdateInput = {
        article_id: c.article_id,
        categoryIds: c.categoryIds,
        combinable: c.combinable,
        description: c.description,
        discount: c.discount,
        id: c.id,
        key: c.key,
        max_uses: c.max_uses,
        min_sum: c.min_sum,
        no_delivery_costs: c.no_delivery_costs,
        note_intern: c.note_intern,
        product_id: c.product_id,
        sale_allowed: c.sale_allowed,
        title: c.title,
        valid_from: c.valid_from,
        valid_until: c.valid_until,
        value: c.value,
      }
      setCouponToUpdate(cu)
      setOpenAddDialog(true)
    } else {
      setOpenAddDialog(false)
    }
  }

  const handleAddClick = () => {
    setOpenAddDialog(true)
  }

  const closeAddDialog = () => {
    setOpenAddDialog(false)
    setCouponToUpdate(undefined)
  }

  const addUpdateCoupon = (coupon: CouponUpdateInput) => {
    if (coupon.id > 0) {
      couponUpdate(coupon)
    } else {
      const { id, ...rest } = coupon
      couponCreate(rest)
    }
    setOpenAddDialog(false)
  }

  const columnCoupon: GridColDef[] = [
    {
      field: "key",
      headerName: "Key",
    },
    {
      field: "title",
      headerName: "Titel",
    },
    { field: "description", headerName: "Beschreibung" },
    {
      field: "value",
      headerName: "Wert",
      type: "number",
      valueGetter: (params) =>
        params.row.value.toLocaleString("de-DE", {
          style: "currency",
          currency: "EUR",
        }),
    },
    {
      field: "discount",
      headerName: "Rabatt",
      type: "number",
      valueGetter: (params) => params.row.discount + " %",
    },
    {
      field: "min_sum",
      headerName: "Mindestbestellwert",
      type: "number",
      valueGetter: (params) =>
        params.row.min_sum.toLocaleString("de-DE", {
          style: "currency",
          currency: "EUR",
        }),
    },
    {
      field: "sale_allowed",
      headerName: "Sale",
      type: "boolean",
    },
    { field: "combinable", headerName: "kombinierbar", type: "boolean" },
    {
      field: "no_delivery_costs",
      headerName: "Keine Lieferkosten",
      type: "boolean",
    },
    {
      field: "max_uses",
      headerName: "Maximale Nutzungen",
      type: "number",
    },
    {
      field: "valid_from",
      headerName: "gültig ab",
      width: 100,
      valueFormatter: (params) => {
        if (params.value !== "") {
          return dayjs(params.value).format("DD.MM.YYYY")
        }
      },
    },
    {
      field: "valid_until",
      headerName: "gültig bis",
      width: 100,
      valueFormatter: (params) => {
        if (params.value !== "") {
          return dayjs(params.value).format("DD.MM.YYYY")
        }
      },
    },
    {
      field: "article_id",
      headerName: "Artikel ID",
      type: "number",
      width: 100,
    },
    {
      field: "product_id",
      headerName: "Produkt ID",
      type: "number",
      width: 100,
    },
    {
      field: "categoryIds",
      headerName: "Kategorie IDs",
      type: "number",
      width: 100,
    },
    {
      field: "note_intern",
      headerName: "Interner Hinweis",
    },
    {
      field: "actions",
      type: "actions",
      width: 135,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        return [
          <Tooltip title="Bearbeiten">
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              disabled={!row.editable}
              onClick={handleEditClick(id)}
            />
          </Tooltip>,
          <Tooltip title="Löschen">
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              disabled={!row.editable}
              onClick={handleDeleteCouponClick(id)}
            />
          </Tooltip>,
        ]
      },
    },
  ]

  const CustomToolbar = (props: EditToolbarProps) => {
    return (
      <GridToolbarContainer
        sx={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Button startIcon={<AddIcon />} onClick={props.addClick}>
          {props.titel} hinzufügen
        </Button>

        <Button
          disabled={props.selectedRows.length <= 0}
          startIcon={<DeleteIcon />}
          onClick={props.deleteClick}
        >
          Löschen
        </Button>
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    )
  }

  return (
    <Container maxWidth={false}>
      <Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "right",
            alignItems: "right",
            flex: 1,
            marginBottom: 10,
          }}
          gap={5}
        >
          <TextField
            autoFocus
            label="key"
            value={searchKey}
            onChange={(e) => {
              setSearchKey(e.target.value)
            }}
          ></TextField>
          <DatePicker
            label="gültig am"
            value={dayjs(searchDate)}
            onChange={(e) => {
              setSearchDate(
                e ? dayjs(e).format("YYYY-MM-DD") : dayjs().format("YYYY-MM-DD")
              )
              setSearchField("date")
            }}
            localeText={datePickerLocaleDEde}
          />
          <FormControlLabel
            control={<Checkbox checked={hideSOV} onChange={handleSOVChange} />}
            label="SOV Ausblenden"
          />
        </Box>
        <Box sx={{ height: "80vh", width: "100%" }}>
          <DataGridPro
            rows={couponData}
            getRowHeight={() => "auto"}
            loading={loading}
            columns={columnCoupon}
            disableRowSelectionOnClick
            localeText={dataGridDEde}
            getRowId={(row) => row.id}
            slots={{
              toolbar: CustomToolbar,
            }}
            slotProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                selectedRows,
                deleteClick: handleDeleteCouponsClick,
                addClick: handleAddClick,
                title: "Gutscheine",
              },
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 50,
                },
              },
            }}
            pageSizeOptions={[
              { value: couponData.length, label: "alle" },
              25,
              50,
              100,
            ]}
            isRowSelectable={(params) => params.row.editable}
            checkboxSelection
            onRowSelectionModelChange={(value) => {
              setSelectedRows(value)
            }}
            rowSelectionModel={selectedRows}
          />
        </Box>
      </Box>

      <DeleteDialog
        open={openDeleteDialog}
        onClose={handleDeleteClose}
        onDelete={deleteCoupons}
      >
        Soll der Gutschein gelöscht werden?
      </DeleteDialog>

      <CouponAddDialog
        open={openAddDialog}
        onClose={closeAddDialog}
        onSubmit={addUpdateCoupon}
        coupon={couponToUpdate}
        originalKey={couponToUpdate?.key ?? ""}
      />
    </Container>
  )
}
