import { gql } from "graphql-tag"
import { fetchGQL } from "app/api"
import {
  CreateRsShipmentMutation,
  CreateRsShipmentMutationVariables,
  RsShipmentInput,
} from "generated/graphql"

const RS_SHIPMENT = gql`
  mutation createRsShipment($in: RsShipmentInput!) {
    createRsShipment(in: $in) {
      Info
      PDFURL
    }
  }
`

export const rsShipmentCreate = async (
  _in: RsShipmentInput
): Promise<CreateRsShipmentMutation | undefined> => {
  const variables: CreateRsShipmentMutationVariables = { in: _in }
  const data = await fetchGQL<
    CreateRsShipmentMutation,
    CreateRsShipmentMutationVariables
  >(RS_SHIPMENT, variables)
  return data
}
