import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"

import { GridColDef, DataGridPro } from "@mui/x-data-grid-pro"
import { dataGridDEde } from "constants/dataGridLocale"
import { Address, ArticleItem } from "generated/graphql"
import { enqueueSnackbar } from "notistack"
import { articleSearch } from "queries/articleSearch"
import { customerOrderCreate } from "queries/customerOrderCreate"
import { useEffect, useRef, useState } from "react"
import { ArticleItemWithCount } from "pages/AricleSearchRoundAdd/ArticleSearchRoundAdd"
import { CustomerEditPageAddress } from "./CustomerEditPageAddress"
import { ZahlartForm } from "components/ZahlartForm/ZahlartForm"
import { OrderStatusAutocomplete } from "components/OrderStatusAutocomplete/OrderStatusAutocomplete"

type AddOrderDialogProps = {
  customerID: string
  open: boolean
  close: () => void
  closeNewOrder: () => void
}

export const AddOrderDialog = (props: AddOrderDialogProps) => {
  const { customerID, open, close, closeNewOrder } = props
  const [selectedItems, setSelectedItems] = useState<ArticleItemWithCount[]>([])
  const searchRef = useRef<HTMLInputElement>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [rows, setRows] = useState<ArticleItem[]>([])
  const [address, setAddress] = useState<Address | null>(null)
  const [status, setStatus] = useState<number>(0)
  const [zahlart, setZahlart] = useState<string>("")

  const handleOnClose = () => {
    setSelectedItems([])
    setRows([])
    setZahlart("")
    setStatus(0)
    setAddress(null)
    close()
  }

  const saveOrder = () => {
    const orderItems = selectedItems.map((item) => ({
      bdb: parseInt(item.bdb),
      anzahl: item.count,
      stueckpreis: Math.round(parseFloat(item.price) * 100),
    }))
    if (orderItems && zahlart && status && address) {
      const newOrder = {
        customerID: parseInt(customerID),
        status: status,
        zahlart: zahlart,
        deliveryAdrId: address.id,
        orderItems: orderItems,
      }
      customerOrderCreate({ in: { ...newOrder } })
        .then((res) => {
          if (res?.customerOrderCreate.orderID) {
            enqueueSnackbar(
              `Order ${res?.customerOrderCreate.orderID} angelegt`,
              {
                variant: "success",
              }
            )
          }
        })
        .finally(() => {
          setSelectedItems([])
          setRows([])
          setZahlart("")
          setStatus(0)
          setAddress(null)
          closeNewOrder()
        })
    }
  }

  const colArticle: GridColDef<ArticleItem>[] = [
    {
      field: "bdb",
      headerName: "BDB",
      width: 120,
      renderCell: (params) => {
        return (
          <Typography
            variant="body2"
            sx={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "primary.main",
            }}
            component="div"
            onClick={() =>
              window.open(
                `${window.location.origin}/#/editArticle/${params.row.bdb}`,
                "_blank",
                "noopener,noreferrer"
              )
            }
          >
            {params.row.bdb}
          </Typography>
        )
      },
    },
    {
      field: "pflNR",
      headerName: "PflNR",
      width: 120,
    },
    {
      field: "botname",
      headerName: "Artikel",
      renderCell: (params) => {
        return (
          <div>
            <div>{params.row.botname}</div>
            <div>
              {params.row.sizeFrom} - {params.row.sizeTo}
            </div>
          </div>
        )
      },
      width: 300,
    },
    {
      field: "add",
      headerName: "Hinzufügen",
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              const item = selectedItems.find((i) => i.bdb === params.row.bdb)
              if (item === undefined) {
                setSelectedItems([
                  ...selectedItems,
                  { ...params.row, count: 1 },
                ])
              } else {
                item.count++
                setSelectedItems([...selectedItems])
              }
            }}
          >
            Hinzufügen
          </Button>
        )
      },
      width: 120,
    },
  ]

  const colArticleItemWithCount: GridColDef<ArticleItemWithCount>[] = [
    {
      field: "bdb",
      headerName: "BDB",
      width: 80,
      renderCell: (params) => {
        return (
          <Typography
            variant="body2"
            sx={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "primary.main",
            }}
            component="div"
            onClick={() =>
              window.open(
                `${window.location.origin}/#/editArticle/${params.row.bdb}`,
                "_blank",
                "noopener,noreferrer"
              )
            }
          >
            {params.row.bdb}
          </Typography>
        )
      },
    },
    {
      field: "botname",
      headerName: "Artikel",
      renderCell: (params) => {
        return (
          <div>
            <div>{params.row.botname}</div>
            <div>
              {params.row.sizeFrom} - {params.row.sizeTo}
            </div>
          </div>
        )
      },
      width: 300,
    },
    {
      field: "price",
      headerName: "Stückpreis",
      width: 100,
      type: "number",
      editable: true,
    },

    {
      field: "count",
      headerName: "Anzahl",
      width: 100,
      type: "number",
      editable: true,
    },
    {
      field: "remove",
      headerName: "Entfernen",
      width: 100,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => {
              const item = selectedItems.find((i) => i.bdb === params.row.bdb)
              if (item !== undefined) {
                setSelectedItems(
                  selectedItems.filter((i) => i.bdb !== item.bdb)
                )
              }
            }}
          >
            <DeleteIcon color="error" />
          </IconButton>
        )
      },
    },
  ]

  useEffect(() => {
    if (searchRef.current !== null) {
      searchRef.current.focus()
    }
  }, [])

  const handleSearchCommit = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      const searchValue = searchRef.current?.value
      if (searchValue !== undefined && searchValue !== null) {
        if (searchValue === "") {
          enqueueSnackbar("Bitte geben Sie einen Suchbegriff ein", {
            variant: "info",
          })
        } else {
          setIsLoading(true)
          articleSearch(searchValue)
            .then((result) => {
              if (result?.articleSearch) {
                setRows(result.articleSearch)
              }
            })
            .finally(() => {
              setIsLoading(false)
              setTimeout(() => {
                if (searchRef.current !== null) {
                  searchRef.current.focus()
                }
              }, 100)
            })
        }
      }
    }
  }

  return (
    <Dialog open={open} onClose={handleOnClose} fullWidth maxWidth="xl">
      <DialogTitle>Bestellung hinzufügen</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" component="div">
              Stammdaten
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <OrderStatusAutocomplete
              status={status}
              handleOrderStatusChange={(newStatus) => {
                setStatus(newStatus)
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <ZahlartForm
              disabled={false}
              value={zahlart}
              onChange={(event: SelectChangeEvent) =>
                setZahlart(event.target.value)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <CustomerEditPageAddress
              customerID={customerID}
              setSelectedAddress={setAddress}
              open={false}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" component="div">
              Artikel hinzufügen
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  label="Suche"
                  inputRef={searchRef}
                  placeholder="Suche"
                  onKeyUp={handleSearchCommit}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ height: "50vh", width: "100%" }}>
                  <DataGridPro
                    rows={rows}
                    getRowId={(row) => row.bdb}
                    columns={colArticle}
                    localeText={dataGridDEde}
                    loading={isLoading}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ height: "50vh", width: "100%" }}>
                  <DataGridPro
                    rows={selectedItems}
                    getRowId={(row) => row.bdb + row.pflNR}
                    columns={colArticleItemWithCount}
                    localeText={dataGridDEde}
                    loading={isLoading}
                    processRowUpdate={(newRow, oldRow) => {
                      if (
                        newRow.count === oldRow.count &&
                        newRow.price === oldRow.price
                      ) {
                        return newRow
                      }
                      if (newRow.count <= 0) {
                        enqueueSnackbar(
                          "Anzahl darf nicht kleiner als 1 sein",
                          {
                            variant: "error",
                          }
                        )
                        return oldRow
                      }
                      const item = selectedItems.find(
                        (i) => i.bdb === newRow.bdb
                      )
                      if (item !== undefined) {
                        item.count = Number(newRow.count)
                        item.price = newRow.price
                        setSelectedItems([...selectedItems])
                      }
                      return newRow
                    }}
                    onProcessRowUpdateError={(error) => {
                      enqueueSnackbar(error, { variant: "error" })
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          disabled={
            selectedItems.length === 0 ||
            zahlart === "" ||
            status <= 0 ||
            address === null
          }
          onClick={saveOrder}
        >
          Speichern
        </Button>
        <Button variant="contained" onClick={handleOnClose}>
          Abbrechen
        </Button>
      </DialogActions>
    </Dialog>
  )
}
