import { fetchGQL } from "app/api"
import {
  CustomerSearchInput,
  CustomerSearchQuery,
  CustomerSearchQueryVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"

const CUSTOMER_SEARCH = gql`
  query customerSearch($in: CustomerSearchInput!) {
    customerSearch(in: $in) {
      id
      nickName
      firstName
      lastName
      email
      telefon
      handy
    }
  }
`

export const customerSearch = async (
  input: CustomerSearchInput
): Promise<CustomerSearchQuery | undefined> => {
  const variables: CustomerSearchQueryVariables = {
    in: { ...input },
  }
  const data = await fetchGQL<
    CustomerSearchQuery,
    CustomerSearchQueryVariables
  >(CUSTOMER_SEARCH, variables)
  return data
}
