import { Box, Button, Container, Grid, TextField } from "@mui/material"
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro"
import { dataGridDEde } from "constants/dataGridLocale"
import { CustomerSearchInput, CustomerSearchRes } from "generated/graphql"
import { enqueueSnackbar } from "notistack"
import { customerSearch } from "queries/customerSearch"
import { useState, useRef, useEffect } from "react"
import { useNavigate } from "react-router-dom"

const columns: GridColDef<CustomerSearchRes>[] = [
  {
    field: "id",
    headerName: "ID",
    width: 80,
  },
  {
    field: "nickName",
    headerName: "Nickname",
    width: 200,
  },
  {
    field: "firstName",
    headerName: "Vorname",
    width: 200,
  },
  {
    field: "lastName",
    headerName: "Nachname",
    width: 200,
  },
  {
    field: "email",
    headerName: "Email",
    width: 200,
  },
  {
    field: "telefon",
    headerName: "Telefon",
    width: 120,
  },
  {
    field: "handy",
    headerName: "Mobil Telefon",
    width: 120,
  },
]

const emptySearchInput: CustomerSearchInput = {
  lastName: "",
  firstName: "",
  nickName: "",
  phone: "",
  email: "",
  street: "",
  zip: "",
  city: "",
  inbase: "",
  country: "",
  customerId: "",
}

const CustomerSearchPage = (): JSX.Element => {
  const ref = useRef<HTMLInputElement>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [rows, setRows] = useState<CustomerSearchRes[]>([])
  const [search, setSearch] = useState<CustomerSearchInput>(emptySearchInput)
  const navigate = useNavigate()

  const handleSearchCommit = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setIsLoading(true)
      customerSearch(search)
        .then((result) => {
          if (result?.customerSearch && result.customerSearch.length > 0) {
            enqueueSnackbar(
              `Suche erfolgreich. ${result.customerSearch.length} ${
                result.customerSearch.length === 1 ? "Ergebnis" : "Ergebnisse"
              } gefunden.`,
              { variant: "success" }
            )
            setRows(result.customerSearch)
          } else {
            enqueueSnackbar("Keine Ergebnisse gefunden.", { variant: "info" })
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const resetSearch = () => {
    setSearch(emptySearchInput)
    setRows([])
  }

  useEffect(() => {
    if (ref.current !== null) {
      ref.current.focus()
    }
  }, [])

  return (
    <Container maxWidth={false}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={2}>
          <TextField
            label="Kundennummer"
            id="Kundennummer"
            inputRef={ref}
            placeholder="Kundennummer"
            fullWidth
            onChange={(event) => {
              setSearch({ ...search, customerId: event.target.value })
            }}
            onKeyUp={handleSearchCommit}
            value={search.customerId}
            disabled={isLoading}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            label="Name"
            id="Name"
            placeholder="Name"
            fullWidth
            onKeyUp={handleSearchCommit}
            disabled={isLoading}
            onChange={(event) => {
              setSearch({ ...search, lastName: event.target.value })
            }}
            value={search.lastName}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            label="Vorname"
            id="Vorname"
            placeholder="Vorname"
            fullWidth
            onKeyUp={handleSearchCommit}
            disabled={isLoading}
            onChange={(event) => {
              setSearch({ ...search, firstName: event.target.value })
            }}
            value={search.firstName}
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <TextField
            label="Nickname"
            id="Nickname"
            placeholder="Nickname"
            fullWidth
            onKeyUp={handleSearchCommit}
            disabled={isLoading}
            onChange={(event) => {
              setSearch({ ...search, nickName: event.target.value })
            }}
            value={search.nickName}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            fullWidth
            label="Telefon / Handy"
            id="Telefon"
            placeholder="Telefon"
            onKeyUp={handleSearchCommit}
            disabled={isLoading}
            onChange={(event) => {
              setSearch({ ...search, phone: event.target.value })
            }}
            value={search.phone}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            fullWidth
            label="Email"
            id="Email"
            placeholder="Email"
            onKeyUp={handleSearchCommit}
            disabled={isLoading}
            onChange={(event) => {
              setSearch({ ...search, email: event.target.value })
            }}
            value={search.email}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            fullWidth
            label="Straße"
            id="Strasse"
            placeholder="Straße"
            onKeyUp={handleSearchCommit}
            disabled={isLoading}
            onChange={(event) => {
              setSearch({ ...search, street: event.target.value })
            }}
            value={search.street}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            fullWidth
            label="PLZ"
            id="PLZ"
            placeholder="PLZ"
            onKeyUp={handleSearchCommit}
            disabled={isLoading}
            onChange={(event) => {
              setSearch({ ...search, zip: event.target.value })
            }}
            value={search.zip}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            label="Ort"
            id="Ort"
            placeholder="Ort"
            onKeyUp={handleSearchCommit}
            disabled={isLoading}
            fullWidth
            onChange={(event) => {
              setSearch({ ...search, city: event.target.value })
            }}
            value={search.city}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            label="Inbase"
            id="inbase"
            placeholder="Inbase"
            fullWidth
            onKeyUp={handleSearchCommit}
            disabled={isLoading}
            onChange={(event) => {
              setSearch({ ...search, inbase: event.target.value })
            }}
            value={search.inbase}
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <TextField
            label="Land"
            id="Land"
            placeholder="Land"
            fullWidth
            onKeyUp={handleSearchCommit}
            disabled={isLoading}
            onChange={(event) => {
              setSearch({ ...search, country: event.target.value })
            }}
            value={search.country}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
            variant="contained"
            fullWidth
            sx={{ height: "100%" }}
            onClick={resetSearch}
          >
            Zurücksetzen
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ height: "75vh", width: "100%" }}>
            <DataGridPro
              rows={rows}
              onRowClick={(params) => {
                navigate(`/editCustomer/${params.id}`)
              }}
              getRowId={(row) => row.id}
              columns={columns}
              localeText={dataGridDEde}
              loading={isLoading}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default CustomerSearchPage
