import { fetchGQL } from "app/api"
import {
  RoundCreateMutation,
  RoundCreateMutationVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"

const ROUND_CREATE = gql`
  mutation roundCreate($input: RoundCreateInput!) {
    roundCreate(in: $input) {
      msg
    }
  }
`

export const roundCreate = async (
  round: number,
  roundName: string,
  startDate: string,
  endDate: string,
  status: number
): Promise<RoundCreateMutation | undefined> => {
  var _in: RoundCreateMutationVariables = {
    input: {
      round: round,
      roundName: roundName,
      startDate: startDate,
      endDate: endDate,
      status: status,
    },
  }
  const data = await fetchGQL<
    RoundCreateMutation,
    RoundCreateMutationVariables
  >(ROUND_CREATE, _in)
  return data
}
