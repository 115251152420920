import { LoadingButton } from "@mui/lab"
import { Box, DialogActions, DialogContent, Typography } from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro"
import { dataGridDEde } from "constants/dataGridLocale"
import { RoundOrderArticle, SupplierOrderItem } from "generated/graphql"
import { useState } from "react"

export interface RoundOrderDeleteItemDialogProps {
  open: boolean
  onClose: () => void
  supplierName: string
  selection: RoundOrderArticle[]
  supplierOrderInfo: SupplierOrderItem[]
  onDelete: (data: number[], callback: () => void) => void
  isLoading: boolean
}

export const RoundOrderDeleteItemDialog = (
  props: RoundOrderDeleteItemDialogProps
): JSX.Element => {
  const {
    open,
    onClose,
    selection,
    supplierOrderInfo,
    supplierName,
    onDelete,
    isLoading,
  } = props
  const [editData, setEditData] = useState<number[]>([])

  const filterSelctedData = (
    data: SupplierOrderItem[]
  ): SupplierOrderItem[] => {
    const l: SupplierOrderItem[] = []
    for (let i = 0; i < selection.length; i++) {
      for (let j = 0; j < data.length; j++) {
        if (selection[i].articleId === data[j].bdb) {
          l.push(data[j])
        }
      }
    }
    return l
  }

  const cols: GridColDef<SupplierOrderItem>[] = [
    { field: "id", headerName: "Lieferant Bestellnr.", width: 150 },
    { field: "orderID", headerName: "Kunde Bestellnr.", width: 150 },
    { field: "ewID", headerName: "EwID", width: 100 },
    { field: "bdb", headerName: "BDB", width: 120 },
    {
      field: "botname",
      headerName: "Artikel",
      renderCell: (params) => {
        return (
          <Box>
            <Typography variant="body1" component="div">
              {params.row.botname}
            </Typography>
            <Typography variant="body2" color="text.secondary" component="div">
              {params.row.sorte} - {params.row.size}
            </Typography>
          </Box>
        )
      },
      width: 300,
    },
    { field: "quantity", headerName: "Anzahl", width: 100 },
  ]

  const handleDelete = (): void => {
    onDelete(editData, () => setEditData([]))
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl">
      <DialogTitle>Artikel-Entfernen von {supplierName}</DialogTitle>
      <DialogContent sx={{ height: "70vh", width: "100%" }}>
        <DataGridPro
          rowHeight={80}
          rows={filterSelctedData(supplierOrderInfo)}
          columns={cols}
          disableRowSelectionOnClick
          checkboxSelection
          localeText={dataGridDEde}
          loading={isLoading}
          onRowSelectionModelChange={(newSelection) => {
            if (
              newSelection instanceof Array &&
              newSelection.every((x) => typeof x === "number")
            ) {
              setEditData(newSelection as number[])
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          fullWidth
          onClick={handleDelete}
          color="primary"
        >
          Entfernen
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
