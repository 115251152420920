import { gql } from "graphql-tag"
import { fetchGQL } from "app/api"
import {
  RoundOrderItemsQuery,
  RoundOrderItemsQueryVariables,
  SendOrderListInput,
  SendOrderListMutation,
  SendOrderListMutationVariables,
} from "generated/graphql"

const ORDERLIST_GET = gql`
  query roundOrderItems($round: Int!) {
    roundOrderList(round: $round) {
      roundExists
      items {
        supplierID
        round
        supplierName
        phone
        email
        fax
        sendFax
        sendEmail
        faxSentAt
        emailSentAt
        g24
        flavour
        inStorage
      }
    }
  }
`

export const roundOrderListGet = async (
  round: number
): Promise<RoundOrderItemsQuery | undefined> => {
  const variables: RoundOrderItemsQueryVariables = { round }
  const data = await fetchGQL<
    RoundOrderItemsQuery,
    RoundOrderItemsQueryVariables
  >(ORDERLIST_GET, variables)
  return data
}

const ORDERLIST_SEND = gql`
  mutation sendOrderList($in: SendOrderListInput!) {
    sendOrderList(in: $in)
  }
`

export const orderListSend = async (
  wtf: SendOrderListInput
): Promise<SendOrderListMutation | undefined> => {
  const variables: SendOrderListMutationVariables = { in: wtf }
  const data = await fetchGQL<
    SendOrderListMutation,
    SendOrderListMutationVariables
  >(ORDERLIST_SEND, variables)
  return data
}
