import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  TextField,
} from "@mui/material"
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro"
import { dataGridDEde } from "constants/dataGridLocale"
import { ArticleItem } from "generated/graphql"
import { useSnackbar } from "notistack"
import { articleSearch } from "queries/articleSearch"
import { useEffect, useRef, useState } from "react"
import DeleteIcon from "@mui/icons-material/Delete"

export interface ArticleItemWithCount extends ArticleItem {
  count: number
}

export interface ArticleSearchRoundAddProps {
  selectedItems: ArticleItemWithCount[]
  setSelectedItems: (items: ArticleItemWithCount[]) => void
}

const ArticleSearchRoundAdd = (
  props: ArticleSearchRoundAddProps
): JSX.Element => {
  const { selectedItems, setSelectedItems } = props
  const { enqueueSnackbar } = useSnackbar()
  const searchRef = useRef<HTMLInputElement>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [rows, setRows] = useState<ArticleItem[]>([])

  const colArticle: GridColDef<ArticleItem>[] = [
    {
      field: "bdb",
      headerName: "BDB",
      width: 120,
    },
    {
      field: "pflNR",
      headerName: "PflNR",
      width: 120,
    },
    {
      field: "botname",
      headerName: "Artikel",
      renderCell: (params) => {
        return (
          <div>
            <div>{params.row.botname}</div>
            <div>
              {params.row.sizeFrom} - {params.row.sizeTo}
            </div>
          </div>
        )
      },
      width: 300,
    },
    {
      field: "add",
      headerName: "Hinzufügen",
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              const item = selectedItems.find((i) => i.bdb === params.row.bdb)
              if (item === undefined) {
                setSelectedItems([
                  ...selectedItems,
                  { ...params.row, count: 1 },
                ])
              } else {
                item.count++
                setSelectedItems([...selectedItems])
              }
            }}
          >
            Hinzufügen
          </Button>
        )
      },
      width: 120,
    },
  ]

  const colArticleItemWithCount: GridColDef<ArticleItemWithCount>[] = [
    {
      field: "bdb",
      headerName: "BDB",
      width: 120,
    },
    {
      field: "botname",
      headerName: "Artikel",
      renderCell: (params) => {
        return (
          <div>
            <div>{params.row.botname}</div>
            <div>
              {params.row.sizeFrom} - {params.row.sizeTo}
            </div>
          </div>
        )
      },
      width: 350,
    },
    {
      field: "count",
      headerName: "Anzahl",
      width: 120,
      type: "number",
      editable: true,
    },
    {
      field: "remove",
      headerName: "Entfernen",
      width: 120,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => {
              const item = selectedItems.find((i) => i.bdb === params.row.bdb)
              if (item !== undefined) {
                setSelectedItems(
                  selectedItems.filter((i) => i.bdb !== item.bdb)
                )
              }
            }}
          >
            <DeleteIcon color="error" />
          </IconButton>
        )
      },
    },
  ]

  useEffect(() => {
    if (searchRef.current !== null) {
      searchRef.current.focus()
    }
  }, [])

  const handleSearchCommit = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      const searchValue = searchRef.current?.value
      if (searchValue !== undefined && searchValue !== null) {
        if (searchValue === "") {
          enqueueSnackbar("Bitte geben Sie einen Suchbegriff ein", {
            variant: "info",
          })
        } else {
          setIsLoading(true)
          articleSearch(searchValue)
            .then((result) => {
              if (result?.articleSearch) {
                setRows(result.articleSearch)
              }
            })
            .finally(() => {
              setIsLoading(false)
              setTimeout(() => {
                if (searchRef.current !== null) {
                  searchRef.current.focus()
                }
              }, 100)
            })
        }
      }
    }
  }

  return (
    <Container maxWidth="xl">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            label="Suche"
            inputRef={searchRef}
            placeholder="Suche"
            onKeyUp={handleSearchCommit}
            disabled={isLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ height: "50vh", width: "100%" }}>
            <DataGridPro
              rows={rows}
              getRowId={(row) => row.bdb}
              columns={colArticle}
              localeText={dataGridDEde}
              loading={isLoading}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ height: "50vh", width: "100%" }}>
            <DataGridPro
              rows={selectedItems}
              getRowId={(row) => row.bdb + row.pflNR}
              columns={colArticleItemWithCount}
              localeText={dataGridDEde}
              loading={isLoading}
              processRowUpdate={(newRow, oldRow) => {
                if (newRow.count === oldRow.count) {
                  return newRow
                }
                if (newRow.count <= 0) {
                  enqueueSnackbar("Anzahl darf nicht kleiner als 1 sein", {
                    variant: "error",
                  })
                  return oldRow
                }
                const item = selectedItems.find((i) => i.bdb === newRow.bdb)
                if (item !== undefined) {
                  item.count = Number(newRow.count)
                  setSelectedItems([...selectedItems])
                }
                return newRow
              }}
              onProcessRowUpdateError={(error) => {
                enqueueSnackbar(error, { variant: "error" })
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ArticleSearchRoundAdd
