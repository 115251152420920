import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "app/store"
import { enqueueSnackbar } from "notistack"

export interface snackState {
  item: SnackItem
}

const initialState: snackState = {
  item: { msg: "", severity: "info" },
}

export interface SnackItem {
  msg: string
  severity: "error" | "warning" | "info" | "success"
}

export const snackSlice = createSlice({
  name: "snack",
  initialState,
  reducers: {
    qSnack: (state, action: PayloadAction<SnackItem>) => {
      state.item = action.payload
      const persist =
        action.payload.severity === "error" ||
        action.payload.severity === "warning"
          ? true
          : false
      enqueueSnackbar(action.payload.msg, {
        variant: action.payload.severity,
        persist: persist,
      })
    },
  },
})

export const { qSnack } = snackSlice.actions

export const selectSnack = (state: RootState) => state.snackState

export default snackSlice.reducer
