import {
  PackErrorsQuery,
  PackErrorsQueryVariables,
  PackErrorResolveMutation,
} from "generated/graphql"
import { gql } from "graphql-tag"
import { fetchGQL } from "app/api"

const GET_PACKERRORS = gql`
  query packErrors {
    packErrors {
      id
      created
      detail
      orderID
      customerID
      addressID
      packer
    }
  }
`
const RESOLVE_PACKERRORS = gql`
  mutation packErrorResolve($id: Int!) {
    packErrorResolve(id: $id)
  }
`

export const packErrorsGet = async (): Promise<PackErrorsQuery | undefined> => {
  const variables: PackErrorsQueryVariables = {}
  const data = await fetchGQL<PackErrorsQuery, PackErrorsQueryVariables>(
    GET_PACKERRORS,
    variables
  )

  return data
}

export const packErrorResolve = async (
  id: number
): Promise<PackErrorResolveMutation | undefined> => {
  const variables = { id }
  const data = await fetchGQL<PackErrorResolveMutation>(
    RESOLVE_PACKERRORS,
    variables
  )
  return data
}
