export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
}

export type Address = {
  __typename?: "Address"
  anrede: Scalars["String"]
  city: Scalars["String"]
  company: Scalars["String"]
  country: Scalars["String"]
  countryCode: Scalars["String"]
  firstName: Scalars["String"]
  fullName: Scalars["String"]
  id: Scalars["Int"]
  lastName: Scalars["String"]
  street: Scalars["String"]
  zipCode: Scalars["String"]
}

export type AddressInput = {
  anrede: Scalars["String"]
  city: Scalars["String"]
  company: Scalars["String"]
  country: Scalars["String"]
  countryCode: Scalars["String"]
  customerID: Scalars["Int"]
  firstName: Scalars["String"]
  fullName: Scalars["String"]
  id: Scalars["Int"]
  lastName: Scalars["String"]
  street: Scalars["String"]
  zipCode: Scalars["String"]
}

export type Article = {
  __typename?: "Article"
  artNum: Scalars["Int"]
  count: Scalars["Int"]
  currentPrice: Scalars["Float"]
  distributed: Scalars["Boolean"]
  id: Scalars["Int"]
  isDeleted: Scalars["Boolean"]
  isPalletArticle: Scalars["Boolean"]
  isSplitted: Scalars["Boolean"]
  name: Scalars["String"]
  personDistributed: Scalars["String"]
  personTagged: Scalars["String"]
  pmLocation: Scalars["String"]
  size: Scalars["String"]
  supplier: Scalars["String"]
  tagged: Scalars["Boolean"]
  /** in this format: 24.04.2021 09:35 */
  timeDistributed: Scalars["String"]
  /** in this format: 24.04.2021 09:35 */
  timeTagged: Scalars["String"]
}

export type ArticleChangelogItem = {
  __typename?: "ArticleChangelogItem"
  action: Scalars["String"]
  bdb: Scalars["String"]
  changeDate: Scalars["String"]
  computerName: Scalars["String"]
  id: Scalars["Int"]
  userName: Scalars["String"]
}

export type ArticleDetail = {
  __typename?: "ArticleDetail"
  articleGroup: Scalars["Int"]
  availableFrom: Scalars["String"]
  bdb: Scalars["String"]
  botname: Scalars["String"]
  customerContainerSize: Scalars["String"]
  gername: Scalars["String"]
  h24: Scalars["String"]
  localStock: Scalars["String"]
  location: Scalars["String"]
  pflNR: Scalars["String"]
  plantTypes: Array<PlantType>
  price: Scalars["String"]
  quali: Scalars["String"]
  reducedPrice: Scalars["String"]
  reducedPriceUntil: Scalars["String"]
  saleMinCount: Scalars["String"]
  salePct: Scalars["String"]
  sizeFrom: Scalars["String"]
  sizeTo: Scalars["String"]
  sorte: Scalars["String"]
  statusAfterSoldOut: Scalars["String"]
  stock: Scalars["String"]
  supplierAfterSoldOut: Scalars["Int"]
  supplierContainerSize: Scalars["String"]
  trunkHeight: Scalars["String"]
  trunkSizeFrom: Scalars["String"]
  trunkSizeTo: Scalars["String"]
  variant: Scalars["String"]
}

export type ArticleDetailsInput = {
  articleGroup: Scalars["Int"]
  availableFrom: Scalars["String"]
  bdb: Scalars["String"]
  botname: Scalars["String"]
  customerContainerSize: Scalars["String"]
  gername: Scalars["String"]
  h24: Scalars["String"]
  localStock: Scalars["Int"]
  location: Scalars["String"]
  pfNr: Scalars["String"]
  price: Scalars["String"]
  quali: Scalars["String"]
  reducedPrice: Scalars["String"]
  reducedPriceUntil: Scalars["String"]
  saleMinCount: Scalars["String"]
  salePct: Scalars["String"]
  sizeFrom: Scalars["String"]
  sizeTo: Scalars["String"]
  sorte: Scalars["String"]
  statusAfterSoldOut: Scalars["String"]
  stock: Scalars["Int"]
  supplierAfterSoldOut: Scalars["Int"]
  supplierContainerSize: Scalars["String"]
  trunkHeight: Scalars["String"]
  trunkSizeFrom: Scalars["String"]
  trunkSizeTo: Scalars["String"]
  variant: Scalars["String"]
}

export type ArticleHistory = {
  __typename?: "ArticleHistory"
  articleName: Scalars["String"]
  finishedAt: Scalars["String"]
  from: Scalars["Int"]
  orderID: Scalars["Int"]
  quantity: Scalars["Int"]
  round: Scalars["Int"]
  sh: Scalars["Int"]
  taskType: Scalars["Int"]
  to: Scalars["Int"]
}

export type ArticleInventoryReport = {
  __typename?: "ArticleInventoryReport"
  bestellcode: Scalars["String"]
  createdAt: Scalars["String"]
  id: Scalars["Int"]
  pmbdb: Scalars["String"]
  price: Scalars["String"]
  quality: Scalars["String"]
  quantity: Scalars["Int"]
  realbdb: Scalars["String"]
  saugtxt: Scalars["String"]
  sticky: Scalars["Boolean"]
  supplier: Scalars["String"]
  supplierID: Scalars["Int"]
}

export type ArticleItem = {
  __typename?: "ArticleItem"
  bdb: Scalars["String"]
  botname: Scalars["String"]
  gername: Scalars["String"]
  pflNR: Scalars["String"]
  price: Scalars["String"]
  reducedPrice: Scalars["String"]
  sizeFrom: Scalars["Int"]
  sizeTo: Scalars["Int"]
}

export type ArticleNewInput = {
  newPrice: Scalars["Float"]
  position: Scalars["String"]
  quantity: Scalars["Int"]
  sourceArticleBDB: Scalars["String"]
}

export type ArticlePositionInput = {
  barcode: Scalars["String"]
  position: Scalars["String"]
  supplier: Scalars["String"]
}

export type ArticlePriceHistory = {
  __typename?: "ArticlePriceHistory"
  action: Scalars["String"]
  bdb: Scalars["String"]
  createdAt: Scalars["String"]
  id: Scalars["Int"]
  price: Scalars["String"]
  supplierID: Scalars["Int"]
  supplierName: Scalars["String"]
}

export type ArticleSplitInput = {
  articleIds: Array<Scalars["Int"]>
}

export type ArticleSplitResponse = {
  __typename?: "ArticleSplitResponse"
  message?: Maybe<Scalars["String"]>
  newOrderDetails?: Maybe<Order>
  orderDetails?: Maybe<Order>
  statusCode: Scalars["Int"]
}

export type ArticleStoragePlaceInput = {
  eanBarcode: Scalars["String"]
  storagePlace: Scalars["String"]
}

export type ArticleSupplier = {
  __typename?: "ArticleSupplier"
  articleID: Scalars["Int"]
  createdAt: Scalars["String"]
  createdBy: Scalars["String"]
  id: Scalars["Int"]
  note: Scalars["String"]
  price: Scalars["Int"]
  priority: Scalars["Int"]
  quality: Array<Scalars["String"]>
  supplierID: Scalars["Int"]
  supplierName: Scalars["String"]
  updatedAt: Scalars["String"]
}

export type ArticleSupplierCrossRef = {
  __typename?: "ArticleSupplierCrossRef"
  articleID: Scalars["Int"]
  price: Scalars["Int"]
  priority: Scalars["Int"]
  supplierID: Scalars["Int"]
  supplierName: Scalars["String"]
}

export type ArticleSupplierInput = {
  articleID: Scalars["Int"]
  id: Scalars["Int"]
  note: Scalars["String"]
  price: Scalars["Int"]
  priority: Scalars["Int"]
  quality: Array<Scalars["String"]>
  supplierID: Scalars["Int"]
  supplierName: Scalars["String"]
}

export type AskDanteTimeTracking = {
  __typename?: "AskDanteTimeTracking"
  Duration: Scalars["Int"]
  date: Scalars["String"]
  /** an askdante users time tracking for a given day */
  userId: Scalars["String"]
}

export type AskDanteUser = {
  __typename?: "AskDanteUser"
  email: Scalars["String"]
  firstName: Scalars["String"]
  /** an askDante User */
  id: Scalars["String"]
  lastName: Scalars["String"]
  personnelNumber: Scalars["String"]
  tags: Array<Scalars["String"]>
}

export type AuthRequest = {
  password: Scalars["String"]
  username: Scalars["String"]
}

/**
 * This is a description of the AuthResponse type.
 * It's used for returning the user and the token after a successful login.
 */
export type AuthResponse = {
  __typename?: "AuthResponse"
  token: Scalars["String"]
  user: User
}

export enum Carrier {
  Dhl = "DHL",
  DhlWarenpost = "DHL_WARENPOST",
  Dpd = "DPD",
  Gls = "GLS",
  GlsExpress = "GLS_EXPRESS",
  GlsLetterbox = "GLS_LETTERBOX",
}

export type Carton = {
  __typename?: "Carton"
  barcode: Scalars["String"]
  description: Scalars["String"]
  heightMM: Scalars["Int"]
  id: Scalars["Int"]
  lengthMM: Scalars["Int"]
  widthMM: Scalars["Int"]
}

export type CartonInput = {
  barcode: Scalars["String"]
  description: Scalars["String"]
  heightMM: Scalars["Int"]
  id: Scalars["Int"]
  lengthMM: Scalars["Int"]
  widthMM: Scalars["Int"]
}

export type CartonStatistic = {
  __typename?: "CartonStatistic"
  carton: Carton
  count: Scalars["Int"]
  countPercent: Scalars["Float"]
}

export type Coupon = {
  __typename?: "Coupon"
  article_id?: Maybe<Scalars["String"]>
  categoryIds?: Maybe<Array<Scalars["String"]>>
  combinable: Scalars["Boolean"]
  created_on: Scalars["String"]
  description: Scalars["String"]
  discount: Scalars["Int"]
  editable: Scalars["Boolean"]
  email?: Maybe<Scalars["String"]>
  id: Scalars["Int"]
  key: Scalars["String"]
  max_uses?: Maybe<Scalars["Int"]>
  min_sum: Scalars["Int"]
  no_delivery_costs: Scalars["Boolean"]
  note_intern: Scalars["String"]
  product_id?: Maybe<Scalars["String"]>
  sale_allowed: Scalars["Boolean"]
  title: Scalars["String"]
  valid_from?: Maybe<Scalars["String"]>
  valid_until?: Maybe<Scalars["String"]>
  value: Scalars["Int"]
  who_created: Scalars["String"]
}

export type CouponByIdInput = {
  excludeSov: Scalars["Boolean"]
  id: Scalars["Int"]
}

export type CouponCreateInput = {
  article_id?: InputMaybe<Scalars["String"]>
  categoryIds?: InputMaybe<Array<Scalars["String"]>>
  combinable: Scalars["Boolean"]
  description: Scalars["String"]
  discount: Scalars["Int"]
  key: Scalars["String"]
  max_uses?: InputMaybe<Scalars["Int"]>
  min_sum: Scalars["Int"]
  no_delivery_costs: Scalars["Boolean"]
  note_intern: Scalars["String"]
  product_id?: InputMaybe<Scalars["String"]>
  sale_allowed: Scalars["Boolean"]
  title: Scalars["String"]
  valid_from?: InputMaybe<Scalars["String"]>
  valid_until?: InputMaybe<Scalars["String"]>
  value: Scalars["Int"]
}

export type CouponDeleteInput = {
  id: Array<Scalars["Int"]>
}

export type CouponUpdateInput = {
  article_id?: InputMaybe<Scalars["String"]>
  categoryIds?: InputMaybe<Array<Scalars["String"]>>
  combinable: Scalars["Boolean"]
  description: Scalars["String"]
  discount: Scalars["Int"]
  id: Scalars["Int"]
  key: Scalars["String"]
  max_uses?: InputMaybe<Scalars["Int"]>
  min_sum: Scalars["Int"]
  no_delivery_costs: Scalars["Boolean"]
  note_intern: Scalars["String"]
  product_id?: InputMaybe<Scalars["String"]>
  sale_allowed: Scalars["Boolean"]
  title: Scalars["String"]
  valid_from?: InputMaybe<Scalars["String"]>
  valid_until?: InputMaybe<Scalars["String"]>
  value: Scalars["Int"]
}

export type CouponsByActiveDateInput = {
  activeDate: Scalars["String"]
  excludeSov: Scalars["Boolean"]
}

export type CouponsByKeyInput = {
  excludeSov: Scalars["Boolean"]
  key: Scalars["String"]
}

export type CustomerDetail = {
  __typename?: "CustomerDetail"
  anrede: Scalars["String"]
  city: Scalars["String"]
  company: Scalars["String"]
  country: Scalars["String"]
  custnote: Scalars["String"]
  email: Scalars["String"]
  firstName: Scalars["String"]
  handy: Scalars["String"]
  id: Scalars["Int"]
  inbase: Scalars["String"]
  lastName: Scalars["String"]
  nickName: Scalars["String"]
  rechName: Scalars["String"]
  rechOrt: Scalars["String"]
  rechPlz: Scalars["String"]
  rechStreet: Scalars["String"]
  street: Scalars["String"]
  telefon: Scalars["String"]
  zip: Scalars["String"]
}

export type CustomerDetailInput = {
  anrede: Scalars["String"]
  city: Scalars["String"]
  company: Scalars["String"]
  country: Scalars["String"]
  custnote: Scalars["String"]
  email: Scalars["String"]
  firstName: Scalars["String"]
  handy: Scalars["String"]
  id: Scalars["Int"]
  inbase: Scalars["String"]
  lastName: Scalars["String"]
  nickName: Scalars["String"]
  rechName: Scalars["String"]
  rechOrt: Scalars["String"]
  rechPlz: Scalars["String"]
  rechStreet: Scalars["String"]
  street: Scalars["String"]
  telefon: Scalars["String"]
  zip: Scalars["String"]
}

export type CustomerOrder = {
  __typename?: "CustomerOrder"
  bezahlt: Scalars["Float"]
  custnote: Scalars["String"]
  datum: Scalars["String"]
  frachtpausch: Scalars["Float"]
  notiz: Scalars["String"]
  orderID: Scalars["Int"]
  rechnum: Scalars["Int"]
  rechsum: Scalars["Float"]
  status: Scalars["Int"]
  urorder: Scalars["Int"]
  zahlart: Scalars["String"]
  zieldat: Scalars["String"]
}

export type CustomerOrderInput = {
  customerID: Scalars["Int"]
  deliveryAdrId: Scalars["Int"]
  orderItems: Array<CustomerOrderItemInput>
  status: Scalars["Int"]
  zahlart: Scalars["String"]
}

export type CustomerOrderItemInput = {
  anzahl: Scalars["Int"]
  bdb: Scalars["Int"]
  stueckpreis: Scalars["Int"]
}

export type CustomerRefund = {
  __typename?: "CustomerRefund"
  amount: Scalars["Int"]
  articleID?: Maybe<Scalars["Int"]>
  createdAt: Scalars["String"]
  createdBy: Scalars["String"]
  customerID: Scalars["Int"]
  id: Scalars["Int"]
  note?: Maybe<Scalars["String"]>
  orderID: Scalars["Int"]
  settledAt?: Maybe<Scalars["String"]>
  settledBy?: Maybe<Scalars["String"]>
}

export type CustomerRefundCreateInput = {
  amount: Scalars["Int"]
  articleID?: InputMaybe<Scalars["Int"]>
  customerID: Scalars["Int"]
  note?: InputMaybe<Scalars["String"]>
  orderID: Scalars["Int"]
}

export type CustomerRefundSettleInput = {
  id: Scalars["Int"]
}

export type CustomerSearchInput = {
  city: Scalars["String"]
  country: Scalars["String"]
  customerId: Scalars["String"]
  email: Scalars["String"]
  firstName: Scalars["String"]
  inbase: Scalars["String"]
  lastName: Scalars["String"]
  nickName: Scalars["String"]
  phone: Scalars["String"]
  street: Scalars["String"]
  zip: Scalars["String"]
}

export type CustomerSearchRes = {
  __typename?: "CustomerSearchRes"
  email: Scalars["String"]
  firstName: Scalars["String"]
  handy: Scalars["String"]
  id: Scalars["Int"]
  lastName: Scalars["String"]
  nickName: Scalars["String"]
  telefon: Scalars["String"]
}

export type EmployeeStats = {
  __typename?: "EmployeeStats"
  askDanteFirstName: Scalars["String"]
  /** contains all a employees statistics */
  askDanteId: Scalars["String"]
  askDanteLastName: Scalars["String"]
  askDanteRole: Scalars["String"]
  chatAssignedCount: Scalars["Int"]
  chatMessagesCount: Scalars["Int"]
  chatRatings: Array<RatingValue>
  chatResolvedCount: Scalars["Int"]
  chatUniqueResolvedCount: Scalars["Int"]
  closedCount: Scalars["Int"]
  email: Scalars["String"]
  freshdeskId: Scalars["String"]
  freshdeskName: Scalars["String"]
  ratings: Array<FreshdeskRating>
  resolvedCount: Scalars["Int"]
  statsHistory: Array<EmployeeStatsDay>
  tags: Array<Scalars["String"]>
  tickets: Array<FreshdeskTicket>
  workedCount: Scalars["Int"]
}

export type EmployeeStatsDay = {
  __typename?: "EmployeeStatsDay"
  conversations: Array<FreshdeskConversation>
  /** Contains employee stats for a specific day */
  date: Scalars["String"]
  timeTrackingDuration: Scalars["Int"]
}

export type EmployeeStatsInput = {
  from: Scalars["String"]
  to: Scalars["String"]
}

export type EmployeeStatsOutput = {
  __typename?: "EmployeeStatsOutput"
  lastUpdate: Scalars["String"]
  stats: Array<EmployeeStats>
}

export type EtikettenData = {
  __typename?: "EtikettenData"
  bdb: Scalars["Int"]
  bis: Scalars["Int"]
  botname: Scalars["String"]
  etiNum: Scalars["Int"]
  ewid: Scalars["Int"]
  orderID: Scalars["Int"]
  posten: Scalars["Int"]
  quantity: Scalars["Int"]
  sh: Scalars["Int"]
  sorte: Scalars["String"]
  supplierID: Scalars["Int"]
  supplierName: Scalars["String"]
  totalBasketItems: Scalars["Int"]
  von: Scalars["Int"]
}

export type FileInput = {
  Filename: Scalars["String"]
  Value: Scalars["String"]
}

export type FreshdeskAgent = {
  __typename?: "FreshdeskAgent"
  email: Scalars["String"]
  /** a freshdesk agent */
  id: Scalars["String"]
  name: Scalars["String"]
}

export type FreshdeskConversation = {
  __typename?: "FreshdeskConversation"
  agentId: Scalars["String"]
  category: Scalars["Int"]
  incoming: Scalars["Boolean"]
  isEmail: Scalars["Boolean"]
  private: Scalars["Boolean"]
  source: Scalars["Int"]
  /** a freshdesk conversation */
  ticketId: Scalars["String"]
  updatedAt: Scalars["String"]
}

export type FreshdeskRating = {
  __typename?: "FreshdeskRating"
  rating: Scalars["Int"]
  updatedAt: Scalars["String"]
}

export type FreshdeskTicket = {
  __typename?: "FreshdeskTicket"
  agentId: Scalars["Int"]
  group: Scalars["String"]
  /** a freshdesk ticket */
  id: Scalars["String"]
  status: Scalars["Int"]
  type: Scalars["String"]
  updatedAt: Scalars["String"]
}

export type FreshdeskTicketInput = {
  description: Scalars["String"]
  files: Array<FileInput>
  selectedSubject: Scalars["String"]
  subject: Scalars["String"]
}

export type Libra = {
  __typename?: "Libra"
  id: Scalars["Int"]
  ip: Scalars["String"]
  port: Scalars["String"]
}

/** represents a logistic statistic of the system. */
export type LogisticStat = {
  __typename?: "LogisticStat"
  distributed: Scalars["Int"]
  packagesSent: Scalars["Int"]
  tagged: Scalars["Int"]
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type Mutation = {
  __typename?: "Mutation"
  /** articleDetailsSave saves the details of an article */
  articleDetailsSave: Scalars["Boolean"]
  /**
   * articleNew creates a new article
   * this procedure is for left over articles that needs to be pushed into the store
   * at a reduced price to get rid of them
   */
  articleNew: Scalars["Boolean"]
  articlePosition: Scalars["Int"]
  /** articleSplit splits an order */
  articleSplit: ArticleSplitResponse
  /**
   * articleSupplierDelete deletes the supplied articleSupplier entry
   * returns true on successful deletion, otherwise false
   */
  articleSupplierDelete: Scalars["Boolean"]
  /**
   * articleSupplierSave saves a supplier for a specific article
   * IF id == 0
   *   create a new supplier for the article
   * ELSE
   *   update the supplier for the article
   */
  articleSupplierSave: ArticleSupplier
  /**
   * articleSupplierSwapPriority swaps the priority of two suppliers
   * id1 and id2 will be swapped like this:
   * id1 -> priority of id2
   * id2 -> priority of id1
   */
  articleSupplierSwapPriority: Scalars["Boolean"]
  /** articleUpdateStorageplace updates the storage place of an article */
  articleUpdateStorageplace: Scalars["Boolean"]
  /** Create a new box */
  boxCreate: Carton
  /** Delete a existing box */
  boxDelete: Scalars["Boolean"]
  /** Update a existing box */
  boxUpdate: Carton
  /** coupon create */
  couponCreate: Coupon
  /** coupon delete */
  couponDelete: Scalars["Boolean"]
  /** coupon update */
  couponUpdate: Coupon
  /** create a rs logistic shipment */
  createRsShipment: RsShipmentResponse
  /** save customer delivery address */
  customerAddressSave: Address
  /** save customer Details (single) */
  customerDetailsSave: CustomerDetail
  /** create order for customer */
  customerOrderCreate: CustomerOrder
  /** customerRefund create */
  customerRefundCreate: CustomerRefund
  /** customerRefund settle */
  customerRefundSettle: CustomerRefund
  /**
   * delete plant type
   * safe delete if there is an entry with this plant type id it won't be deleted
   */
  deletePlantType: Scalars["String"]
  editAskDanteUserTags: Scalars["Boolean"]
  /** Create a ticket for freshdesk */
  freshdeskTicketCreate: Scalars["Boolean"]
  /** orderCancel cancel an order */
  orderCancel: Scalars["Boolean"]
  /** orderChange changes specific order details */
  orderChange: Scalars["Boolean"]
  /** orderItemDistribute */
  orderItemDistribute: ScanResponse
  /**
   * orderItemReset
   * reset the state of a item that has already been tagged or distributed
   * warning will reset also the people who tagged / distributed / packed it
   */
  orderItemReset: ScanResponse
  /** orderItemsCancel cancel some or all order items */
  orderItemsCancel: Scalars["Boolean"]
  /** orderSplit split an order */
  orderSplit: Scalars["String"]
  /** set a package error to resolved. */
  packErrorResolve: Scalars["Boolean"]
  /** rabenShipmentCreate */
  rabenShipmentCreate: RabenShipmentResponse
  /** rmlShipmentCreate */
  rmlShipmentCreate: Scalars["String"]
  /**
   * @in: create a round with the details
   * @res: create a round
   */
  roundCreate: RoundCreateResponse
  /**
   * roundItemEditDescription
   * will edit the description of a supplier order item
   * @in: the details of the edited article
   */
  roundItemEditDescription: Scalars["Boolean"]
  /**
   * roundItemsAdd
   * will create a storage order for the given article and put it on the supplier order list
   * @in: articles to be added to the current round and supplier
   */
  roundItemsAdd: Scalars["Boolean"]
  /**
   * roundItemsEditQuantity
   * will create a new or edit an existing storage orders for the given article and update the supplier order list
   * @in: the details of the edited article
   */
  roundItemsEdit: Scalars["Boolean"]
  /**
   * roundItemsSplit split round positions into new orders
   * the orders will be split into 'nachlieferungen' and the order position will be deleted from the roundlist for that supplier
   * @roundListIds = the id of the item to be moved to a new order
   * @statusCode = the next Status for the new order
   * @mergeStatuses = the status for other orders that should be summed up in one order
   */
  roundItemsSplit: RoundItemsSplitResponse
  /**
   * save new plant type
   * create new plant type or update existing one
   * given id is for update
   */
  savePlantType: PlantType
  /** send orderList as email or fax  to the supplier */
  sendOrderList: Scalars["Boolean"]
  /**
   * shipLabel creates an shipping label for a specific carrier on shipcloud platform and prints it!
   * @returns paketjournalID for canceling the label if accidental created
   */
  shipLabel: Scalars["Int"]
  /** shipCancel try to cancel a shipment from shipcloud @ the carrier (Only possible until a specific time, carrier specific */
  shipLabelCancel: Scalars["Boolean"]
  sortOrder: SortOrderRes
  /** specialChanges sets special options for the specified label */
  specialChanges: Scalars["Boolean"]
  /** Create a new supplier. */
  supplierCreate: Supplier
  /**
   * supplierRoundItemDelete deletes an item from the round list
   * @roundListIDs = the id of the item in the round list can be fetched from the API
   */
  supplierRoundItemDelete: Scalars["Boolean"]
  /**
   * supplierRoundItemMove moves an item from one supplier to another
   * if the quantity is 0 the item will be deleted
   * also it checks if the new supplier can deliver the item
   * after this call the frontend should be reloaded to get the new data
   * @roundListID = the id of the item in the round list can be fetched from the API
   * @round = the round number
   * @newSupplierID = the new supplier id
   * @quantity = how much should be moved to the new supplier
   */
  supplierRoundItemMove: Scalars["Boolean"]
  /**
   * supplierRoundNoteSave updates the note for a specific supplier in a specific round
   * @round = the round number the id will be determined by the backend
   * @supplierID = the supplier id (lieferanten(kurzwahl))
   * @note = max 255 characters
   */
  supplierRoundNoteSave: Scalars["Boolean"]
  /** Update a existing supplier. */
  supplierUpdate: Supplier
  /** tagItem labels an item as received and ready to be further processed */
  tagItem: ScanResponse
  /** timeLineInsert */
  timelineInsert: Timeline
  /**
   * save a user
   * if id = 0
   *   create a new user
   * else
   *   update the user with the given id
   */
  userSave: User
  userSettingSave: UserSettingSaveResponse
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationArticleDetailsSaveArgs = {
  in: ArticleDetailsInput
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationArticleNewArgs = {
  input: ArticleNewInput
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationArticlePositionArgs = {
  input: ArticlePositionInput
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationArticleSplitArgs = {
  in: ArticleSplitInput
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationArticleSupplierDeleteArgs = {
  in: ArticleSupplierInput
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationArticleSupplierSaveArgs = {
  in: ArticleSupplierInput
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationArticleSupplierSwapPriorityArgs = {
  id1: Scalars["Int"]
  id2: Scalars["Int"]
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationArticleUpdateStorageplaceArgs = {
  input: ArticleStoragePlaceInput
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationBoxCreateArgs = {
  in: CartonInput
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationBoxDeleteArgs = {
  id: Scalars["Int"]
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationBoxUpdateArgs = {
  in: CartonInput
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationCouponCreateArgs = {
  in?: InputMaybe<CouponCreateInput>
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationCouponDeleteArgs = {
  in?: InputMaybe<CouponDeleteInput>
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationCouponUpdateArgs = {
  in?: InputMaybe<CouponUpdateInput>
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationCreateRsShipmentArgs = {
  in: RsShipmentInput
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationCustomerAddressSaveArgs = {
  in: AddressInput
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationCustomerDetailsSaveArgs = {
  in: CustomerDetailInput
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationCustomerOrderCreateArgs = {
  in: CustomerOrderInput
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationCustomerRefundCreateArgs = {
  in: CustomerRefundCreateInput
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationCustomerRefundSettleArgs = {
  in: CustomerRefundSettleInput
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationDeletePlantTypeArgs = {
  id: Scalars["Int"]
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationEditAskDanteUserTagsArgs = {
  tags: Array<Scalars["String"]>
  userId: Scalars["String"]
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationFreshdeskTicketCreateArgs = {
  in: FreshdeskTicketInput
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationOrderCancelArgs = {
  in: OrderCancelInput
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationOrderChangeArgs = {
  in: OrderChangeInput
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationOrderItemDistributeArgs = {
  in: OrderItemDistributeInput
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationOrderItemResetArgs = {
  in: OrderItemResetInput
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationOrderItemsCancelArgs = {
  in: OrderItemsCancelInput
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationOrderSplitArgs = {
  in: OrderSplitInput
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationPackErrorResolveArgs = {
  id: Scalars["Int"]
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationRabenShipmentCreateArgs = {
  in: RabenShipmentInput
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationRmlShipmentCreateArgs = {
  in: RmlShipmentInput
  printer: Scalars["String"]
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationRoundCreateArgs = {
  in: RoundCreateInput
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationRoundItemEditDescriptionArgs = {
  in: RoundItemEditDescriptionInput
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationRoundItemsAddArgs = {
  in: Array<RoundItemAddInput>
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationRoundItemsEditArgs = {
  in: RoundItemEditInput
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationRoundItemsSplitArgs = {
  in: RoundItemsSplitInput
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationSavePlantTypeArgs = {
  id?: InputMaybe<Scalars["Int"]>
  name: Scalars["String"]
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationSendOrderListArgs = {
  in: SendOrderListInput
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationShipLabelArgs = {
  in: ShiplabelInput
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationShipLabelCancelArgs = {
  id: Scalars["String"]
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationSortOrderArgs = {
  in: SortOrderInput
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationSpecialChangesArgs = {
  in: SpecialChangesInput
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationSupplierCreateArgs = {
  in: SupplierInput
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationSupplierRoundItemDeleteArgs = {
  roundListIDs: Array<Scalars["Int"]>
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationSupplierRoundItemMoveArgs = {
  newSupplierID: Scalars["Int"]
  quantity: Scalars["Int"]
  round: Scalars["Int"]
  roundListID: Scalars["Int"]
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationSupplierRoundNoteSaveArgs = {
  note: Scalars["String"]
  round: Scalars["Int"]
  supplierID: Scalars["Int"]
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationSupplierUpdateArgs = {
  in: SupplierInput
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationTagItemArgs = {
  barcode: Scalars["String"]
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationTimelineInsertArgs = {
  in: TimelineInput
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationUserSaveArgs = {
  in: UserUpdateInput
}

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationUserSettingSaveArgs = {
  in: UserSettingSaveInput
}

export type NewOrdersByStatusRes = {
  __typename?: "NewOrdersByStatusRes"
  rows: Array<PlantOrderSum>
}

export type NoteInput = {
  nr: Scalars["Int"]
  txt: Scalars["String"]
}

export type Order = {
  __typename?: "Order"
  deliveryAddress?: Maybe<Address>
  /** total item count */
  itemCount: Scalars["Int"]
  items: Array<Article>
  orderID: Scalars["Int"]
  packageCount: Scalars["Int"]
  /** total plant count */
  plantCount: Scalars["Int"]
  sh: Scalars["Int"]
  status: Scalars["Int"]
  targetDate: Scalars["String"]
}

export type OrderBillPdfResponse = {
  __typename?: "OrderBillPDFResponse"
  msg: Scalars["String"]
  pdfBase64: Scalars["String"]
}

export enum OrderCancelAction {
  CreditBackToCustomerDeposit = "CREDIT_BACK_TO_CUSTOMER_DEPOSIT",
  TransferToBankAccount = "TRANSFER_TO_BANK_ACCOUNT",
}

export type OrderCancelInput = {
  action: OrderCancelAction
  orderID: Scalars["String"]
}

export type OrderChangeInput = {
  Frachtpausch: Scalars["Float"]
  articles: Array<OrderItemInput>
  custnote: Scalars["String"]
  deliveryAdrId: Scalars["Int"]
  notiz: Scalars["String"]
  orderID: Scalars["String"]
  rabatt: Scalars["Float"]
  secretnote: Scalars["String"]
  status: Scalars["Int"]
  zielDatum: Scalars["String"]
}

export type OrderDetails = {
  __typename?: "OrderDetails"
  customerEmail: Scalars["String"]
  customerID: Scalars["Int"]
  /**
   * telephone number or mobile number
   * mobile is preferred if available
   */
  customerPhone: Scalars["String"]
  invoiceSum: Scalars["Float"]
  note: Scalars["String"]
  orderID: Scalars["Int"]
  paymentMethod: Scalars["String"]
  secretNote: Scalars["String"]
  statusID: Scalars["Int"]
  targetShipDate: Scalars["String"]
}

export type OrderDetailsMore = {
  __typename?: "OrderDetailsMore"
  bezahlt: Scalars["Float"]
  couponKey: Scalars["String"]
  couponValue: Scalars["Float"]
  custnote: Scalars["String"]
  datum: Scalars["String"]
  deliveryAdr: Address
  frachtpausch: Scalars["Float"]
  kundnum: Scalars["Int"]
  notiz: Scalars["String"]
  orderID: Scalars["Int"]
  orderItems: Array<OrderItem>
  packages: Array<PackageStat>
  rabatt: Scalars["Float"]
  rechnum: Scalars["Int"]
  rechsum: Scalars["Float"]
  roundName: Scalars["String"]
  rue: Scalars["Float"]
  rueText: Scalars["String"]
  secretnote: Scalars["String"]
  sh: Scalars["Int"]
  status: Scalars["Int"]
  urorder: Scalars["Int"]
  zahlart: Scalars["String"]
  zieldat: Scalars["String"]
}

export type OrderItem = {
  __typename?: "OrderItem"
  anzahl: Scalars["Int"]
  artnum: Scalars["Int"]
  bestellt: Scalars["Boolean"]
  bis: Scalars["Int"]
  botname: Scalars["String"]
  etikettiert: Scalars["Boolean"]
  ewID: Scalars["Int"]
  lager: Scalars["String"]
  source: Scalars["String"]
  splitstatus: Scalars["Boolean"]
  stahoeh: Scalars["Int"]
  stubis: Scalars["Int"]
  stueckpreis: Scalars["Float"]
  stuvon: Scalars["Int"]
  verteilt: Scalars["Boolean"]
  von: Scalars["Int"]
}

export type OrderItemCancelInput = {
  articleId: Scalars["String"]
  count: Scalars["Int"]
}

export type OrderItemDistributeInput = {
  barcode: Scalars["String"]
  hasSuffixStep1: Scalars["Boolean"]
}

export type OrderItemInput = {
  anzahl: Scalars["Int"]
  bdb: Scalars["Int"]
  ewId: Scalars["Int"]
  stueckpreis: Scalars["Float"]
}

export type OrderItemResetInput = {
  barcode: Scalars["String"]
  hasSuffix: Scalars["Boolean"]
}

export type OrderItemsCancelInput = {
  action: OrderCancelAction
  orderID: Scalars["String"]
  orderItems: Array<OrderItemCancelInput>
}

export type OrderListInput = {
  date: Scalars["String"]
  supplierID: Scalars["Int"]
}

export type OrderListPreviewPdfResponse = {
  __typename?: "OrderListPreviewPDFResponse"
  msg: Scalars["String"]
  pdfBase64: Scalars["String"]
}

export type OrderSplitInput = {
  items: Array<SplitItemInput>
  mergeStatuses: Array<Scalars["Int"]>
  targetStatus: Scalars["Int"]
}

export type OrderStatus = {
  __typename?: "OrderStatus"
  etiStop: Scalars["Boolean"]
  packStop: Scalars["Boolean"]
  remark: Scalars["String"]
  status: Scalars["Int"]
  verteilenStop: Scalars["Boolean"]
}

export type PackError = {
  __typename?: "PackError"
  addressID: Scalars["Int"]
  created: Scalars["String"]
  customerID: Scalars["Int"]
  detail: Scalars["String"]
  id: Scalars["Int"]
  orderID: Scalars["Int"]
  packer: Scalars["String"]
}

export type PackageStat = {
  __typename?: "PackageStat"
  carrier: Scalars["String"]
  createdAt: Scalars["String"]
  id: Scalars["Int"]
  labelURL: Scalars["String"]
  orderID: Scalars["Int"]
  packageNumber: Scalars["String"]
  ps: Scalars["String"]
  trackingURL: Scalars["String"]
}

/**
 * use one of these:
 * height is kinda trivial one pallet == one place in the container
 * 1 - 120x80x100  - 150kg		  - euro pallet 			  ep
 * 2 - 60x80x100   -	75kg		  - half pallet 			  ds
 * 3 - 40x60x100   -	50kg		  - quarter pallet		  cp
 */
export type PalletInput = {
  Depth: Scalars["Float"]
  Height: Scalars["Float"]
  KG: Scalars["Float"]
  TypeCode: Scalars["String"]
  Width: Scalars["Float"]
}

export type Performance = {
  __typename?: "Performance"
  itemsDispatched: Scalars["Int"]
  itemsDistributed: Scalars["Int"]
  itemsTagged: Scalars["Int"]
  worker: Scalars["String"]
}

export type PlantOrderSum = {
  __typename?: "PlantOrderSum"
  bdb: Scalars["String"]
  botname: Scalars["String"]
  quantity: Scalars["Int"]
  singles: Scalars["Int"]
  soldStatus: Scalars["String"]
  supplier: Scalars["String"]
}

export type PlantType = {
  __typename?: "PlantType"
  id: Scalars["Int"]
  name: Scalars["String"]
}

export type PrintDeliveryNoteInput = {
  orderID: Scalars["Int"]
  printerIpPort: Scalars["String"]
}

export type Printer = {
  __typename?: "Printer"
  printerID: Scalars["Int"]
  printerIP: Scalars["String"]
  printerName: Scalars["String"]
  printerPort: Scalars["String"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type Query = {
  __typename?: "Query"
  /** returns the 4 active rounds back */
  activeRounds: Array<Scalars["String"]>
  /**
   * articleChangelog get all changes for a specific article
   * what was done in the past on this article
   */
  articleChangelog: Array<ArticleChangelogItem>
  /** articleCheckEanExists checks if an ean exists in the db */
  articleCheckEanExists: Scalars["Boolean"]
  /**
   * articleDetails gives you back information about a specific article
   * BY BDB
   */
  articleDetails: ArticleDetail
  /** query all data from the inventory report */
  articleInventoryReports: Array<ArticleInventoryReport>
  /** articlePriceHistory get all price changes for a specific article */
  articlePriceHistory: Array<ArticlePriceHistory>
  /**
   * search article database for a specific article by a search term which will include search by:
   * BDB
   * Botanic Name
   */
  articleSearch: Array<ArticleItem>
  /** articleStatus retrieves the current state of an order */
  articleStatus: Order
  /** get all supplier for one article ordered by priority */
  articleSupplier: Array<ArticleSupplier>
  /** articleSupplierInfo get each specific orderItem (not summed up) for a supplierId and round */
  articleSupplierInfo: Array<SupplierOrderItem>
  /** articleSupplierRefs get all other suppliers for articles that are also ordered from the given supplierId and round */
  articleSupplierRefs: Array<ArticleSupplierCrossRef>
  /** askDanteUsers get all askDante users with corresponding tags */
  askDanteUsers: Array<AskDanteUser>
  /** Get a list of all cartons, which were created */
  boxList: Array<Carton>
  /** Collect a list of box and their statistics with the given parameters. The start and end are required and shouldn't be empty. The carrier and orderId are optional. */
  boxStatistics: Array<CartonStatistic>
  /** Get a list of all carriers which are used in the package journal */
  carriers: Array<Scalars["String"]>
  /** get the specified coupon */
  couponById?: Maybe<Coupon>
  /** returns true if at least one coupon with the specified key exists */
  couponKeyInUse?: Maybe<Scalars["Boolean"]>
  /** gets coupons active on the specified date */
  couponsByActiveDate: Array<Coupon>
  /** get the specified coupon by key */
  couponsByKey: Array<Coupon>
  customerAddress: Array<Address>
  customerById: CustomerDetail
  customerOrders: Array<CustomerOrder>
  /** customerRefunds get all customer refunds */
  customerRefunds: Array<CustomerRefund>
  /**
   * customerSearch search for a user by a search term
   * database will be searched for:
   *   id
   *   first_name
   *   last_name
   *   email
   *   username
   */
  customerSearch: Array<CustomerSearchRes>
  /** download a pdf as base64 string from a specific url and return it as a string base64 */
  downloadPdf: Scalars["String"]
  /** get all employee statistics within the specified time range */
  employeeStats: EmployeeStatsOutput
  /** export pdf bill for a specific order */
  exportBill: Scalars["String"]
  /** getEtikettenRound get all etiketten data for a specific round */
  getEtikettenRound: Array<EtikettenData>
  /**
   * getNextRoundNumber get the next round number
   * (according to the database check which could be the next round number for displaying purpose in the frontend so the user
   * can create a new round with the next round number)
   */
  getNextRoundNumber: Scalars["Int"]
  /** get plant type by bdb */
  getPlantType: Array<PlantType>
  /** libraList get a list of all libras on the db */
  libraList: Array<Libra>
  /**
   * login with username and password and get a JWT token which can be used for authentication in the authorization header.
   * for example: Authorization: "Bearer <token>"
   * the token is valid for 24 hours. For security reasons, the token is not stored in the database and is not refreshable.
   * this endpoint can't be used here, because it would introduce too much overhead on the server with parsing the GQL.
   * USE login REST ENDPOINT INSTEAD it has the same type and input in a restful way.
   */
  login: AuthResponse
  /** Get the logistic statistics of the current day back. */
  logisticStat: LogisticStat
  newOrdersByStatus: NewOrdersByStatusRes
  /** get order details by order id */
  orderByID: OrderDetailsMore
  /**
   * orderByStatus get all customer created orders with article details as list
   * by a specific status
   */
  orderByStatus: Array<RoundCreateOrder>
  /** get all order lists with supplier which can be printed */
  orderListGetPrintItems: Array<RoundOrderPrintItem>
  /** preview orderList pdf for a specific round and supplier */
  orderListPreviewPDF: OrderListPreviewPdfResponse
  /**
   * print orderList pdf for a specific round and supplier array (multiple suppliers)
   * @returns message if the pdf was printed successfully (or not with a good message for the ENDUSER)
   * the STRING return value contains expected errors from the server
   * the supplierIDs get iterated and each request ist printed synchronously
   * IF one fails the FIRST error is returned and displayed (in the graphql errors)
   */
  orderListPrintPDFs: Scalars["String"]
  /** orderStatus list all order status */
  orderStatus: Array<OrderStatus>
  /** get timeline entries for a specific order */
  orderTimeline: Array<Timeline>
  /** Get a list of all pack errors. */
  packErrors: Array<PackError>
  /**
   * get a list of all sent pacakges filtered by from to dates in the format of:
   * 2012-2-2 = yyyy-MM-dd without the prefix 0
   */
  packageStats: Array<PackageStat>
  packageStatsByOrderID: Array<PackageStat>
  /**
   * get the performance of the workers with a date time span.
   * with the format: yyyy-MM-dd
   * the reason we use that format is that the database can work with that
   */
  performance: Array<Performance>
  /** plantTypes get all plant types */
  plantTypes: Array<PlantType>
  /**
   * printDailyQRcode from a zebraprinter ( scan barcode on the printer )
   * each day a token is generated and saved in the db
   * it will be valid until 23:59:59
   */
  printDailyQRCode: Scalars["Boolean"]
  /** print a delivery note for an order (by order id) the server checks if the note was already printed once */
  printDeliveryNote: Scalars["Boolean"]
  /** print etiketten for a specific round and supplierIds on printerName (cups) */
  printEtiketten: Scalars["String"]
  /** print orderList kistenZettel for a specific round ( all orders from customers ) */
  printOrderListKistenZettel: Scalars["String"]
  /** print rs logistic label again */
  printRsLabel: Scalars["Boolean"]
  printShipcloudLabel: Scalars["Boolean"]
  /** get a list of all printers */
  printers: Array<Printer>
  /** docID is provided from raben */
  rabenLabelPrint: Scalars["Boolean"]
  /** rmlLabelPrint */
  rmlLabelPrint: Scalars["Boolean"]
  /** roundOrderItems get all order items for a specific round */
  roundOrderList: RoundOrderListResponse
  /** roundOrderdata get all order data for a specific round */
  roundOrderdata: Array<RoundOrderdata>
  /** gets the data required for the round order info screen */
  roundOrdersGet: RoundOrdersResponse
  /** gets the data required for the round overview screen */
  roundOverviewGet: RoundOverviewResponse
  /**
   * Collect the statistics for the given rounds.
   * For example if in the input rounds: [1,2,3] is given, the statistics for the first three rounds will be returned.
   * If none are given, the statistics for the current 4 rounds will be returned.
   */
  roundStats: Array<RoundStat>
  /** Collect a list of supplier with statistic of the given round. */
  roundSuppliers: Array<RoundSupplier>
  /** gets all round id's */
  roundsGet: RoundsGetResponse
  /** returns the sales stats for the given date range */
  salesStats: Array<SalesStat>
  /** get details for an order */
  shipmentDetails: ShipmentDetails
  /**
   * supplier ordered items get items that have been ordered from suppliers in
   * a specific round.
   * It will all be fetched and can be filtered in the frontend.
   */
  supplierOrderedItems: Array<SupplierOrderedItems>
  /** supplierRoundNotes get all notes for a specific a specific round */
  supplierRoundNotes: Array<SupplierRoundNote>
  /** Get a list of all suppliers. */
  suppliers: Array<Supplier>
  /** userAchievment get the current worker performance(achievment) for the current day */
  userAchievment: UserHistory
  /** Get a list of all users. */
  users: Array<User>
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryArticleChangelogArgs = {
  bdb: Scalars["String"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryArticleCheckEanExistsArgs = {
  ean: Scalars["String"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryArticleDetailsArgs = {
  bdb: Scalars["String"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryArticleInventoryReportsArgs = {
  bdb: Scalars["String"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryArticlePriceHistoryArgs = {
  bdb: Scalars["String"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryArticleSearchArgs = {
  t: Scalars["String"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryArticleStatusArgs = {
  barcode: Scalars["String"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryArticleSupplierArgs = {
  bdb: Scalars["String"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryArticleSupplierInfoArgs = {
  round: Scalars["Int"]
  supplierID: Scalars["Int"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryArticleSupplierRefsArgs = {
  round: Scalars["Int"]
  supplierID: Scalars["Int"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryBoxStatisticsArgs = {
  carrier: Scalars["String"]
  end: Scalars["String"]
  orderId: Scalars["String"]
  start: Scalars["String"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryCouponByIdArgs = {
  in?: InputMaybe<CouponByIdInput>
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryCouponKeyInUseArgs = {
  key: Scalars["String"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryCouponsByActiveDateArgs = {
  in: CouponsByActiveDateInput
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryCouponsByKeyArgs = {
  in: CouponsByKeyInput
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryCustomerAddressArgs = {
  customerID: Scalars["Int"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryCustomerByIdArgs = {
  customerID: Scalars["Int"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryCustomerOrdersArgs = {
  customerID: Scalars["Int"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryCustomerSearchArgs = {
  in: CustomerSearchInput
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryDownloadPdfArgs = {
  carrier: Scalars["String"]
  url: Scalars["String"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryEmployeeStatsArgs = {
  in: EmployeeStatsInput
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryExportBillArgs = {
  orderID: Scalars["Int"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryGetEtikettenRoundArgs = {
  round: Scalars["Int"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryGetPlantTypeArgs = {
  bdb: Scalars["String"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryLoginArgs = {
  in: AuthRequest
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryNewOrdersByStatusArgs = {
  status: Scalars["String"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryOrderByIdArgs = {
  orderID: Scalars["Int"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryOrderByStatusArgs = {
  status: Scalars["String"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryOrderListGetPrintItemsArgs = {
  round: Scalars["Int"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryOrderListPreviewPdfArgs = {
  date: Scalars["String"]
  round: Scalars["Int"]
  supplierID: Scalars["Int"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryOrderListPrintPdFsArgs = {
  printerName: Scalars["String"]
  round: Scalars["Int"]
  supplier: Array<OrderListInput>
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryOrderTimelineArgs = {
  orderID: Scalars["Int"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryPackageStatsArgs = {
  from: Scalars["String"]
  to: Scalars["String"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryPackageStatsByOrderIdArgs = {
  orderID: Scalars["String"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryPerformanceArgs = {
  from: Scalars["String"]
  to: Scalars["String"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryPrintDailyQrCodeArgs = {
  printerID: Scalars["String"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryPrintDeliveryNoteArgs = {
  in: PrintDeliveryNoteInput
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryPrintEtikettenArgs = {
  isSteck: Scalars["Boolean"]
  printerIpPort: Scalars["String"]
  round: Scalars["Int"]
  selectedEwIds: Array<Scalars["Int"]>
  supplierIDs: Array<Scalars["Int"]>
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryPrintOrderListKistenZettelArgs = {
  orderIds: Array<Scalars["Int"]>
  printerIpPort: Scalars["String"]
  round: Scalars["Int"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryPrintRsLabelArgs = {
  printerIpPort: Scalars["String"]
  rsLabelURL: Scalars["String"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryPrintShipcloudLabelArgs = {
  printerIpPort: Scalars["String"]
  shipcloudLabelURL: Scalars["String"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryRabenLabelPrintArgs = {
  docID: Scalars["String"]
  printerIpPort: Scalars["String"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryRmlLabelPrintArgs = {
  printerIpPort: Scalars["String"]
  rmlOrderID: Scalars["String"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryRoundOrderListArgs = {
  round: Scalars["Int"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryRoundOrderdataArgs = {
  round: Scalars["Int"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryRoundOrdersGetArgs = {
  in: RoundOrdersInput
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryRoundOverviewGetArgs = {
  in: RoundOverviewInput
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryRoundStatsArgs = {
  in: RoundStatInput
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryRoundSuppliersArgs = {
  in: Scalars["Int"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QuerySalesStatsArgs = {
  from: Scalars["String"]
  to: Scalars["String"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryShipmentDetailsArgs = {
  orderID: Scalars["String"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QuerySupplierOrderedItemsArgs = {
  round: Scalars["Int"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QuerySupplierRoundNotesArgs = {
  round: Scalars["Int"]
}

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryUserAchievmentArgs = {
  userID: Scalars["Int"]
}

export type RabenShipmentInput = {
  CountryCode: Scalars["String"]
  avd: Scalars["Boolean"]
  city: Scalars["String"]
  email: Scalars["String"]
  logisticService: Scalars["String"]
  myd: Scalars["Boolean"]
  name: Scalars["String"]
  orderID: Scalars["Int"]
  pallet: PalletInput
  phone: Scalars["String"]
  postalCode: Scalars["String"]
  printerIpPort: Scalars["String"]
  street: Scalars["String"]
}

export type RabenShipmentResponse = {
  __typename?: "RabenShipmentResponse"
  DocID: Scalars["String"]
}

export type RatingValue = {
  __typename?: "RatingValue"
  count: Scalars["Int"]
  rating: Scalars["Int"]
}

export type RmlHinweis = {
  code: Scalars["String"]
  info: Scalars["String"]
}

export type RmlShipmentInput = {
  comment: Scalars["String"]
  deliveryAdrId: Scalars["Int"]
  heightMM: Scalars["Int"]
  hinweise: Array<RmlHinweis>
  lengthMM: Scalars["Int"]
  orderGoodDescription: Scalars["String"]
  orderID: Scalars["String"]
  pallet: Scalars["String"]
  termcode: Scalars["String"]
  termdata: Scalars["String"]
  weightGramm: Scalars["Int"]
  widthMM: Scalars["Int"]
}

export type Round = {
  __typename?: "Round"
  id: Scalars["Int"]
  name: Scalars["String"]
  round: Scalars["Int"]
}

export type RoundCreateInput = {
  endDate: Scalars["String"]
  round: Scalars["Int"]
  roundName: Scalars["String"]
  startDate: Scalars["String"]
  status: Scalars["Int"]
}

export type RoundCreateOrder = {
  __typename?: "RoundCreateOrder"
  bdb: Scalars["String"]
  botname: Scalars["String"]
  count: Scalars["Int"]
  ewid: Scalars["String"]
  orderID: Scalars["String"]
  supplier?: Maybe<SupplierInfo>
}

export type RoundCreateResponse = {
  __typename?: "RoundCreateResponse"
  msg: Scalars["String"]
}

export type RoundDataRow = {
  __typename?: "RoundDataRow"
  crates: Scalars["Int"]
  date: Scalars["String"]
  delivered: Scalars["Int"]
  id: Scalars["Int"]
  name: Scalars["String"]
  ordered: Scalars["Int"]
  plants: Scalars["Int"]
  round: Scalars["Int"]
  sentMessages: Scalars["Int"]
  singleSum: Scalars["Int"]
  totalMessages: Scalars["Int"]
}

export type RoundItemAddInput = {
  articleID: Scalars["Int"]
  count: Scalars["Int"]
  round: Scalars["Int"]
  supplierID: Scalars["Int"]
}

export type RoundItemEditDescriptionInput = {
  articleID: Scalars["Int"]
  description: Scalars["String"]
  round: Scalars["Int"]
  supplierID: Scalars["Int"]
}

export type RoundItemEditInput = {
  articleID: Scalars["Int"]
  count: Scalars["Int"]
  round: Scalars["Int"]
  supplierID: Scalars["Int"]
}

export type RoundItemsSplitInput = {
  mergeStatuses: Array<Scalars["Int"]>
  roundListIds: Array<Scalars["Int"]>
  statusCode: Scalars["Int"]
}

export type RoundItemsSplitResponse = {
  __typename?: "RoundItemsSplitResponse"
  message: Scalars["String"]
}

export type RoundOrderArticle = {
  __typename?: "RoundOrderArticle"
  articleId: Scalars["String"]
  botName: Scalars["String"]
  description: Scalars["String"]
  position: Scalars["Int"]
  quantityForCustomers: Scalars["Int"]
  quantityForStorage: Scalars["Int"]
  quantityTotal: Scalars["Int"]
  sizeMax: Scalars["Int"]
  sizeMin: Scalars["Int"]
  supplierContainerSize: Scalars["String"]
  supplierID: Scalars["Int"]
  supplierName: Scalars["String"]
  variant: Scalars["String"]
}

export type RoundOrderItem = {
  __typename?: "RoundOrderItem"
  email: Scalars["String"]
  emailSentAt: Scalars["String"]
  fax: Scalars["String"]
  faxSentAt: Scalars["String"]
  flavour: Scalars["Int"]
  g24: Scalars["Boolean"]
  inStorage: Scalars["Boolean"]
  phone: Scalars["String"]
  round: Scalars["Int"]
  sendEmail: Scalars["Boolean"]
  sendFax: Scalars["Boolean"]
  supplierID: Scalars["Int"]
  supplierName: Scalars["String"]
}

export type RoundOrderListResponse = {
  __typename?: "RoundOrderListResponse"
  items: Array<RoundOrderItem>
  roundExists: Scalars["Boolean"]
}

export type RoundOrderPrintItem = {
  __typename?: "RoundOrderPrintItem"
  emailSentAt: Scalars["String"]
  faxSentAt: Scalars["String"]
  supplierID: Scalars["Int"]
  supplierName: Scalars["String"]
}

export type RoundOrderdata = {
  __typename?: "RoundOrderdata"
  city: Scalars["String"]
  country: Scalars["String"]
  firstName: Scalars["String"]
  kundnum: Scalars["Int"]
  lastName: Scalars["String"]
  notiz: Scalars["String"]
  orderid: Scalars["Int"]
  sh: Scalars["Int"]
  status: Scalars["Int"]
  street: Scalars["String"]
  totalArticleCount: Scalars["Int"]
  zahlart: Scalars["String"]
  zieldat: Scalars["String"]
  zip: Scalars["String"]
}

export type RoundOrdersInput = {
  round: Scalars["Int"]
}

export type RoundOrdersResponse = {
  __typename?: "RoundOrdersResponse"
  orderArticles: Array<RoundOrderArticle>
  totalOrdersCount: Scalars["Int"]
}

export type RoundOverviewInput = {
  currentPage: Scalars["Int"]
  rowsPerPage: Scalars["Int"]
}

export type RoundOverviewResponse = {
  __typename?: "RoundOverviewResponse"
  roundDataRows: Array<RoundDataRow>
  totalRoundCount: Scalars["Int"]
}

/** represents a round of the system. */
export type RoundStat = {
  __typename?: "RoundStat"
  articleCount: Scalars["Int"]
  distributedArticleCount: Scalars["Int"]
  idExists: Scalars["Boolean"]
  openOrderCount: Scalars["Int"]
  orderCount: Scalars["Int"]
  percentage: Scalars["Int"]
  roundID: Scalars["Int"]
  roundName: Scalars["String"]
  startDate: Scalars["String"]
  taggedArticleCount: Scalars["Int"]
}

export type RoundStatInput = {
  rounds: Array<Scalars["String"]>
}

/** represents a supplier of the system. */
export type RoundSupplier = {
  __typename?: "RoundSupplier"
  expected: Scalars["Int"]
  rest: Scalars["Int"]
  supplier: Scalars["String"]
  supplierID: Scalars["Int"]
  tagged: Scalars["Int"]
  taggedbybot: Scalars["Int"]
}

export type RoundsGetResponse = {
  __typename?: "RoundsGetResponse"
  rounds: Array<Round>
}

export type RsShipmentInput = {
  GP: Scalars["Int"]
  HP: Scalars["Int"]
  SP: Scalars["Int"]
  VP: Scalars["Int"]
  carrierID: Scalars["String"]
  mainNote: Scalars["String"]
  notes: Array<NoteInput>
  orderID: Scalars["String"]
  /** if true the package will be placed on the property of the customer. */
  permitToPlacePackage: Scalars["Boolean"]
  printerName: Scalars["String"]
}

export type RsShipmentResponse = {
  __typename?: "RsShipmentResponse"
  /**
   * a list of things that went wrong during the shipment process
   * it's purpose is to give the user information about what went wrong
   */
  Info: Array<Scalars["String"]>
  PDFURL: Scalars["String"]
}

export type SalesStat = {
  __typename?: "SalesStat"
  artnum: Scalars["Int"]
  /** a entry for a sales stats for a specific day */
  botname: Scalars["String"]
  count: Scalars["Int"]
  from: Scalars["Int"]
  gername: Scalars["String"]
  pflNR: Scalars["Int"]
  price: Scalars["Float"]
  to: Scalars["Int"]
}

export type ScanResponse = {
  __typename?: "ScanResponse"
  orderDetails?: Maybe<Order>
  scannedArticle?: Maybe<Article>
  status: Status
}

export type SendOrderListInput = {
  round: Scalars["Int"]
  sendEmail: Scalars["Boolean"]
  sendFax: Scalars["Boolean"]
  supplier: Array<OrderListInput>
}

export type ShiplabelInput = {
  LibraIpPort: Scalars["String"]
  PrinterIpPort: Scalars["String"]
  carrier: Carrier
  cartonBarcode: Scalars["String"]
  orderID: Scalars["String"]
}

export type ShipmentDetails = {
  __typename?: "ShipmentDetails"
  addressDetails: Address
  custEmail: Scalars["String"]
  custMobile: Scalars["String"]
  custPhone: Scalars["String"]
  msg: Array<Scalars["String"]>
  orderDetails: OrderDetails
  payOnDelivery: Scalars["Boolean"]
  pdfURL: Scalars["String"]
  /** if set to true the package will be placed at the garage or something */
  permitToPlacePackage: Scalars["Boolean"]
}

export type SortOrderInput = {
  createNewRound: Scalars["Boolean"]
  newStatus: Scalars["String"]
  oldStatus: Scalars["String"]
  onlySingles: Scalars["Boolean"]
  plantTypeIDs: Array<Scalars["Int"]>
  preview: Scalars["Boolean"]
  roundDetails?: InputMaybe<RoundCreateInput>
  splitSoldOut: Scalars["Boolean"]
  supplierIDs: Array<Scalars["Int"]>
}

export type SortOrderRes = {
  __typename?: "SortOrderRes"
  message: Scalars["String"]
  preview: Array<PlantOrderSum>
}

export type SpecialChangesInput = {
  barcode: Scalars["String"]
  isPalletArticle: Scalars["Boolean"]
  pmLocation?: InputMaybe<Scalars["String"]>
}

export type SplitItemInput = {
  articleId: Scalars["Int"]
  custId: Scalars["Int"]
  orderId: Scalars["Int"]
}

export enum Status {
  AlreadyDistributed = "ALREADY_DISTRIBUTED",
  AlreadyTagged = "ALREADY_TAGGED",
  BarcodeInvalid = "BARCODE_INVALID",
  OrderComplete = "ORDER_COMPLETE",
  PlantSplitted = "PLANT_SPLITTED",
  Stop = "STOP",
  Success = "SUCCESS",
}

export type Supplier = {
  __typename?: "Supplier"
  G24: Scalars["Boolean"]
  active: Scalars["Boolean"]
  city: Scalars["String"]
  doegeGBF: Scalars["Boolean"]
  email: Scalars["String"]
  fax: Scalars["String"]
  flavour: Scalars["Int"]
  id: Scalars["Int"]
  inStorage: Scalars["Boolean"]
  name: Scalars["String"]
  phone: Scalars["String"]
  sendEmail: Scalars["Boolean"]
  sendFax: Scalars["Boolean"]
  street: Scalars["String"]
  zip: Scalars["String"]
}

export type SupplierInfo = {
  __typename?: "SupplierInfo"
  id: Scalars["Int"]
  name: Scalars["String"]
}

export type SupplierInput = {
  G24: Scalars["Boolean"]
  GBF: Scalars["Boolean"]
  active: Scalars["Boolean"]
  city: Scalars["String"]
  email: Scalars["String"]
  fax: Scalars["String"]
  flavour: Scalars["Int"]
  /** set the id to 0 if you want to create a new supplier. */
  id: Scalars["Int"]
  inStorage: Scalars["Boolean"]
  name: Scalars["String"]
  phone: Scalars["String"]
  sendEmail: Scalars["Boolean"]
  sendFax: Scalars["Boolean"]
  street: Scalars["String"]
  zip: Scalars["String"]
}

export type SupplierOrder = {
  __typename?: "SupplierOrder"
  articleID: Scalars["String"]
  botanicName: Scalars["String"]
  count: Scalars["Int"]
  gerName: Scalars["String"]
  orderID: Scalars["String"]
  orderItemID: Scalars["String"]
  round: Scalars["Int"]
  sh: Scalars["Int"]
  source: Scalars["Int"]
  source1: Scalars["String"]
  source1_p: Scalars["Float"]
  source2: Scalars["String"]
  source2_p: Scalars["Float"]
  source3: Scalars["String"]
  source3_p: Scalars["Float"]
  source4: Scalars["String"]
  source4_p: Scalars["Float"]
  source5: Scalars["String"]
  source5_p: Scalars["Float"]
}

export type SupplierOrderItem = {
  __typename?: "SupplierOrderItem"
  bdb: Scalars["String"]
  botname: Scalars["String"]
  ewID: Scalars["Int"]
  id: Scalars["Int"]
  orderID: Scalars["Int"]
  quantity: Scalars["Int"]
  size: Scalars["String"]
  sorte: Scalars["String"]
  supplierID: Scalars["Int"]
  supplierName: Scalars["String"]
}

export type SupplierOrderedItems = {
  __typename?: "SupplierOrderedItems"
  BOTNAME: Scalars["String"]
  Bis: Scalars["Int"]
  CreatedAt: Scalars["String"]
  Ewid: Scalars["Int"]
  Flavour: Scalars["Int"]
  G24: Scalars["Int"]
  GERNAME: Scalars["String"]
  Is24hDelivery: Scalars["Boolean"]
  IsTagged: Scalars["Boolean"]
  Lieferant: Scalars["String"]
  MainbaseBdb: Scalars["Int"]
  Orderid: Scalars["Int"]
  Quantity: Scalars["Int"]
  RoundListID: Scalars["Int"]
  Sorte: Scalars["String"]
  SupplierID: Scalars["Int"]
  Von: Scalars["Int"]
}

export type SupplierRoundNote = {
  __typename?: "SupplierRoundNote"
  note: Scalars["String"]
  supplierId: Scalars["Int"]
}

export type Timeline = {
  __typename?: "Timeline"
  action: Scalars["String"]
  computername: Scalars["String"]
  flavour?: Maybe<Scalars["Int"]>
  id: Scalars["Int"]
  orderID: Scalars["Int"]
  username: Scalars["String"]
  zeitpunkt: Scalars["String"]
}

export type TimelineInput = {
  action: Scalars["String"]
  orderId: Scalars["Int"]
}

/** represents a user of the system. */
export type User = {
  __typename?: "User"
  id: Scalars["Int"]
  language: Scalars["String"]
  /**
   * Priviliges will be saved as a bit mask. The following privileges are available:
   * IF admin is set, all other privileges are ignored.
   * So if you want to compare the privileges, you have to check if the admin flag is set.
   * for example privileges & 1 = 1 -> admin
   * this is and int32 value, so you can combine multiple privileges by adding them up.
   * for example: privileges = 1 + 2  = 3 == 11 in binary == ADMIN | TAG_ITEMS == 1 | 2
   * ADMIN can create new users and assign privileges to them.
   * TAG can tag items as received in the warehouse.
   * DISTRIBUTE can distribute items to the corresponding orders.
   * DISPATCH can dispatch items to the customer in the warehouse.
   * 1: ADMIN
   * 2: TAG
   * 4: DISTRIBUTE
   * 8: DISPATCH
   * 16: SPLIT
   * 32: DEACTIVATE ACCOUNT
   */
  privileges: Scalars["Int"]
  username: Scalars["String"]
}

/** userAchievements, userHistory, and articleHistroy for statusBar */
export type UserHistory = {
  __typename?: "UserHistory"
  articleHistory: Array<ArticleHistory>
  itemsDistributed: Scalars["Int"]
  itemsLabled: Scalars["Int"]
  packagesDispatched: Scalars["Int"]
}

export type UserSettingSaveInput = {
  key: Scalars["String"]
  value: Scalars["String"]
}

export type UserSettingSaveResponse = {
  __typename?: "UserSettingSaveResponse"
  message?: Maybe<Scalars["String"]>
  statusCode: Scalars["Int"]
}

export type UserUpdateInput = {
  id: Scalars["Int"]
  /**
   * if the password is empty, the password will not be changed.
   * since the password is hashed, it's not possible to get the old password back.
   * or lets say it's not easy to get the old password back.
   */
  newPassword: Scalars["String"]
  privileges: Scalars["Int"]
  username: Scalars["String"]
}

export type ActiveRoundsQueryVariables = Exact<{ [key: string]: never }>

export type ActiveRoundsQuery = {
  __typename?: "Query"
  activeRounds: Array<string>
}

export type ArticleDetailsSaveMutationVariables = Exact<{
  in: ArticleDetailsInput
}>

export type ArticleDetailsSaveMutation = {
  __typename?: "Mutation"
  articleDetailsSave: boolean
}

export type ArticleDetailsMultiQueryVariables = Exact<{
  bdb: Scalars["String"]
}>

export type ArticleDetailsMultiQuery = {
  __typename?: "Query"
  articleDetails: {
    __typename?: "ArticleDetail"
    bdb: string
    pflNR: string
    botname: string
    gername: string
    price: string
    reducedPrice: string
    reducedPriceUntil: string
    location: string
    stock: string
    localStock: string
    h24: string
    sizeFrom: string
    sizeTo: string
    trunkSizeFrom: string
    trunkSizeTo: string
    trunkHeight: string
    customerContainerSize: string
    supplierContainerSize: string
    sorte: string
    quali: string
    variant: string
    saleMinCount: string
    salePct: string
    availableFrom: string
    statusAfterSoldOut: string
    supplierAfterSoldOut: number
    articleGroup: number
    plantTypes: Array<{ __typename?: "PlantType"; id: number; name: string }>
  }
  articleSupplier: Array<{
    __typename?: "ArticleSupplier"
    id: number
    articleID: number
    supplierID: number
    supplierName: string
    price: number
    createdAt: string
    updatedAt: string
    createdBy: string
    note: string
    quality: Array<string>
    priority: number
  }>
  articleInventoryReports: Array<{
    __typename?: "ArticleInventoryReport"
    id: number
    pmbdb: string
    realbdb: string
    supplier: string
    supplierID: number
    quality: string
    bestellcode: string
    sticky: boolean
    price: string
    quantity: number
    saugtxt: string
    createdAt: string
  }>
  articleChangelog: Array<{
    __typename?: "ArticleChangelogItem"
    id: number
    bdb: string
    changeDate: string
    userName: string
    computerName: string
    action: string
  }>
  articlePriceHistory: Array<{
    __typename?: "ArticlePriceHistory"
    id: number
    bdb: string
    supplierID: number
    supplierName: string
    price: string
    createdAt: string
    action: string
  }>
  plantTypes: Array<{ __typename?: "PlantType"; id: number; name: string }>
}

export type ArticleSearchQueryVariables = Exact<{
  t: Scalars["String"]
}>

export type ArticleSearchQuery = {
  __typename?: "Query"
  articleSearch: Array<{
    __typename?: "ArticleItem"
    bdb: string
    pflNR: string
    botname: string
    gername: string
    price: string
    reducedPrice: string
    sizeFrom: number
    sizeTo: number
  }>
}

export type ArticleSupplierDeleteMutationVariables = Exact<{
  in: ArticleSupplierInput
}>

export type ArticleSupplierDeleteMutation = {
  __typename?: "Mutation"
  articleSupplierDelete: boolean
}

export type ArticleSupplierSaveMutationVariables = Exact<{
  in: ArticleSupplierInput
}>

export type ArticleSupplierSaveMutation = {
  __typename?: "Mutation"
  articleSupplierSave: {
    __typename?: "ArticleSupplier"
    id: number
    articleID: number
    supplierID: number
    supplierName: string
    price: number
    createdAt: string
    updatedAt: string
    createdBy: string
    note: string
    quality: Array<string>
    priority: number
  }
}

export type ArticleSupplierSwapPrioMutationVariables = Exact<{
  id1: Scalars["Int"]
  id2: Scalars["Int"]
}>

export type ArticleSupplierSwapPrioMutation = {
  __typename?: "Mutation"
  articleSupplierSwapPriority: boolean
}

export type AskDanteUsersQueryVariables = Exact<{ [key: string]: never }>

export type AskDanteUsersQuery = {
  __typename?: "Query"
  askDanteUsers: Array<{
    __typename?: "AskDanteUser"
    id: string
    firstName: string
    lastName: string
    personnelNumber: string
    email: string
    tags: Array<string>
  }>
}

export type BoxCreateMutationVariables = Exact<{
  in: CartonInput
}>

export type BoxCreateMutation = {
  __typename?: "Mutation"
  boxCreate: {
    __typename?: "Carton"
    id: number
    barcode: string
    description: string
    lengthMM: number
    widthMM: number
    heightMM: number
  }
}

export type BoxDeleteMutationVariables = Exact<{
  id: Scalars["Int"]
}>

export type BoxDeleteMutation = { __typename?: "Mutation"; boxDelete: boolean }

export type BoxListQueryVariables = Exact<{ [key: string]: never }>

export type BoxListQuery = {
  __typename?: "Query"
  boxList: Array<{
    __typename?: "Carton"
    id: number
    barcode: string
    description: string
    lengthMM: number
    widthMM: number
    heightMM: number
  }>
}

export type BoxStatisticsQueryVariables = Exact<{
  start: Scalars["String"]
  end: Scalars["String"]
  carrier: Scalars["String"]
  orderId: Scalars["String"]
}>

export type BoxStatisticsQuery = {
  __typename?: "Query"
  boxStatistics: Array<{
    __typename?: "CartonStatistic"
    count: number
    countPercent: number
    carton: {
      __typename?: "Carton"
      id: number
      barcode: string
      description: string
      lengthMM: number
      widthMM: number
      heightMM: number
    }
  }>
}

export type BoxUpdateMutationVariables = Exact<{
  in: CartonInput
}>

export type BoxUpdateMutation = {
  __typename?: "Mutation"
  boxUpdate: {
    __typename?: "Carton"
    id: number
    barcode: string
    description: string
    lengthMM: number
    widthMM: number
    heightMM: number
  }
}

export type CarriersQueryVariables = Exact<{ [key: string]: never }>

export type CarriersQuery = { __typename?: "Query"; carriers: Array<string> }

export type CouponByIdQueryVariables = Exact<{
  in: CouponByIdInput
}>

export type CouponByIdQuery = {
  __typename?: "Query"
  couponById?: {
    __typename?: "Coupon"
    id: number
    key: string
    title: string
    description: string
    value: number
    discount: number
    min_sum: number
    article_id?: string | null
    product_id?: string | null
    valid_from?: string | null
    valid_until?: string | null
    combinable: boolean
    max_uses?: number | null
    no_delivery_costs: boolean
    sale_allowed: boolean
    categoryIds?: Array<string> | null
    note_intern: string
    editable: boolean
  } | null
}

export type CouponCreateMutationVariables = Exact<{
  in: CouponCreateInput
}>

export type CouponCreateMutation = {
  __typename?: "Mutation"
  couponCreate: {
    __typename?: "Coupon"
    id: number
    key: string
    title: string
    description: string
    value: number
    discount: number
    min_sum: number
    article_id?: string | null
    product_id?: string | null
    valid_from?: string | null
    valid_until?: string | null
    combinable: boolean
    max_uses?: number | null
    no_delivery_costs: boolean
    sale_allowed: boolean
    categoryIds?: Array<string> | null
    note_intern: string
  }
}

export type CouponDeleteMutationVariables = Exact<{
  in: CouponDeleteInput
}>

export type CouponDeleteMutation = {
  __typename?: "Mutation"
  couponDelete: boolean
}

export type CouponKeyInUseQueryVariables = Exact<{
  key: Scalars["String"]
}>

export type CouponKeyInUseQuery = {
  __typename?: "Query"
  couponKeyInUse?: boolean | null
}

export type CouponUpdateMutationVariables = Exact<{
  in: CouponUpdateInput
}>

export type CouponUpdateMutation = {
  __typename?: "Mutation"
  couponUpdate: {
    __typename?: "Coupon"
    id: number
    key: string
    title: string
    description: string
    value: number
    discount: number
    min_sum: number
    article_id?: string | null
    product_id?: string | null
    valid_from?: string | null
    valid_until?: string | null
    combinable: boolean
    max_uses?: number | null
    no_delivery_costs: boolean
    sale_allowed: boolean
    categoryIds?: Array<string> | null
    note_intern: string
    editable: boolean
    who_created: string
    created_on: string
  }
}

export type CouponsByActiveDateQueryVariables = Exact<{
  in: CouponsByActiveDateInput
}>

export type CouponsByActiveDateQuery = {
  __typename?: "Query"
  couponsByActiveDate: Array<{
    __typename?: "Coupon"
    id: number
    key: string
    title: string
    description: string
    value: number
    discount: number
    min_sum: number
    article_id?: string | null
    product_id?: string | null
    valid_from?: string | null
    valid_until?: string | null
    combinable: boolean
    max_uses?: number | null
    no_delivery_costs: boolean
    sale_allowed: boolean
    categoryIds?: Array<string> | null
    note_intern: string
    editable: boolean
    who_created: string
    created_on: string
  }>
}

export type CouponsByKeyQueryVariables = Exact<{
  in: CouponsByKeyInput
}>

export type CouponsByKeyQuery = {
  __typename?: "Query"
  couponsByKey: Array<{
    __typename?: "Coupon"
    id: number
    key: string
    title: string
    description: string
    value: number
    discount: number
    min_sum: number
    article_id?: string | null
    product_id?: string | null
    valid_from?: string | null
    valid_until?: string | null
    combinable: boolean
    max_uses?: number | null
    no_delivery_costs: boolean
    sale_allowed: boolean
    categoryIds?: Array<string> | null
    note_intern: string
    editable: boolean
    who_created: string
    created_on: string
  }>
}

export type CreateRsShipmentMutationVariables = Exact<{
  in: RsShipmentInput
}>

export type CreateRsShipmentMutation = {
  __typename?: "Mutation"
  createRsShipment: {
    __typename?: "RsShipmentResponse"
    Info: Array<string>
    PDFURL: string
  }
}

export type CustomerAddressQueryVariables = Exact<{
  customerID: Scalars["Int"]
}>

export type CustomerAddressQuery = {
  __typename?: "Query"
  customerAddress: Array<{
    __typename?: "Address"
    id: number
    company: string
    fullName: string
    firstName: string
    lastName: string
    street: string
    zipCode: string
    city: string
    country: string
    countryCode: string
    anrede: string
  }>
}

export type CustomerAddressSaveMutationVariables = Exact<{
  in: AddressInput
}>

export type CustomerAddressSaveMutation = {
  __typename?: "Mutation"
  customerAddressSave: { __typename?: "Address"; id: number }
}

export type CustomerByIdQueryVariables = Exact<{
  customerID: Scalars["Int"]
}>

export type CustomerByIdQuery = {
  __typename?: "Query"
  customerById: {
    __typename?: "CustomerDetail"
    id: number
    nickName: string
    firstName: string
    lastName: string
    company: string
    email: string
    telefon: string
    handy: string
    street: string
    zip: string
    city: string
    country: string
    inbase: string
    custnote: string
    rechName: string
    rechStreet: string
    rechOrt: string
    rechPlz: string
    anrede: string
  }
}

export type CustomerDetailsSaveMutationVariables = Exact<{
  in: CustomerDetailInput
}>

export type CustomerDetailsSaveMutation = {
  __typename?: "Mutation"
  customerDetailsSave: { __typename?: "CustomerDetail"; id: number }
}

export type CustomerOrderCreateMutationVariables = Exact<{
  in: CustomerOrderInput
}>

export type CustomerOrderCreateMutation = {
  __typename?: "Mutation"
  customerOrderCreate: { __typename?: "CustomerOrder"; orderID: number }
}

export type CustomerOrdersQueryVariables = Exact<{
  customerID: Scalars["Int"]
}>

export type CustomerOrdersQuery = {
  __typename?: "Query"
  customerOrders: Array<{
    __typename?: "CustomerOrder"
    orderID: number
    urorder: number
    datum: string
    zieldat: string
    status: number
    zahlart: string
    rechnum: number
    rechsum: number
    bezahlt: number
    frachtpausch: number
    notiz: string
    custnote: string
  }>
}

export type CustomerRefundsQueryVariables = Exact<{ [key: string]: never }>

export type CustomerRefundsQuery = {
  __typename?: "Query"
  customerRefunds: Array<{
    __typename?: "CustomerRefund"
    id: number
    orderID: number
    articleID?: number | null
    customerID: number
    amount: number
    createdAt: string
    createdBy: string
    settledAt?: string | null
    settledBy?: string | null
    note?: string | null
  }>
}

export type CustomerRefundCreateMutationVariables = Exact<{
  in: CustomerRefundCreateInput
}>

export type CustomerRefundCreateMutation = {
  __typename?: "Mutation"
  customerRefundCreate: {
    __typename?: "CustomerRefund"
    id: number
    orderID: number
    articleID?: number | null
    customerID: number
    amount: number
    createdAt: string
    createdBy: string
    settledAt?: string | null
    settledBy?: string | null
    note?: string | null
  }
}

export type CustomerRefundSettleMutationVariables = Exact<{
  in: CustomerRefundSettleInput
}>

export type CustomerRefundSettleMutation = {
  __typename?: "Mutation"
  customerRefundSettle: {
    __typename?: "CustomerRefund"
    id: number
    orderID: number
    articleID?: number | null
    customerID: number
    amount: number
    createdAt: string
    createdBy: string
    settledAt?: string | null
    settledBy?: string | null
    note?: string | null
  }
}

export type CustomerSearchQueryVariables = Exact<{
  in: CustomerSearchInput
}>

export type CustomerSearchQuery = {
  __typename?: "Query"
  customerSearch: Array<{
    __typename?: "CustomerSearchRes"
    id: number
    nickName: string
    firstName: string
    lastName: string
    email: string
    telefon: string
    handy: string
  }>
}

export type DownloadPdfQueryVariables = Exact<{
  url: Scalars["String"]
  carrier: Scalars["String"]
}>

export type DownloadPdfQuery = { __typename?: "Query"; downloadPdf: string }

export type EtikettenDataQueryVariables = Exact<{
  round: Scalars["Int"]
}>

export type EtikettenDataQuery = {
  __typename?: "Query"
  getEtikettenRound: Array<{
    __typename?: "EtikettenData"
    orderID: number
    bdb: number
    sh: number
    quantity: number
    supplierID: number
    supplierName: string
    ewid: number
    botname: string
    von: number
    bis: number
    sorte: string
    totalBasketItems: number
    posten: number
    etiNum: number
  }>
}

export type PrintEtikettenQueryVariables = Exact<{
  round: Scalars["Int"]
  supplierIds: Array<Scalars["Int"]> | Scalars["Int"]
  printerIpPort: Scalars["String"]
  isSteck: Scalars["Boolean"]
  selectedEwIds: Array<Scalars["Int"]> | Scalars["Int"]
}>

export type PrintEtikettenQuery = {
  __typename?: "Query"
  printEtiketten: string
}

export type ExportBillQueryVariables = Exact<{
  orderID: Scalars["Int"]
}>

export type ExportBillQuery = { __typename?: "Query"; exportBill: string }

export type EmployeeStatsQueryVariables = Exact<{
  from: Scalars["String"]
  to: Scalars["String"]
}>

export type EmployeeStatsQuery = {
  __typename?: "Query"
  employeeStats: {
    __typename?: "EmployeeStatsOutput"
    employeeStatsOutput: string
    stats: Array<{
      __typename?: "EmployeeStats"
      email: string
      tags: Array<string>
      freshdeskId: string
      freshdeskName: string
      askDanteId: string
      askDanteFirstName: string
      askDanteLastName: string
      askDanteRole: string
      workedCount: number
      resolvedCount: number
      closedCount: number
      chatAssignedCount: number
      chatResolvedCount: number
      chatUniqueResolvedCount: number
      chatMessagesCount: number
      chatRatings: Array<{
        __typename?: "RatingValue"
        rating: number
        count: number
      }>
      statsHistory: Array<{
        __typename?: "EmployeeStatsDay"
        date: string
        timeTrackingDuration: number
        conversations: Array<{
          __typename?: "FreshdeskConversation"
          agentId: string
          category: number
          incoming: boolean
          isEmail: boolean
          private: boolean
          source: number
          ticketId: string
          updatedAt: string
        }>
      }>
      tickets: Array<{
        __typename?: "FreshdeskTicket"
        agentId: number
        status: number
        id: string
        updatedAt: string
        type: string
        group: string
      }>
      ratings: Array<{
        __typename?: "FreshdeskRating"
        rating: number
        updatedAt: string
      }>
    }>
  }
}

export type OrderByStatusQueryVariables = Exact<{
  status: Scalars["String"]
}>

export type OrderByStatusQuery = {
  __typename?: "Query"
  orderByStatus: Array<{
    __typename?: "RoundCreateOrder"
    orderID: string
    ewid: string
    bdb: string
    botname: string
    count: number
    supplier?: { __typename?: "SupplierInfo"; id: number; name: string } | null
  }>
}

export type OrderCancelMutationVariables = Exact<{
  in: OrderCancelInput
}>

export type OrderCancelMutation = {
  __typename?: "Mutation"
  orderCancel: boolean
}

export type OrderChangeMutationVariables = Exact<{
  in: OrderChangeInput
}>

export type OrderChangeMutation = {
  __typename?: "Mutation"
  orderChange: boolean
}

export type OrderSplitMutationVariables = Exact<{
  in: OrderSplitInput
}>

export type OrderSplitMutation = { __typename?: "Mutation"; orderSplit: string }

export type NewOrdersByStatusQueryVariables = Exact<{
  status: Scalars["String"]
}>

export type NewOrdersByStatusQuery = {
  __typename?: "Query"
  newOrdersByStatus: {
    __typename?: "NewOrdersByStatusRes"
    rows: Array<{
      __typename?: "PlantOrderSum"
      bdb: string
      botname: string
      quantity: number
      supplier: string
      singles: number
      soldStatus: string
    }>
  }
}

export type RmlShipmentCreateMutationVariables = Exact<{
  in: RmlShipmentInput
  printer: Scalars["String"]
}>

export type RmlShipmentCreateMutation = {
  __typename?: "Mutation"
  rmlShipmentCreate: string
}

export type RmlLabelPrintQueryVariables = Exact<{
  printerIpPort: Scalars["String"]
  rmlOrderID: Scalars["String"]
}>

export type RmlLabelPrintQuery = {
  __typename?: "Query"
  rmlLabelPrint: boolean
}

export type RoundOrdersGetQueryVariables = Exact<{
  round: Scalars["Int"]
}>

export type RoundOrdersGetQuery = {
  __typename?: "Query"
  roundOrdersGet: {
    __typename?: "RoundOrdersResponse"
    totalOrdersCount: number
    orderArticles: Array<{
      __typename?: "RoundOrderArticle"
      position: number
      articleId: string
      botName: string
      variant: string
      quantityTotal: number
      quantityForCustomers: number
      quantityForStorage: number
      sizeMin: number
      sizeMax: number
      supplierContainerSize: string
      supplierID: number
      supplierName: string
      description: string
    }>
  }
}

export type RoundOverviewGetQueryVariables = Exact<{
  rowsPerPage: Scalars["Int"]
  currentPage: Scalars["Int"]
}>

export type RoundOverviewGetQuery = {
  __typename?: "Query"
  roundOverviewGet: {
    __typename?: "RoundOverviewResponse"
    totalRoundCount: number
    roundDataRows: Array<{
      __typename?: "RoundDataRow"
      id: number
      round: number
      name: string
      date: string
      plants: number
      crates: number
      ordered: number
      delivered: number
      totalMessages: number
      sentMessages: number
      singleSum: number
    }>
  }
}

export type RoundsGetQueryVariables = Exact<{ [key: string]: never }>

export type RoundsGetQuery = {
  __typename?: "Query"
  roundsGet: {
    __typename?: "RoundsGetResponse"
    rounds: Array<{
      __typename?: "Round"
      id: number
      round: number
      name: string
    }>
  }
}

export type SortOrdersMutationVariables = Exact<{
  input: SortOrderInput
}>

export type SortOrdersMutation = {
  __typename?: "Mutation"
  sortOrder: {
    __typename?: "SortOrderRes"
    message: string
    preview: Array<{
      __typename?: "PlantOrderSum"
      bdb: string
      quantity: number
      botname: string
      supplier: string
      singles: number
      soldStatus: string
    }>
  }
}

export type SortOrderBaseQueryVariables = Exact<{ [key: string]: never }>

export type SortOrderBaseQuery = {
  __typename?: "Query"
  plantTypes: Array<{ __typename?: "PlantType"; id: number; name: string }>
  suppliers: Array<{
    __typename?: "Supplier"
    id: number
    name: string
    flavour: number
    G24: boolean
  }>
}

export type FreshdeskTicketCreateMutationVariables = Exact<{
  input: FreshdeskTicketInput
}>

export type FreshdeskTicketCreateMutation = {
  __typename?: "Mutation"
  freshdeskTicketCreate: boolean
}

export type GetNextRoundNumberQueryVariables = Exact<{ [key: string]: never }>

export type GetNextRoundNumberQuery = {
  __typename?: "Query"
  getNextRoundNumber: number
}

export type PrintOrderListKistenZettelQueryVariables = Exact<{
  round: Scalars["Int"]
  printerIpPort: Scalars["String"]
  orderIds: Array<Scalars["Int"]> | Scalars["Int"]
}>

export type PrintOrderListKistenZettelQuery = {
  __typename?: "Query"
  printOrderListKistenZettel: string
}

export type EditAskDanteUserTagsMutationVariables = Exact<{
  userId: Scalars["String"]
  tags: Array<Scalars["String"]> | Scalars["String"]
}>

export type EditAskDanteUserTagsMutation = {
  __typename?: "Mutation"
  editAskDanteUserTags: boolean
}

export type OrderByIdQueryVariables = Exact<{
  orderID: Scalars["Int"]
}>

export type OrderByIdQuery = {
  __typename?: "Query"
  orderByID: {
    __typename?: "OrderDetailsMore"
    orderID: number
    kundnum: number
    urorder: number
    datum: string
    zieldat: string
    status: number
    zahlart: string
    rechnum: number
    rechsum: number
    bezahlt: number
    rabatt: number
    frachtpausch: number
    notiz: string
    custnote: string
    secretnote: string
    couponKey: string
    couponValue: number
    sh: number
    roundName: string
    rue: number
    rueText: string
    deliveryAdr: {
      __typename?: "Address"
      id: number
      company: string
      anrede: string
      fullName: string
      firstName: string
      lastName: string
      street: string
      zipCode: string
      city: string
      country: string
      countryCode: string
    }
    orderItems: Array<{
      __typename?: "OrderItem"
      ewID: number
      artnum: number
      botname: string
      von: number
      bis: number
      stahoeh: number
      stuvon: number
      stubis: number
      anzahl: number
      stueckpreis: number
      source: string
      lager: string
      bestellt: boolean
      etikettiert: boolean
      verteilt: boolean
      splitstatus: boolean
    }>
    packages: Array<{
      __typename?: "PackageStat"
      id: number
      orderID: number
      carrier: string
      ps: string
      packageNumber: string
      createdAt: string
      labelURL: string
      trackingURL: string
    }>
  }
}

export type OrderItemsCancelMutationVariables = Exact<{
  in: OrderItemsCancelInput
}>

export type OrderItemsCancelMutation = {
  __typename?: "Mutation"
  orderItemsCancel: boolean
}

export type OrderListGetPrintItemsQueryVariables = Exact<{
  round: Scalars["Int"]
}>

export type OrderListGetPrintItemsQuery = {
  __typename?: "Query"
  orderListGetPrintItems: Array<{
    __typename?: "RoundOrderPrintItem"
    supplierID: number
    supplierName: string
    emailSentAt: string
    faxSentAt: string
  }>
}

export type OrderListPreviewPdfQueryVariables = Exact<{
  round: Scalars["Int"]
  supplierID: Scalars["Int"]
  date: Scalars["String"]
}>

export type OrderListPreviewPdfQuery = {
  __typename?: "Query"
  orderListPreviewPDF: {
    __typename?: "OrderListPreviewPDFResponse"
    pdfBase64: string
    msg: string
  }
}

export type OrderListPrintPdFsQueryVariables = Exact<{
  round: Scalars["Int"]
  supplier: Array<OrderListInput> | OrderListInput
  printerName: Scalars["String"]
}>

export type OrderListPrintPdFsQuery = {
  __typename?: "Query"
  orderListPrintPDFs: string
}

export type OrderStatusQueryVariables = Exact<{ [key: string]: never }>

export type OrderStatusQuery = {
  __typename?: "Query"
  orderStatus: Array<{
    __typename?: "OrderStatus"
    status: number
    etiStop: boolean
    verteilenStop: boolean
    packStop: boolean
    remark: string
  }>
}

export type OrderTimelineQueryVariables = Exact<{
  orderID: Scalars["Int"]
}>

export type OrderTimelineQuery = {
  __typename?: "Query"
  orderTimeline: Array<{
    __typename?: "Timeline"
    id: number
    zeitpunkt: string
    action: string
    username: string
    computername: string
    orderID: number
    flavour?: number | null
  }>
}

export type PackErrorResolveMutationVariables = Exact<{
  id: Scalars["Int"]
}>

export type PackErrorResolveMutation = {
  __typename?: "Mutation"
  packErrorResolve: boolean
}

export type PackErrorsQueryVariables = Exact<{ [key: string]: never }>

export type PackErrorsQuery = {
  __typename?: "Query"
  packErrors: Array<{
    __typename?: "PackError"
    id: number
    created: string
    detail: string
    orderID: number
    customerID: number
    addressID: number
    packer: string
  }>
}

export type PackageStatsQueryVariables = Exact<{
  from: Scalars["String"]
  to: Scalars["String"]
}>

export type PackageStatsQuery = {
  __typename?: "Query"
  packageStats: Array<{
    __typename?: "PackageStat"
    id: number
    orderID: number
    carrier: string
    ps: string
    packageNumber: string
    createdAt: string
    labelURL: string
    trackingURL: string
  }>
}

export type PackageStatsByOrderIdQueryVariables = Exact<{
  orderID: Scalars["String"]
}>

export type PackageStatsByOrderIdQuery = {
  __typename?: "Query"
  packageStatsByOrderID: Array<{
    __typename?: "PackageStat"
    id: number
    orderID: number
    carrier: string
    ps: string
    packageNumber: string
    createdAt: string
    labelURL: string
    trackingURL: string
  }>
}

export type PerformanceQueryVariables = Exact<{
  from: Scalars["String"]
  to: Scalars["String"]
}>

export type PerformanceQuery = {
  __typename?: "Query"
  performance: Array<{
    __typename?: "Performance"
    worker: string
    itemsTagged: number
    itemsDistributed: number
    itemsDispatched: number
  }>
}

export type PrintDeliveryNoteQueryVariables = Exact<{
  in: PrintDeliveryNoteInput
}>

export type PrintDeliveryNoteQuery = {
  __typename?: "Query"
  printDeliveryNote: boolean
}

export type PrintRsLabelQueryVariables = Exact<{
  printerIpPort: Scalars["String"]
  rsLabelURL: Scalars["String"]
}>

export type PrintRsLabelQuery = { __typename?: "Query"; printRsLabel: boolean }

export type PrintersQueryVariables = Exact<{ [key: string]: never }>

export type PrintersQuery = {
  __typename?: "Query"
  printers: Array<{
    __typename?: "Printer"
    printerID: number
    printerName: string
    printerIP: string
    printerPort: string
  }>
}

export type RabenLabelPrintQueryVariables = Exact<{
  printer: Scalars["String"]
  docID: Scalars["String"]
}>

export type RabenLabelPrintQuery = {
  __typename?: "Query"
  rabenLabelPrint: boolean
}

export type RabenShipmentCreateMutationVariables = Exact<{
  in: RabenShipmentInput
}>

export type RabenShipmentCreateMutation = {
  __typename?: "Mutation"
  rabenShipmentCreate: { __typename?: "RabenShipmentResponse"; DocID: string }
}

export type RoundCreateMutationVariables = Exact<{
  input: RoundCreateInput
}>

export type RoundCreateMutation = {
  __typename?: "Mutation"
  roundCreate: { __typename?: "RoundCreateResponse"; msg: string }
}

export type RoundItemEditDescriptionMutationVariables = Exact<{
  in: RoundItemEditDescriptionInput
}>

export type RoundItemEditDescriptionMutation = {
  __typename?: "Mutation"
  roundItemEditDescription: boolean
}

export type RoundItemAddMutationVariables = Exact<{
  in: Array<RoundItemAddInput> | RoundItemAddInput
}>

export type RoundItemAddMutation = {
  __typename?: "Mutation"
  roundItemsAdd: boolean
}

export type RoundItemEditMutationVariables = Exact<{
  in: RoundItemEditInput
}>

export type RoundItemEditMutation = {
  __typename?: "Mutation"
  roundItemsEdit: boolean
}

export type RoundItemsSplitMutationVariables = Exact<{
  in: RoundItemsSplitInput
}>

export type RoundItemsSplitMutation = {
  __typename?: "Mutation"
  roundItemsSplit: { __typename?: "RoundItemsSplitResponse"; message: string }
}

export type RoundLogisticStatQueryVariables = Exact<{
  in: RoundStatInput
}>

export type RoundLogisticStatQuery = {
  __typename?: "Query"
  roundStats: Array<{
    __typename?: "RoundStat"
    idExists: boolean
    roundID: number
    roundName: string
    startDate: string
    orderCount: number
    openOrderCount: number
    articleCount: number
    taggedArticleCount: number
    distributedArticleCount: number
    percentage: number
  }>
  logisticStat: {
    __typename?: "LogisticStat"
    tagged: number
    distributed: number
    packagesSent: number
  }
}

export type RoundOrderDataQueryVariables = Exact<{
  round: Scalars["Int"]
}>

export type RoundOrderDataQuery = {
  __typename?: "Query"
  roundOrderdata: Array<{
    __typename?: "RoundOrderdata"
    sh: number
    totalArticleCount: number
    orderid: number
    zieldat: string
    notiz: string
    zahlart: string
    kundnum: number
    status: number
    firstName: string
    lastName: string
    street: string
    city: string
    zip: string
    country: string
  }>
}

export type RoundOrderItemsQueryVariables = Exact<{
  round: Scalars["Int"]
}>

export type RoundOrderItemsQuery = {
  __typename?: "Query"
  roundOrderList: {
    __typename?: "RoundOrderListResponse"
    roundExists: boolean
    items: Array<{
      __typename?: "RoundOrderItem"
      supplierID: number
      round: number
      supplierName: string
      phone: string
      email: string
      fax: string
      sendFax: boolean
      sendEmail: boolean
      emailSentAt: string
      faxSentAt: string
      g24: boolean
      flavour: number
      inStorage: boolean
    }>
  }
}

export type RoundStatsQueryVariables = Exact<{
  in: RoundStatInput
}>

export type RoundStatsQuery = {
  __typename?: "Query"
  roundStats: Array<{
    __typename?: "RoundStat"
    idExists: boolean
    roundID: number
    roundName: string
    startDate: string
    orderCount: number
    openOrderCount: number
    articleCount: number
    taggedArticleCount: number
    distributedArticleCount: number
    percentage: number
  }>
}

export type RoundSuppliersQueryVariables = Exact<{
  in: Scalars["Int"]
}>

export type RoundSuppliersQuery = {
  __typename?: "Query"
  roundSuppliers: Array<{
    __typename?: "RoundSupplier"
    supplierID: number
    supplier: string
    expected: number
    tagged: number
    rest: number
    taggedbybot: number
  }>
}

export type SalesStatsQueryVariables = Exact<{
  from: Scalars["String"]
  to: Scalars["String"]
}>

export type SalesStatsQuery = {
  __typename?: "Query"
  salesStats: Array<{
    __typename?: "SalesStat"
    botname: string
    gername: string
    from: number
    to: number
    pflNR: number
    artnum: number
    count: number
    price: number
  }>
}

export type SendOrderListMutationVariables = Exact<{
  in: SendOrderListInput
}>

export type SendOrderListMutation = {
  __typename?: "Mutation"
  sendOrderList: boolean
}

export type PrintShipcloudLabelQueryVariables = Exact<{
  ip: Scalars["String"]
  url: Scalars["String"]
}>

export type PrintShipcloudLabelQuery = {
  __typename?: "Query"
  printShipcloudLabel: boolean
}

export type ShipmentDetailsQueryVariables = Exact<{
  oid: Scalars["String"]
}>

export type ShipmentDetailsQuery = {
  __typename?: "Query"
  shipmentDetails: {
    __typename?: "ShipmentDetails"
    msg: Array<string>
    custPhone: string
    custEmail: string
    custMobile: string
    payOnDelivery: boolean
    permitToPlacePackage: boolean
    pdfURL: string
    orderDetails: {
      __typename?: "OrderDetails"
      orderID: number
      customerID: number
      customerEmail: string
      customerPhone: string
      paymentMethod: string
      targetShipDate: string
      note: string
      secretNote: string
      statusID: number
      invoiceSum: number
    }
    addressDetails: {
      __typename?: "Address"
      id: number
      company: string
      fullName: string
      firstName: string
      lastName: string
      street: string
      zipCode: string
      city: string
      country: string
      countryCode: string
      anrede: string
    }
  }
}

export type SupplierCreateMutationVariables = Exact<{
  input: SupplierInput
}>

export type SupplierCreateMutation = {
  __typename?: "Mutation"
  supplierCreate: {
    __typename?: "Supplier"
    id: number
    name: string
    street: string
    city: string
    zip: string
    phone: string
    fax: string
    email: string
    active: boolean
    flavour: number
    G24: boolean
    inStorage: boolean
    sendEmail: boolean
    sendFax: boolean
  }
}

export type SupplierRoundNoteQueryVariables = Exact<{
  round: Scalars["Int"]
}>

export type SupplierRoundNoteQuery = {
  __typename?: "Query"
  supplierRoundNotes: Array<{
    __typename?: "SupplierRoundNote"
    supplierId: number
    note: string
  }>
}

export type SupplierRoundNoteSaveMutationVariables = Exact<{
  round: Scalars["Int"]
  supplierID: Scalars["Int"]
  note: Scalars["String"]
}>

export type SupplierRoundNoteSaveMutation = {
  __typename?: "Mutation"
  supplierRoundNoteSave: boolean
}

export type SupplierOrderedItemsQueryVariables = Exact<{
  round: Scalars["Int"]
}>

export type SupplierOrderedItemsQuery = {
  __typename?: "Query"
  supplierOrderedItems: Array<{
    __typename?: "SupplierOrderedItems"
    RoundListID: number
    Orderid: number
    Ewid: number
    IsTagged: boolean
    SupplierID: number
    Quantity: number
    MainbaseBdb: number
    CreatedAt: string
    GERNAME: string
    BOTNAME: string
    Sorte: string
    Von: number
    Bis: number
    Lieferant: string
    G24: number
    Flavour: number
    Is24hDelivery: boolean
  }>
}

export type ArticleMoveInfoQueryVariables = Exact<{
  round: Scalars["Int"]
  supplierID: Scalars["Int"]
}>

export type ArticleMoveInfoQuery = {
  __typename?: "Query"
  articleSupplierInfo: Array<{
    __typename?: "SupplierOrderItem"
    id: number
    orderID: number
    ewID: number
    supplierID: number
    supplierName: string
    bdb: string
    quantity: number
    botname: string
    sorte: string
    size: string
  }>
  articleSupplierRefs: Array<{
    __typename?: "ArticleSupplierCrossRef"
    supplierName: string
    supplierID: number
    price: number
    articleID: number
    priority: number
  }>
}

export type SupplierRoundItemMoveMutationVariables = Exact<{
  roundListID: Scalars["Int"]
  round: Scalars["Int"]
  newSupplierID: Scalars["Int"]
  quantity: Scalars["Int"]
}>

export type SupplierRoundItemMoveMutation = {
  __typename?: "Mutation"
  supplierRoundItemMove: boolean
}

export type RoundlistItemDeleteMutationVariables = Exact<{
  ids: Array<Scalars["Int"]> | Scalars["Int"]
}>

export type RoundlistItemDeleteMutation = {
  __typename?: "Mutation"
  supplierRoundItemDelete: boolean
}

export type SupplierUpdateMutationVariables = Exact<{
  input: SupplierInput
}>

export type SupplierUpdateMutation = {
  __typename?: "Mutation"
  supplierUpdate: {
    __typename?: "Supplier"
    id: number
    name: string
    street: string
    city: string
    zip: string
    phone: string
    fax: string
    email: string
    active: boolean
    flavour: number
    G24: boolean
    inStorage: boolean
    sendEmail: boolean
    sendFax: boolean
  }
}

export type SuppliersQueryVariables = Exact<{ [key: string]: never }>

export type SuppliersQuery = {
  __typename?: "Query"
  suppliers: Array<{
    __typename?: "Supplier"
    id: number
    name: string
    street: string
    city: string
    zip: string
    phone: string
    fax: string
    email: string
    active: boolean
    flavour: number
    G24: boolean
    inStorage: boolean
    sendEmail: boolean
    sendFax: boolean
    doegeGBF: boolean
  }>
}

export type TimelineInsertMutationVariables = Exact<{
  in: TimelineInput
}>

export type TimelineInsertMutation = {
  __typename?: "Mutation"
  timelineInsert: {
    __typename?: "Timeline"
    id: number
    zeitpunkt: string
    action: string
    username: string
    computername: string
    orderID: number
    flavour?: number | null
  }
}

export type UserSaveMutationVariables = Exact<{
  input: UserUpdateInput
}>

export type UserSaveMutation = {
  __typename?: "Mutation"
  userSave: {
    __typename?: "User"
    id: number
    username: string
    privileges: number
  }
}

export type UsersQueryVariables = Exact<{ [key: string]: never }>

export type UsersQuery = {
  __typename?: "Query"
  users: Array<{
    __typename?: "User"
    id: number
    username: string
    privileges: number
    language: string
  }>
}
