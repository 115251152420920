import AddIcon from "@mui/icons-material/Add"
import CancelIcon from "@mui/icons-material/Close"
import SaveIcon from "@mui/icons-material/Save"
import { Box, Button } from "@mui/material"
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowSelectionModel,
  GridSlotsComponentsProps,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro"
import { CollapsibleTable } from "components/CollapsibleTable/CollapsibleTable"
import { dataGridDEde } from "constants/dataGridLocale"
import { Address } from "generated/graphql"
import { enqueueSnackbar } from "notistack"
import { customerAddress } from "queries/customerAddress"
import { customerAddressSave } from "queries/customerAddressSave"
import { useEffect, useState } from "react"
import { AddAddress } from "components/AddAddress/AddAddress"
import { countries } from "constants/countries"

export const CustomToolbar = (
  props: NonNullable<GridSlotsComponentsProps["footer"]>
) => {
  const { addClick } = props

  return (
    <GridToolbarContainer
      sx={{ display: "flex", justifyContent: "space-between" }}
    >
      <GridToolbarQuickFilter />
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={addClick}
        sx={{ m: 1 }}
      >
        Neue Lieferadresse hinzufügen
      </Button>
    </GridToolbarContainer>
  )
}

type CustomerEditPageAddressProps = {
  customerID: string
  setSelectedAddress?: (arg: Address | null) => void
  open: boolean
}

export const CustomerEditPageAddress = (
  props: CustomerEditPageAddressProps
) => {
  const { customerID, setSelectedAddress, open } = props
  const handleSetSelectedAddress = setSelectedAddress || (() => {})
  const [openAddAddress, setOpenAddAddress] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [addresses, setAddresses] = useState<Address[]>([])
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})

  useEffect(() => {
    const id = customerID ? parseInt(customerID) : undefined
    if (id !== undefined) {
      setIsLoading(true)
      customerAddress(id)
        .then((result) => {
          if (result?.customerAddress) {
            setAddresses(result.customerAddress)
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [customerID])

  const columnsAddresses: GridColDef<Address>[] = [
    {
      field: "id",
      headerName: "ID",
      width: 80,
    },
    {
      field: "anrede",
      headerName: "Anrede",
      width: 120,
      editable: true,
      type: "singleSelect",
      valueOptions: ["Herr", "Frau", "Firma"],
    },
    {
      field: "company",
      headerName: "Firma",
      width: 180,
      editable: true,
    },

    {
      field: "firstName",
      headerName: "Vorname",
      width: 190,
      editable: true,
    },
    {
      field: "lastName",
      headerName: "Name",
      width: 190,
      editable: true,
    },
    {
      field: "street",
      headerName: "Straße",
      width: 190,
      editable: true,
    },
    {
      field: "zipCode",
      headerName: "PLZ",
      width: 80,
      editable: true,
    },
    {
      field: "city",
      headerName: "Ort",
      width: 140,
      editable: true,
    },
    {
      field: "country",
      headerName: "Land",
      width: 170,
      editable: true,
      type: "singleSelect",
      valueOptions: countries.map((item) => item.name),
    },
    {
      field: "action",
      headerName: "Aktionen",
      type: "actions",
      cellClassName: "actions",
      width: 100,
      sortable: false,
      getActions: ({ row }: Partial<GridRowParams>) => {
        const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(row.id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              onClick={handleCancelClick(row.id)}
            />,
          ]
        }
        return []
      },
    },
  ]

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View },
    })
  }

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    })
  }

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  const findCountryCode = (country: string) => {
    const item = countries.find((item) => item.name === country)
    if (item) return item.code
    return ""
  }

  const processRowUpdate = async (
    newRow: GridRowModel<Address>,
    oldRow: GridRowModel<Address>
  ): Promise<Address> => {
    const res = await customerAddressSave({
      in: {
        ...newRow,
        customerID: Number(customerID),
        countryCode: findCountryCode(newRow.country),
      },
    })
    if (res?.customerAddressSave) {
      enqueueSnackbar("Adresse erfolgreich gespeichert", { variant: "success" })
      return { ...newRow, id: res.customerAddressSave.id }
    }
    enqueueSnackbar("Adresse konnte nicht gespeichert werden", {
      variant: "error",
    })
    return oldRow
  }

  // const addAddress = async () => {
  // const res = await customerAddressSave({
  //   in: {
  //     id: 0,
  //     customerID: Number(customerID),
  //     anrede: "",
  //     city: "",
  //     company: "",
  //     country: "",
  //     countryCode: "",
  //     firstName: "",
  //     fullName: "",
  //     lastName: "",
  //     street: "",
  //     zipCode: "",
  //   },
  // })
  // if (res?.customerAddressSave) {
  //   setAddresses((oldRows) => [
  //     ...oldRows,
  //     {
  //       id: res.customerAddressSave.id,
  //       anrede: "",
  //       city: "",
  //       company: "",
  //       country: "",
  //       countryCode: "",
  //       firstName: "",
  //       fullName: "",
  //       lastName: "",
  //       street: "",
  //       zipCode: "",
  //     },
  //   ])
  //   setRowModesModel((oldRowModesModel) => ({
  //     ...oldRowModesModel,
  //     [res.customerAddressSave.id]: {
  //       mode: GridRowModes.Edit,
  //       field: "company",
  //     },
  //   }))
  // }
  // }

  const addAddress = () => {
    setOpenAddAddress(true)
  }

  const closeAddAddress = () => {
    setOpenAddAddress(false)
  }

  return (
    <>
      <CollapsibleTable
        defaultOpen={open}
        title={`Lieferadressen (${addresses.length})`}
      >
        {addresses && (
          <Box sx={{ height: 600 }}>
            <DataGridPro
              rows={addresses}
              columns={columnsAddresses}
              localeText={dataGridDEde}
              loading={isLoading}
              editMode="row"
              rowModesModel={rowModesModel}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              onRowModesModelChange={setRowModesModel}
              slots={{
                toolbar: CustomToolbar,
              }}
              slotProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 100 },
                  addClick: addAddress,
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              disableMultipleRowSelection={true}
              onRowSelectionModelChange={(id: GridRowSelectionModel) => {
                if (id.length === 0) return
                const newAddress = addresses.find(
                  (item) => item.id === Number(id[0])
                )
                if (newAddress) handleSetSelectedAddress(newAddress)
              }}
            />
          </Box>
        )}
      </CollapsibleTable>
      <AddAddress
        open={openAddAddress}
        close={closeAddAddress}
        customerID={Number(customerID)}
        setAddresses={setAddresses}
      />
    </>
  )
}
