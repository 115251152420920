export const downloadBlob = (blob: Blob, filename: string) => {
  // Create a Blob URL
  const blobURL = URL.createObjectURL(blob)

  // Create a temporary anchor element
  const anchor = document.createElement("a")
  anchor.target = "_blank"
  anchor.style.display = "none"
  anchor.href = blobURL
  anchor.download = filename // Set the download filename

  // Append the anchor to the body, click it, and remove it
  document.body.appendChild(anchor)
  anchor.click()
  document.body.removeChild(anchor)

  // Optional: Release the Blob URL
  setTimeout(() => {
    URL.revokeObjectURL(blobURL)
  }, 100)
}
