import React from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { store } from "./app/store"
import App from "./App"
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import "./index.css"
// import type {} from "@mui/x-data-grid-pro/themeAugmentation"

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#7fb962",
      light: "#b2ec95",
      dark: "#4b8c3b",
      contrastText: "#fff",
    },
    secondary: {
      main: "#9c62b9",
      light: "#c995ec",
      dark: "#6a3b8c",
      contrastText: "#fff",
    },
    action: {
      hover: "#b2ec95",
    },
    grey: {
      "100": "#808080",
    },
  },
  //components: {
  ////@ts-ignore - this isn't in the TS because DataGrid is not exported from `@mui/material`
  //MuiDataGrid: {
  //styleOverrides: {
  //row: {
  //"&.Mui-selected": {
  //backgroundColor: "#4b8c3b",
  //color: "#fff",
  //"&:hover": {
  //backgroundColor: "#7fb962",
  //},
  //},
  //},
  //},
  //},
  //},
})

const container = document.getElementById("root")!
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
)
