import { fetchGQL } from "app/api"
import {
  CustomerAddressQuery,
  CustomerAddressQueryVariables,
} from "generated/graphql"

import { gql } from "graphql-tag"

const CUSTOMER_ADDRESS = gql`
  query customerAddress($customerID: Int!) {
    customerAddress(customerID: $customerID) {
      id
      company
      fullName
      firstName
      lastName
      street
      zipCode
      city
      country
      countryCode
      anrede
    }
  }
`

export const customerAddress = async (
  input: number
): Promise<CustomerAddressQuery | undefined> => {
  const variables: CustomerAddressQueryVariables = {
    customerID: input,
  }
  const data = await fetchGQL<
    CustomerAddressQuery,
    CustomerAddressQueryVariables
  >(CUSTOMER_ADDRESS, variables)
  return data
}
