import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import userReducer from "app/userSlice"
import snackReducer from "app/snackSlice"
import roundSlice from "./roundSlice"
import loadingSlice from "./loadingSlice"

export const store = configureStore({
  reducer: {
    userState: userReducer,
    snackState: snackReducer,
    roundState: roundSlice,
    loadingState: loadingSlice,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
