import { Alert, Button, Checkbox, Container } from "@mui/material"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { qSnack } from "app/snackSlice"
import { store } from "app/store"
import { selectUser } from "app/userSlice"
import DialogAddSupplier from "components/DialogAddSupplier/DialogAddSupplier"
import { dataGridDEde } from "constants/dataGridLocale"
import { ADMIN, BASE_DATA, hasPrivilege } from "constants/privileges"
import { Supplier, SupplierInput } from "generated/graphql"
import { suppliersGet, supplierUpdate } from "queries/fetchSuppliers"
import { useEffect, useState } from "react"

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "Kurzwahl",
    width: 80,
  },
  {
    field: "name",
    headerName: "Name",
    width: 150,
    editable: true,
  },
  {
    field: "street",
    headerName: "Straße",
    width: 180,
    editable: true,
  },
  {
    field: "city",
    headerName: "Stadt",
    width: 150,
    editable: true,
  },
  {
    field: "zip",
    headerName: "PLZ",
    width: 100,
    editable: true,
  },
  {
    field: "phone",
    headerName: "Telefon",
    width: 150,
    editable: true,
  },
  {
    field: "fax",
    headerName: "Fax",
    width: 150,
    editable: true,
  },
  {
    field: "email",
    headerName: "Email",
    width: 200,
    editable: true,
  },
  {
    field: "active",
    headerName: "Aktiv",
    width: 80,
    renderCell: (params) => <CheckboxRenderer {...params} />,
  },
  {
    field: "flavour",
    headerName: "Flavour",
    width: 80,
    editable: true,
  },
  {
    field: "G24",
    headerName: "G24",
    width: 80,
    renderCell: (params) => <CheckboxRenderer {...params} />,
  },
  {
    field: "inStorage",
    headerName: "Lager",
    width: 80,
    renderCell: (params) => <CheckboxRenderer {...params} />,
  },
  {
    field: "sendEmail",
    headerName: "Email",
    width: 80,
    renderCell: (params) => <CheckboxRenderer {...params} />,
  },
  {
    field: "sendFax",
    headerName: "Fax",
    width: 80,
    renderCell: (params) => <CheckboxRenderer {...params} />,
  },
  {
    field: "doegeGBF",
    headerName: "GBF",
    editable: false,
    width: 80,
    renderCell: (params) => <CheckboxRenderer {...params} />,
  },
]

const CheckboxRenderer = (props: GridRenderCellParams) => {
  const { api, field, id } = props

  const handleChange = async (event: any) => {
    // call api to update the row
    let item = api.getRow(id) as Supplier
    item = { ...item, [field]: event.target.checked }
    const input: SupplierInput = {
      id: item.id,
      name: item.name,
      street: item.street,
      city: item.city,
      zip: item.zip,
      phone: item.phone,
      fax: item.fax,
      email: item.email,
      active: item.active,
      flavour: item.flavour,
      G24: item.G24,
      inStorage: item.inStorage,
      GBF: item.doegeGBF,
      sendEmail: item.sendEmail,
      sendFax: item.sendFax,
    }

    supplierUpdate(input).then((res) => {
      if (res?.supplierUpdate) {
        store.dispatch(
          qSnack({ msg: "Lieferant aktualisiert", severity: "success" })
        )
      } else {
        store.dispatch(
          qSnack({ msg: "Fehler beim aktualisieren", severity: "error" })
        )
      }
    })
    const updatedRows = [
      {
        id,
        [field]: event.target.checked,
      },
    ]

    api.updateRows(updatedRows)

    // Set the cell focus after the update.
    // api.setCellFocus(id, field)
  }

  return <Checkbox checked={props.value || false} onChange={handleChange} />
}

const SuppliersPage = () => {
  const [loading, setloading] = useState(false)
  const [suppliersData, setSuppliersData] = useState<Supplier[]>([])
  const [isAddSupplierDialogOpen, setIsAddSupplierDialogOpen] = useState(false)
  const user = useAppSelector(selectUser)
  const hasAdminPermission = hasPrivilege(user.user.privileges, ADMIN)
  let hasEditPermission = hasPrivilege(user.user.privileges, BASE_DATA)
  if (hasAdminPermission) {
    hasEditPermission = true
  }

  const dispatch = useAppDispatch()

  useEffect(() => {
    setloading(true)
    suppliersGet()
      .then((res) => {
        if (res?.suppliers) {
          setSuppliersData(res.suppliers)
        }
      })
      .finally(() => {
        setloading(false)
      })
  }, [setSuppliersData, setloading])

  useEffect(() => {
    if (isAddSupplierDialogOpen === false) {
      suppliersGet()
        .then((res) => {
          if (res?.suppliers) {
            setSuppliersData(res.suppliers)
          }
        })
        .finally(() => {
          setloading(false)
        })
    }
  }, [isAddSupplierDialogOpen])

  const handleRowUpdate = async (newRow: Supplier, oldRow: Supplier) => {
    if (!hasEditPermission) {
      dispatch(
        qSnack({
          msg: "Sie haben keine Berechtigung zum Bearbeiten",
          severity: "error",
        })
      )
      return oldRow
    }
    if (JSON.stringify(newRow) === JSON.stringify(oldRow)) {
      return newRow
    }

    const input: SupplierInput = {
      id: newRow.id,
      name: newRow.name,
      street: newRow.street,
      city: newRow.city,
      zip: newRow.zip,
      phone: newRow.phone,
      fax: newRow.fax,
      email: newRow.email,
      active: newRow.active,
      flavour: newRow.flavour,
      sendEmail: newRow.sendEmail,
      sendFax: newRow.sendFax,
      G24: newRow.G24,
      inStorage: newRow.inStorage,
      GBF: newRow.doegeGBF,
    }
    const res = await supplierUpdate(input)
    if (res?.supplierUpdate) {
      dispatch(qSnack({ msg: "Lieferant aktualisiert", severity: "success" }))
      return newRow
    }
    dispatch(qSnack({ msg: "Fehler beim aktualisieren", severity: "error" }))
    return oldRow
  }

  const handleRowUpdateError = (error: any) => {
    dispatch(qSnack({ msg: error.message, severity: "error" }))
  }

  //function that search in suppliersData for greatest id and returns it + 1
  const getNewId = () => {
    let maxId = 0
    suppliersData.forEach((supplier) => {
      if (supplier.id > maxId) {
        maxId = supplier.id
      }
    })
    return maxId + 1
  }

  return (
    <Container maxWidth="xl">
      <>
        <Grid
          container
          justifyContent="start"
          alignItems="center"
          sx={{ marginBottom: 2 }}
        >
          <Grid item xs={12} md={3}>
            <Button
              variant="contained"
              disabled={!hasEditPermission}
              onClick={() => setIsAddSupplierDialogOpen(true)}
              sx={{
                height: "100%",
              }}
            >
              Lieferant hinzufügen
            </Button>
          </Grid>
          <Grid item xs={12} md={5}>
            <Alert severity="info">
              Nur Nutzer mit der Rolle "Admin" können Lieferanten
              hinzufügen/editieren
            </Alert>
          </Grid>
        </Grid>
        <DialogAddSupplier
          isOpen={isAddSupplierDialogOpen}
          onClose={() => setIsAddSupplierDialogOpen(false)}
          getNewID={getNewId}
        />
        <Grid container justifyContent={"center"}>
          <Grid item xs={12}>
            <Box sx={{ height: "80vh", width: "100%" }}>
              <DataGridPro
                rows={suppliersData}
                columns={columns}
                loading={loading}
                localeText={dataGridDEde}
                processRowUpdate={handleRowUpdate}
                onProcessRowUpdateError={handleRowUpdateError}
                sortingOrder={["desc", "asc"]}
              />
            </Box>
          </Grid>
        </Grid>
      </>
    </Container>
  )
}

export default SuppliersPage
