import { Box, Typography } from "@mui/material"

interface TipeInputProps {
  label: string
  value: string
  noMarginRight?: boolean
}

const Tile = (props: TipeInputProps): JSX.Element => {
  const { label, value, noMarginRight } = props
  return (
    <>
      <Box
        sx={{
          display: "inline-flex",
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "5px",
          border: "1px solid #eaeaea",
          alignItems: "center",
          justifyContent: "center",
          padding: "7px",
          backgroundColor: "#eaeaea",
        }}
      >
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          {label}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "inline-flex",
          alignItems: "center",
          border: "1px solid #eaeaea",
          borderTopRightRadius: "5px",
          borderBottomRightRadius: "5px",
          padding: "7px",
          marginRight: noMarginRight ? null : "5px",
        }}
      >
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          {value}
        </Typography>
      </Box>
    </>
  )
}

export default Tile
