import {
  RoundStatInput,
  RoundStatsQuery,
  RoundLogisticStatQueryVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"
import { fetchGQL } from "app/api"

const GET_ROUND_STATS = gql`
  query roundStats($in: RoundStatInput!) {
    roundStats(in: $in) {
      idExists
      roundID
      roundName
      startDate
      orderCount
      openOrderCount
      articleCount
      taggedArticleCount
      distributedArticleCount
      percentage
    }
  }
`

export const fetchRoundStats = async (
  input: RoundStatInput
): Promise<RoundStatsQuery | undefined> => {
  const variables: RoundLogisticStatQueryVariables = { in: input }
  const data = await fetchGQL<RoundStatsQuery, RoundLogisticStatQueryVariables>(
    GET_ROUND_STATS,
    variables
  )
  return data
}
