import { fetchGQL } from "app/api"
import { gql } from "graphql-tag"
import {
  ArticleSupplierSwapPrioMutation,
  ArticleSupplierSwapPrioMutationVariables,
} from "../generated/graphql"

const ARTICLE_SWAP_PRIO = gql`
  mutation articleSupplierSwapPrio($id1: Int!, $id2: Int!) {
    articleSupplierSwapPriority(id1: $id1, id2: $id2)
  }
`

export const articleSwapPrio = async (
  id1: number,
  id2: number
): Promise<ArticleSupplierSwapPrioMutation | undefined> => {
  const variables: ArticleSupplierSwapPrioMutationVariables = {
    id1,
    id2,
  }
  const data = await fetchGQL<
    ArticleSupplierSwapPrioMutation,
    ArticleSupplierSwapPrioMutationVariables
  >(ARTICLE_SWAP_PRIO, variables)
  return data
}
