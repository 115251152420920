import LoadingButton from "@mui/lab/LoadingButton"
import {
  Box,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material"
import {
  DataGridPro,
  GridColDef,
  GridRowSelectionModel,
} from "@mui/x-data-grid-pro"
import { useAppSelector } from "app/hooks"
import { selectCurrentRound } from "app/roundSlice"
import RoundOrderAddItem from "components/RoundOrderAddItem/RoundOrderAddItem"
import { RoundOrderDeleteItemDialog } from "components/RoundOrderDeleteDialog/RoundOrderDeleteDialog"
import {
  EditSup,
  RoundOrderMoveItemDialog,
} from "components/RoundOrderMoveItemDialog/RoundOrderMoveItemDialog"
import { RoundOrderSplitItemDialog } from "components/RoundOrderSplitItemDialog/RoundOrderSplitItemDialog"
import SupplierSelector from "components/SupplierSelector/SupplierSelector"
import { dataGridDEde } from "constants/dataGridLocale"
import {
  ArticleSupplierCrossRef,
  RoundItemAddInput,
  RoundItemEditDescriptionInput,
  RoundItemEditInput,
  RoundOrderArticle,
  RoundOrdersResponse,
  SupplierOrderItem,
  SupplierRoundNote,
} from "generated/graphql"
import { sortedUniqBy } from "lodash"
import { useSnackbar } from "notistack"
import { ArticleItemWithCount } from "pages/AricleSearchRoundAdd/ArticleSearchRoundAdd"
import { fetchRoundOrders } from "queries/fetchRoundOrders"
import { roundItemsAdd } from "queries/roundItemsAdd"
import { roundItemsSplit } from "queries/roundItemsSplit"
import {
  supplierRoundNoteGet,
  supplierRoundNoteSave,
} from "queries/supplierRoundNote"
import {
  roundlistItemDelete,
  supplierRoundInfo,
  supplierRoundItemMove,
} from "queries/supplierRoundOrder"
import { useEffect, useMemo, useRef, useState } from "react"
import { roundItemsEdit } from "queries/roundItemsEdit"
import { roundItemEditDescription } from "queries/roundItemEditDescription"

const colsSupplier: GridColDef<RoundOrderArticle>[] = [
  {
    field: "position",
    headerName: "Posten",
  },
  {
    field: "botName",
    headerName: "Artikel",
    renderCell: (params) => {
      return (
        <Box>
          <Typography variant="body1" component="div">
            {params.row.botName}
          </Typography>
          <Typography variant="body2" color="text.secondary" component="div">
            {params.row.variant}
          </Typography>
        </Box>
      )
    },
    width: 350,
  },
  {
    field: "articleId",
    headerName: "BDB",
    width: 150,
    renderCell: (params) => {
      return (
        <Link href={`/#/editArticle/${params.row.articleId}`}>
          <Typography variant="body2" color="secondary" component="div">
            {params.row.articleId}
          </Typography>
        </Link>
      )
    },
  },
  {
    field: "quantityTotal",
    headerName: "Anzahl",
    width: 150,
    type: "number",
    editable: true,
  },
  {
    field: "size",
    headerName: "Größe",
    valueGetter: (params) => {
      if (!params.row.sizeMin || !params.row.sizeMax) return ""
      if (params.row.sizeMin === params.row.sizeMax) return params.row.sizeMin
      return params.row.sizeMin + " - " + params.row.sizeMax
    },
    width: 160,
  },
  { field: "supplierContainerSize", headerName: "Lieferform", width: 200 },
  {
    field: "description",
    headerName: "Beschreibung",
    width: 200,
    editable: true,
  },
]

interface supplier {
  id: number
  name: string
}

const RoundOrderPage = (): JSX.Element => {
  const [roundData, setRoundData] = useState<RoundOrderArticle[]>([])
  const [totalOrderCount, setTotalOrderount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [currentSupplier, setCurrentSupplier] = useState<supplier | null>(null)
  const [allSupplier, setAllSupplier] = useState<supplier[]>([])
  const [supplierRoundNotes, setSupplierRoundNotes] = useState<
    SupplierRoundNote[]
  >([])
  const [selection, setSelection] = useState<RoundOrderArticle[]>([])
  const [moveItemIsOpen, setMoveItemIsOpen] = useState<boolean>(false)
  const [deleteItemIsOpen, setDeleteItemIsOpen] = useState<boolean>(false)
  const [addItemIsOpen, setAddItemIsOpen] = useState<boolean>(false)
  const [splitItemIsOpen, setSplitItemIsOpen] = useState<boolean>(false)
  const [supplierOrderInfo, setSupplierOrderInfo] = useState<
    SupplierOrderItem[]
  >([])
  const [supplierItemCrossRefs, setSupplierItemCrossRefs] = useState<
    ArticleSupplierCrossRef[]
  >([])
  const selectedRound = useAppSelector(selectCurrentRound)
  const sessionStorageSelectedSupplierKey = `roundOrderPage${selectedRound?.id}_selectedSupplierId`
  const noteRef = useRef<HTMLInputElement>(null)
  const [receivedData, setReceivedData] = useState<RoundOrdersResponse | null>(
    null
  )

  useMemo(() => {
    if (!receivedData) return
    const data = receivedData
    setRoundData(data.orderArticles)
    setTotalOrderount(data.totalOrdersCount)
    const supplier: supplier[] = data.orderArticles.map((order) => {
      return { id: order.supplierID, name: order.supplierName }
    })
    const uniqueSupplier = sortedUniqBy(supplier, (x) => x.id)
    setAllSupplier(uniqueSupplier)
    if (uniqueSupplier.length > 0) {
      const storedValue = window.sessionStorage.getItem(
        sessionStorageSelectedSupplierKey
      )
      if (storedValue) {
        const prevSelectedSupplierId: number = JSON.parse(storedValue)
        const prevSelectedSupplier = uniqueSupplier.find(
          (s: supplier) => s.id === prevSelectedSupplierId
        )
        if (prevSelectedSupplier) {
          setCurrentSupplier(prevSelectedSupplier)
        }
      } else if (uniqueSupplier.length > 0) {
        setCurrentSupplier(uniqueSupplier[0])
      }
    }
  }, [
    setAllSupplier,
    setRoundData,
    setTotalOrderount,
    setCurrentSupplier,
    receivedData,
    sessionStorageSelectedSupplierKey,
  ])

  useEffect(() => {
    if (!currentSupplier) return
    if (noteRef.current) {
      supplierRoundNotes.forEach((x) => {
        if (x.supplierId === currentSupplier.id) {
          if (noteRef.current) {
            noteRef.current.value = x.note
            noteRef.current.focus()
          }
        }
      })
    }
  }, [supplierRoundNotes, currentSupplier])

  const handleSupplierNoteEnter = (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (event.key === "Enter") {
      if (!currentSupplier) return
      if (!selectedRound) return
      if (!noteRef.current) return

      setIsLoading(true)
      supplierRoundNoteSave(
        selectedRound.round,
        currentSupplier.id,
        noteRef.current.value
      )
        .then((res) => {
          if (res?.supplierRoundNoteSave) {
            enqueueSnackbar("Notiz gespeichert", { variant: "success" })
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const handleSelectionModelChange = (
    selectionModel: GridRowSelectionModel
  ) => {
    if (!currentSupplier) return
    const currentSupplierItems = roundData.filter(
      (x) => x.supplierID === currentSupplier.id
    )
    const l: RoundOrderArticle[] = []
    for (const item of currentSupplierItems) {
      if (selectionModel.includes(currentSupplier.id + item.articleId)) {
        l.push(item)
      }
    }
    setSelection(l)
  }

  const handleSplitItemDialogOpen = () => {
    if (!currentSupplier) return
    if (!selectedRound) return
    if (selection.length > 0) {
      setIsLoading(true)
      supplierRoundInfo(selectedRound.round, currentSupplier.id)
        .then((data) => {
          if (data?.articleSupplierInfo) {
            setSupplierOrderInfo(data.articleSupplierInfo)
          }
        })
        .finally(() => {
          setIsLoading(false)
          setSplitItemIsOpen(true)
        })
    }
  }

  const handleMoveItemDialogOpen = () => {
    if (!currentSupplier) return
    if (!selectedRound) return
    if (selection.length > 0) {
      setIsLoading(true)
      supplierRoundInfo(selectedRound.round, currentSupplier.id)
        .then((data) => {
          if (data?.articleSupplierInfo) {
            setSupplierOrderInfo(data.articleSupplierInfo)
          }
          if (data?.articleSupplierRefs) {
            setSupplierItemCrossRefs(data.articleSupplierRefs)
          }
        })
        .finally(() => {
          setIsLoading(false)
          setMoveItemIsOpen(true)
        })
    }
  }

  const handleDeleteItemDialogOpen = () => {
    if (!currentSupplier) return
    if (!selectedRound) return
    if (selection.length > 0) {
      setIsLoading(true)
      supplierRoundInfo(selectedRound.round, currentSupplier.id)
        .then((data) => {
          if (data?.articleSupplierInfo) {
            setSupplierOrderInfo(data.articleSupplierInfo)
          }
          if (data?.articleSupplierRefs) {
            setSupplierItemCrossRefs(data.articleSupplierRefs)
          }
        })
        .finally(() => {
          setIsLoading(false)
          setDeleteItemIsOpen(true)
        })
    }
  }

  const handleDeleteItems = async (data: number[], callback: () => void) => {
    if (!selectedRound) return
    if (!currentSupplier) return
    const res = await roundlistItemDelete(data)
    if (res?.supplierRoundItemDelete !== true) {
      enqueueSnackbar("Fehler beim Löschen", { variant: "error" })
      return
    }

    callback()
    setDeleteItemIsOpen(false)
    //reload data
    setSelection([])
    setCurrentSupplier(null)
    setSupplierOrderInfo([])
    setSupplierItemCrossRefs([])
    setRoundData([])
    setSupplierRoundNotes([])

    // reload data
    const noteRes = await supplierRoundNoteGet(selectedRound.round)

    if (noteRes?.supplierRoundNotes) {
      setSupplierRoundNotes(noteRes.supplierRoundNotes)
    }
    const roundOrdersRes = await fetchRoundOrders(selectedRound.round)
    if (roundOrdersRes?.roundOrdersGet) {
      setReceivedData(roundOrdersRes.roundOrdersGet)
    }
  }

  const handleMoveItems = async (data: EditSup[]) => {
    if (!selectedRound) return
    if (!currentSupplier) return
    setIsLoading(true)
    for (let i = 0; i < data.length; i++) {
      if (data[i].newSupplierId === currentSupplier.id) {
        // skip wrong input
        continue
      }
      // push to the server if ONE fails QUIT
      const res = await supplierRoundItemMove(
        data[i].roundlistId,
        selectedRound.round,
        data[i].newSupplierId,
        data[i].quantity
      )
      if (res?.supplierRoundItemMove !== true) {
        enqueueSnackbar("Fehler beim Verschieben", { variant: "error" })
        break
      }
    }
    // reload data from the server anyway even on error
    // clean old state
    setSelection([])
    setCurrentSupplier(null)
    setSupplierOrderInfo([])
    setSupplierItemCrossRefs([])
    setRoundData([])
    setSupplierRoundNotes([])

    // reload data
    const noteRes = await supplierRoundNoteGet(selectedRound.round)

    if (noteRes?.supplierRoundNotes) {
      setSupplierRoundNotes(noteRes.supplierRoundNotes)
    }
    const roundOrdersRes = await fetchRoundOrders(selectedRound.round)
    if (roundOrdersRes?.roundOrdersGet) {
      setReceivedData(roundOrdersRes.roundOrdersGet)
    }

    setMoveItemIsOpen(false)
    setIsLoading(false)
  }

  useEffect(() => {
    if (selectedRound !== null && selectedRound !== undefined) {
      setIsLoading(true)
      setCurrentSupplier(null)
      supplierRoundNoteGet(selectedRound.round).then((data) => {
        if (data?.supplierRoundNotes) {
          setSupplierRoundNotes(data.supplierRoundNotes)
        }
      })
      fetchRoundOrders(selectedRound.round)
        .then((data) => {
          if (data?.roundOrdersGet) {
            setReceivedData(data.roundOrdersGet)
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [enqueueSnackbar, selectedRound])

  const handleRoundItemsSplit = (
    items: SupplierOrderItem[],
    statusCode: number,
    mergeStatuses: number[]
  ) => {
    if (!selectedRound) return
    setIsLoading(true)
    roundItemsSplit({
      in: {
        roundListIds: items.map((x) => x.id),
        statusCode: statusCode,
        mergeStatuses: mergeStatuses,
      },
    }).then((res) => {
      if (res?.roundItemsSplit) {
        if (res.roundItemsSplit.message) {
          const rejectedRids = res.roundItemsSplit.message.split(",")
          const rejectedEwids = rejectedRids.map(
            (rid) => items.find((item) => item.id.toString() === rid)?.orderID
          )
          const message =
            "Diese Bestellungen konnten aufgrund ihres Status nicht aufgeteilt werden: "
          enqueueSnackbar(message + rejectedEwids.join(", "), {
            variant: "info",
          })
        } else {
          enqueueSnackbar("Artikel gesplittet", { variant: "success" })
        }
      }
      // reload data
      setSelection([])
      setCurrentSupplier(null)
      setSupplierOrderInfo([])
      setSupplierItemCrossRefs([])
      setRoundData([])
      setSupplierRoundNotes([])

      // reload data
      supplierRoundNoteGet(selectedRound.round)
        .then((data) => {
          if (data?.supplierRoundNotes) {
            setSupplierRoundNotes(data.supplierRoundNotes)
          }
        })
        .then(() => {
          fetchRoundOrders(selectedRound.round)
            .then((data) => {
              if (data?.roundOrdersGet) {
                setReceivedData(data.roundOrdersGet)
              }
            })
            .finally(() => {
              setSplitItemIsOpen(false)
              setIsLoading(false)
            })
        })
    })
  }

  const handleRoundItemAdd = (
    selectedItems: ArticleItemWithCount[],
    supplierId: number
  ) => {
    if (!selectedRound) return
    const items: RoundItemAddInput[] = selectedItems.map((x) => {
      return {
        round: selectedRound.round,
        articleID: Number(x.bdb),
        supplierID: supplierId,
        count: x.count,
      }
    })
    setIsLoading(true)
    roundItemsAdd(items).then((res) => {
      if (res?.roundItemsAdd) {
        enqueueSnackbar("Artikel hinzugefügt", { variant: "success" })
      }
      // reload data
      setSelection([])
      setCurrentSupplier(null)
      setSupplierOrderInfo([])
      setSupplierItemCrossRefs([])
      setRoundData([])
      setSupplierRoundNotes([])

      // reload data
      supplierRoundNoteGet(selectedRound.round)
        .then((data) => {
          if (data?.supplierRoundNotes) {
            setSupplierRoundNotes(data.supplierRoundNotes)
          }
        })
        .then(() => {
          fetchRoundOrders(selectedRound.round)
            .then((data) => {
              if (data?.roundOrdersGet) {
                setReceivedData(data.roundOrdersGet)
              }
            })
            .finally(() => {
              setAddItemIsOpen(false)
              setIsLoading(false)
            })
        })
    })
  }

  const handleRoundQuantityItemEdit = (articleId: number, count: number) => {
    if (!selectedRound || !currentSupplier) {
      return
    }
    const item: RoundItemEditInput = {
      round: selectedRound.round,
      articleID: articleId,
      supplierID: currentSupplier.id,
      count: count,
    }
    setIsLoading(true)
    roundItemsEdit(item).then((res) => {
      if (res?.roundItemsEdit) {
        enqueueSnackbar("Artikel geändert", { variant: "success" })
      }
      // reload data
      setSelection([])
      setCurrentSupplier(null)
      setSupplierOrderInfo([])
      setSupplierItemCrossRefs([])
      setRoundData([])
      setSupplierRoundNotes([])

      // reload data
      supplierRoundNoteGet(selectedRound.round)
        .then((data) => {
          if (data?.supplierRoundNotes) {
            setSupplierRoundNotes(data.supplierRoundNotes)
          }
        })
        .then(() => {
          fetchRoundOrders(selectedRound.round)
            .then((data) => {
              if (data?.roundOrdersGet) {
                setReceivedData(data.roundOrdersGet)
              }
            })
            .finally(() => {
              setAddItemIsOpen(false)
              setIsLoading(false)
            })
        })
    })
  }

  const handleRoundDescriptionItemEdit = (
    articleId: number,
    description: string
  ) => {
    if (!selectedRound || !currentSupplier) {
      return
    }
    const item: RoundItemEditDescriptionInput = {
      round: selectedRound.round,
      articleID: articleId,
      supplierID: currentSupplier.id,
      description: description,
    }
    setIsLoading(true)
    roundItemEditDescription(item)
      .then((res) => {
        if (res?.roundItemEditDescription) {
          enqueueSnackbar("Artikel geändert", { variant: "success" })
        }
        // update state
        const newRoundData = roundData.map((x) => {
          if (Number(x.articleId) === articleId) {
            return { ...x, description: description }
          }
          return x
        })
        setRoundData(newRoundData)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleSelectedSupplierChange = (supplierName: string): void => {
    if (noteRef.current) {
      noteRef.current.value = ""
    }
    const supplier = allSupplier.find((x) => x.name === supplierName)
    if (supplier) {
      setCurrentSupplier(supplier)
      window.sessionStorage.setItem(
        sessionStorageSelectedSupplierKey,
        supplier.id.toString()
      )
    }
  }

  return (
    <Container maxWidth="xl">
      {selectedRound && (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <SupplierSelector
              items={allSupplier.map((x) => x.name)}
              selectedItem={currentSupplier ? currentSupplier.name : ""}
              handleSelectionChange={handleSelectedSupplierChange}
            />
          </Grid>
          <Grid item xs={2}>
            <LoadingButton
              color="primary"
              variant="contained"
              sx={{ height: "100%" }}
              fullWidth
              onClick={() => {
                setAddItemIsOpen(true)
              }}
              disabled={!currentSupplier}
              loading={isLoading}
            >
              Hinzufügen
            </LoadingButton>
          </Grid>
          <Grid item xs={2}>
            <LoadingButton
              color="primary"
              variant="contained"
              sx={{ height: "100%" }}
              fullWidth
              onClick={handleSplitItemDialogOpen}
              disabled={selection.length === 0}
              loading={isLoading}
            >
              ({selection.length}) Splitten
            </LoadingButton>
          </Grid>
          <Grid item xs={2}>
            <LoadingButton
              color="primary"
              variant="contained"
              sx={{ height: "100%" }}
              fullWidth
              onClick={handleMoveItemDialogOpen}
              disabled={selection.length === 0 || selection.length > 1}
              loading={isLoading}
            >
              ({selection.length}) Verschieben
            </LoadingButton>
          </Grid>
          <Grid item xs={2}>
            <LoadingButton
              color="primary"
              variant="contained"
              sx={{ height: "100%" }}
              fullWidth
              onClick={handleDeleteItemDialogOpen}
              disabled={selection.length === 0}
            >
              ({selection.length}) Entfernen
            </LoadingButton>
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              placeholder="Information den Lieferanten-Bestellung zuweisen mit Enter senden"
              fullWidth
              inputRef={noteRef}
              onKeyUp={handleSupplierNoteEnter}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              <DataGridPro
                getRowId={(row) => row.supplierID + row.articleId}
                rowHeight={60}
                rows={roundData.filter(
                  (x) => x.supplierID === currentSupplier?.id
                )}
                checkboxSelection
                columns={colsSupplier}
                disableRowSelectionOnClick
                localeText={dataGridDEde}
                loading={isLoading}
                onRowSelectionModelChange={handleSelectionModelChange}
                rowCount={totalOrderCount}
                processRowUpdate={(updatedRow, originalRow) => {
                  if (
                    updatedRow.quantityTotal >=
                      originalRow.quantityForCustomers &&
                    updatedRow.quantityTotal !== originalRow.quantityTotal
                  ) {
                    handleRoundQuantityItemEdit(
                      parseInt(updatedRow.articleId),
                      updatedRow.quantityTotal
                    )
                    return updatedRow
                  } else if (
                    updatedRow.description !== originalRow.description
                  ) {
                    handleRoundDescriptionItemEdit(
                      parseInt(updatedRow.articleId),
                      updatedRow.description
                    )
                    return updatedRow
                  }
                  return originalRow
                }}
              />
            </Box>
          </Grid>
        </Grid>
      )}
      <RoundOrderAddItem
        open={addItemIsOpen}
        onClose={() => setAddItemIsOpen(false)}
        supplierName={currentSupplier ? currentSupplier.name : ""}
        supplierId={currentSupplier ? currentSupplier.id : -1}
        onSave={handleRoundItemAdd}
      />
      <RoundOrderSplitItemDialog
        open={splitItemIsOpen}
        onClose={() => setSplitItemIsOpen(false)}
        selection={selection}
        supplierName={currentSupplier ? currentSupplier.name : ""}
        supplierOrderInfo={supplierOrderInfo}
        onSplit={handleRoundItemsSplit}
        isLoading={isLoading}
      />
      <RoundOrderMoveItemDialog
        open={moveItemIsOpen}
        onClose={() => setMoveItemIsOpen(false)}
        selection={selection}
        supplierName={currentSupplier ? currentSupplier.name : ""}
        supplierOrderInfo={supplierOrderInfo}
        supplierItemCrossRefs={supplierItemCrossRefs}
        onMove={handleMoveItems}
        isLoading={isLoading}
      />
      <RoundOrderDeleteItemDialog
        open={deleteItemIsOpen}
        onClose={() => setDeleteItemIsOpen(false)}
        selection={selection}
        supplierName={currentSupplier ? currentSupplier.name : ""}
        supplierOrderInfo={supplierOrderInfo}
        onDelete={handleDeleteItems}
        isLoading={isLoading}
      />
    </Container>
  )
}

export default RoundOrderPage
