import {
  SupplierInput,
  SuppliersQuery,
  SuppliersQueryVariables,
  SupplierUpdateMutation,
  SupplierUpdateMutationVariables,
  SupplierCreateMutation,
  SupplierCreateMutationVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"
import { fetchGQL } from "app/api"

const GET_SUPPLIERS = gql`
  query suppliers {
    suppliers {
      id
      name
      street
      city
      zip
      phone
      fax
      email
      active
      flavour
      sendEmail
      sendFax
      G24
      inStorage
      doegeGBF
    }
  }
`
const UPDATE_SUPPLIERS = gql`
  mutation supplierUpdate($input: SupplierInput!) {
    supplierUpdate(in: $input) {
      id
      name
      street
      city
      zip
      phone
      fax
      email
      active
      flavour
      sendEmail
      sendFax
      G24
      inStorage
      doegeGBF
    }
  }
`

const CREATE_SUPPLIERS = gql`
  mutation supplierCreate($input: SupplierInput!) {
    supplierCreate(in: $input) {
      id
      name
      street
      city
      zip
      phone
      fax
      email
      active
      flavour
      sendEmail
      sendFax
      G24
      inStorage
      doegeGBF
    }
  }
`

export const suppliersGet = async (): Promise<SuppliersQuery | undefined> => {
  const variables: SuppliersQueryVariables = {}
  const data = await fetchGQL<SuppliersQuery, SuppliersQueryVariables>(
    GET_SUPPLIERS,
    variables
  )
  return data
}

export const supplierUpdate = async (
  input: SupplierInput
): Promise<SupplierUpdateMutation | undefined> => {
  const variables: SupplierUpdateMutationVariables = {
    input: input,
  }
  const data = await fetchGQL<
    SupplierUpdateMutation,
    SupplierUpdateMutationVariables
  >(UPDATE_SUPPLIERS, variables)
  return data
}

export const supplierCreate = async (
  input: SupplierInput
): Promise<SupplierCreateMutation | undefined> => {
  const variables: SupplierCreateMutationVariables = {
    input: input,
  }
  const data = await fetchGQL<
    SupplierCreateMutation,
    SupplierCreateMutationVariables
  >(CREATE_SUPPLIERS, variables)
  return data
}
