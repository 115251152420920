import { BoxListQuery, BoxListQueryVariables } from "../generated/graphql"
import { gql } from "graphql-tag"
import { fetchGQL } from "app/api"

const GET_BOX_LIST = gql`
  query boxList {
    boxList {
      id
      barcode
      description
      lengthMM
      widthMM
      heightMM
    }
  }
`

export const boxListGet = async (): Promise<BoxListQuery | undefined> => {
  const variables: BoxListQueryVariables = {}
  const data = await fetchGQL<BoxListQuery, BoxListQueryVariables>(
    GET_BOX_LIST,
    variables
  )
  return data
}
