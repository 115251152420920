import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"
import { countries } from "./../../constants/countries"
import { Address } from "generated/graphql"
import { customerAddressSave } from "queries/customerAddressSave"
import { Dispatch, SetStateAction } from "react"

type AddAddressDialog = {
  open: boolean
  close: () => void
  customerID: number
  setAddresses: Dispatch<SetStateAction<Address[]>>
}

export const AddAddress = (props: AddAddressDialog) => {
  const { open, close, customerID, setAddresses } = { ...props }

  const getFullName = (firstName: string | null, lastName: string): string => {
    if (firstName) {
      return firstName + " " + lastName
    } else {
      return lastName
    }
  }

  const getCountryCode = (country: string): string => {
    return countries.filter((item) => item.name === country)[0].code
  }

  const saveAddress = async (newAddress: Address) => {
    if (newAddress) {
      const res = await customerAddressSave({
        in: {
          ...newAddress,
          customerID: Number(customerID),
        },
      })

      if (res) {
        setAddresses((oldRows: Address[]) => [
          ...oldRows,
          {
            ...newAddress,
            id: res.customerAddressSave.id,
          },
        ])
      }
    }
    close()
  }

  return (
    <Dialog
      open={open}
      onClose={close}
      PaperProps={{
        component: "form",
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault()
          const formData = new FormData(event.currentTarget)
          const formJson = Object.fromEntries((formData as any).entries())

          saveAddress({
            anrede: formJson.anrede,
            city: formJson.city,
            company: formJson.company,
            country: formJson.country,
            countryCode: getCountryCode(formJson.country),
            firstName: formJson.firstName,
            fullName: getFullName(formJson.firstName, formJson.lastName),
            id: 0,
            lastName: formJson.lastName,
            street: formJson.street,
            zipCode: formJson.zipCode,
          })
        },
      }}
    >
      <DialogTitle>Addresse hinzufügen</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="dense" required>
          <InputLabel id="anrede-label">Anrede</InputLabel>
          <Select
            labelId="anrede-label"
            id="anrede-label"
            name="anrede"
            label="Anrede"
            defaultValue=""
          >
            <MenuItem value="">Keine Anrede</MenuItem>
            <MenuItem value={"Frau"}>Frau</MenuItem>
            <MenuItem value={"Herr"}>Herr</MenuItem>
            <MenuItem value={"Firma"}>Firma</MenuItem>
          </Select>
        </FormControl>

        <TextField
          autoFocus
          margin="dense"
          id="company"
          name="company"
          label="Firma"
          type="text"
          fullWidth
        />
        <TextField
          autoFocus
          margin="dense"
          id="firstName"
          name="firstName"
          label="Vorname"
          type="text"
          fullWidth
        />
        <TextField
          autoFocus
          required
          margin="dense"
          id="lastName"
          name="lastName"
          label="Nachname"
          type="text"
          fullWidth
        />
        <TextField
          autoFocus
          required
          margin="dense"
          id="street"
          name="street"
          label="Straße"
          type="text"
          fullWidth
        />
        <TextField
          autoFocus
          required
          margin="dense"
          id="zipCode"
          name="zipCode"
          label="PLZ"
          type="text"
          fullWidth
        />
        <TextField
          autoFocus
          required
          margin="dense"
          id="city"
          name="city"
          label="Ort"
          type="text"
          fullWidth
        />
        <FormControl fullWidth margin="dense" required>
          <InputLabel id="land-label">Land</InputLabel>
          <Select
            labelId="land-label"
            id="country"
            name="country"
            label="Land"
            defaultValue={"Deutschland"}
          >
            {countries.map((item) => (
              <MenuItem key={item.name} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button type="submit">Speichern</Button>
        <Button onClick={close}>Abbrechen</Button>
      </DialogActions>
    </Dialog>
  )
}
