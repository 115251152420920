import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material"
import ArticleSearchRoundAdd, {
  ArticleItemWithCount,
} from "pages/AricleSearchRoundAdd/ArticleSearchRoundAdd"
import { useState } from "react"

type RoundOrderAddItemProps = {
  open: boolean
  onClose: () => void
  onSave: (selectedItems: ArticleItemWithCount[], supplierId: number) => void
  supplierName: string
  supplierId: number
}

const RoundOrderAddItem = (props: RoundOrderAddItemProps): JSX.Element => {
  const { open, onClose, supplierName, onSave } = props
  const [selectedItems, setSelectedItems] = useState<ArticleItemWithCount[]>([])

  const handleSave = () => {
    onSave(selectedItems, props.supplierId)
    onClose()
    setSelectedItems([])
  }

  const handleOnClose = () => {
    onClose()
    setSelectedItems([])
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl">
      <DialogTitle>Artikel hinzufügen zu {supplierName}</DialogTitle>
      <DialogContent sx={{ height: "70vh", width: "100%" }}>
        <ArticleSearchRoundAdd
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
        />
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          variant="contained"
          color="error"
          onClick={handleOnClose}
        >
          Abbrechen
        </Button>
        <Button
          fullWidth
          variant="contained"
          disabled={selectedItems.length === 0}
          onClick={handleSave}
        >
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RoundOrderAddItem
