import { fetchGQL } from "app/api"
import {
  NewOrdersByStatusQuery,
  NewOrdersByStatusQueryVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"

const GET_ORDER_AGG = gql`
  query newOrdersByStatus($status: String!) {
    newOrdersByStatus(status: $status) {
      rows {
        bdb
        botname
        quantity
        supplier
        singles
        soldStatus
      }
    }
  }
`

export const fetchOrderAggregateByStatus = async (
  status: string
): Promise<NewOrdersByStatusQuery | undefined> => {
  const variables: NewOrdersByStatusQueryVariables = { status }
  const data = await fetchGQL<
    NewOrdersByStatusQuery,
    NewOrdersByStatusQueryVariables
  >(GET_ORDER_AGG, variables)
  return data
}
