import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material"
import {
  OrderCancelAction,
  OrderItem,
  OrderItemsCancelInput,
} from "generated/graphql"
import { useState } from "react"
import { CancelOrderItemsDialog } from "./CancelOrderItemsDialog"

type CancelOrderDialogProps = {
  open: boolean
  close: () => void
  handleCancel: (arg: OrderItemsCancelInput) => void
  orderItems: OrderItem[]
}

const emptyCancelOrderInput: OrderItemsCancelInput = {
  orderID: "",
  orderItems: [],
  action: OrderCancelAction.CreditBackToCustomerDeposit,
}

export const CancelOrderDialog = (props: CancelOrderDialogProps) => {
  const { open, close, handleCancel, orderItems } = { ...props }
  const [cancelOrderInput, setCancelOrderInput] =
    useState<OrderItemsCancelInput>(emptyCancelOrderInput)
  const [openDialog, setOpenDialog] = useState(false)
  const [step, setStep] = useState<number>(0)

  const handleNotCancel = () => {
    setOpenDialog(false)
    setStep(0)
    close()
    setCancelOrderInput(emptyCancelOrderInput)
  }

  const handleReallyCancel = () => {
    handleCancel(cancelOrderInput)
    setOpenDialog(false)
    setStep(0)
    setCancelOrderInput(emptyCancelOrderInput)
    close()
  }

  const backToStart = () => {
    setStep(0)
    setCancelOrderInput(emptyCancelOrderInput)
  }

  return (
    <>
      {step === 0 && (
        <Dialog open={open} onClose={handleNotCancel}>
          <DialogTitle>Auftrag stornieren</DialogTitle>
          <DialogContent>
            <Typography>
              Ganzen Auftrag oder einzelnen Artikel stornieren?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => setStep(2)}>
              Ganzen Auftrag
            </Button>
            <Button variant="outlined" onClick={() => setStep(1)}>
              Einzelne Artikel
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {step === 1 && (
        <Dialog open={open} onClose={handleNotCancel} maxWidth="md">
          <DialogTitle>Welche Artikel sollen storniert werden?</DialogTitle>
          <DialogContent>
            <CancelOrderItemsDialog
              orderItems={orderItems}
              setOrderItemsCancelInput={setCancelOrderInput}
              orderItemsCancelInput={cancelOrderInput}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => setStep(2)}>
              OK
            </Button>
            <Button variant="outlined" onClick={backToStart}>
              Zurück
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {step === 2 && (
        <Dialog open={open} onClose={handleNotCancel}>
          <DialogTitle>
            Was soll mit dem bereits gezahlten Betrag geschehen?
          </DialogTitle>
          <DialogContent>
            <InputLabel id="action">Action</InputLabel>
            <FormControl fullWidth>
              <Select
                labelId="action"
                id="action"
                value={cancelOrderInput.action}
                label="Action"
                onChange={(e) => {
                  setCancelOrderInput({
                    ...cancelOrderInput,
                    action:
                      e.target.value ===
                      OrderCancelAction.CreditBackToCustomerDeposit
                        ? OrderCancelAction.CreditBackToCustomerDeposit
                        : OrderCancelAction.TransferToBankAccount,
                  })
                }}
              >
                <MenuItem value={OrderCancelAction.CreditBackToCustomerDeposit}>
                  Kundenkonto
                </MenuItem>
                <MenuItem value={OrderCancelAction.TransferToBankAccount}>
                  Rücküberweisung
                </MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => setOpenDialog(true)}>
              Stornieren
            </Button>
            <Button variant="contained" onClick={handleNotCancel}>
              Abbrechen
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Wirklich stornieren</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={handleReallyCancel}>
            Ja
          </Button>
          <Button variant="contained" onClick={handleNotCancel}>
            Nein
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
