import {
  RoundStatInput,
  RoundLogisticStatQuery,
  RoundLogisticStatQueryVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"
import { fetchGQL } from "app/api"

const GET_ROUND_LOGISTIC_STAT = gql`
  query roundLogisticStat($in: RoundStatInput!) {
    roundStats(in: $in) {
      idExists
      roundID
      roundName
      startDate
      orderCount
      openOrderCount
      articleCount
      taggedArticleCount
      distributedArticleCount
      percentage
    }
    logisticStat {
      tagged
      distributed
      packagesSent
    }
  }
`

export const fetchRoundLogisticStat = async (
  input: RoundStatInput
): Promise<RoundLogisticStatQuery | undefined> => {
  const variables: RoundLogisticStatQueryVariables = { in: input }
  const data = await fetchGQL<
    RoundLogisticStatQuery,
    RoundLogisticStatQueryVariables
  >(GET_ROUND_LOGISTIC_STAT, variables)
  return data
}
