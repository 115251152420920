import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material"
import { Carton } from "generated/graphql"

export interface DialogRoundSupplierProps {
  isOpen: boolean
  selectedCarton?: Carton
  onClose: () => void
  onDelete: () => void
}

const DialogBoxDelete = (props: DialogRoundSupplierProps): JSX.Element => {
  const { isOpen, onClose, onDelete, selectedCarton } = props

  let name = ""
  if (selectedCarton) {
    name = selectedCarton.description
  }

  return (
    <Dialog maxWidth="xs" open={isOpen} onClose={onClose}>
      <DialogTitle>Wollen Sie wirklich diese Box löschen?</DialogTitle>
      <DialogContent dividers>
        <Typography variant={"body1"}>
          {'Wenn Sie die Box "' +
            name +
            '" löschen, werden alle Daten unwiderruflich gelöscht'}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button fullWidth variant="contained" onClick={onDelete}>
          Löschen
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogBoxDelete
