import LoadingButton from "@mui/lab/LoadingButton"
import {
  Box,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material"
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro"
import { useAppSelector } from "app/hooks"
import { selectCurrentRound } from "app/roundSlice"
import { dataGridDEde } from "constants/dataGridLocale"
import dayjs from "dayjs"
import { OrderListInput, Printer, RoundOrderPrintItem } from "generated/graphql"
import { enqueueSnackbar } from "notistack"
import { fetchPrinters } from "queries/fetchPrinters"
import { orderListGetPrintItems } from "queries/orderListGetPrintItems"
import { orderListPreviewPDF } from "queries/orderListPreviewPDF"
import { orderListPrintPDFs } from "queries/orderListPrintPDFs"
import { useEffect, useState } from "react"
import { base64ToBlob } from "utils/base64ToBlob"
import { downloadBlob } from "utils/downloadBlob"

const RoundOrderPrintingPage = (): JSX.Element => {
  const round = useAppSelector(selectCurrentRound)
  const [isLoading, setIsLoading] = useState(false)
  const [gridData, setGridData] = useState<RoundOrderPrintItem[]>([])
  const [selection, setSelection] = useState<RoundOrderPrintItem[]>([])

  const handleSelectionModelChange = (selectionModel: any) => {
    //selection model is an array of selected row ids here routeIDs
    // e.g [1,2,3] this comes from the database
    const selectedOrderList = selectionModel.map((id: any) =>
      gridData.find(
        (item) => item.supplierID + item.emailSentAt + item.faxSentAt === id
      )
    )
    setSelection(selectedOrderList)
  }

  const colsPrinting: GridColDef<RoundOrderPrintItem>[] = [
    {
      field: "supplierID",
      headerName: "Kurzwahl",
      width: 200,
    },
    {
      field: "supplierName",
      headerName: "Lieferant",
      width: 200,
    },
    {
      field: "orderList",
      headerName: "Bestellliste",
      width: 200,
      renderCell: (params) => {
        const handleClick = async (_: any) => {
          if (!round) return
          let date = params.row.emailSentAt
          if (date === "") {
            date = params.row.faxSentAt
          }
          orderListPreviewPDF(round?.round, params.row.supplierID, date)
            .then((data) => {
              if (data?.orderListPreviewPDF) {
                const blb = base64ToBlob(
                  data.orderListPreviewPDF.pdfBase64,
                  "application/pdf"
                )
                const now = dayjs().format("DD.MM.YYYY_HH.mm")
                downloadBlob(
                  blb,
                  "R" +
                    round.round.toString() +
                    "_" +
                    params.row.supplierID.toString() +
                    "_" +
                    params.row.supplierName +
                    "_" +
                    now +
                    ".pdf"
                )
              }
            })
            .finally(() => {
              // do nothing
            })
        }
        return (
          <Typography
            variant="body2"
            sx={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "primary.main",
            }}
            component="div"
            onClick={handleClick}
          >
            PDF Anzeigen
          </Typography>
        )
      },
    },
    {
      field: "emailSentAt",
      headerName: "Email gesendet am",
      width: 200,
      valueFormatter: (params) => {
        if (params.value) {
          return dayjs(params.value).format("DD.MM.YYYY HH:mm")
        }
        return ""
      },
    },
    {
      field: "faxSentAt",
      headerName: "Fax gesendet am",
      width: 200,
      valueFormatter: (params) => {
        if (params.value) {
          return dayjs(params.value).format("DD.MM.YYYY HH:mm")
        }
        return ""
      },
    },
  ]

  useEffect(() => {
    if (!round) {
      return
    }
    setIsLoading(true)
    orderListGetPrintItems(round.round)
      .then((res) => {
        if (res?.orderListGetPrintItems) {
          setGridData(
            res.orderListGetPrintItems.sort((a, b) =>
              a.supplierName.localeCompare(b.supplierName)
            )
          )
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [round])

  const [selectedPrinter, setSelectedPrinter] = useState<Printer | null>(null)
  const [printers, setPrinters] = useState<Printer[]>([])

  useEffect(() => {
    // load printer from server
    fetchPrinters().then((data) => {
      if (data?.printers) {
        setPrinters(data.printers)
        const sp = localStorage.getItem("selectedPrinter-OrderPrinting")
        if (sp) {
          const printer = data.printers.find(
            (printer) => printer.printerID === Number(sp)
          )
          if (printer) {
            setSelectedPrinter(printer)
          }
        }
      }
    })
  }, [])

  const handlePrintOrders = () => {
    const printItems: OrderListInput[] = selection.map((item) => {
      let date = item.emailSentAt
      if (date === "") {
        date = item.faxSentAt
      }
      return {
        supplierID: item.supplierID,
        date: date,
      }
    })
    const printerName = selectedPrinter?.printerName
    if (
      printItems.length > 0 &&
      printerName !== undefined &&
      printerName !== "" &&
      round !== null
    ) {
      setIsLoading(true)
      orderListPrintPDFs(round.round, printItems, printerName)
        .then((data) => {
          if (data?.orderListPrintPDFs) {
            enqueueSnackbar(
              `Bestellungen wurden zum Drucker ${printerName} geschickt`,
              { variant: "success" }
            )
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  return (
    <Container maxWidth="xl">
      <Grid container spacing={1}>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <InputLabel id="printer-select-label">Drucker</InputLabel>
            <Select
              labelId="printer-select-label"
              id="printer-select"
              value={selectedPrinter?.printerID || ""}
              label="Drucker"
              onChange={(event) => {
                const printerID = event.target.value as number
                const printer = printers.find(
                  (printer) => printer.printerID === printerID
                )
                setSelectedPrinter(printer || null)
                localStorage.setItem(
                  "selectedPrinter-OrderPrinting",
                  printerID.toString()
                )
              }}
            >
              {printers.map((printer) => (
                <MenuItem key={printer.printerID} value={printer.printerID}>
                  {printer.printerID} - {printer.printerIP}:
                  {printer.printerPort} - {printer.printerName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            sx={{ height: "100%" }}
            onClick={handlePrintOrders}
            loading={isLoading}
            disabled={selection.length === 0 || gridData.length === 0}
          >
            ({selection.length}) Bestellungen drucken
          </LoadingButton>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ height: "80vh", width: "100%" }}>
            <DataGridPro
              rows={gridData}
              checkboxSelection
              getRowId={(row) =>
                row.supplierID + row.emailSentAt + row.faxSentAt
              }
              disableRowSelectionOnClick
              localeText={dataGridDEde}
              columns={colsPrinting}
              loading={isLoading}
              onRowSelectionModelChange={handleSelectionModelChange}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default RoundOrderPrintingPage
