import { fetchGQL } from "app/api"
import { gql } from "graphql-tag"
import {
  ArticleSearchQuery,
  ArticleSearchQueryVariables,
} from "../generated/graphql"

const ARTICLE_SEARCH = gql`
  query articleSearch($t: String!) {
    articleSearch(t: $t) {
      bdb
      pflNR
      botname
      gername
      price
      reducedPrice
      sizeFrom
      sizeTo
    }
  }
`

export const articleSearch = async (
  t: string
): Promise<ArticleSearchQuery | undefined> => {
  const variables: ArticleSearchQueryVariables = {
    t,
  }
  const data = await fetchGQL<ArticleSearchQuery, ArticleSearchQueryVariables>(
    ARTICLE_SEARCH,
    variables
  )
  return data
}
