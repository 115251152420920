import { gql } from "graphql-tag"
import { fetchGQL } from "app/api"
import {
  GetNextRoundNumberQuery,
  GetNextRoundNumberQueryVariables,
} from "generated/graphql"

const GET_NEXT_ROUND_NUMBER = gql`
  query getNextRoundNumber {
    getNextRoundNumber
  }
`

export const getNextRoundNumber = async (): Promise<
  GetNextRoundNumberQuery | undefined
> => {
  const variables: GetNextRoundNumberQueryVariables = {}
  const data = await fetchGQL<
    GetNextRoundNumberQuery,
    GetNextRoundNumberQueryVariables
  >(GET_NEXT_ROUND_NUMBER, variables)
  return data
}
