import { Box, Button, Container, Typography } from "@mui/material"
import { Refresh } from "@mui/icons-material"
import TaskAltIcon from "@mui/icons-material/TaskAlt"
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked"

import {
  DataGridPro,
  GridColDef,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro"
import { dataGridDEde } from "constants/dataGridLocale"
import { enqueueSnackbar } from "notistack"
import { customerOrderSettle, getCustomerRefund } from "queries/customerRefund"
import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { germanDateFormatter } from "utils/datefmt"
import { CustomerRefund } from "generated/graphql"

const getEuro = (betrag: number) => {
  return betrag.toFixed(2) + " €"
}

export const RefundListPage = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false)
  const [refundData, setRefundData] = useState<CustomerRefund[]>([])
  const navigate = useNavigate()

  const fetchRefundsData = useCallback(async () => {
    setLoading(true)
    const res = await getCustomerRefund()
    if (res?.customerRefunds) {
      //  enqueueSnackbar("Rücküberweisungen geladen!", { variant: "success" })
      setRefundData(res.customerRefunds)
    }
    setLoading(false)
  }, [])

  useEffect(() => {
    fetchRefundsData()
  }, [fetchRefundsData])

  const handleSettleClick = async (id: number) => {
    const res = await customerOrderSettle(id)
    if (res?.customerRefundSettle) {
      enqueueSnackbar("Rücküberweisung erfolgreich", { variant: "success" })
      setRefundData((prev) =>
        prev.map((item) => {
          if (item.id === id) {
            return res.customerRefundSettle
          }
          return item
        })
      )
    } else {
      enqueueSnackbar("Fehler beim Setzen der Rücküberweisung", {
        variant: "error",
      })
    }
  }

  const columnRefunds: GridColDef[] = [
    {
      field: "orderID",
      headerName: "Bestell ID",
      renderCell: (params) => {
        return (
          <Typography
            variant="body2"
            sx={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "primary.main",
            }}
            component="div"
            onClick={() => navigate("/orderDetails/" + params.row.orderID)}
          >
            {params.row.orderID}
          </Typography>
        )
      },
    },
    {
      field: "customerID",
      headerName: "Kunde ID",
      renderCell: (params) => {
        return (
          <Typography
            variant="body2"
            sx={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "primary.main",
            }}
            component="div"
            onClick={() => navigate("/editCustomer/" + params.row.customerID)}
          >
            {params.row.customerID}
          </Typography>
        )
      },
    },
    { field: "articleID", headerName: "Artikel ID" },
    {
      field: "amount",
      headerName: "Betrag",
      valueGetter: (params) => getEuro(params.row.amount / 100), //TODO: if amount is in EUR-Cent
    },
    {
      field: "note",
      headerName: "Detail",
      width: 600,
      renderCell: (params) => (
        <Typography
          variant="body2"
          component="div"
          sx={{
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "createdAt",
      headerName: "angelegt am",
      width: 150,
      valueFormatter: (params) => {
        if (params.value !== "") {
          return germanDateFormatter.format(new Date(params.value as string))
        }
      },
    },
    { field: "createdBy", headerName: "angelegt von" },
    {
      field: "settledAt",
      headerName: "erledigt am",
      width: 150,
      valueFormatter: (params) => {
        if (params.value !== "" && params.value !== null) {
          return germanDateFormatter.format(new Date(params.value as string))
        } else {
          return null
        }
      },
    },
    { field: "settledBy", headerName: "erledigt von" },
    {
      field: "action",
      headerName: "Aktion",
      width: 100,
      renderCell: (params) => (
        <Button
          fullWidth
          variant="contained"
          disabled={params.row.settledBy ? true : false}
          sx={{
            textTransform: "none",
            backgroundColor: "red",
            "&.Mui-disabled": {
              backgroundColor: "green",
              color: "white",
            },
          }}
          onClick={() => handleSettleClick(params.row.id)}
        >
          {params.row.settledBy ? (
            <TaskAltIcon fontSize="small" />
          ) : (
            <RadioButtonUncheckedIcon fontSize="small" />
          )}
        </Button>
      ),
    },
  ]

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    )
  }

  return (
    <Container
      maxWidth={false}
      sx={{
        paddingTop: 6,
      }}
    >
      <Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            flex: 1,
            marginBottom: 10,
          }}
        >
          <Button
            variant="contained"
            startIcon={<Refresh />}
            sx={{ textTransform: "none" }}
            onClick={() => {
              fetchRefundsData()
            }}
          >
            Aktualisieren
          </Button>
        </Box>
        {!loading && refundData.length === 0 && (
          <Typography
            variant="h6"
            component="h1"
            gutterBottom
            style={{ textAlign: "center" }}
          >
            Keine Rücküberweisungen
          </Typography>
        )}
        <Box sx={{ height: "80vh", width: "100%" }}>
          <DataGridPro
            rows={refundData}
            getRowHeight={() => "auto"}
            loading={loading}
            columns={columnRefunds}
            disableRowSelectionOnClick
            localeText={dataGridDEde}
            getRowId={(row) => row.id}
            slots={{
              toolbar: CustomToolbar,
            }}
            slotProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
              },
            }}
          />
        </Box>
      </Box>
    </Container>
  )
}
