import { fetchGQL } from "app/api"
import {
  RoundOrderDataQuery,
  RoundOrderDataQueryVariables,
} from "generated/graphql"
import gql from "graphql-tag"

const Q = gql`
  query roundOrderData($round: Int!) {
    roundOrderdata(round: $round) {
      sh
      totalArticleCount
      orderid
      zieldat
      notiz
      zahlart
      kundnum
      status
      firstName
      lastName
      street
      city
      zip
      country
    }
  }
`

export const roundOrderdata = async (
  round: number
): Promise<RoundOrderDataQuery | undefined> => {
  const variables: RoundOrderDataQueryVariables = { round }
  const data = await fetchGQL<
    RoundOrderDataQuery,
    RoundOrderDataQueryVariables
  >(Q, variables)
  return data
}
