import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material"
import LoadingButton from "@mui/lab/LoadingButton"
import dayjs, { Dayjs } from "dayjs"
import { useEffect, useState } from "react"
import { carriersGet } from "queries/fetchCarriers"
import { boxStatisticGet } from "queries/fetchBoxStatistics"
import { CartonStatistic } from "generated/graphql"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import { Pie } from "react-chartjs-2"
import { dataGridDEde } from "constants/dataGridLocale"
import { useAppDispatch } from "app/hooks"
import { qSnack } from "app/snackSlice"
import {
  DataGridPro,
  GridColDef,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro"
import { DateRange, DateRangePicker } from "@mui/x-date-pickers-pro"
import { shortcutsItems } from "utils/shortcutItems"
import { formatDateFromDate } from "utils/formatDate"

ChartJS.register(ArcElement, Tooltip, Legend)

interface PieChartEnty {
  title: string
  value: number
}

const columns: GridColDef[] = [
  {
    field: "barcode",
    headerName: "Barcode",
    width: 100,
    editable: false,
    valueGetter: (params: GridValueGetterParams) => params.row.carton.barcode,
  },
  {
    field: "carton.description",
    headerName: "Beschreibung",
    width: 180,
    editable: false,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.carton.description,
  },
  {
    field: "carton.lengthMM",
    headerName: "Länge",
    width: 80,
    editable: false,
    valueGetter: (params: GridValueGetterParams) => params.row.carton.lengthMM,
  },
  {
    field: "carton.widthMM",
    headerName: "Breite",
    width: 80,
    editable: false,
    valueGetter: (params: GridValueGetterParams) => params.row.carton.widthMM,
  },
  {
    field: "carton.heightMM",
    headerName: "Höhe",
    width: 80,
    editable: false,
    valueGetter: (params: GridValueGetterParams) => params.row.carton.heightMM,
  },
  {
    field: "count",
    headerName: "Anzahl",
    width: 90,
    editable: false,
  },
  {
    field: "countPercent",
    headerName: "%",
    width: 80,
    editable: false,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.countPercent.toLocaleString("de", {
        maximumFractionDigits: 2,
      }),
  },
]

const BoxStatistic = () => {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const [filterCarrier, setFilterCarrier] = useState<string>("all")
  const [filterOrderId, setFilterOrderId] = useState<string>("")
  const [pieChartData, setPieChartData] = useState<PieChartEnty[]>([])
  const [cartonStatisticList, setCartonStatisticList] = useState<
    CartonStatistic[]
  >([])
  const [carrierList, setCarrierList] = useState<string[]>([])
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([
    dayjs().startOf("month"),
    dayjs(),
  ])

  //load data for carton statistics
  const loadCartonStatistics = () => {
    let carrier = ""
    if (!isLoading) setIsLoading(true)
    if (filterCarrier !== "all") carrier = filterCarrier
    const fromDateString = dateRange[0]
      ? formatDateFromDate(dateRange[0].toDate())
      : ""
    const toDateString = dateRange[1]
      ? formatDateFromDate(dateRange[1].toDate())
      : ""
    if (!fromDateString || !toDateString) return
    if (fromDateString === "" || toDateString === "") return

    boxStatisticGet(fromDateString, toDateString, carrier, filterOrderId).then(
      (data) => {
        if (data) {
          let newPieChartData: PieChartEnty[] = []
          let newCartonStatistics: CartonStatistic[] = []
          if (data?.boxStatistics) {
            newCartonStatistics = data.boxStatistics
            if (data.boxStatistics.length === 0) {
              dispatch(
                qSnack({
                  msg: "Keine Daten gefunden für die gewählten Parameter",
                  severity: "warning",
                })
              )
            } else {
              dispatch(
                qSnack({ msg: "Statistik geladen", severity: "success" })
              )
            }
            for (const entry of data.boxStatistics) {
              newPieChartData.push({
                title: entry.carton.description,
                value: entry.countPercent,
              })
            }
            newPieChartData.sort((a, b) => (a.value > b.value ? -1 : 1))
            newCartonStatistics.sort((a, b) =>
              a.countPercent > b.countPercent ? -1 : 1
            )
          }
          setCartonStatisticList(newCartonStatistics)
          setPieChartData(newPieChartData)
          setIsLoading(false)
        }
      }
    )
  }

  useEffect(() => {
    carriersGet().then((data) => {
      if (data) {
        setCarrierList(data.carriers.filter((c) => c !== ""))
      }
      setIsLoading(false)
    })
  }, [])

  return (
    <Container maxWidth="xl">
      <Grid container justifyContent={"center"} spacing={3}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: 500 }}>
              <DateRangePicker
                slotProps={{
                  shortcuts: {
                    items: shortcutsItems,
                  },
                }}
                localeText={{ start: "Von", end: "Bis" }}
                value={dateRange}
                onChange={(newValue) => setDateRange(newValue)}
                disableFuture={true}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container justifyContent={"center"} spacing={3}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: 500, marginTop: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="filter-carrier-label">Kurier</InputLabel>
                    <Select
                      labelId="filter-carrier-label"
                      id="filter-carrier-select"
                      label="Kurier"
                      value={filterCarrier}
                      onChange={(event: SelectChangeEvent<string>) => {
                        setFilterCarrier(event.target.value)
                      }}
                    >
                      <MenuItem value="all">Alle</MenuItem>
                      {carrierList.map((carrier: string, index: number) => (
                        <MenuItem key={index} value={carrier}>
                          {carrier}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="orderId"
                    label="Bestellnummer"
                    variant="outlined"
                    value={filterOrderId}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setFilterOrderId(event.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <LoadingButton
                    loading={isLoading}
                    variant="contained"
                    fullWidth
                    onClick={loadCartonStatistics}
                  >
                    Lade Daten
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ marginTop: 3 }}>
        <Grid item xs={12} md={6}>
          <DataGridPro
            rows={cartonStatisticList}
            localeText={dataGridDEde}
            columns={columns}
            getRowId={(item) => item.carton.id}
            loading={isLoading}
            sortingOrder={["desc", "asc"]}
          />
        </Grid>
        <Grid item xs={12} md={6} justifyContent="center">
          <Grid item sm={8}>
            <Pie
              data={{
                labels: pieChartData.map((entry) => entry.title),
                datasets: [
                  {
                    label: "#",
                    data: pieChartData,
                    borderWidth: 1,
                    backgroundColor: [
                      "rgb(255, 99, 132)",
                      "rgb(54, 162, 235)",
                      "rgb(255, 206, 86)",
                      "rgb(75, 192, 192)",
                      "rgb(153, 102, 255)",
                      "rgb(255, 159, 64)",
                      "rgb(180 178 179)",
                      "rgb(88 156 91)",
                      "rgb(108 102 133)",
                      "rgb(218 53 89)",
                      "rgb(255 246 39)",
                      "rgb(39 255 249)",
                      "rgb(173 255 39)",
                      "rgb(255 39 185)",
                      "rgb(53 154 255)",
                    ],
                  },
                ],
              }}
              options={{
                responsive: true,
                parsing: {
                  key: "value",
                },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default BoxStatistic
