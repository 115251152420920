import AddIcon from "@mui/icons-material/Add"
import { Box, Button, Typography } from "@mui/material"
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GridSlotsComponentsProps,
  GridSortModel,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro"
import { CollapsibleTable } from "components/CollapsibleTable/CollapsibleTable"
import { dataGridDEde } from "constants/dataGridLocale"
import dayjs from "dayjs"
import { CustomerOrder } from "generated/graphql"
import { customerOrders } from "queries/customerOrders"
import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { AddOrderDialog } from "./AddOrderDialog"

declare module "@mui/x-data-grid-pro" {
  interface FooterPropsOverrides {
    addClick: () => void
  }
}

const getEuro = (betrag: number) => {
  return betrag.toFixed(2) + " €"
}

type CustomerOrderExtended = CustomerOrder & {
  sortDatum: string
}

const columnsOrder: GridColDef<CustomerOrderExtended>[] = [
  {
    field: "urorder",
    headerName: "Urorder",
    width: 100,
  },
  {
    field: "sortDatum",
    headerName: "sortDatum",
    width: 100,
    valueGetter: (params) => dayjs(params.value).format("DD.MM.YYYY"),
  },
  {
    field: "datum",
    headerName: "Datum",
    width: 100,
    valueGetter: (params) => dayjs(params.value).format("DD.MM.YYYY"),
  },
  {
    field: "zieldat",
    headerName: "Zieldatum",
    width: 180,
  },
  {
    field: "status",
    headerName: "Status",
    width: 80,
  },
  {
    field: "zahlart",
    headerName: "Zahlart",
    width: 120,
  },
  {
    field: "rechsum",
    headerName: "Summe",
    width: 120,
    valueGetter: (params) =>
      getEuro(params.row.rechsum ? params.row.rechsum : 0),
  },
  {
    field: "bezahlt",
    headerName: "Bezahlt",
    width: 100,
    valueGetter: (params) =>
      getEuro(params.row.rechsum ? params.row.rechsum : 0),
  },
  {
    field: "frachtpausch",
    headerName: "Fracht",
    width: 100,
    valueGetter: (params) =>
      getEuro(params.row.rechsum ? params.row.rechsum : 0),
  },

  {
    field: "notiz",
    headerName: "Notiz",
    width: 200,
  },
  {
    field: "custnote",
    headerName: "Kundennotiz",
    width: 200,
  },
]

type CustomerEditPageOrderProps = {
  customerID: string
  addOrder: boolean
}

export const CustomerEditPageOrders = (props: CustomerEditPageOrderProps) => {
  const { customerID, addOrder } = props
  const [isLoading, setIsLoading] = useState(false)
  const [orders, setOrders] = useState<CustomerOrderExtended[]>()
  const [openAddOrderDialog, setOpenAddOrderDialog] = useState(false)
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "sortDatum",
      sort: "desc",
    },
  ])

  const navigate = useNavigate()

  const getSortDatum = (order: CustomerOrder, orders: CustomerOrder[]) => {
    const splittedOrder = orders.filter(
      (item) => item.urorder === order.orderID
    )
    if (splittedOrder.length === 0) {
      return order.datum
    }
    const newestOrder = splittedOrder.sort((a, b) => {
      return new Date(b.datum).getTime() - new Date(a.datum).getTime()
    })

    return newestOrder[0].datum
  }

  const OrderAddSortDatum = useCallback((orders: CustomerOrder[]) => {
    const newOrders = orders.map((item) => {
      const newOrder: CustomerOrderExtended = {
        ...item,
        sortDatum: getSortDatum(item, orders),
      }
      return newOrder
    })
    return newOrders
  }, [])

  const fetchOrders = () => {
    const id = customerID ? parseInt(customerID) : undefined
    if (id !== undefined) {
      setIsLoading(true)
      customerOrders(id)
        .then((result) => {
          if (result?.customerOrders) {
            setOrders(OrderAddSortDatum(result.customerOrders))
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  useEffect(() => {
    const id = customerID ? parseInt(customerID) : undefined
    if (id !== undefined) {
      setIsLoading(true)
      customerOrders(id)
        .then((result) => {
          if (result?.customerOrders) {
            setOrders(OrderAddSortDatum(result.customerOrders))
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [OrderAddSortDatum, customerID])

  const getGesamtUmsatz = (): string => {
    if (orders) {
      const sum = orders.reduce((accumulator, currentItem) => {
        return accumulator + currentItem.rechsum
      }, 0)
      return getEuro(sum)
    }
    return ""
  }

  const CustomToolbar = (
    props: NonNullable<GridSlotsComponentsProps["toolbar"]>
  ) => {
    const { addClick } = props

    return (
      <GridToolbarContainer
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <GridToolbarQuickFilter />
        {addOrder && (
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={addClick}
            sx={{ m: 1 }}
          >
            Auftrag anlegen
          </Button>
        )}
      </GridToolbarContainer>
    )
  }

  const CustomFooterStatusComponent = (
    props: NonNullable<GridSlotsComponentsProps["footer"]>
  ) => {
    return (
      <GridToolbarContainer
        sx={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Typography variant="h6">Gesamtumsatz: {getGesamtUmsatz()}</Typography>
      </GridToolbarContainer>
    )
  }

  const closeAddOrderDialog = () => {
    setOpenAddOrderDialog(false)
  }

  const closeAddOrderDialogNewOrder = async () => {
    setOpenAddOrderDialog(false)
    fetchOrders()
  }

  const getTreeDataPath: DataGridProProps["getTreeDataPath"] = (row) => {
    if (row.urorder > 0) return [row.urorder, row.orderID]
    return [row.orderID]
  }

  const groupingColDef: DataGridProProps["groupingColDef"] = {
    headerName: "OrderID",
    width: 160,
  }

  return (
    <>
      {orders && (
        <CollapsibleTable
          defaultOpen={true}
          title={`Aufträge (${orders.length})`}
        >
          <Box sx={{ height: 600 }}>
            <DataGridPro
              treeData
              getTreeDataPath={getTreeDataPath}
              groupingColDef={groupingColDef}
              defaultGroupingExpansionDepth={-1}
              rows={orders}
              getRowId={(row) => row.orderID}
              columns={columnsOrder}
              sortModel={sortModel}
              onSortModelChange={(model) => setSortModel(model)}
              localeText={dataGridDEde}
              rowHeight={60}
              loading={isLoading}
              slots={{
                footer: CustomFooterStatusComponent,
                toolbar: CustomToolbar,
              }}
              slotProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 100 },
                  addClick: () => setOpenAddOrderDialog(true),
                },
              }}
              onRowClick={(params) => {
                navigate(`/orderDetails/${params.id}`)
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              columnVisibilityModel={{
                sortDatum: false,
              }}
            />
          </Box>
        </CollapsibleTable>
      )}
      <AddOrderDialog
        customerID={customerID}
        open={openAddOrderDialog}
        close={closeAddOrderDialog}
        closeNewOrder={closeAddOrderDialogNewOrder}
      />
    </>
  )
}
