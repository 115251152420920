import { fetchGQL } from "app/api"
import { gql } from "graphql-tag"
import {
  ArticleSupplierDeleteMutation,
  ArticleSupplierDeleteMutationVariables,
} from "../generated/graphql"

const ARTICLE_SUPPLIER_DELETE = gql`
  mutation articleSupplierDelete($in: ArticleSupplierInput!) {
    articleSupplierDelete(in: $in)
  }
`

export const articleSupplierDelete = async (
  vars: ArticleSupplierDeleteMutationVariables
): Promise<ArticleSupplierDeleteMutation | undefined> => {
  const data = await fetchGQL<
    ArticleSupplierDeleteMutation,
    ArticleSupplierDeleteMutationVariables
  >(ARTICLE_SUPPLIER_DELETE, vars)
  return data
}
