import {
  Alert,
  Autocomplete,
  AutocompleteRenderInputParams,
} from "@mui/material"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import Container from "@mui/material/Container"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormGroup from "@mui/material/FormGroup"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { qSnack } from "app/snackSlice"
import { selectUser } from "app/userSlice"
import {
  ADMIN,
  BASE_DATA,
  CUSTOMER_SUPPORT,
  DEACTIVATED,
  DISPATCH,
  DISTRIBUTE,
  hasPrivilege,
  ROUNDS,
  SHIPPING_CREATE,
  SHIPPING_STATISTIC,
  SPLIT,
  STATISTIC,
  TAG,
} from "constants/privileges"
import { User, UserUpdateInput } from "generated/graphql"
import { userSave, usersGet } from "queries/fetchUsers"
import { ChangeEvent, useEffect, useState } from "react"

const UserAdministration = (): JSX.Element => {
  const us = useAppSelector(selectUser)
  const dispatch = useAppDispatch()
  const isAdmin = hasPrivilege(us.user.privileges, ADMIN)
  const [users, setUsers] = useState<User[]>([])
  const [newUser, setNewUser] = useState<UserUpdateInput>({
    id: -1,
    username: "",
    newPassword: "",
    privileges: 0,
  })

  const handleChange = (_: any, newInputValue: User | null) => {
    if (!newInputValue) return
    if (newInputValue.id === -1) {
      setNewUser({
        id: -1,
        username: "",
        newPassword: "",
        privileges: 0,
      })
      return
    }

    setNewUser({
      id: newInputValue.id,
      username: newInputValue.username,
      newPassword: "",
      privileges: newInputValue.privileges,
    })
  }

  const autocompleteIsOptionEqualToValue = (
    option: User,
    value: User
  ): boolean => {
    return option.id === value.id
  }

  const handleSave = () => {
    if (newUser.id === -1 && newUser.username === "") {
      dispatch(
        qSnack({ msg: "Bitte Benutzername eingeben", severity: "warning" })
      )
      return
    }
    if (newUser.id === -1 && newUser.newPassword === "") {
      dispatch(qSnack({ msg: "Bitte Passwort eingeben", severity: "warning" }))
      return
    }
    if (
      newUser.id === -1 &&
      users.find((user) => user.username === newUser.username)
    ) {
      dispatch(
        qSnack({ msg: "Benutzername bereits vergeben", severity: "warning" })
      )
      return
    }
    userSave(newUser).then((data) => {
      if (data?.userSave) {
        dispatch(qSnack({ msg: "Benutzer gespeichert", severity: "success" }))
        usersGet().then((data) => {
          if (data) {
            setUsers(data.users)
          }
        })
      } else {
        dispatch(qSnack({ msg: "Fehler beim Speichern", severity: "error" }))
      }
    })
  }

  const handleChangePrivileges = (
    e: ChangeEvent<HTMLInputElement>,
    privilege: number
  ) => {
    if (e.target.checked) {
      // set the bit
      setNewUser({
        ...newUser,
        privileges: (newUser.privileges |= privilege),
      })
    } else {
      // unset the bit
      setNewUser({
        ...newUser,
        privileges: (newUser.privileges &= ~privilege),
      })
    }
  }

  useEffect(() => {
    if (isAdmin) {
      usersGet().then((data) => {
        if (data) {
          const users = data.users
          users.unshift({
            id: -1,
            username: "",
            privileges: 0,
            language: "",
          })
          setUsers(users)
        }
      })
    }
  }, [isAdmin])

  const [showActiveUser, setShowActiveUser] = useState(true)
  const [showDeactivatedUser, setShowDeactivatedUser] = useState(false)

  return (
    <Container maxWidth="xl">
      {!isAdmin && <div>Not Admin</div>}
      {isAdmin && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}></Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                fullWidth
                disablePortal
                id="user-autocomplete"
                onChange={handleChange}
                // @ts-ignore
                // language is not part of the type in the database will be fetched from a diffrent table
                value={newUser}
                options={users.filter((user) => {
                  if (showActiveUser && showDeactivatedUser) {
                    return true
                  }

                  if (showActiveUser && !showDeactivatedUser) {
                    return !hasPrivilege(user.privileges, DEACTIVATED)
                  }

                  if (!showActiveUser && showDeactivatedUser) {
                    return hasPrivilege(user.privileges, DEACTIVATED)
                  }
                  return false
                })}
                getOptionLabel={(option: User) => {
                  if (option.id === -1) {
                    return "Neuer Benutzer"
                  } else {
                    return option.username
                  }
                }}
                isOptionEqualToValue={autocompleteIsOptionEqualToValue}
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <TextField {...params} label={"Benutzer"} />
                )}
              />

              <FormControlLabel
                label="Aktive Benutzer anzeigen"
                control={
                  <Checkbox
                    checked={showActiveUser}
                    onChange={(e) => setShowActiveUser(e.target.checked)}
                  />
                }
              />

              <FormControlLabel
                label="Deaktivierte Benutzer anzeigen"
                control={
                  <Checkbox
                    checked={showDeactivatedUser}
                    onChange={(e) => {
                      setShowDeactivatedUser(e.target.checked)
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="username-new"
                    label="Benutzername"
                    value={newUser.username}
                    onChange={(e) => {
                      setNewUser({ ...newUser, username: e.target.value })
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="password-new"
                    label="Neues Passwort"
                    value={newUser.newPassword}
                    onChange={(e) => {
                      setNewUser({ ...newUser, newPassword: e.target.value })
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={hasPrivilege(newUser.privileges, ADMIN)}
                          onChange={(e) => {
                            handleChangePrivileges(e, ADMIN)
                          }}
                        />
                      }
                      label="Admin"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={hasPrivilege(newUser.privileges, TAG)}
                          onChange={(e) => {
                            handleChangePrivileges(e, TAG)
                          }}
                        />
                      }
                      label="Etikettieren"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={hasPrivilege(newUser.privileges, DISTRIBUTE)}
                          onChange={(e) => {
                            handleChangePrivileges(e, DISTRIBUTE)
                          }}
                        />
                      }
                      label="Verteilen"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={hasPrivilege(newUser.privileges, DISPATCH)}
                          onChange={(e) => {
                            handleChangePrivileges(e, DISPATCH)
                          }}
                        />
                      }
                      label="Packen"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={hasPrivilege(newUser.privileges, SPLIT)}
                          onChange={(e) => {
                            handleChangePrivileges(e, SPLIT)
                          }}
                        />
                      }
                      label="Aufträge splitten"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={hasPrivilege(
                            newUser.privileges,
                            DEACTIVATED
                          )}
                          onChange={(e) => {
                            handleChangePrivileges(e, DEACTIVATED)
                          }}
                        />
                      }
                      label="Benutzer deaktivieren"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={hasPrivilege(newUser.privileges, STATISTIC)}
                          onChange={(e) => {
                            handleChangePrivileges(e, STATISTIC)
                          }}
                        />
                      }
                      label="Statistik einsehen"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={hasPrivilege(newUser.privileges, ROUNDS)}
                          onChange={(e) => {
                            handleChangePrivileges(e, ROUNDS)
                          }}
                        />
                      }
                      label="Runden bearbeiten/erstellen"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={hasPrivilege(newUser.privileges, BASE_DATA)}
                          onChange={(e) => {
                            handleChangePrivileges(e, BASE_DATA)
                          }}
                        />
                      }
                      label="Stamm-Daten bearbeiten"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={hasPrivilege(
                            newUser.privileges,
                            SHIPPING_STATISTIC
                          )}
                          onChange={(e) => {
                            handleChangePrivileges(e, SHIPPING_STATISTIC)
                          }}
                        />
                      }
                      label="Versand-Statistik einsehen"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={hasPrivilege(
                            newUser.privileges,
                            SHIPPING_CREATE
                          )}
                          onChange={(e) => {
                            handleChangePrivileges(e, SHIPPING_CREATE)
                          }}
                        />
                      }
                      label="Logistik Aufträge erstellen"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={hasPrivilege(
                            newUser.privileges,
                            CUSTOMER_SUPPORT
                          )}
                          onChange={(e) => {
                            handleChangePrivileges(e, CUSTOMER_SUPPORT)
                          }}
                        />
                      }
                      label="Kunden Support"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Alert severity="info" sx={{ marginTop: 2 }}>
                  Die Rechte werden erst nach dem Speichern übernommen. Sowie
                  alle anderen Änderungen.
                </Alert>
                <Alert severity="info" sx={{ marginTop: 2, marginBottom: 2 }}>
                  Um einen Benutzer zu erstellen muss in der Liste "Neuer
                  Benutzer" ausgewählt werden.
                </Alert>
                <Button variant="contained" fullWidth onClick={handleSave}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  )
}

export default UserAdministration
