import { fetchGQL } from "app/api"
import {
  RoundOverviewGetQuery,
  RoundOverviewGetQueryVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"

const GET_ORDER_BY_STATUS = gql`
  query roundOverviewGet($rowsPerPage: Int!, $currentPage: Int!) {
    roundOverviewGet(
      in: { rowsPerPage: $rowsPerPage, currentPage: $currentPage }
    ) {
      totalRoundCount
      roundDataRows {
        id
        round
        name
        date
        plants
        crates
        ordered
        delivered
        totalMessages
        sentMessages
        singleSum
      }
    }
  }
`

export const fetchRoundOverviews = async (
  r: number,
  c: number
): Promise<RoundOverviewGetQuery | undefined> => {
  const variables: RoundOverviewGetQueryVariables = {
    rowsPerPage: r,
    currentPage: c,
  }
  const data = await fetchGQL<
    RoundOverviewGetQuery,
    RoundOverviewGetQueryVariables
  >(GET_ORDER_BY_STATUS, variables)
  return data
}
