import dayjs, { Dayjs } from "dayjs"

export const formatDateFromDate = (date: Date): string => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  return `${year}-${month}-${day}`
}

export const formatDateFromDayjs = (
  date: Dayjs | null,
  fallback: Dayjs | null
): string => {
  if (!date) {
    if (!fallback) {
      return ""
    } else {
      return fallback.format("YYYY-MM-DD")
    }
  }
  return date.format("YYYY-MM-DD")
}

export const formatDate = (dateString: string): string => {
  const date = dayjs(dateString)
  return date.format("D.M.YYYY - HH:mm [Uhr]")
}
