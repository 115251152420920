import { fetchGQL } from "app/api"
import {
  PrintOrderListKistenZettelQuery,
  PrintOrderListKistenZettelQueryVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"

const KISTENZETTEL_PRINT = gql`
  query printOrderListKistenZettel(
    $round: Int!
    $printerIpPort: String!
    $orderIds: [Int!]!
  ) {
    printOrderListKistenZettel(
      round: $round
      printerIpPort: $printerIpPort
      orderIds: $orderIds
    )
  }
`

export const kistenzettelPrint = async (
  round: number,
  printerIpPort: string,
  orderIds: number[]
): Promise<PrintOrderListKistenZettelQuery | undefined> => {
  const variables: PrintOrderListKistenZettelQueryVariables = {
    round,
    printerIpPort,
    orderIds,
  }
  const data = await fetchGQL<
    PrintOrderListKistenZettelQuery,
    PrintOrderListKistenZettelQueryVariables
  >(KISTENZETTEL_PRINT, variables)
  return data
}
