import { Container, Grid, TextField } from "@mui/material"
import { OrderIdSearch } from "components/OrderIdSearch/OrderIdSearch"
import { CustomerEditPageOrders } from "pages/CustomerEditPage/CustomerEditPageOrders"
import { useState } from "react"

export const OrderSearchPage = () => {
  const [customerId, setCustomerId] = useState<string>("")
  const [value, setValue] = useState<string>("")

  const handleSearchCommit = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setCustomerId(value)
    }
  }

  return (
    <Container maxWidth={false}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={2}>
          <TextField
            label="Kundennummer"
            id="Kundennummer"
            placeholder="Kundennummer"
            fullWidth
            onChange={(event) => setValue(event.target.value)}
            onKeyUp={handleSearchCommit}
            value={value}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <OrderIdSearch size="medium" />
        </Grid>
        <Grid item xs={12}>
          <CustomerEditPageOrders customerID={customerId} addOrder={false} />
        </Grid>
      </Grid>
    </Container>
  )
}
