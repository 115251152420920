import { fetchGQL } from "app/api"
import {
  RabenLabelPrintQuery,
  RabenShipmentCreateMutation,
  RabenShipmentCreateMutationVariables,
  RabenShipmentInput,
} from "generated/graphql"
import { gql } from "graphql-tag"

const RABEN_SHIPMENT = gql`
  mutation rabenShipmentCreate($in: RabenShipmentInput!) {
    rabenShipmentCreate(in: $in) {
      DocID
    }
  }
`

export const rabenShipmentCreate = async (
  _in: RabenShipmentInput
): Promise<RabenShipmentCreateMutation | undefined> => {
  const variables: RabenShipmentCreateMutationVariables = { in: _in }
  const data = await fetchGQL<
    RabenShipmentCreateMutation,
    RabenShipmentCreateMutationVariables
  >(RABEN_SHIPMENT, variables)
  return data
}

const RABEN_PRINT_LABEL = gql`
  query rabenLabelPrint($printer: String!, $docID: String!) {
    rabenLabelPrint(printerIpPort: $printer, docID: $docID)
  }
`

export const rabenLabelPrint = async (
  printer: string,
  docID: string
): Promise<RabenLabelPrintQuery | undefined> => {
  const variables = { printer, docID }
  const data = await fetchGQL<RabenLabelPrintQuery>(
    RABEN_PRINT_LABEL,
    variables
  )
  return data
}
