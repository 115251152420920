import { fetchGQL } from "app/api"
import {
  RoundItemEditDescriptionInput,
  RoundItemEditDescriptionMutation,
  RoundItemEditDescriptionMutationVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"

const ROUND_ITEMS_EDIT_DESCRIPTION = gql`
  mutation roundItemEditDescription($in: RoundItemEditDescriptionInput!) {
    roundItemEditDescription(in: $in)
  }
`

export const roundItemEditDescription = async (
  _in: RoundItemEditDescriptionInput
): Promise<RoundItemEditDescriptionMutation | undefined> => {
  var vars: RoundItemEditDescriptionMutationVariables = {
    in: _in,
  }
  const data = await fetchGQL<
    RoundItemEditDescriptionMutation,
    RoundItemEditDescriptionMutationVariables
  >(ROUND_ITEMS_EDIT_DESCRIPTION, vars)
  return data
}
