import { fetchGQL } from "app/api"
import {
  OrderListInput,
  OrderListPrintPdFsQuery,
  OrderListPrintPdFsQueryVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"

const ORDER_LIST_PRINT_PDF = gql`
  query orderListPrintPDFs(
    $round: Int!
    $supplier: [OrderListInput!]!
    $printerName: String!
  ) {
    orderListPrintPDFs(
      round: $round
      supplier: $supplier
      printerName: $printerName
    )
  }
`

export const orderListPrintPDFs = async (
  round: number,
  supplier: OrderListInput[],
  printerName: string
): Promise<OrderListPrintPdFsQuery | undefined> => {
  const variables: OrderListPrintPdFsQueryVariables = {
    round,
    supplier,
    printerName,
  }
  const data = await fetchGQL<
    OrderListPrintPdFsQuery,
    OrderListPrintPdFsQueryVariables
  >(ORDER_LIST_PRINT_PDF, variables)
  return data
}
