import { fetchGQL } from "app/api"
import {
  ArticleMoveInfoQuery,
  ArticleMoveInfoQueryVariables,
  RoundlistItemDeleteMutation,
  RoundlistItemDeleteMutationVariables,
  SupplierRoundItemMoveMutation,
  SupplierRoundItemMoveMutationVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"

const ROUND_SUPPLIER_INFO = gql`
  query articleMoveInfo($round: Int!, $supplierID: Int!) {
    articleSupplierInfo(round: $round, supplierID: $supplierID) {
      id
      orderID
      ewID
      supplierID
      supplierName
      bdb
      quantity
      botname
      sorte
      size
    }
    articleSupplierRefs(round: $round, supplierID: $supplierID) {
      supplierName
      supplierID
      price
      articleID
      priority
    }
  }
`

export const supplierRoundInfo = async (
  round: number,
  supplierID: number
): Promise<ArticleMoveInfoQuery | undefined> => {
  const variables: ArticleMoveInfoQueryVariables = { round, supplierID }
  const data = await fetchGQL<
    ArticleMoveInfoQuery,
    ArticleMoveInfoQueryVariables
  >(ROUND_SUPPLIER_INFO, variables)
  return data
}

const ROUND_SUPPLIER_ITEM_MOVE = gql`
  mutation supplierRoundItemMove(
    $roundListID: Int!
    $round: Int!
    $newSupplierID: Int!
    $quantity: Int!
  ) {
    supplierRoundItemMove(
      roundListID: $roundListID
      round: $round
      newSupplierID: $newSupplierID
      quantity: $quantity
    )
  }
`

export const supplierRoundItemMove = async (
  roundListID: number,
  round: number,
  newSupplierID: number,
  quantity: number
): Promise<SupplierRoundItemMoveMutation | undefined> => {
  const variables: SupplierRoundItemMoveMutationVariables = {
    round,
    newSupplierID,
    quantity,
    roundListID,
  }
  const data = await fetchGQL<
    SupplierRoundItemMoveMutation,
    SupplierRoundItemMoveMutationVariables
  >(ROUND_SUPPLIER_ITEM_MOVE, variables)
  return data
}

const ROUNDLIST_DELETE_ITEM = gql`
  mutation roundlistItemDelete($ids: [Int!]!) {
    supplierRoundItemDelete(roundListIDs: $ids)
  }
`

export const roundlistItemDelete = async (
  ids: number[]
): Promise<RoundlistItemDeleteMutation | undefined> => {
  const variables: RoundlistItemDeleteMutationVariables = { ids }
  const data = await fetchGQL<
    RoundlistItemDeleteMutation,
    RoundlistItemDeleteMutationVariables
  >(ROUNDLIST_DELETE_ITEM, variables)
  return data
}
