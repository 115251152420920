import { fetchGQL } from "app/api"
import {
  OrderCancelAction,
  OrderCancelMutation,
  OrderCancelMutationVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"

const ORDER_CANCEL = gql`
  mutation orderCancel($in: OrderCancelInput!) {
    orderCancel(in: $in)
  }
`

export const fetchOrderCancel = async (
  orderID: string,
  action: OrderCancelAction
): Promise<OrderCancelMutation | undefined> => {
  const variables: OrderCancelMutationVariables = {
    in: {
      orderID,
      action,
    },
  }
  const data = await fetchGQL<
    OrderCancelMutation,
    OrderCancelMutationVariables
  >(ORDER_CANCEL, variables)

  return data
}
