import React, { useEffect, useState } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  SelectChangeEvent,
  Grid,
  InputAdornment,
} from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { CouponUpdateInput } from "generated/graphql"
import dayjs from "dayjs"
import { datePickerLocaleDEde } from "constants/datePickerLocale"
import { couponKeyInUse } from "queries/coupons"

interface CouponAddDialogProps {
  open: boolean
  onClose: () => void
  onSubmit: (couponData: CouponUpdateInput) => void
  coupon?: CouponUpdateInput
  originalKey: string
}

const couponEmpty = {
  id: -1,
  key: "",
  title: "",
  description: "",
  value: 0,
  discount: 0,
  min_sum: 0,
  article_id: undefined,
  product_id: undefined,
  valid_from: dayjs().format("YYYY-MM-DD"),
  valid_until: dayjs().add(1, "month").format("YYYY-MM-DD"),
  combinable: false,
  max_uses: null,
  no_delivery_costs: false,
  sale_allowed: false,
  categoryIds: [],
  note_intern: "",
}

const CouponAddDialog: React.FC<CouponAddDialogProps> = ({
  open,
  onClose,
  onSubmit,
  coupon,
  originalKey,
}) => {
  const [couponData, setCouponData] = useState<CouponUpdateInput>(couponEmpty)
  const [formValid, setFormValid] = useState(false)
  const [keyInUse, setKeyInUse] = useState(false)

  useEffect(() => {
    if (coupon) {
      setCouponData((prevData) => ({
        ...prevData,
        ...coupon,
      }))
    } else {
      setCouponData(couponEmpty)
    }
  }, [coupon])

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      if (couponData?.key === originalKey || !couponData.key) {
        setKeyInUse(false)
        return
      }

      couponKeyInUse(couponData.key).then((inUse) => {
        if (!inUse) {
          setKeyInUse(true)
        } else {
          setKeyInUse(inUse.couponKeyInUse ?? true)
        }
      })
    }, 300)
    return () => clearTimeout(delayInputTimeoutId)
  }, [couponData.key, originalKey])

  useEffect(() => {
    const isValid =
      couponData.key !== "" &&
      !keyInUse &&
      couponData.valid_from !== null &&
      couponData.valid_until !== null &&
      (couponData.value > 0 || couponData.discount > 0)
    setFormValid(isValid)
  }, [
    couponData.key,
    keyInUse,
    couponData.valid_from,
    couponData.valid_until,
    couponData.value,
    couponData.discount,
  ])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target
    setCouponData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  const handleSelectChange = (event: SelectChangeEvent<string[]>) => {
    const { name, value } = event.target
    setCouponData((prevData) => ({
      ...prevData,
      [name]: typeof value === "string" ? value.split(",") : value,
    }))
  }

  const handleSubmit = () => {
    onSubmit(couponData)
    setCouponData(couponEmpty)
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        setCouponData(couponEmpty)
      }}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Gutschein hinzufügen/bearbeiten</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              margin="normal"
              name="key"
              label="Key"
              required
              value={couponData.key}
              onChange={handleChange}
              error={keyInUse}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              margin="normal"
              name="title"
              label="Titel"
              value={couponData.title}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              fullWidth
              margin="normal"
              name="description"
              label="Beschreibung"
              value={couponData.description}
              onChange={handleChange}
              multiline
              rows={3}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              margin="normal"
              name="value"
              label="Wert"
              type="number"
              value={couponData.value}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">€</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              margin="normal"
              name="discount"
              label="Rabatt"
              type="number"
              value={couponData.discount}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              margin="normal"
              name="min_sum"
              label="Mindestbestellwert"
              type="number"
              value={couponData.min_sum}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">€</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              margin="normal"
              name="max_uses"
              label="max. nutzbar"
              type="number"
              value={couponData.max_uses}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              margin="normal"
              name="article_id"
              label="Article ID"
              value={couponData.article_id}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              margin="normal"
              name="product_id"
              label="Product ID"
              value={couponData.product_id}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DatePicker
              label="gültig ab"
              value={
                couponData.valid_from ? dayjs(couponData.valid_from) : null
              }
              onChange={(e) => {
                setCouponData((prevData) => ({
                  ...prevData,
                  valid_from: e ? dayjs(e).format("YYYY-MM-DD") : null,
                }))
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  margin: "normal",
                  required: true,
                },
              }}
              localeText={datePickerLocaleDEde}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DatePicker
              label="gültig bis"
              value={
                couponData.valid_until ? dayjs(couponData.valid_until) : null
              }
              onChange={(e) => {
                setCouponData((prevData) => ({
                  ...prevData,
                  valid_until: e ? dayjs(e).format("YYYY-MM-DD") : null,
                }))
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  margin: "normal",
                  required: true,
                },
              }}
              localeText={datePickerLocaleDEde}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={couponData.combinable}
                  onChange={handleChange}
                  name="combinable"
                />
              }
              label="Kombinierbar"
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={couponData.no_delivery_costs}
                  onChange={handleChange}
                  name="no_delivery_costs"
                />
              }
              label="Keine Lieferkosten"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={couponData.sale_allowed}
                  onChange={handleChange}
                  name="sale_allowed"
                />
              }
              label="Sale erlaubt"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Kategorien</InputLabel>
              <Select
                multiple
                name="categoryIds"
                value={couponData.categoryIds || ""}
                onChange={handleSelectChange}
                renderValue={(selected) => (selected as string[]).join(", ")}
              >
                {/* TODO: Replace with actual category options */}
                <MenuItem value="category1">Category 1</MenuItem>
                <MenuItem value="category2">Category 2</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              fullWidth
              margin="normal"
              name="note_intern"
              label="Notiz"
              value={couponData.note_intern}
              onChange={handleChange}
              multiline
              rows={3}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose()
            setCouponData(couponEmpty)
          }}
        >
          Abbrechen
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={!formValid}
        >
          Hinzufügen
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CouponAddDialog
