import { fetchGQL } from "app/api"
import { SortOrderBaseQuery } from "generated/graphql"
import { gql } from "graphql-tag"

const SORT_ORDER_BASE = gql`
  query sortOrderBase {
    plantTypes {
      id
      name
    }

    suppliers {
      id
      name
      flavour
      G24
    }
  }
`

export const fetchPlantTypesAndSupplier = async (): Promise<
  SortOrderBaseQuery | undefined
> => {
  const data = await fetchGQL<SortOrderBaseQuery, undefined>(
    SORT_ORDER_BASE,
    undefined
  )
  return data
}
