import { Link } from "@mui/material"
import { GridRenderCellParams } from "@mui/x-data-grid-pro"

const LinkRenderer = (props: GridRenderCellParams) => {
  const { value } = props

  return (
    <>
      {value === "" && "Kein Link"}
      {value && value !== "" && (
        <Link href={value as string} target="_blank" rel="noreferrer">
          Link
        </Link>
      )}
    </>
  )
}

export default LinkRenderer
