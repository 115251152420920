import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
} from "@mui/material"

export interface DialogRoundSupplierProps {
  roundStatList: string[]
  isOpen: boolean
  onLoadRoundStat: () => void
  setRoundStatList: (roundList: string[]) => void
  onClose: () => void
}

const DialogRoundStateChange = (
  props: DialogRoundSupplierProps
): JSX.Element => {
  const { roundStatList, isOpen, setRoundStatList, onLoadRoundStat, onClose } =
    props

  return (
    <Dialog maxWidth="xs" open={isOpen} onClose={onClose}>
      <DialogTitle>Bitte die Runden eingeben</DialogTitle>
      <DialogContent dividers>
        {roundStatList.map((round: string, index: number) => {
          return (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 2,
              }}
            >
              <TextField
                id="standard-basic"
                label={"Runde " + (index + 1)}
                variant="outlined"
                value={round}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const roundList = [...roundStatList]
                  roundList[index] = event.target.value.replace(/([^0-9])/g, "")
                  setRoundStatList(roundList)
                }}
              />
            </Box>
          )
        })}
      </DialogContent>
      <DialogActions>
        <Button fullWidth variant="contained" onClick={onLoadRoundStat}>
          Laden
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogRoundStateChange
