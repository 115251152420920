export type Countries = {
  name: string
  code: string
}

export const countries: Countries[] = [
  { name: "Belgien", code: "be" },
  { name: "Costa Rica", code: "cr" },
  { name: "Bulgarien", code: "bg" },
  { name: "Dänemark", code: "dk" },
  { name: "Deutschland", code: "de" },
  { name: "Estland", code: "ee" },
  { name: "Finnland", code: "fi" },
  { name: "Frankreich", code: "fr" },
  { name: "Georgien", code: "ge" },
  { name: "Gibraltar", code: "gi" },
  { name: "Griechenland", code: "gr" },
  { name: "Großbritannien", code: "gb" },
  { name: "Irland", code: "ie" },
  { name: "Italien", code: "it" },
  { name: "Kroatien", code: "hr" },
  { name: "Lettland", code: "lv" },
  { name: "Liechtenstein", code: "li" },
  { name: "Litauen", code: "lt" },
  { name: "Luxemburg", code: "lu" },
  { name: "Malta", code: "mt" },
  { name: "Niederlande", code: "nl" },
  { name: "Norwegen", code: "no" },
  { name: "Österreich", code: "at" },
  { name: "Polen", code: "pl" },
  { name: "Portugal", code: "pt" },
  { name: "Rumänien", code: "ro" },
  { name: "Schweden", code: "se" },
  { name: "Schweiz", code: "ch" },
  { name: "Slowakei", code: "sk" },
  { name: "Slowenien", code: "si" },
  { name: "Spanien", code: "es" },
  { name: "Tschechische Republik", code: "cz" },
  { name: "Ungarn", code: "hu" },
  { name: "USA", code: "us" },
  { name: "Zypern", code: "cy" },
]
