import {
  Typography,
  TextField,
  Grid,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material"
import { CustomerDetail } from "generated/graphql"
import { enqueueSnackbar } from "notistack"
import { customerById } from "queries/customerById"
import { customerDetailsSave } from "queries/customerDetailSave"
import { useState, useEffect } from "react"
import { DeleteDialog } from "./DeleteDialog"
import { useNavigate } from "react-router-dom"

type CustomerEditPageBaseProps = {
  customerID: string
}

export const CustomerEditPageBaseData = (props: CustomerEditPageBaseProps) => {
  const { customerID } = props
  const [isLoading, setIsLoading] = useState(false)
  const [customer, setCustomer] = useState<CustomerDetail>()
  const [customerBackup, setCustomerBackup] = useState<CustomerDetail>()
  const [customerChanged, setCustomerChanged] = useState(false)
  const [deleteCustomerOpen, setDeleteCutomerOpen] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const id = customerID ? parseInt(customerID) : undefined
    if (id !== undefined) {
      setIsLoading(true)
      customerById(id)
        .then((result) => {
          if (result?.customerById) {
            setCustomer(result.customerById)
            setCustomerBackup(result.customerById)
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [customerID])

  const submitCustomer = () => {
    //TODO: Validierung der Daten
    if (customer)
      customerDetailsSave({ in: { ...customer } }).then((res) => {
        if (res?.customerDetailsSave.id === customer.id) {
          enqueueSnackbar("Kunde geändert und gespeichert", {
            variant: "success",
          })
          setCustomerBackup(customer)
          setCustomerChanged(false)
        } else {
          enqueueSnackbar("Kunde konnte nicht geändert werden", {
            variant: "warning",
          })
          setCustomer(customerBackup)
          setCustomerChanged(false)
        }
      })
  }

  const handleDeleteCustomer = () => {
    enqueueSnackbar("Die Funktion ist noch nicht weiter implementiert", {
      variant: "warning",
    })
    setDeleteCutomerOpen(false)
    navigate("/searchCustomer/")
  }

  return (
    <>
      {isLoading && <Typography>... is loading</Typography>}
      {customer && !isLoading && (
        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h6" component="div" gutterBottom>
                  Stammdaten
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Kundennummer"
                  value={customer.id}
                  disabled
                  onChange={(e) => {
                    setCustomer({
                      ...customer,
                      id: parseInt(e.target.value),
                    })
                    setCustomerChanged(true)
                  }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="custnote"
                  value={customer.custnote}
                  multiline
                  rows={4}
                  onChange={(e) => {
                    setCustomer({
                      ...customer,
                      custnote: e.target.value,
                    })
                    setCustomerChanged(true)
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={3}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h6" component="div" gutterBottom>
                  Kontakt
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  value={customer.email}
                  onChange={(e) => {
                    setCustomer({
                      ...customer,
                      email: e.target.value,
                    })
                    setCustomerChanged(true)
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Telefon"
                  value={customer.telefon}
                  onChange={(e) => {
                    setCustomer({
                      ...customer,
                      telefon: e.target.value,
                    })
                    setCustomerChanged(true)
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Mobil"
                  value={customer.handy}
                  onChange={(e) => {
                    setCustomer({
                      ...customer,
                      handy: e.target.value,
                    })
                    setCustomerChanged(true)
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={3}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h6" component="div" gutterBottom>
                  Name
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Firma"
                  value={customer.company}
                  onChange={(e) => {
                    setCustomer({
                      ...customer,
                      company: e.target.value,
                    })
                    setCustomerChanged(true)
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="anrede-label">Anrede</InputLabel>
                  <Select
                    labelId="anrede-label"
                    id="anrede"
                    value={customer.anrede}
                    label="Anrede"
                    onChange={(e) => {
                      setCustomer({
                        ...customer,
                        anrede: e.target.value,
                      })
                      setCustomerChanged(true)
                    }}
                  >
                    <MenuItem value="Herr">Herr</MenuItem>
                    <MenuItem value="Frau">Frau</MenuItem>
                    <MenuItem value="Firma">Firma</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Nickname"
                  value={customer.nickName}
                  onChange={(e) => {
                    setCustomer({
                      ...customer,
                      nickName: e.target.value,
                    })
                    setCustomerChanged(true)
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Vorname"
                  value={customer.firstName}
                  onChange={(e) => {
                    setCustomer({
                      ...customer,
                      firstName: e.target.value,
                    })
                    setCustomerChanged(true)
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Nachname"
                  value={customer.lastName}
                  onChange={(e) => {
                    setCustomer({
                      ...customer,
                      lastName: e.target.value,
                    })
                    setCustomerChanged(true)
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={3}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h6" component="div" gutterBottom>
                  Rechnungsadresse
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Name"
                  value={customer.rechName}
                  onChange={(e) => {
                    setCustomer({
                      ...customer,
                      rechName: e.target.value,
                    })
                    setCustomerChanged(true)
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Straße & Nr."
                  value={customer.rechStreet}
                  onChange={(e) => {
                    setCustomer({
                      ...customer,
                      rechStreet: e.target.value,
                    })
                    setCustomerChanged(true)
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="PLZ"
                  value={customer.rechPlz}
                  onChange={(e) => {
                    setCustomer({
                      ...customer,
                      rechPlz: e.target.value,
                    })
                    setCustomerChanged(true)
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Ort"
                  value={customer.rechOrt}
                  onChange={(e) => {
                    setCustomer({
                      ...customer,
                      rechOrt: e.target.value,
                    })
                    setCustomerChanged(true)
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={3}>
            <Button
              variant="contained"
              fullWidth
              onClick={submitCustomer}
              disabled={!customerChanged}
            >
              Speichern
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                setCustomer(customerBackup)
                setCustomerChanged(false)
              }}
              disabled={!customerChanged}
            >
              Zurücksetzen
            </Button>
          </Grid>
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={3}>
            <Button
              variant="contained"
              fullWidth
              color="error"
              onClick={() => {
                setDeleteCutomerOpen(true)
              }}
            >
              Kundenkonto löschen
            </Button>
          </Grid>
        </Grid>
      )}
      <DeleteDialog
        open={deleteCustomerOpen}
        onClose={() => setDeleteCutomerOpen(false)}
        onDelete={handleDeleteCustomer}
      >
        <Typography>Soll das Kundenkonto wirklich gelöscht werden?</Typography>
        <Typography>
          Der Vorgang kann nicht rückgängig gemacht werden.
        </Typography>
      </DeleteDialog>
    </>
  )
}
