import { fetchGQL } from "app/api"
import { gql } from "graphql-tag"
import {
  ArticleDetailsSaveMutation,
  ArticleDetailsSaveMutationVariables,
} from "../generated/graphql"

const ARTICLE_DETAILS_SAVE = gql`
  mutation articleDetailsSave($in: ArticleDetailsInput!) {
    articleDetailsSave(in: $in)
  }
`

export const articleDetailsSave = async (
  vars: ArticleDetailsSaveMutationVariables
): Promise<ArticleDetailsSaveMutation | undefined> => {
  const data = await fetchGQL<
    ArticleDetailsSaveMutation,
    ArticleDetailsSaveMutationVariables
  >(ARTICLE_DETAILS_SAVE, vars)
  return data
}
