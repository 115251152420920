import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import { Grid, Typography, useTheme } from "@mui/material"
import { ShipmentDetails } from "generated/graphql"

export interface ShipmentDetailsContainerProps {
  shipmentDetails: ShipmentDetails
}

const ShipmentDetailsContainer = (
  props: ShipmentDetailsContainerProps
): JSX.Element => {
  const { shipmentDetails } = props
  const theme = useTheme()
  return (
    <Grid
      container
      spacing={1}
      sx={{
        border: "1px solid black",
        borderColor: "grey.500",
        borderRadius: 5,
        paddingBottom: 2,
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Grid item xs={12}>
        <Typography variant="h6">Bestellung</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          Bestell ID: {shipmentDetails.orderDetails.orderID}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          Status: {shipmentDetails.orderDetails.statusID}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          Zahlungsart: {shipmentDetails.orderDetails.paymentMethod}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          Rechnungsbetrag: {shipmentDetails.orderDetails.invoiceSum}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          Zieldatum: {shipmentDetails.orderDetails.targetShipDate}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          Note: {shipmentDetails.orderDetails.note}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          Secret Note: {shipmentDetails.orderDetails.secretNote}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          component="div"
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          Abstellgenehmigung:
          {shipmentDetails.permitToPlacePackage ? (
            <CheckIcon
              sx={{ marginLeft: 2, color: theme.palette.success.main }}
            />
          ) : (
            <CloseIcon
              sx={{ marginLeft: 2, color: theme.palette.error.main }}
            />
          )}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default ShipmentDetailsContainer
