import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material"

type DeleteDialogProps = {
  open: boolean
  onClose: () => void
  onDelete: () => void
  children: React.ReactNode
}

export const DeleteDialog = (props: DeleteDialogProps) => {
  const { open, onClose, onDelete } = props

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          NO
        </Button>
        <Button variant="outlined" onClick={onDelete} autoFocus color="error">
          YES
        </Button>
      </DialogActions>
    </Dialog>
  )
}
