import { Box, Container, Grid, Typography } from "@mui/material"
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { selectRounds, setSelectedRound } from "app/roundSlice"
import { dataGridDEde } from "constants/dataGridLocale"
import { RoundDataRow } from "generated/graphql"
import { useSnackbar } from "notistack"
import { fetchRoundOverviews as fetchRoundOverview } from "queries/fetchRoundOverviews"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { germanDateFormatter } from "utils/datefmt"

// sum up table a
const RoundCreatePage = (): JSX.Element => {
  const [roundData, setRoundData] = useState<RoundDataRow[]>([])
  const [totalRoundCount, setTotalRowCount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 15,
  })

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const rounds = useAppSelector(selectRounds)

  const colsSupplier: GridColDef<RoundDataRow>[] = [
    {
      field: "round",
      headerName: "Runde",
      renderCell: (params) => {
        const handleClick = () => {
          const round = rounds.find((r) => r.round === params.row.round)
          if (round) {
            dispatch(setSelectedRound(round))
            navigate("/roundOrders")
          }
        }
        return (
          <Typography
            variant="body2"
            color="secondary"
            component="div"
            sx={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={handleClick}
          >
            {params.row.round}
          </Typography>
        )
      },
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        const isDone = params.row.delivered === params.row.ordered
        return (
          <Typography
            variant="body2"
            color={isDone ? "success.main" : "info.dark"}
          >
            {isDone ? "Abgeschlossen" : "Aktiv"}
          </Typography>
        )
      },
      width: 160,
    },
    //{ field: "problems", headerName: "Probleme", width: 150 },
    { field: "name", headerName: "Name", width: 250 },
    {
      field: "date",
      headerName: "Datum",
      width: 160,
      valueFormatter: (params) => {
        return germanDateFormatter.format(new Date(params.value as string))
      },
    },
    { field: "plants", headerName: "Pflanzen" },
    { field: "crates", headerName: "Kisten" },
    {
      field: "ordered",
      headerName: "Offen/Gsmt. Best.",
      valueGetter: (params) => {
        return (params.row.delivered || 0) + "/" + (params.row.ordered || 0)
      },
      width: 160,
    },
    {
      field: "singleSum",
      headerName: "Singles",
    },
    {
      field: "faxes",
      headerName: "Bestellfaxe",
      valueGetter: (params) => {
        return (
          (params.row.sentMessages || 0) + "/" + (params.row.totalMessages || 0)
        )
      },
    },
  ]

  useEffect(() => {
    setIsLoading(true)
    fetchRoundOverview(paginationModel.pageSize, paginationModel.page + 1)
      .then((data) => {
        if (data?.roundOverviewGet) {
          enqueueSnackbar("Rundenübersicht geladen", { variant: "success" })
          setRoundData(data.roundOverviewGet.roundDataRows)
          setTotalRowCount(data.roundOverviewGet.totalRoundCount)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [enqueueSnackbar, paginationModel])

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ height: "85vh", width: "100%" }}>
            <DataGridPro
              rows={roundData}
              columns={colsSupplier}
              disableRowSelectionOnClick
              localeText={dataGridDEde}
              loading={isLoading}
              pagination
              rowCount={totalRoundCount}
              paginationMode="server"
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 15, page: 0 },
                },
              }}
              onPaginationModelChange={setPaginationModel}
              pageSizeOptions={[15, 30]}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default RoundCreatePage
