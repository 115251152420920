import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "app/store"
import { Round } from "generated/graphql"

export interface snackState {
  selectedRound: Round | null
  rounds: Round[]
}

const initialState: snackState = {
  selectedRound: null,
  rounds: [],
}

export const roundSlice = createSlice({
  name: "round",
  initialState,
  reducers: {
    setRounds: (state, action: PayloadAction<Round[]>) => {
      state.rounds = action.payload
    },
    setSelectedRound: (state, action: PayloadAction<Round>) => {
      state.selectedRound = action.payload
    },
  },
})

export const { setRounds, setSelectedRound } = roundSlice.actions

export const selectCurrentRound = (state: RootState) =>
  state.roundState.selectedRound
export const selectRounds = (state: RootState) => state.roundState.rounds

export default roundSlice.reducer
