import { gql } from "graphql-tag"
import { fetchGQL } from "app/api"
import {
  PrintShipcloudLabelQuery,
  PrintShipcloudLabelQueryVariables,
} from "generated/graphql"

const PRINT_SHIPCLOUD_LABEL = gql`
  query printShipcloudLabel($ip: String!, $url: String!) {
    printShipcloudLabel(printerIpPort: $ip, shipcloudLabelURL: $url)
  }
`

export const shipcloudLabelPrint = async (
  printerIpPort: string,
  labelURL: string
): Promise<PrintShipcloudLabelQuery | undefined> => {
  const variables: PrintShipcloudLabelQueryVariables = {
    ip: printerIpPort,
    url: labelURL,
  }
  const data = await fetchGQL<
    PrintShipcloudLabelQuery,
    PrintShipcloudLabelQueryVariables
  >(PRINT_SHIPCLOUD_LABEL, variables)
  return data
}
