import {
  SupplierOrderedItemsQueryVariables,
  SupplierOrderedItemsQuery,
} from "generated/graphql"
import { gql } from "graphql-tag"
import { fetchGQL } from "app/api"

const GET_SUPPLIER_ORDERED_ITEMS = gql`
  query supplierOrderedItems($round: Int!) {
    supplierOrderedItems(round: $round) {
      RoundListID
      Orderid
      Ewid
      IsTagged
      SupplierID
      Quantity
      MainbaseBdb
      CreatedAt
      GERNAME
      BOTNAME
      Sorte
      Von
      Bis
      Lieferant
      G24
      Flavour
      Is24hDelivery
    }
  }
`

export const fetchSupplierOrderedItems = async (
  round: number
): Promise<SupplierOrderedItemsQuery | undefined> => {
  const variables: SupplierOrderedItemsQueryVariables = { round }
  const data = await fetchGQL<
    SupplierOrderedItemsQuery,
    SupplierOrderedItemsQueryVariables
  >(GET_SUPPLIER_ORDERED_ITEMS, variables)
  return data
}
