import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material"

type ZahlartFormProps = {
  disabled: boolean
  value: string
  onChange: (event: SelectChangeEvent) => void
}

export const ZahlartForm = (props: ZahlartFormProps) => {
  const { disabled, value, onChange } = { ...props }
  return (
    <FormControl fullWidth>
      <InputLabel id="zahlart">Zahlart</InputLabel>
      <Select
        disabled={disabled}
        labelId="zahlart"
        id="zahlart"
        value={value}
        label="Zahlart"
        onChange={onChange}
      >
        <MenuItem value={"Vorkasse"}>Vorkasse</MenuItem>
        <MenuItem value={"Paypal"}>Paypal</MenuItem>
        <MenuItem value={"Rechnung"}>Rechnung</MenuItem>
      </Select>
    </FormControl>
  )
}
