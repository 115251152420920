import { fetchGQL } from "app/api"
import {
  CustomerAddressSaveMutationVariables,
  CustomerAddressSaveMutation,
} from "generated/graphql"

import { gql } from "graphql-tag"

const CUSTOMER_ADDRESS_SAVE = gql`
  mutation customerAddressSave($in: AddressInput!) {
    customerAddressSave(in: $in) {
      id
    }
  }
`

export const customerAddressSave = async (
  vars: CustomerAddressSaveMutationVariables
): Promise<CustomerAddressSaveMutation | undefined> => {
  const data = await fetchGQL<
    CustomerAddressSaveMutation,
    CustomerAddressSaveMutationVariables
  >(CUSTOMER_ADDRESS_SAVE, vars)
  return data
}
