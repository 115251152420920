import { fetchGQL } from "app/api"
import {
  OrderListPreviewPdfQuery,
  OrderListPreviewPdfQueryVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"

const ORDER_LIST_PREVIEW_PDF = gql`
  query orderListPreviewPDF($round: Int!, $supplierID: Int!, $date: String!) {
    orderListPreviewPDF(round: $round, supplierID: $supplierID, date: $date) {
      pdfBase64
      msg
    }
  }
`

export const orderListPreviewPDF = async (
  round: number,
  supplierID: number,
  date: string
): Promise<OrderListPreviewPdfQuery | undefined> => {
  const variables: OrderListPreviewPdfQueryVariables = {
    round,
    supplierID,
    date,
  }
  const data = await fetchGQL<
    OrderListPreviewPdfQuery,
    OrderListPreviewPdfQueryVariables
  >(ORDER_LIST_PREVIEW_PDF, variables)
  return data
}
