import { useCallback, useEffect, useState } from "react"
import Grid from "@mui/material/Grid"
import { boxListGet } from "queries/fetchBoxList"
import { boxUpdate } from "queries/updateBox"
import { boxCreate } from "queries/createBox"
import { boxDelete } from "queries/deleteBox"
import { Carton } from "generated/graphql"
import Container from "@mui/material/Container"
import { Alert, Box, Button, TextField, Typography } from "@mui/material"
import { Delete, Refresh } from "@mui/icons-material"
import { useAppDispatch } from "app/hooks"
import { qSnack } from "app/snackSlice"
import Barcode from "react-barcode"
import DialogBoxDelete from "components/DialogBoxDelete/DialogBoxDelete"
import { toPng } from "html-to-image"
import { dataGridDEde } from "constants/dataGridLocale"
import {
  DataGridPro,
  GridColDef,
  GridRowSelectionModel,
} from "@mui/x-data-grid-pro"

const columns: GridColDef[] = [
  { field: "id", headerName: "id", width: 30, editable: false },
  { field: "barcode", headerName: "Barcode", width: 130, editable: true },
  {
    field: "description",
    headerName: "Beschreibung",
    width: 300,
    editable: true,
  },
  { field: "lengthMM", headerName: "Länge", width: 130, editable: true },
  { field: "widthMM", headerName: "Breite", width: 130, editable: true },
  { field: "heightMM", headerName: "Höhe", width: 130, editable: true },
]

const BoxEdit = () => {
  const dispatch = useAppDispatch()
  const [isBoxDeleteDialogOpen, setIsBoxDeleteDialogOpen] =
    useState<boolean>(false)
  const [selectedCarton, setSelectedCarton] = useState<Carton | undefined>(
    undefined
  )
  const [barcode, setBarcode] = useState<string>("")
  const [cartons, setCartons] = useState<Carton[]>([])
  const [newCarton, setNewCarton] = useState<Carton>({
    id: 0,
    barcode: "",
    description: "",
    lengthMM: 0,
    widthMM: 0,
    heightMM: 0,
  })

  const resetNewCarton = () => {
    setNewCarton({
      id: 0,
      barcode: "",
      description: "",
      lengthMM: 0,
      widthMM: 0,
      heightMM: 0,
    })
  }

  const notifyError = (msg: string) => {
    dispatch(
      qSnack({
        msg: msg,
        severity: "warning",
      })
    )
  }

  const reloadCartons = () => {
    boxListGet().then((data) => {
      let cartonList: Carton[] = []
      if (data) {
        cartonList = data.boxList
      }
      setCartons(cartonList)
    })
  }

  const isBarcodeTaken = useCallback(
    (c: Carton): boolean => {
      for (const carton of cartons) {
        if (carton.id === c.id && c.id !== 0) {
          continue
        }
        if (carton.barcode.toLowerCase() === c.barcode.toLowerCase()) {
          return true
        }
      }
      return false
    },
    [cartons]
  )

  const isValidCarton = (carton: Carton): boolean => {
    if (isBarcodeTaken(carton)) {
      notifyError("Barcode ist bereits vergeben.")
      return false
    }
    if (carton.barcode === "") {
      notifyError("Barcode darf nicht leer sein.")
      return false
    }
    if (carton.description === "") {
      notifyError("Beschreibung darf nicht leer sein")
      return false
    }
    if (carton.lengthMM === null || carton.lengthMM === undefined) {
      notifyError("Länge darf nicht leer sein")
      return false
    }
    if (carton.lengthMM <= 0) {
      notifyError("Länge muss größer 0 sein")
      return false
    }
    if (carton.widthMM === null || carton.widthMM === undefined) {
      notifyError("Breite darf nicht leer sein")
      return false
    }
    if (carton.widthMM <= 0) {
      notifyError("Breite muss größer 0 sein")
      return false
    }
    if (carton.heightMM === null || carton.heightMM === undefined) {
      notifyError("Höhe darf nicht leer sein")
      return false
    }
    if (carton.heightMM <= 0) {
      notifyError("Höhe muss größer 0 sein")
      return false
    }
    return true
  }

  const handleRowUpdate = async (newRow: Carton, oldRow: Carton) => {
    if (isValidCarton(newRow)) {
      const res = await boxUpdate(newRow)
      if (res?.boxUpdate) {
        dispatch(qSnack({ msg: "Karton aktualisiert", severity: "success" }))
        return newRow
      }
      dispatch(qSnack({ msg: "Fehler beim aktualisieren", severity: "error" }))
      return oldRow
    } else {
      return oldRow
    }
  }

  const handleRowUpdateError = (error: any) => {
    dispatch(qSnack({ msg: error.message, severity: "error" }))
  }

  const handleAddCarton = () => {
    if (isValidCarton(newCarton)) {
      boxCreate(newCarton).then((data) => {
        if (data?.boxCreate !== undefined) {
          dispatch(qSnack({ msg: "Karton erstellt", severity: "success" }))
          reloadCartons()
          resetNewCarton()
          return
        }
        notifyError("Fehler beim Erstellen des Kartons")
      })
    }
  }

  const handleDeleteSelectedBox = () => {
    if (selectedCarton !== undefined) {
      boxDelete({ id: selectedCarton.id }).then((data) => {
        setIsBoxDeleteDialogOpen(false)
        setSelectedCarton(undefined)
        if (data) {
          reloadCartons()
          return
        }

        notifyError("Fehler beim Löschen des Kartons")
      })
    }
  }

  const downloadPng = async (e: any) => {
    const svg = e.currentTarget.querySelector("svg")

    try {
      // cast SVGElement to HTMLElement to satisfy TS
      const pngDataUrl = await toPng(svg)
      const link = document.createElement("a")
      link.href = pngDataUrl
      link.download = "barcode.png"
      link.click()
    } catch (err) {
      console.error("Failed to convert SVG to PNG:", err)
    }
  }

  useEffect(() => {
    reloadCartons()
  }, [])

  return (
    <Container maxWidth="xl">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              flex: 1,
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{ textTransform: "none" }}
              onClick={() => {
                reloadCartons()
              }}
              startIcon={<Refresh />}
            >
              Aktualisieren
            </Button>
            <Button
              variant="contained"
              disabled={selectedCarton === undefined}
              color="error"
              sx={{ textTransform: "none", marginLeft: "10px" }}
              onClick={() => {
                setIsBoxDeleteDialogOpen(true)
              }}
              startIcon={<Delete />}
            >
              Löschen
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{ minHeight: "80vh", maxHeight: "80vh" }}>
          <DataGridPro
            rows={cartons}
            columns={columns}
            localeText={dataGridDEde}
            processRowUpdate={handleRowUpdate}
            onProcessRowUpdateError={handleRowUpdateError}
            onRowSelectionModelChange={(
              rowSelections: GridRowSelectionModel
            ) => {
              if (rowSelections.length === 0) {
                setSelectedCarton(undefined)
              } else {
                const id = rowSelections[0]
                const carton = cartons.find((c) => c.id === id)
                setSelectedCarton(carton)
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{ minHeight: "80vh", maxHeight: "80vh" }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                id="carton-barcode"
                label="Barcode"
                value={newCarton.barcode}
                fullWidth
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setNewCarton({ ...newCarton, barcode: event.target.value })
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="carton-description"
                label="Beschreibung"
                value={newCarton.description}
                fullWidth
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setNewCarton({
                    ...newCarton,
                    description: event.target.value,
                  })
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="carton-length"
                label="Länge in mm"
                value={newCarton.lengthMM}
                fullWidth
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (isNaN(Number(event.target.value))) {
                    return
                  }
                  setNewCarton({
                    ...newCarton,
                    lengthMM: Number(event.target.value),
                  })
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="carton-width"
                label="Breite in mm"
                value={newCarton.widthMM}
                fullWidth
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (isNaN(Number(event.target.value))) {
                    return
                  }
                  setNewCarton({
                    ...newCarton,
                    widthMM: Number(event.target.value),
                  })
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="carton-height"
                label="Höhe in mm"
                value={newCarton.heightMM}
                fullWidth
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (isNaN(Number(event.target.value))) {
                    return
                  }
                  setNewCarton({
                    ...newCarton,
                    heightMM: Number(event.target.value),
                  })
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  handleAddCarton()
                }}
              >
                Hinzufügen
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ marginTop: 2 }}>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                Barcode generieren
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="barcode-input"
                label="Barcode"
                value={barcode}
                fullWidth
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setBarcode(event.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {barcode.trim().length > 0 && (
                  <div onClick={downloadPng}>
                    <Barcode value={barcode} width={4} format="CODE128" />
                  </div>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              {barcode.trim().length > 0 && (
                <Alert severity="info">
                  Mit Linksklick auf das generierte Barcodebild klicken zum
                  Herunterladen. Wird automatisch als PNG-Bild gespeichert.
                </Alert>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DialogBoxDelete
        selectedCarton={selectedCarton}
        isOpen={isBoxDeleteDialogOpen}
        onClose={() => {
          setIsBoxDeleteDialogOpen(false)
        }}
        onDelete={handleDeleteSelectedBox}
      />
    </Container>
  )
}

export default BoxEdit
