import LoadingButton from "@mui/lab/LoadingButton"
import {
  Box,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material"
import {
  DataGridPro,
  GridColDef,
  GridFilterInputValueProps,
  GridFilterItem,
  GridFilterOperator,
  getGridNumericOperators,
  useGridRootProps,
} from "@mui/x-data-grid-pro"
import { useAppSelector } from "app/hooks"
import { selectCurrentRound } from "app/roundSlice"
import { dataGridDEde } from "constants/dataGridLocale"
import { Printer, RoundOrderdata } from "generated/graphql"
import { enqueueSnackbar } from "notistack"
import { fetchPrinters } from "queries/fetchPrinters"
import { kistenzettelPrint } from "queries/kistenzettelPrint"
import { roundOrderdata } from "queries/roundOrderData"
import { useEffect, useRef, useState } from "react"
import SyncIcon from "@mui/icons-material/Sync"

function InputNumberInterval(props: GridFilterInputValueProps) {
  const rootProps = useGridRootProps()
  const { item, applyValue, focusElementRef = null } = props

  const filterTimeout = useRef<any>()
  const [filterValueState, setFilterValueState] = useState<[string, string]>(
    item.value ?? ""
  )
  const [applying, setIsApplying] = useState(false)

  useEffect(() => {
    return () => {
      clearTimeout(filterTimeout.current)
    }
  }, [])

  useEffect(() => {
    const itemValue = item.value ?? [undefined, undefined]
    setFilterValueState(itemValue)
  }, [item.value])

  const updateFilterValue = (lowerBound: string, upperBound: string) => {
    clearTimeout(filterTimeout.current)
    setFilterValueState([lowerBound, upperBound])

    setIsApplying(true)
    filterTimeout.current = setTimeout(() => {
      setIsApplying(false)
      applyValue({ ...item, value: [lowerBound, upperBound] })
    }, rootProps.filterDebounceMs)
  }

  const handleUpperFilterChange: TextFieldProps["onChange"] = (event) => {
    const newUpperBound = event.target.value
    updateFilterValue(filterValueState[0], newUpperBound)
  }
  const handleLowerFilterChange: TextFieldProps["onChange"] = (event) => {
    const newLowerBound = event.target.value
    updateFilterValue(newLowerBound, filterValueState[1])
  }

  return (
    <Box
      sx={{
        display: "inline-flex",
        flexDirection: "row",
        alignItems: "end",
        height: 48,
        pl: "20px",
      }}
    >
      <TextField
        name="lower-bound-input"
        placeholder="Von"
        label="Von"
        variant="standard"
        value={Number(filterValueState[0])}
        onChange={handleLowerFilterChange}
        type="number"
        inputRef={focusElementRef}
        sx={{ mr: 2 }}
      />
      <TextField
        name="upper-bound-input"
        placeholder="Bis"
        label="Bis"
        variant="standard"
        value={Number(filterValueState[1])}
        onChange={handleUpperFilterChange}
        type="number"
        InputProps={applying ? { endAdornment: <SyncIcon /> } : {}}
      />
    </Box>
  )
}

export const valueBetweenOperator: GridFilterOperator[] = [
  {
    label: "zwischen",
    value: "zwischen",
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
        return null
      }
      if (filterItem.value[0] == null || filterItem.value[1] == null) {
        return null
      }

      return ({ value }) => {
        return (
          value !== null &&
          filterItem.value[0] <= value &&
          value <= filterItem.value[1]
        )
      }
    },
    InputComponent: InputNumberInterval,
  },
]

const PrintKistenzettelPage = (): JSX.Element => {
  const round = useAppSelector(selectCurrentRound)
  const [isLoading, setIsLoading] = useState(false)
  const [gridData, setGridData] = useState<RoundOrderdata[]>([])
  const [selection, setSelection] = useState<RoundOrderdata[]>([])

  const handleSelectionModelChange = (selectionModel: any) => {
    //selection model is an array of selected row ids here routeIDs
    // e.g [1,2,3] this comes from the database
    const selectedOrderList = selectionModel.map((id: any) =>
      gridData.find((item) => item.orderid === id)
    )
    setSelection(selectedOrderList)
  }

  const cols: GridColDef<RoundOrderdata>[] = [
    {
      field: "sh",
      headerName: "SH",
      width: 100,
      filterOperators: valueBetweenOperator.concat(getGridNumericOperators()),
      type: "number",
    },
    {
      field: "orderid",
      headerName: "Bestellnr.",
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
    },
    {
      field: "totalArticleCount",
      headerName: "Artikel gesamt",
      width: 150,
    },
    {
      field: "zahlart",
      headerName: "Zahlart",
      width: 150,
    },
    {
      field: "zieldat",
      headerName: "Zieldatum",
      width: 200,
    },
    {
      field: "notiz",
      headerName: "Notiz",
      width: 500,
      renderCell: (params) => (
        <Typography
          variant="body2"
          component="div"
          sx={{
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
          }}
        >
          {params.value}
        </Typography>
      ),
    },
  ]

  useEffect(() => {
    if (!round) {
      return
    }
    setIsLoading(true)
    roundOrderdata(round.round)
      .then((res) => {
        if (res?.roundOrderdata) {
          setGridData(res.roundOrderdata)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [round])

  const [selectedPrinter, setSelectedPrinter] = useState<Printer | null>(null)
  const [printers, setPrinters] = useState<Printer[]>([])

  useEffect(() => {
    // load printer from server
    fetchPrinters().then((data) => {
      if (data?.printers) {
        setPrinters(data.printers)
        const sp = localStorage.getItem("selectedPrinter-KistenzettelPrinting")
        if (sp) {
          const printer = data.printers.find(
            (printer) => printer.printerID === Number(sp)
          )
          if (printer) {
            setSelectedPrinter(printer)
          }
        }
      }
    })
  }, [])

  const handlePrintKistenzettel = () => {
    if (!round) return
    const selectedOrderIDs = selection.map((item) => item.orderid)
    if (selectedOrderIDs.length === 0) return

    if (!selectedPrinter) {
      enqueueSnackbar("Bitte wählen Sie einen Drucker aus", {
        variant: "error",
      })
      return
    }
    setIsLoading(true)
    const printerIpPort = `${selectedPrinter.printerIP}:${selectedPrinter.printerPort}`
    kistenzettelPrint(round.round, printerIpPort, selectedOrderIDs)
      .then((res) => {
        if (res?.printOrderListKistenZettel) {
          enqueueSnackbar(res.printOrderListKistenZettel, {
            variant: "info",
          })
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <Container maxWidth="xl">
      <Grid container spacing={1}>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <InputLabel id="printer-select-label">Drucker</InputLabel>
            <Select
              labelId="printer-select-label"
              id="printer-select"
              value={selectedPrinter?.printerID || ""}
              label="Drucker"
              onChange={(event) => {
                const printerID = event.target.value as number
                const printer = printers.find(
                  (printer) => printer.printerID === printerID
                )
                setSelectedPrinter(printer || null)
                localStorage.setItem(
                  "selectedPrinter-KistenzettelPrinting",
                  printerID.toString()
                )
              }}
            >
              {printers.map((printer) => (
                <MenuItem key={printer.printerID} value={printer.printerID}>
                  {printer.printerID} - {printer.printerIP}:
                  {printer.printerPort} - {printer.printerName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            sx={{ height: "100%" }}
            onClick={handlePrintKistenzettel}
            loading={isLoading}
            disabled={selection.length === 0 || gridData.length === 0}
          >
            ({selection.length}) Kistenzettel drucken
          </LoadingButton>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ height: "80vh", width: "100%" }}>
            <DataGridPro
              rows={gridData}
              getRowHeight={() => "auto"}
              checkboxSelection
              getRowId={(row) => row.orderid}
              disableRowSelectionOnClick
              localeText={dataGridDEde}
              columns={cols}
              loading={isLoading}
              onRowSelectionModelChange={handleSelectionModelChange}
              onFilterModelChange={(_) => setSelection([])}
              rowSelectionModel={selection.map((item) => item.orderid)}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default PrintKistenzettelPage
