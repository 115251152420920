import { Autocomplete, Grid, IconButton, TextField } from "@mui/material"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"

interface SupplierSelectorSelectorProps {
  items: string[]
  selectedItem: string
  handleSelectionChange: (s: string) => void
}

const SupplierSelector = (
  props: SupplierSelectorSelectorProps
): JSX.Element => {
  const { items, handleSelectionChange, selectedItem } = props

  const renderInput = (params: any) => {
    return <TextField {...params} label="Lieferant" variant="outlined" />
  }

  const handleNextItem = (direction: number) => {
    if (direction === 0) return
    if (direction > 1 || direction < -1) return
    const currentIndex = items.indexOf(selectedItem)
    const nextIndex = currentIndex + direction
    // handle underflow (start from end)
    if (nextIndex === -1 && items.length > 0) {
      handleSelectionChange(items[items.length - 1])
      return
    }
    if (nextIndex < items.length) {
      handleSelectionChange(items[nextIndex])
      return
    }
    // handle overflow (start from beginning)
    if (nextIndex === items.length && items.length > 0) {
      handleSelectionChange(items[0])
      return
    }
  }

  const handleClickNext = () => {
    handleNextItem(1)
  }

  const handleClickPrevious = () => {
    handleNextItem(-1)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Autocomplete
          disablePortal
          options={items}
          renderInput={renderInput}
          value={selectedItem}
          onChange={(_, newValue) => {
            if (newValue) handleSelectionChange(newValue)
          }}
        />
      </Grid>
      <Grid
        item
        xs={2}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <IconButton onClick={handleClickPrevious}>
          <ChevronLeftIcon />
        </IconButton>
      </Grid>

      <Grid
        item
        xs={2}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <IconButton onClick={handleClickNext}>
          <ChevronRightIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default SupplierSelector
