import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"

export interface CircularProgessProps {
  progess: number
}

const CircularProgess = (props: CircularProgessProps): JSX.Element => {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        value={100}
        sx={{ color: "#d6d6d6" }}
        size={80}
        thickness={4}
      />
      <CircularProgress
        variant="determinate"
        size={80}
        thickness={4}
        sx={{ position: "absolute", left: 0 }}
        value={props.progess}
      />
      <Box
        sx={{
          top: 0,
          left: props.progess < 9 ? 8 : 2,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{ fontSize: "16px", textAlign: "center" }}
        >
          {props.progess + "%"}
        </Typography>
      </Box>
    </Box>
  )
}

export default CircularProgess
