import {
  BoxDeleteMutation,
  BoxDeleteMutationVariables,
} from "../generated/graphql"
import { gql } from "graphql-tag"
import { fetchGQL } from "app/api"

const DELETE_BOX = gql`
  mutation boxDelete($id: Int!) {
    boxDelete(id: $id)
  }
`

export const boxDelete = async (
  input: BoxDeleteMutationVariables
): Promise<BoxDeleteMutation | undefined> => {
  const variables: BoxDeleteMutationVariables = input
  const data = await fetchGQL<BoxDeleteMutation, BoxDeleteMutationVariables>(
    DELETE_BOX,
    variables
  )
  return data
}
