import { DateRange, PickersShortcutsItem } from "@mui/x-date-pickers-pro"
import dayjs, { Dayjs } from "dayjs"

export const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  {
    label: "Letze Woche",
    getValue: () => {
      const today = dayjs()
      const prevWeek = today.subtract(1, "week")
      // first day of week is monday
      // last day of week is sunday
      const monday = prevWeek.day(1)
      const sunday = prevWeek.day(7)
      return [monday, sunday]
    },
  },
  {
    label: "Letze 7 Tage",
    getValue: () => {
      const today = dayjs()
      return [today.subtract(7, "day"), today]
    },
  },
  {
    label: "Letze 4 Wochen",
    getValue: () => {
      const today = dayjs()
      return [today.subtract(4, "week"), today]
    },
  },
  {
    label: "Aktueller Monat",
    getValue: () => {
      const today = dayjs()
      return [today.startOf("month"), today]
    },
  },
  {
    label: "Dieses Jahr",
    getValue: () => {
      const today = dayjs()
      return [today.startOf("year"), today]
    },
  },
  {
    label: "Letztes Jahr",
    getValue: () => {
      const today = dayjs()
      return [
        today.subtract(1, "year").startOf("year"),
        today.subtract(1, "year").endOf("year"),
      ]
    },
  },
]
