import { gql } from "graphql-tag"
import { fetchGQL } from "app/api"
import {
  PrintRsLabelQuery,
  PrintRsLabelQueryVariables,
} from "generated/graphql"

const RSLABEL_PRINT = gql`
  query printRsLabel($printerIpPort: String!, $rsLabelURL: String!) {
    printRsLabel(printerIpPort: $printerIpPort, rsLabelURL: $rsLabelURL)
  }
`

export const rsLabelPrint = async (
  rsLabelURL: string,
  printerIpPort: string
): Promise<PrintRsLabelQuery | undefined> => {
  const variables: PrintRsLabelQueryVariables = { printerIpPort, rsLabelURL }
  const data = await fetchGQL<PrintRsLabelQuery, PrintRsLabelQueryVariables>(
    RSLABEL_PRINT,
    variables
  )
  return data
}
