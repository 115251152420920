import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  CircularProgress,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  DialogActions,
  Button,
} from "@mui/material"
import { RoundSupplierDialogData } from "pages/RoundsStatisticPage/RoundsStatisticPage"

export interface DialogRoundSupplierProps {
  data: RoundSupplierDialogData
  onClose: () => void
}

const DialogRoundSupplier = (props: DialogRoundSupplierProps): JSX.Element => {
  const { data, onClose } = props

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { maxWidth: 960, maxHeight: 435 } }}
      maxWidth="xs"
      open={data.isOpen}
      onClose={onClose}
    >
      <DialogTitle>Lieferanten für Runde {data.roundID}</DialogTitle>
      <DialogContent dividers>
        {data.isLoading && (
          <Grid container justifyContent={"center"}>
            <CircularProgress />
          </Grid>
        )}
        {!data.isLoading && data.suppliers.length === 0 && (
          <Grid container justifyContent={"center"}>
            <Typography>Keine Lieferanten gefunden</Typography>
          </Grid>
        )}
        {!data.isLoading && data.suppliers.length > 0 && (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Lieferant</TableCell>
                <TableCell>Erwartet</TableCell>
                <TableCell>Etikettiert</TableCell>
                <TableCell>Fehlen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.suppliers.map((supplier) => (
                <TableRow
                  key={supplier.supplierID}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {supplier.supplier}
                  </TableCell>
                  <TableCell>{supplier.expected}</TableCell>
                  <TableCell>{supplier.tagged}</TableCell>
                  <TableCell>{supplier.rest}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={data.isLoading} onClick={onClose}>
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogRoundSupplier
