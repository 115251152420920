import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew"
import { LinearProgress } from "@mui/material"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import IconButton from "@mui/material/IconButton"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { selectIsLoading } from "app/loadingSlice"
import { logout, selectUser } from "app/userSlice"
import PmLogo from "components/PmLogo/PmLogo"
import { useCallback } from "react"
import { TopNavigation } from "./TopNavigation"

const Navbar = () => {
  const dispatch = useAppDispatch()
  const us = useAppSelector(selectUser)
  const isLoading = useAppSelector(selectIsLoading)
  const handleLogout = useCallback(() => dispatch(logout()), [dispatch])

  return (
    <AppBar color="inherit">
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Box sx={{ marginTop: 1, marginRight: 3 }}>
            <PmLogo />
          </Box>

          <TopNavigation />

          <Box
            sx={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Box sx={{ flexGrow: 0 }}>
              <Typography>{us.user?.username}</Typography>
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <IconButton
                aria-label="logout button"
                onClick={handleLogout}
                color="error"
              >
                <PowerSettingsNewIcon sx={{ fontSize: 32 }} />
              </IconButton>
            </Box>
          </Box>
        </Toolbar>
      </Container>
      <Box
        sx={{
          height: 3,
        }}
      >
        {isLoading && <LinearProgress />}
      </Box>
    </AppBar>
  )
}
export default Navbar
