import { fetchGQL } from "app/api"
import { OrderStatusQuery, OrderStatusQueryVariables } from "generated/graphql"
import { gql } from "graphql-tag"

const ORDER_STATUS = gql`
  query orderStatus {
    orderStatus {
      status
      etiStop
      verteilenStop
      packStop
      remark
    }
  }
`

export const orderStatus = async (): Promise<OrderStatusQuery | undefined> => {
  const variables: OrderStatusQueryVariables = {}
  const data = await fetchGQL<OrderStatusQuery, OrderStatusQueryVariables>(
    ORDER_STATUS,
    variables
  )
  return data
}
