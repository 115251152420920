import { gql } from "graphql-tag"
import { fetchGQL } from "app/api"
import { PrintersQuery, PrintersQueryVariables } from "generated/graphql"

const PRINTERS_GET = gql`
  query printers {
    printers {
      printerID
      printerName
      printerIP
      printerPort
    }
  }
`

export const fetchPrinters = async (): Promise<PrintersQuery | undefined> => {
  const variables: PrintersQueryVariables = {}
  const data = await fetchGQL<PrintersQuery, PrintersQueryVariables>(
    PRINTERS_GET,
    variables
  )
  return data
}
