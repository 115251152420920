import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  Button,
  Box,
  Typography,
} from "@mui/material"
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro"
import { dataGridDEde } from "constants/dataGridLocale"
import { ArticleItem, OrderDetailsMore, OrderItem } from "generated/graphql"
import { enqueueSnackbar } from "notistack"
import { articleSearch } from "queries/articleSearch"
import { useEffect, useRef, useState } from "react"

type AddArticleProps = {
  order: OrderDetailsMore
  setOrder: (arg: OrderDetailsMore) => void
  open: boolean
  close: () => void
  setOrderChanged: (arg: boolean) => void
}

export const AddArticleDialog = (props: AddArticleProps) => {
  const { open, close, order, setOrder, setOrderChanged } = props
  const [selectedItems, setSelectedItems] = useState<OrderItem[]>(
    order.orderItems
  )
  const searchRef = useRef<HTMLInputElement>(null)
  const [articleSearchResult, setArticleSearchResult] = useState<ArticleItem[]>(
    []
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleOnClose = () => {
    close()
    setArticleSearchResult([])
  }

  const saveOrder = () => {
    if (
      order.status === 3 ||
      order.status === 5 ||
      order.status === 15 ||
      order.status === 17
    ) {
      enqueueSnackbar(
        "Artikel können nicht hinzugefügt werden, da der Auftrag bereits abgeschlossen/Storniert ist",
        { variant: "error" }
      )
      return
    }
    setOrder({ ...order, orderItems: [...selectedItems] })
    setOrderChanged(true)
    enqueueSnackbar(
      "Artikel zum Prüfen hinzugefügt, es muss noch Abgespeichert werden!",
      { variant: "success" }
    )
    handleOnClose()
  }

  const colArticleSearch: GridColDef<ArticleItem>[] = [
    {
      field: "bdb",
      headerName: "BDB",
      width: 80,
      renderCell: (params) => {
        return (
          <Typography
            variant="body2"
            sx={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "primary.main",
            }}
            component="div"
            onClick={() =>
              window.open(
                `${window.location.origin}/#/editArticle/${params.row.bdb}`,
                "_blank",
                "noopener,noreferrer"
              )
            }
          >
            {params.row.bdb}
          </Typography>
        )
      },
    },
    {
      field: "botname",
      headerName: "Botname",
      width: 250,
    },
    {
      field: "price",
      headerName: "Stückpreis",
      width: 150,
      type: "number",
    },
    {
      field: "size",
      headerName: "Größe",
      valueGetter: (params) => {
        return `${params.row.sizeFrom} - ${params.row.sizeTo}`
      },
      width: 80,
    },
    {
      field: "add",
      headerName: "Hinzufügen",
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              const item = selectedItems.find(
                (i) => i.artnum.toString() === params.row.bdb
              )
              if (item === undefined) {
                setSelectedItems([
                  ...selectedItems,
                  {
                    ewID: -1, //NOTE: not created yet
                    artnum: Number(params.row.bdb),
                    botname: params.row.botname,
                    stueckpreis: Number(params.row.price),
                    anzahl: 1,
                    stahoeh: 0,
                    stuvon: 0,
                    stubis: 0,
                    bestellt: false,
                    etikettiert: false,
                    splitstatus: false,
                    verteilt: false,
                    lager: "",
                    source: "",
                    von: params.row.sizeFrom,
                    bis: params.row.sizeTo,
                  },
                ])
              } else {
                item.anzahl++
                setSelectedItems([...selectedItems])
              }
            }}
          >
            Hinzufügen
          </Button>
        )
      },
      width: 120,
    },
  ]

  const colOrderArticles: GridColDef<OrderItem>[] = [
    {
      field: "artnum",
      headerName: "BDB",
      width: 80,
      renderCell: (params) => {
        return (
          <Typography
            variant="body2"
            sx={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "primary.main",
            }}
            component="div"
            onClick={() =>
              window.open(
                `${window.location.origin}/#/editArticle/${params.row.artnum}`,
                "_blank",
                "noopener,noreferrer"
              )
            }
          >
            {params.row.artnum}
          </Typography>
        )
      },
    },
    {
      field: "botname",
      headerName: "Botname",
      width: 250,
    },
    {
      field: "size",
      headerName: "Größe",
      width: 80,
    },
    {
      field: "stueckpreis",
      headerName: "Stückpreis",
      width: 100,
      type: "number",
      editable: true,
    },

    {
      field: "anzahl",
      headerName: "Anzahl",
      width: 100,
      type: "number",
      editable: true,
    },
    // {
    //   field: "remove",
    //   headerName: "Entfernen",
    //   width: 100,
    //   renderCell: (params) => {
    //     return (
    //       <IconButton
    //         onClick={() => {
    //           const item = selectedItems.find(
    //             (i) => i.artnum === params.row.artnum
    //           )
    //           if (item !== undefined) {
    //             setSelectedItems(
    //               selectedItems.filter((i) => i.artnum !== item.artnum)
    //             )
    //           }
    //         }}
    //       >
    //         <DeleteIcon color="error" />
    //       </IconButton>
    //     )
    //   },
    // },
  ]

  useEffect(() => {
    if (searchRef.current !== null) {
      searchRef.current.focus()
    }
  }, [])

  const handleSearchCommit = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      const searchValue = searchRef.current?.value
      if (searchValue !== undefined && searchValue !== null) {
        if (searchValue === "") {
          enqueueSnackbar("Bitte geben Sie einen Suchbegriff ein", {
            variant: "info",
          })
        } else {
          setIsLoading(true)
          articleSearch(searchValue)
            .then((result) => {
              if (result?.articleSearch) {
                //TODO: fixme
                setArticleSearchResult(result.articleSearch)
              }
            })
            .finally(() => {
              setIsLoading(false)
              setTimeout(() => {
                if (searchRef.current !== null) {
                  searchRef.current.focus()
                }
              }, 100)
            })
        }
      }
    }
  }

  return (
    <Dialog open={open} onClose={handleOnClose} fullWidth maxWidth="xl">
      <DialogTitle>Artikel ändern</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  label="Suche"
                  inputRef={searchRef}
                  placeholder="Suche"
                  onKeyUp={handleSearchCommit}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ height: "50vh" }}>
                  <DataGridPro
                    rows={articleSearchResult}
                    getRowId={(row) => row.bdb}
                    columns={colArticleSearch}
                    localeText={dataGridDEde}
                    loading={isLoading}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ height: "50vh" }}>
                  <DataGridPro
                    rows={selectedItems}
                    getRowId={(row) => row.artnum}
                    columns={colOrderArticles}
                    localeText={dataGridDEde}
                    loading={isLoading}
                    processRowUpdate={(newRow, oldRow) => {
                      if (
                        order.status === 3 ||
                        order.status === 5 ||
                        order.status === 15 ||
                        order.status === 17
                      ) {
                        enqueueSnackbar(
                          "Artikel können nicht hinzugefügt werden, da der Auftrag bereits abgeschlossen/Storniert ist",
                          { variant: "error" }
                        )
                        return oldRow
                      }
                      if (oldRow.splitstatus) {
                        enqueueSnackbar(
                          "Artikel kann nicht geändert werden, da der Artikel bereits gesplittet wurde",
                          { variant: "error" }
                        )
                        return oldRow
                      }
                      if (
                        newRow.anzahl === oldRow.anzahl &&
                        newRow.stueckpreis === oldRow.stueckpreis
                      ) {
                        return newRow
                      }
                      if (newRow.anzahl <= 0) {
                        enqueueSnackbar(
                          "Anzahl darf nicht kleiner als 1 sein",
                          {
                            variant: "error",
                          }
                        )
                        return oldRow
                      }
                      const item = selectedItems.find(
                        (i) => i.artnum === newRow.artnum
                      )
                      if (item !== undefined) {
                        item.anzahl = Number(newRow.anzahl)
                        item.stueckpreis = newRow.stueckpreis
                        setSelectedItems([...selectedItems])
                      }
                      return newRow
                    }}
                    onProcessRowUpdateError={(error) => {
                      enqueueSnackbar(error, { variant: "error" })
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          disabled={selectedItems.length === 0}
          onClick={saveOrder}
        >
          Speichern
        </Button>
        <Button variant="contained" onClick={handleOnClose}>
          Abbrechen
        </Button>
      </DialogActions>
    </Dialog>
  )
}
