import { fetchGQL } from "app/api"
import {
  RoundOrdersGetQuery,
  RoundOrdersGetQueryVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"

const ROUND_ORDERS_GET = gql`
  query roundOrdersGet($round: Int!) {
    roundOrdersGet(in: { round: $round }) {
      totalOrdersCount
      orderArticles {
        position
        articleId
        botName
        variant
        quantityTotal
        quantityForCustomers
        quantityForStorage
        sizeMin
        sizeMax
        supplierContainerSize
        supplierID
        supplierName
        description
      }
    }
  }
`

export const fetchRoundOrders = async (
  r: number
): Promise<RoundOrdersGetQuery | undefined> => {
  const variables: RoundOrdersGetQueryVariables = {
    round: r,
  }
  const data = await fetchGQL<
    RoundOrdersGetQuery,
    RoundOrdersGetQueryVariables
  >(ROUND_ORDERS_GET, variables)
  return data
}
