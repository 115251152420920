import { fetchGQL } from "app/api"
import {
  RoundItemEditInput,
  RoundItemEditMutation,
  RoundItemEditMutationVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"

const ROUND_ITEMS_EDIT = gql`
  mutation roundItemEdit($in: RoundItemEditInput!) {
    roundItemsEdit(in: $in)
  }
`

export const roundItemsEdit = async (
  _in: RoundItemEditInput
): Promise<RoundItemEditMutation | undefined> => {
  var vars: RoundItemEditMutationVariables = {
    in: _in,
  }
  const data = await fetchGQL<
    RoundItemEditMutation,
    RoundItemEditMutationVariables
  >(ROUND_ITEMS_EDIT, vars)
  return data
}
