import { fetchGQL } from "app/api"
import {
  CustomerOrdersQuery,
  CustomerOrdersQueryVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"

const CUSTOMER_SEARCH = gql`
  query customerOrders($customerID: Int!) {
    customerOrders(customerID: $customerID) {
      orderID
      urorder
      datum
      zieldat
      status
      zahlart
      rechnum
      rechsum
      bezahlt
      frachtpausch
      notiz
      custnote
    }
  }
`

export const customerOrders = async (
  input: number
): Promise<CustomerOrdersQuery | undefined> => {
  const variables: CustomerOrdersQueryVariables = {
    customerID: input,
  }
  const data = await fetchGQL<
    CustomerOrdersQuery,
    CustomerOrdersQueryVariables
  >(CUSTOMER_SEARCH, variables)
  return data
}
