import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { RoundStat } from "generated/graphql"
import CircularProgess from "components/CircularProgess/CircularProgess"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"

export interface RoundStatBoxProps {
  data: RoundStat
  onOpenSupplierDialog: (roundID: number) => void
  onOpenChangeRoundDialog: () => void
}

const RoundStatBox = (props: RoundStatBoxProps): JSX.Element => {
  return (
    <Box
      borderRadius={"10px"}
      padding={"10px"}
      margin={"4px"}
      bgcolor={"#fff"}
      boxShadow={"2px 2px 8px 0 rgba(0,0,0,0.2)"}
    >
      <Grid container>
        <Grid container item xs={12} sx={{ position: "relative" }}>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Typography
              sx={{ fontSize: 16, fontWeight: "bold", color: "grey.100" }}
            >
              Runde
            </Typography>
            <Typography sx={{ fontSize: 30, fontWeight: "bold" }}>
              {props.data.roundID}
            </Typography>
            <Typography
              sx={{ fontSize: 16, color: "grey.100", paddingTop: "5%" }}
            >
              {props.data.roundName}
            </Typography>
          </Grid>
          <Grid item xs sx={{ position: "absolute", right: 5 }}>
            <CircularProgess progess={props.data.percentage} />
          </Grid>
        </Grid>
        <Grid container item xs={12} marginTop={4} justifyContent={"center"}>
          <Grid item>
            <Table sx={{ width: "50%" }}>
              <TableBody>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      border: "none",
                    }}
                  >
                    Anfangsdatum
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "primary.main",
                      textAlign: "center",
                      fontWeight: "bold",
                      border: "none",
                    }}
                  >
                    {props.data.startDate}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      border: "none",
                    }}
                  >
                    Bstg.
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "primary.main",
                      textAlign: "center",
                      fontWeight: "bold",
                      border: "none",
                    }}
                  >
                    {props.data.orderCount}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      border: "none",
                    }}
                  >
                    Offene Bstg.
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "primary.main",
                      textAlign: "center",
                      fontWeight: "bold",
                      border: "none",
                    }}
                  >
                    {props.data.openOrderCount}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell />
                  <TableCell />
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      border: "none",
                    }}
                  >
                    Anz Artikel.
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "primary.main",
                      textAlign: "center",
                      fontWeight: "bold",
                      border: "none",
                    }}
                  >
                    {props.data.articleCount}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      border: "none",
                    }}
                  >
                    Etikettiert
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "primary.main",
                      textAlign: "center",
                      fontWeight: "bold",
                      border: "none",
                    }}
                  >
                    {props.data.taggedArticleCount}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      border: "none",
                    }}
                  >
                    Verteilt
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "primary.main",
                      textAlign: "center",
                      fontWeight: "bold",
                      border: "none",
                    }}
                  >
                    {props.data.distributedArticleCount}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
        <Grid item xs={12} textAlign={"center"} marginTop={4}>
          <Button
            variant="text"
            sx={{ fontSize: "0.875rem" }}
            onClick={() => {
              props.onOpenSupplierDialog(props.data.roundID)
            }}
          >
            Lieferanten zeigen
          </Button>
        </Grid>
        <Grid item xs={12} textAlign={"end"}>
          <Button
            variant="text"
            sx={{ color: "grey.100", fontSize: "14px" }}
            onClick={props.onOpenChangeRoundDialog}
          >
            Runde ändern
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default RoundStatBox
