import { InputAdornment, TextField } from "@mui/material"
import { ChangeEvent, useEffect, useState } from "react"

export interface CurrencyTextFieldProps {
  label: string
  value: string
  nullAllowed: boolean
  setValueCallback: (value: string) => void
  setIsValidCallback: (isValid: boolean) => void
  disabled?: boolean
  marginBottom?: number
}

const checkIsValid = (value: string, nullAllowed: boolean) => {
  if (!value) {
    return nullAllowed
  }
  const parsed = Number(value.replace(",", "."))
  if (isNaN(parsed)) {
    return false
  }
  if (parsed < 0) {
    return false
  } else if (parsed === 0) {
    return nullAllowed
  }
  return true
}

const niceLookingPrice = (value: string): string => {
  const numVal = Number.parseFloat(value.replace(",", "."))
  if (isNaN(numVal) || numVal === 0) {
    return "0,00"
  }
  return numVal.toFixed(2).replace(".", ",")
}

const CurrencyTextField = (props: CurrencyTextFieldProps): JSX.Element => {
  const {
    label,
    value,
    nullAllowed,
    setValueCallback,
    setIsValidCallback,
    disabled,
    marginBottom,
  } = props
  const [internalValue, setInternalValue] = useState<string>(
    niceLookingPrice(value)
  )
  const [isValid, setIsValid] = useState<boolean>(
    checkIsValid(value, nullAllowed)
  )

  useEffect(() => {
    setIsValidCallback(isValid)
  }, [isValid, setIsValidCallback])

  const handleChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    const sanitizedValue = e.target.value
      .replace(/[^0-9,.]/g, "")
      .replace(".", ",")
    if (sanitizedValue.indexOf(",") !== sanitizedValue.lastIndexOf(",")) {
      return
    }
    setInternalValue(sanitizedValue)
    setIsValid(checkIsValid(sanitizedValue, nullAllowed))
  }

  const handleFocusLost = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    const numberString = e.target.value.replace(",", ".")
    const parsedNumber = Number(numberString ?? "0.00")
    if (parsedNumber === 0) {
      if (nullAllowed) {
        setValueCallback("")
        setInternalValue("")
        setIsValidCallback(true)
        setIsValid(true)
        return
      } else {
        setIsValidCallback(false)
        setIsValid(false)
        return
      }
    }
    const roundedNumberString = parsedNumber.toFixed(2)
    const valid = checkIsValid(roundedNumberString, nullAllowed)
    if (valid) {
      setValueCallback(roundedNumberString)
      setInternalValue(roundedNumberString.replace(".", ","))
    }
    setIsValidCallback(valid)
    setIsValid(valid)
  }

  return (
    <TextField
      label={label}
      value={internalValue}
      onChange={(e) => {
        handleChange(e)
      }}
      onBlur={(e) => {
        handleFocusLost(e)
      }}
      fullWidth
      sx={{ marginBottom: marginBottom ? marginBottom : 1 }}
      error={!isValid}
      disabled={disabled}
      InputProps={{
        startAdornment: <InputAdornment position="start">€</InputAdornment>,
      }}
    />
  )
}

export default CurrencyTextField
