import { fetchGQL } from "app/api"
import {
  EmployeeStatsQuery,
  EmployeeStatsQueryVariables,
} from "generated/graphql"
import gql from "graphql-tag"

const EMPLOYEE_STATS = gql`
  query employeeStats($from: String!, $to: String!) {
    employeeStats(in: { from: $from, to: $to }) {
      employeeStatsOutput: lastUpdate
      stats {
        email
        tags
        freshdeskId
        freshdeskName
        askDanteId
        askDanteFirstName
        askDanteLastName
        askDanteRole
        workedCount
        resolvedCount
        closedCount
        chatAssignedCount
        chatResolvedCount
        chatUniqueResolvedCount
        chatMessagesCount
        chatRatings {
          rating
          count
        }
        statsHistory {
          date
          timeTrackingDuration
          conversations {
            agentId
            category
            incoming
            isEmail
            private
            source
            ticketId
            updatedAt
          }
        }
        tickets {
          agentId
          status
          id
          updatedAt
        }
        ratings {
          rating
          updatedAt
        }
      }
    }
  }
`

export const fetchEmployeeStats = async (
  from: string,
  to: string
): Promise<EmployeeStatsQuery | undefined> => {
  const variables: EmployeeStatsQueryVariables = { from, to }
  const data = await fetchGQL<EmployeeStatsQuery, EmployeeStatsQueryVariables>(
    EMPLOYEE_STATS,
    variables
  )
  return data
}
