import { gql } from "graphql-tag"
import { fetchGQL } from "app/api"
import {
  PrintDeliveryNoteQuery,
  PrintDeliveryNoteQueryVariables,
} from "generated/graphql"

const PRINT_DELIVERY_NOTE = gql`
  query printDeliveryNote($in: PrintDeliveryNoteInput!) {
    printDeliveryNote(in: $in)
  }
`

export const printDeliveryNote = async (
  orderID: number,
  printerIpPort: string
): Promise<PrintDeliveryNoteQuery | undefined> => {
  const variables: PrintDeliveryNoteQueryVariables = {
    in: { orderID: orderID, printerIpPort: printerIpPort },
  }
  const data = await fetchGQL<
    PrintDeliveryNoteQuery,
    PrintDeliveryNoteQueryVariables
  >(PRINT_DELIVERY_NOTE, variables)
  return data
}
