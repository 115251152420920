import { fetchGQL } from "app/api"
import {
  RmlShipmentCreateMutation,
  RmlShipmentCreateMutationVariables,
  RmlShipmentInput,
} from "generated/graphql"
import { gql } from "graphql-tag"

const RML_SHIPMENT = gql`
  mutation rmlShipmentCreate($in: RmlShipmentInput!, $printer: String!) {
    rmlShipmentCreate(in: $in, printer: $printer)
  }
`

export const rmlShipmentCreate = async (
  shp: RmlShipmentInput,
  printer: string
): Promise<RmlShipmentCreateMutation | undefined> => {
  const variables: RmlShipmentCreateMutationVariables = { in: shp, printer }
  const data = await fetchGQL<
    RmlShipmentCreateMutation,
    RmlShipmentCreateMutationVariables
  >(RML_SHIPMENT, variables)
  return data
}
