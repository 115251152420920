import { fetchGQL } from "app/api"
import {
  OrderByStatusQuery,
  OrderByStatusQueryVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"

const GET_ORDER_BY_STATUS = gql`
  query orderByStatus($status: String!) {
    orderByStatus(status: $status) {
      orderID
      ewid
      bdb
      botname
      bdb
      count
      supplier {
        id
        name
      }
    }
  }
`

export const fetchOrderByStatus = async (
  s: string
): Promise<OrderByStatusQuery | undefined> => {
  const variables: OrderByStatusQueryVariables = { status: s }
  const data = await fetchGQL<OrderByStatusQuery, OrderByStatusQueryVariables>(
    GET_ORDER_BY_STATUS,
    variables
  )
  return data
}
