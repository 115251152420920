import { fetchGQL } from "app/api"
import {
  OrderChangeMutation,
  OrderChangeMutationVariables,
  OrderItemInput,
} from "generated/graphql"
import { gql } from "graphql-tag"

const ORDER_CHANGE = gql`
  mutation orderChange($in: OrderChangeInput!) {
    orderChange(in: $in)
  }
`

export const fetchOrderChange = async (
  frachtpausch: number,
  custnote: string,
  deliveryAdrId: number,
  notiz: string,
  orderID: string,
  rabatt: number,
  secretnote: string,
  status: number,
  zielDatum: string,
  articles: OrderItemInput[]
): Promise<OrderChangeMutation | undefined> => {
  const variables: OrderChangeMutationVariables = {
    in: {
      Frachtpausch: frachtpausch,
      custnote,
      deliveryAdrId,
      notiz,
      orderID,
      rabatt,
      secretnote,
      status,
      zielDatum,
      articles,
    },
  }
  const data = await fetchGQL<
    OrderChangeMutation,
    OrderChangeMutationVariables
  >(ORDER_CHANGE, variables)

  return data
}
