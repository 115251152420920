import LoadingButton from "@mui/lab/LoadingButton"
import { Autocomplete, TextField, Typography } from "@mui/material"
import Alert from "@mui/material/Alert"
import Container from "@mui/material/Container"
import FormControl from "@mui/material/FormControl"
import Grid from "@mui/material/Grid"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import Textfield from "@mui/material/TextField"
import { DateTimePicker } from "@mui/x-date-pickers"
import { useAppDispatch } from "app/hooks"
import { qSnack } from "app/snackSlice"
import AddressContainer from "components/AddressContainer/AddressContainer"
import ShipmentDetailsContainer from "components/ShipmentDetailsContainer/ShipmentDetailsContainer"
import dayjs, { Dayjs } from "dayjs"
import { Printer, RmlShipmentInput, ShipmentDetails } from "generated/graphql"
import { fetchPrinters } from "queries/fetchPrinters"
import { rmlShipmentCreate } from "queries/fetchRml"
import { rmlLabelPrint } from "queries/fetchRmlLabelPrint"
import { fetchShipmentDetails } from "queries/fetchShipmentDetails"
import React, { useEffect, useRef, useState } from "react"

const numRegex = /^[0-9]*$/
const ipPortRegex = /^[0-9]*\.[0-9]*\.[0-9]*\.[0-9]*:[0-9]*$/
const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

type PaletteInfo = {
  label: string
  KG: number // max weight in KG for this palette is a default value but can be adjusted
  code: string
  length: number // in m
  depth: number // in m
  height: number // in m height is always 1m but not really it takes one cargo slot in the container which has more than 1m height
}

const paletteInfoRML: PaletteInfo[] = [
  {
    label: "Einweg-Palette",
    KG: 150,
    code: "EP",
    length: 1.2,
    depth: 0.8,
    height: 2.0,
  },
  {
    label: "DB-Euro-Flachpalette",
    KG: 50,
    code: "FP",
    length: 0.4,
    depth: 0.6,
    height: 2.0,
  },
  {
    label: "Halbe Palette",
    KG: 75,
    code: "HP",
    length: 0.6,
    depth: 0.8,
    height: 2.0,
  },
  {
    label: "Sack",
    KG: 50,
    code: "SA",
    length: 0.4,
    depth: 0.6,
    height: 1.0,
  },
]

type TermCodeOptions = {
  label: string
  code: string
}

const termCodeOptions: TermCodeOptions[] = [
  { label: "Keiner", code: "00" },
  { label: "unverbindlicher wunsch(bis)", code: "14" },
  { label: "Speedt.NextDay 8 Uhr (DE)", code: "80" },
  { label: "Speedt.NextDay 10 Uhr (DE)", code: "81" },
  { label: "Speedt.NextDay 12 Uhr (DE)", code: "82" },
  { label: "Speedtime Next Day (DE)", code: "83" },
  { label: "FixDay (DE)", code: "84" },
  { label: "FixDay 8:00 Uhr (DE)", code: "88" },
  { label: "FixDay 10:00 Uhr (DE)", code: "89" },
  { label: "FixDay 12:00 Uhr (DE)", code: "90" },
]

const isDateEnabled = (code: string) => {
  return (
    code === "14" ||
    code === "83" ||
    code === "84" ||
    code === "88" ||
    code === "89" ||
    code === "90"
  )
}

//TELE_TARGET           DK_N = "01" //01 telef. anvisieren
//DRIVING_TEL           DK_N = "02" //02 Fahreavis / Tel.-Nr
//SDG_AVAILABLE         DK_N = "06" //06 Sdg. Zur Verfpg. Fa.
//SDG_LYING_TANSPORT    DK_N = "21" //21 Sdg. liegen transp.
//SELF_COLLECT          DK_N = "25" //25 Empf. ist Seblstabh.
//ONLY_WITH_LIFT        DK_N = "51" //51 nur mit Hebelbühne z...
//DELICATES_GOODS       DK_N = "61" //61 Empfindliche Ware
//RECEIPT_LOTS_PUT_DOWN DK_N = "63" //63 Quittiungslose Abstell.

type RmlHinweis = {
  code: string
  label: string
}

const rmlHinweise: RmlHinweis[] = [
  { code: "", label: "Kein Hinweis" },
  { code: "01", label: "telef. anvisieren" },
  { code: "02", label: "Fahreavis / Tel.-Nr" },
  { code: "06", label: "Sdg. Zur Verfpg. Fa." },
  { code: "21", label: "Sdg. liegen transp." },
  { code: "25", label: "Empf. ist Selbstabh." },
  { code: "51", label: "nur mit Hebebühne zustellbar" },
  { code: "61", label: "Empfindliche Ware" },
  { code: "63", label: "Quittiungslose Abstell." },
]

const getTargetDateFromTermCode = (termCode: TermCodeOptions) => {
  const trg = dayjs()
  switch (termCode.code) {
    case "80":
      return trg.add(1, "day").hour(8).minute(0)
    case "81":
      return trg.add(1, "day").hour(10).minute(0)
    case "82":
      return trg.add(1, "day").hour(12).minute(0)
    case "83":
      return trg.add(1, "day").hour(6).minute(0)
    case "88":
      return trg.hour(8).minute(0)
    case "89":
      return trg.hour(10).minute(0)
    case "90":
      return trg.hour(12).minute(0)
    default:
      return trg
  }
}

interface RmlHinweisProps {
  n: number
  hinweis: RmlHinweis | null
  setHinweis: (hinweis: RmlHinweis | null) => void
  hinweisInfo: string
  setHinweisInfo: (info: string) => void
}

const RmlHinweisSelect = (props: RmlHinweisProps) => {
  const { hinweis, setHinweis, hinweisInfo, setHinweisInfo, n } = props
  const hinweisId = "hinweisSelect" + n.toString()
  const hinweisLabel = "Hinweis " + n.toString()
  return (
    <>
      <FormControl fullWidth sx={{ marginBottom: 1 }}>
        <InputLabel id={hinweisId}>{hinweisLabel}</InputLabel>
        <Select
          labelId={hinweisId}
          id={hinweisId}
          value={hinweis?.code || ""}
          label={hinweisLabel}
          onChange={(event) => {
            const code = event.target.value as string
            const termCode = rmlHinweise.find((option) => option.code === code)
            if (termCode) {
              setHinweis(termCode)
            }
          }}
        >
          {rmlHinweise.map((option) => (
            <MenuItem key={option.code} value={option.code}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Textfield
        fullWidth
        variant="outlined"
        label={hinweisLabel + " Info"}
        value={hinweisInfo}
        onChange={(event) => {
          setHinweisInfo(event.target.value)
        }}
      />
    </>
  )
}

const Rml = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const [orderID, setOrderID] = useState("")
  const [printers, setPrinters] = useState<Printer[]>([])
  const [selectedTermCode, setSelectedTermCode] = useState<TermCodeOptions>(
    termCodeOptions[0]
  )
  const [goodDescription, setGoodDescription] = useState("Pflanzen")
  const [hinweis1, setHinweis1] = useState<RmlHinweis | null>(rmlHinweise[7])
  const [hinweisInfo1, setHinweisInfo1] = useState("")
  const [hinweis2, setHinweis2] = useState<RmlHinweis | null>(null)
  const [hinweisInfo2, setHinweisInfo2] = useState("")
  const [hinweis3, setHinweis3] = useState<RmlHinweis | null>(null)
  const [hinweisInfo3, setHinweisInfo3] = useState("")
  const [hinweis4, setHinweis4] = useState<RmlHinweis | null>(null)
  const [hinweisInfo4, setHinweisInfo4] = useState("")
  const [hinweis5, setHinweis5] = useState<RmlHinweis | null>(null)
  const [hinweisInfo5, setHinweisInfo5] = useState("")
  const [targetDate1, setTargetDate1] = useState<Dayjs | null>(null)
  const [selectedPrinter, setSelectedPrinter] = useState<Printer | null>(null)
  const [email, setEmail] = useState("")
  const [comment, setComment] = useState("")
  const [docID, setDocID] = useState("")
  const [phone, setPhone] = useState("")
  const [shipmentDetails, setShipmentDetails] =
    useState<ShipmentDetails | null>(null)

  const orderIDref = useRef<HTMLInputElement | null>(null)
  const [selectedPalette, setSelectedPalette] = useState<PaletteInfo>(
    paletteInfoRML[0]
  )

  const handleOrderID = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrderID(event.target.value)
  }

  const handleOrderIDCommit = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      //check if orderID is a number
      if (!numRegex.test(orderID)) {
        dispatch(
          qSnack({
            msg: "Bitte eine gültige Auftragsnummer eingeben nur Zahlen erlaubt",
            severity: "error",
          })
        )
        return
      }

      setShipmentDetails(null)
      setSelectedPalette(paletteInfoRML[0])
      setDocID("")
      setEmail("")
      setPhone("")
      setHinweis1(rmlHinweise[7])
      setHinweis2(null)
      setHinweis3(null)
      setHinweis4(null)
      setHinweis5(null)
      setHinweisInfo1("")
      setHinweisInfo2("")
      setHinweisInfo3("")
      setHinweisInfo4("")
      setHinweisInfo5("")
      setComment("")
      setTargetDate1(null)
      setGoodDescription("Pflanzen")
      fetchShipmentDetails(orderID).then((data) => {
        if (data?.shipmentDetails) {
          if (data.shipmentDetails.msg.length > 0) {
            dispatch(
              qSnack({
                msg: data.shipmentDetails.msg.join(","),
                severity: "warning",
              })
            )
          }
          if (data.shipmentDetails.orderDetails.orderID > 0) {
            //update hintsMap first entry
            setShipmentDetails(data.shipmentDetails)
            setEmail(data.shipmentDetails.custEmail)
            setPhone(data.shipmentDetails.custMobile)
          }
        }
      })
    }
  }

  const [isLoading, setIsLoading] = useState(false)

  const handleOrderCreate = async () => {
    // check if selectedPrinter is set
    if (!selectedPrinter) {
      dispatch(
        qSnack({
          msg: "Bitte Drucker auswählen",
          severity: "info",
        })
      )
      return
    }
    if (!shipmentDetails) {
      dispatch(
        qSnack({
          msg: "Bitte Auftragsnummer eingeben",
          severity: "info",
        })
      )
      return
    }

    if (docID !== "") {
      rmlLabelPrint(
        selectedPrinter.printerIP + ":" + selectedPrinter.printerPort,
        docID
      ).then((data) => {
        if (data?.rmlLabelPrint) {
          dispatch(
            qSnack({
              msg: "Label an den Drucker gesendet, wenn nichts passiert bitte Drucker überprüfen.",
              severity: "success",
            })
          )
        }
      })
      return
    }

    setIsLoading(true)
    const printerIPPort =
      selectedPrinter.printerIP + ":" + selectedPrinter.printerPort
    if (!printerIPPort.match(ipPortRegex)) {
      qSnack({ msg: "Drucker IP:Port Format ist falsch", severity: "error" })
      return
    }

    const shp: RmlShipmentInput = {
      orderID: orderID,
      deliveryAdrId: shipmentDetails.addressDetails.id,
      comment: comment,
      hinweise: [],
      orderGoodDescription: goodDescription,
      pallet: selectedPalette.code,
      termcode: selectedTermCode.code,
      termdata: targetDate1 ? targetDate1.format("YYYYMMDDHHmm") : "",
      weightGramm: Math.round(selectedPalette.KG * 1000),
      widthMM: Math.round(selectedPalette.depth * 1000),
      lengthMM: Math.round(selectedPalette.length * 1000),
      heightMM: Math.round(selectedPalette.height * 1000),
    }
    if (hinweis1 && hinweis1.code !== "") {
      shp.hinweise.push({ code: hinweis1.code, info: hinweisInfo1 })
    }
    if (hinweis2 && hinweis2.code !== "") {
      shp.hinweise.push({ code: hinweis2.code, info: hinweisInfo2 })
    }
    if (hinweis3 && hinweis3.code !== "") {
      shp.hinweise.push({ code: hinweis3.code, info: hinweisInfo3 })
    }
    if (hinweis4 && hinweis4.code !== "") {
      shp.hinweise.push({ code: hinweis4.code, info: hinweisInfo4 })
    }
    if (hinweis5 && hinweis5.code !== "") {
      shp.hinweise.push({ code: hinweis5.code, info: hinweisInfo5 })
    }

    rmlShipmentCreate(shp, printerIPPort)
      .then((data) => {
        if (data?.rmlShipmentCreate) {
          setDocID(data.rmlShipmentCreate)
          dispatch(
            qSnack({
              msg: "Druckauftrag erstellt",
              severity: "success",
            })
          )
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const isValidEmail = emailRegex.test(email)

  useEffect(() => {
    if (orderIDref.current) {
      orderIDref.current.focus()
    }
    // load printer from server
    fetchPrinters().then((data) => {
      if (data?.printers) {
        setPrinters(data.printers)
        const sp = localStorage.getItem("selectedPrinter")
        if (sp) {
          const printer = data.printers.find(
            (printer) => printer.printerID === Number(sp)
          )
          if (printer) {
            setSelectedPrinter(printer)
          }
        }
      }
    })
  }, [])

  return (
    <Container maxWidth="xl" sx={{ marginTop: "2.5%", marginBottom: "2.5%" }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4" component="h1" gutterBottom>
            Robert Müller Logistik
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Textfield
            variant="outlined"
            value={orderID}
            onChange={handleOrderID}
            onKeyUp={handleOrderIDCommit}
            inputRef={orderIDref}
            label="Bestell ID"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <InputLabel id="printer-select-label">Drucker</InputLabel>
            <Select
              labelId="printer-select-label"
              id="printer-select"
              value={selectedPrinter?.printerID || ""}
              label="Drucker"
              onChange={(event) => {
                const printerID = event.target.value as number
                const printer = printers.find(
                  (printer) => printer.printerID === printerID
                )
                setSelectedPrinter(printer || null)
                localStorage.setItem("selectedPrinter", printerID.toString())
              }}
            >
              {printers.map((printer) => (
                <MenuItem key={printer.printerID} value={printer.printerID}>
                  {printer.printerID} - {printer.printerIP}:
                  {printer.printerPort}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <LoadingButton
            variant="contained"
            sx={{ height: "100%" }}
            fullWidth
            disabled={
              shipmentDetails === null ||
              !isValidEmail ||
              shipmentDetails?.permitToPlacePackage === false
            }
            onClick={handleOrderCreate}
            loading={isLoading}
          >
            {docID !== "" ? "DRUCKEN" : "ERSTELLEN & DRUCKEN"}
          </LoadingButton>
        </Grid>
        <Grid item xs={12}>
          <Alert severity="info">
            Bitte hier einen ZPL Zebra Drucker auswählen andere Drucker werden
            hier nicht funktionieren.
          </Alert>
        </Grid>
        {shipmentDetails && (
          <Grid item xs={12}>
            <Alert
              severity={
                shipmentDetails?.permitToPlacePackage ? "warning" : "error"
              }
            >
              Abstellgenehmigung:{" "}
              {shipmentDetails?.permitToPlacePackage ? "Ja" : "Nein"}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          {shipmentDetails && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <AddressContainer adr={shipmentDetails.addressDetails} />
              </Grid>
              <Grid item xs={12}>
                <ShipmentDetailsContainer shipmentDetails={shipmentDetails} />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          {shipmentDetails && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  value={selectedPalette}
                  onChange={(_, newValue) => {
                    if (newValue) setSelectedPalette(newValue)
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.code === value.code
                  }
                  disablePortal
                  id="combo-box-demo"
                  fullWidth
                  options={paletteInfoRML}
                  renderInput={(params) => (
                    <TextField {...params} label="Palette" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Textfield
                  fullWidth
                  variant="outlined"
                  label="Gewicht"
                  InputProps={{
                    endAdornment: "KG",
                  }}
                  value={selectedPalette.KG}
                  onChange={(event) => {
                    const kgFloatRegex = /^[0-9]*\.?[0-9]*$/
                    const value = event.target.value
                    if (kgFloatRegex.test(value))
                      setSelectedPalette({
                        ...selectedPalette,
                        KG: Number(value),
                      })
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Alert severity="info">
                  {selectedPalette.label} {selectedPalette.length * 100}x
                  {selectedPalette.depth * 100}x{selectedPalette.height * 100}{" "}
                  (in CM)
                </Alert>
              </Grid>

              <Grid item xs={12}>
                <Textfield
                  fullWidth
                  variant="outlined"
                  label="Kunde Email"
                  error={!isValidEmail}
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value)
                  }}
                />
                {!isValidEmail && (
                  <Alert severity="error">Bitte Kunde-Email eintragen.</Alert>
                )}
              </Grid>
              <Grid item xs={12}>
                <Textfield
                  fullWidth
                  variant="outlined"
                  label="Kunde Telefon"
                  error={phone === ""}
                  value={phone}
                  onChange={(event) => {
                    setPhone(event.target.value)
                  }}
                />
                {phone === "" && (
                  <Alert severity="error">
                    Bitte Telefonnummer eintragen wenn Avisierung gewählt wurde.
                  </Alert>
                )}
              </Grid>
              <Grid item xs={12}>
                <Textfield
                  fullWidth
                  variant="outlined"
                  label="Liefergut Beschreibung"
                  value={goodDescription}
                  onChange={(event) => {
                    setGoodDescription(event.target.value)
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Textfield
                  fullWidth
                  variant="outlined"
                  label="Kommentar"
                  value={comment}
                  onChange={(event) => {
                    setComment(event.target.value)
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        {shipmentDetails && (
          <Grid item xs={12} md={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="deliveryType">Lieferservice</InputLabel>
                  <Select
                    labelId="deliveryType"
                    id="deliveryType"
                    value={selectedTermCode.code}
                    label="Lieferservice"
                    onChange={(event) => {
                      const code = event.target.value as string
                      const termCode = termCodeOptions.find(
                        (option) => option.code === code
                      )
                      if (termCode) {
                        const trg = getTargetDateFromTermCode(termCode)
                        setSelectedTermCode(termCode)
                        setTargetDate1(trg)
                      }
                    }}
                  >
                    {termCodeOptions.map((option) => (
                      <MenuItem key={option.code} value={option.code}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {selectedTermCode.code !== "00" && (
                <Grid item xs={12}>
                  <DateTimePicker
                    value={targetDate1}
                    disablePast
                    disabled={!isDateEnabled(selectedTermCode.code)}
                    onChange={(date) => {
                      setTargetDate1(date)
                    }}
                    label="Zieldatum"
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <RmlHinweisSelect
                  hinweis={hinweis1}
                  setHinweis={setHinweis1}
                  hinweisInfo={hinweisInfo1}
                  setHinweisInfo={setHinweisInfo1}
                  n={1}
                />
              </Grid>
              <Grid item xs={12}>
                <RmlHinweisSelect
                  hinweis={hinweis2}
                  setHinweis={setHinweis2}
                  hinweisInfo={hinweisInfo2}
                  setHinweisInfo={setHinweisInfo2}
                  n={2}
                />
              </Grid>
              <Grid item xs={12}>
                <RmlHinweisSelect
                  hinweis={hinweis3}
                  setHinweis={setHinweis3}
                  hinweisInfo={hinweisInfo3}
                  setHinweisInfo={setHinweisInfo3}
                  n={3}
                />
              </Grid>

              <Grid item xs={12}>
                <RmlHinweisSelect
                  hinweis={hinweis4}
                  setHinweis={setHinweis4}
                  hinweisInfo={hinweisInfo4}
                  setHinweisInfo={setHinweisInfo4}
                  n={4}
                />
              </Grid>
              <Grid item xs={12}>
                <RmlHinweisSelect
                  hinweis={hinweis5}
                  setHinweis={setHinweis5}
                  hinweisInfo={hinweisInfo5}
                  setHinweisInfo={setHinweisInfo5}
                  n={5}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Container>
  )
}

export default Rml
