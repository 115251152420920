// constants for privileges bit mask max 32 int
export const ADMIN = 1
export const TAG = 2
export const DISTRIBUTE = 4
export const DISPATCH = 8
export const SPLIT = 16
export const DEACTIVATED = 32
export const STATISTIC = 64
export const ROUNDS = 128
export const BASE_DATA = 256
export const SHIPPING_STATISTIC = 512
export const SHIPPING_CREATE = 1024
export const CUSTOMER_SUPPORT = 2048

export const hasPrivilege = (
  privileges: number,
  privilege: number
): boolean => {
  return (privileges & privilege) === privilege
}
