import { fetchGQL } from "app/api"
import {
  SupplierRoundNoteQuery,
  SupplierRoundNoteQueryVariables,
  SupplierRoundNoteSaveMutation,
  SupplierRoundNoteSaveMutationVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"

const SUPPLIER_ROUND_NOTE = gql`
  query supplierRoundNote($round: Int!) {
    supplierRoundNotes(round: $round) {
      supplierId
      note
    }
  }
`

export const supplierRoundNoteGet = async (
  round: number
): Promise<SupplierRoundNoteQuery | undefined> => {
  const variables: SupplierRoundNoteQueryVariables = { round }
  const data = await fetchGQL<
    SupplierRoundNoteQuery,
    SupplierRoundNoteQueryVariables
  >(SUPPLIER_ROUND_NOTE, variables)
  return data
}

const SUPPLIER_ROUND_NOTE_SAVE = gql`
  mutation supplierRoundNoteSave(
    $round: Int!
    $supplierID: Int!
    $note: String!
  ) {
    supplierRoundNoteSave(round: $round, supplierID: $supplierID, note: $note)
  }
`

export const supplierRoundNoteSave = async (
  round: number,
  supplierID: number,
  note: string
): Promise<SupplierRoundNoteSaveMutation | undefined> => {
  const variables: SupplierRoundNoteSaveMutationVariables = {
    round,
    supplierID,
    note,
  }
  const data = await fetchGQL<
    SupplierRoundNoteSaveMutation,
    SupplierRoundNoteSaveMutationVariables
  >(SUPPLIER_ROUND_NOTE_SAVE, variables)
  return data
}
