import { Box, Button } from "@mui/material"
import Grid from "@mui/material/Grid"
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro"
import { useAppDispatch } from "app/hooks"
import { qSnack } from "app/snackSlice"
import { CollapsibleTable } from "components/CollapsibleTable/CollapsibleTable"
import DialogPDF from "components/DialogPDF/DialogPDF"
import LinkRenderer from "components/LinkRenderer/LinkRenderer"
import { dataGridDEde } from "constants/dataGridLocale"
import { PackageStat, Printer } from "generated/graphql"
import { fetchPDFBase64 } from "queries/downloadPDF"
import { fetchPrinters } from "queries/fetchPrinters"
import { rabenLabelPrint } from "queries/fetchRaben"
import { rsLabelPrint } from "queries/printRsLabel"
import { shipcloudLabelPrint } from "queries/shipcloudLabel"
import { useEffect, useState } from "react"
import { germanDateFormatter } from "utils/datefmt"

type OrderPackageStatusProps = {
  packages: PackageStat[]
}

export const OrderPackageStatus = (props: OrderPackageStatusProps) => {
  const { packages } = { ...props }
  const dispatch = useAppDispatch()
  const [pdfDialogIsOpen, setPdfDialogIsOpen] = useState(false)
  const [pdfURL, setPdfURL] = useState("")
  const [kind, setKind] = useState("")
  const [pdfBlob, setPdfBlob] = useState<string>("")
  const [printerList, setPrinterList] = useState<Printer[]>([])

  const handleClosePdfDialog = () => {
    setPdfDialogIsOpen(false)
  }

  const handleOpenPdfDialog = async (url: string, carrier: string) => {
    setKind(carrier)
    setPdfURL(url)
    fetchPDFBase64(url, carrier).then((res) => {
      if (res?.downloadPdf) {
        setPdfBlob(res.downloadPdf)
        setPdfDialogIsOpen(true)
      } else {
        dispatch(
          qSnack({
            msg: "Fehler beim Laden des PDFs",
            severity: "error",
          })
        )
      }
    })
  }

  const handlePrint = (printerIpPort: string) => {
    if (kind === "RS Logistik") {
      rsLabelPrint(pdfURL, printerIpPort).then((data) => {
        if (data?.printRsLabel) {
          dispatch(
            qSnack({
              msg: "Label an den Drucker gesendet, wenn nichts passiert bitte Drucker überprüfen.",
              severity: "success",
            })
          )
        }
      })
    } else if (kind === "Raben Group") {
      rabenLabelPrint(printerIpPort, pdfURL).then((data) => {
        if (data?.rabenLabelPrint) {
          dispatch(
            qSnack({
              msg: "Label an den Drucker gesendet, wenn nichts passiert bitte Drucker überprüfen.",
              severity: "success",
            })
          )
        }
      })
    } else {
      shipcloudLabelPrint(printerIpPort, pdfURL).then((data) => {
        if (data?.printShipcloudLabel) {
          dispatch(
            qSnack({
              msg: "Label an den Drucker gesendet, wenn nichts passiert bitte Drucker überprüfen.",
              severity: "success",
            })
          )
        }
      })
    }
  }

  useEffect(() => {
    fetchPrinters().then((res) => {
      if (res?.printers) {
        setPrinterList(res.printers)
      }
    })
  }, [])

  const columns: GridColDef[] = [
    {
      field: "orderID",
      headerName: "Bestellnummer",
      width: 150,
      editable: false,
    },
    {
      field: "createdAt",
      headerName: "Datum",
      width: 200,
      editable: false,
      renderCell(params) {
        const date = new Date(params.value)
        return germanDateFormatter.format(date)
      },
    },
    {
      field: "ps",
      headerName: "PS",
      width: 250,
      editable: false,
    },
    {
      field: "carrier",
      headerName: "Carrier",
      width: 150,
      editable: false,
    },

    {
      field: "trackingURL",
      headerName: "Tracking Link",
      width: 150,
      editable: false,
      renderCell: (params) => <LinkRenderer {...params} />,
    },
    {
      field: "labelURL",
      headerName: "PDF",
      width: 150,
      editable: false,
      renderCell(params) {
        const { row } = params
        let url = row.labelURL
        if (row.carrier === "Raben Group") {
          //package id in case of raben group
          url = row.ps
        }
        return (
          <>
            <Button
              variant="contained"
              color="primary"
              disabled={url === ""}
              onClick={() => {
                handleOpenPdfDialog(url as string, row.carrier)
              }}
            >
              PDF
            </Button>
          </>
        )
      },
    },
  ]

  return (
    <Box>
      <CollapsibleTable
        defaultOpen={true}
        title={`Paketstatus (${packages.length})`}
      >
        <Grid container>
          <Grid item xs={12}>
            <Box sx={{ height: 500 }}>
              <DataGridPro
                rows={packages}
                columns={columns}
                localeText={dataGridDEde}
                getRowId={(item) => item.id}
                sortingOrder={["desc", "asc"]}
                rowSelection={false}
              />
            </Box>
          </Grid>
        </Grid>
      </CollapsibleTable>

      <DialogPDF
        isOpen={pdfDialogIsOpen}
        onClose={handleClosePdfDialog}
        pdfB64={pdfBlob}
        pl={printerList}
        onPrint={handlePrint}
      />
    </Box>
  )
}
