import { fetchGQL } from "app/api"
import {
  OrderSplitMutation,
  OrderSplitMutationVariables,
  SplitItemInput,
} from "generated/graphql"
import { gql } from "graphql-tag"

const ORDER_SPLIT = gql`
  mutation orderSplit($in: OrderSplitInput!) {
    orderSplit(in: $in)
  }
`

export const fetchOrderSplit = async (
  targetStatus: number,
  mergeStatuses: number[],
  items: SplitItemInput[]
): Promise<OrderSplitMutation | undefined> => {
  const variables: OrderSplitMutationVariables = {
    in: {
      targetStatus,
      mergeStatuses,
      items,
    },
  }
  const data = await fetchGQL<OrderSplitMutation, OrderSplitMutationVariables>(
    ORDER_SPLIT,
    variables
  )

  return data
}
