import { Button, Container, Grid, TextField, Typography } from "@mui/material"
import { Box } from "@mui/system"
import happyPlant from "animations/happyPlant.json"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { qSnack } from "app/snackSlice"
import { selectUser, userLogin } from "app/userSlice"
import { useLottie } from "lottie-react"
import { useEffect, useState } from "react"

const LoginPage = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const us = useAppSelector(selectUser)
  const options = {
    animationData: happyPlant,
    loop: true,
  }
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const handleEnterLogin = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && username.length > 0 && password.length > 0) {
      dispatch(
        userLogin({
          username,
          password,
        })
      )
    }
  }

  const { View } = useLottie(options)

  useEffect(() => {
    if (us.status === "failed") {
      dispatch(
        qSnack({
          msg: "Beim Einloggen ist etwas schiefgelaufen.",
          severity: "warning",
        })
      )
    }
    if (us.status === "idle" && us.token !== "") {
      setUsername("")
      setPassword("")
    }
  }, [us, dispatch, setUsername, setPassword])

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography sx={{ textAlign: "center", fontSize: 48 }}>
              PFLANZMICH INTRANET
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: 400,
                  height: 400,
                }}
              >
                {View}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Benutzername eingeben"
              fullWidth
              value={username}
              onKeyUp={handleEnterLogin}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Passwort eingeben"
              fullWidth
              value={password}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              onKeyUp={handleEnterLogin}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              disabled={username.length === 0 || password.length === 0}
              onClick={() => {
                dispatch(
                  userLogin({
                    username,
                    password,
                  })
                )
              }}
            >
              Einloggen
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default LoginPage
