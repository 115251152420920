import {
  ShipmentDetailsQuery,
  ShipmentDetailsQueryVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"
import { fetchGQL } from "app/api"

const SHIPMENT_DETAILS_GET = gql`
  query shipmentDetails($oid: String!) {
    shipmentDetails(orderID: $oid) {
      orderDetails {
        orderID
        customerID
        customerEmail
        customerPhone
        paymentMethod
        targetShipDate
        note
        secretNote
        statusID
        invoiceSum
      }
      addressDetails {
        id
        company
        fullName
        street
        zipCode
        city
        country
        countryCode
        anrede
      }
      msg
      custPhone
      custEmail
      custMobile
      payOnDelivery
      permitToPlacePackage
      pdfURL
    }
  }
`

export const fetchShipmentDetails = async (
  orderID: string
): Promise<ShipmentDetailsQuery | undefined> => {
  const variables: ShipmentDetailsQueryVariables = { oid: orderID }
  const data = await fetchGQL<
    ShipmentDetailsQuery,
    ShipmentDetailsQueryVariables
  >(SHIPMENT_DETAILS_GET, variables)
  return data
}
