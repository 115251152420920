import Container from "@mui/material/Container"
import Textfield from "@mui/material/TextField"
import Grid from "@mui/material/Grid"
import Alert from "@mui/material/Alert"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import InputLabel from "@mui/material/InputLabel"
import FormControl from "@mui/material/FormControl"
import Link from "@mui/material/Link"
import React, { useState, useRef, useEffect } from "react"
import { fetchShipmentDetails } from "queries/fetchShipmentDetails"
import { rsShipmentCreate } from "queries/fetchRs"
import { rsLabelPrint } from "queries/printRsLabel"
import { fetchPrinters } from "queries/fetchPrinters"
import {
  ShipmentDetails,
  Printer,
  NoteInput,
  RsShipmentInput,
} from "generated/graphql"
import { qSnack } from "app/snackSlice"
import { useAppDispatch } from "app/hooks"
import AddressContainer from "components/AddressContainer/AddressContainer"
import ShipmentDetailsContainer from "components/ShipmentDetailsContainer/ShipmentDetailsContainer"
import HintSelector, { Hint } from "components/HintSelector/HintSelector"
import LoadingButton from "@mui/lab/LoadingButton"
import dayjs from "dayjs"
import { Typography } from "@mui/material"

const initalHintOptions: Hint[] = [
  {
    hintID: "001",
    hint: "",
    hintLabel: "Main Hinweis",
    hasAdditionalInfo: false,
    additionalInfo: "",
  },
  {
    hintID: "101",
    hint: "101: Sendung bitte avisieren unter Tel.-Nr.:",
    hintLabel: "",
    hasAdditionalInfo: true,
    additionalInfo: "",
  },
  {
    hintID: "103",
    hint: "103: ACHTUNG Zollgut.:",
    hintLabel: "",
    hasAdditionalInfo: true,
    additionalInfo: "Versenderklärung aus Handelsrechnung",
  },
  {
    hintID: "107",
    hint: "107: Zustellung unbedingt mit Hebebuehnen LKW",
    hintLabel: "",
    hasAdditionalInfo: false,
    additionalInfo: "",
  },
  {
    hintID: "109",
    hint: "109: Empfindliche Ware - vorsichtig behandelt",
    hintLabel: "",
    hasAdditionalInfo: false,
    additionalInfo: "",
  },
  {
    hintID: "202",
    hint: "202: Termindienst! Auslieferung spaetestens am (nicht Eingangstag):",
    hintLabel: "",
    hasAdditionalInfo: true,
    additionalInfo: "",
  },
  {
    hintID: "204",
    hint: "204: Termingut! Unbedingt zustellen in KW:",
    hintLabel: "",
    hasAdditionalInfo: true,
    additionalInfo: "",
  },
  {
    hintID: "250",
    hint: "250: Flash Tag (Zustellung am Eingangstag von 08:00-16:00 Uhr)",
    hintLabel: "",
    hasAdditionalInfo: false,
    additionalInfo: "",
  },
  {
    hintID: "255",
    hint: "255: Flash 10  (Zustellung am Eingangstag von 08:00-10:00 Uhr)",
    hintLabel: "",
    hasAdditionalInfo: false,
    additionalInfo: "",
  },
  {
    hintID: "256",
    hint: "256: Flash 11  (Zustellung am Eingangstag von 08:00-11:00 Uhr)",
    hintLabel: "",
    hasAdditionalInfo: false,
    additionalInfo: "",
  },
  {
    hintID: "257",
    hint: "257: Flash 12  (Zustellung am Eingangstag von 08:00-12:00 Uhr)",
    hintLabel: "",
    hasAdditionalInfo: false,
    additionalInfo: "",
  },
  {
    hintID: "258",
    hint: "258: Flash 14  (Zustellung am Eingangstag von 08:00-14:00 Uhr)",
    hintLabel: "",
    hasAdditionalInfo: false,
    additionalInfo: "",
  },
  {
    hintID: "270",
    hint: "270: Fixtermin! Nicht frueher oder spaeter - am:",
    hintLabel: "",
    hasAdditionalInfo: true,
    additionalInfo: "",
  },
  {
    hintID: "402",
    hint: "402: Achtung Nachnahme, nur gegen bar ausliefern",
    hintLabel: "",
    hasAdditionalInfo: false,
    additionalInfo: "",
  },
]

type HintOrNull = Hint | null

const numRegex = /^[0-9]*$/
const parseNum = (num: string): number => {
  if (num === "") {
    return 0
  }
  if (numRegex.test(num)) {
    return parseInt(num)
  } else {
    return 0
  }
}

const RsLogisticPage = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const [orderID, setOrderID] = useState("")
  const [printers, setPrinters] = useState<Printer[]>([])
  const [selectedPrinter, setSelectedPrinter] = useState<Printer | null>(null)
  const [shipmentDetails, setShipmentDetails] =
    useState<ShipmentDetails | null>(null)

  const [hintOptions, sethintOptions] = useState<Hint[]>(initalHintOptions)

  const [selectedHints, setSelectedHints] = useState<HintOrNull[]>([
    initalHintOptions[0],
    initalHintOptions[3],
    initalHintOptions[4],
    null,
    null,
  ])

  const orderIDref = useRef<HTMLInputElement | null>(null)
  const [GP, setGP] = useState("")
  const [HP, setHP] = useState("")
  const [SP, setSP] = useState("")
  const [VP, setVP] = useState("")

  const handleGP = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGP(event.target.value)
  }
  const handleHP = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHP(event.target.value)
  }
  const handleSP = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSP(event.target.value)
  }
  const handleVP = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVP(event.target.value)
  }

  const handleOrderID = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrderID(event.target.value)
  }

  const handleOrderIDCommit = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      //check if orderID is a number
      if (!numRegex.test(orderID)) {
        dispatch(
          qSnack({
            msg: "Bitte eine gültige Auftragsnummer eingeben nur Zahlen erlaubt",
            severity: "error",
          })
        )
        return
      }

      //reset current shipment details
      setShipmentDetails(null)
      fetchShipmentDetails(orderID).then((data) => {
        if (data?.shipmentDetails) {
          if (data.shipmentDetails.msg.length > 0) {
            dispatch(
              qSnack({
                msg: data.shipmentDetails.msg.join(","),
                severity: "warning",
              })
            )
          }
          if (data.shipmentDetails.orderDetails.orderID > 0) {
            //update hintsMap first entry
            sethintOptions(() => {
              const newHints = [...initalHintOptions]
              newHints[0].hint = data.shipmentDetails.custPhone
              return newHints
            })
            setSelectedHints(() => {
              const newHints = [
                initalHintOptions[0],
                initalHintOptions[3],
                initalHintOptions[4],
                null,
                null,
              ]

              return newHints
            })
            setGP("")
            setHP("")
            setSP("")
            setVP("")
            setShipmentDetails(data.shipmentDetails)
          }
        }
      })
    }
  }

  const handleHintChange = (hint: Hint | null, index: number): void => {
    setSelectedHints((prev) => {
      const newHints = [...prev]
      newHints[index] = hint
      return newHints
    })
  }

  const parseHints = (hint: HintOrNull[]): NoteInput[] | null => {
    const notes: NoteInput[] = []
    for (let i = 0; i < hint.length; i++) {
      const item = hint[i]
      if (i > 0 && item !== null) {
        // validate hint according to hintID and additionalInfo convert to hint request data
        const note = validateHint(item)
        if (note === null) {
          return null
        }
        if (note) notes.push(note)
      }
    }
    return notes
  }

  const validateHint = (item: Hint): NoteInput | null => {
    const dateHintIDs = ["202", "270"]
    const numHintIDs = ["101", "204"]
    if (dateHintIDs.includes(item.hintID)) {
      const parsedDate = dayjs(item.additionalInfo, "DD.MM.YYYY")
      const formattedDate = parsedDate.format("YYYYMMDD")
      if (formattedDate === "Invalid Date") {
        dispatch(
          qSnack({
            msg: `Datum eingabe ist ungültig: ${item.hint} Datumformat: 31.12.1999`,
            severity: "error",
          })
        )
        return null
      }
      // check if date is in the past
      if (parsedDate.isBefore(dayjs(), "day")) {
        dispatch(
          qSnack({
            msg: `Datum eingabe ist ungültig: ${item.hint} Datum ist in der Vergangenheit`,
            severity: "error",
          })
        )
        return null
      }

      return {
        nr: parseNum(item.hintID),
        txt: formattedDate,
      }
    }

    if (numHintIDs.includes(item.hintID)) {
      const parsedNum = parseNum(item.additionalInfo)
      return {
        nr: parseNum(item.hintID),
        txt: parsedNum.toString(),
      }
    }

    return {
      nr: parseNum(item.hintID),
      txt: item.hasAdditionalInfo ? item.additionalInfo : "",
    }
  }

  const [isLoading, setIsLoading] = useState(false)
  const handleOrderCreate = () => {
    // check if selectedPrinter is set
    if (!selectedPrinter) {
      dispatch(
        qSnack({
          msg: "Bitte Drucker auswählen",
          severity: "info",
        })
      )
      return
    }
    if (!shipmentDetails) {
      dispatch(
        qSnack({
          msg: "Bitte Auftragsnummer eingeben",
          severity: "info",
        })
      )
      return
    }
    if (shipmentDetails.pdfURL !== "") {
      rsLabelPrint(
        shipmentDetails.pdfURL,
        selectedPrinter.printerIP + ":" + selectedPrinter.printerPort
      ).then((data) => {
        if (data?.printRsLabel) {
          dispatch(
            qSnack({
              msg: "Label an den Drucker gesendet, wenn nichts passiert bitte Drucker überprüfen.",
              severity: "success",
            })
          )
        }
      })
      return
    }
    // create rs shipment from user data
    // parse HP, GP, SP
    // check if HP, GP, SP are numbers
    // VP == viertel palette == 1/4 palette
    const gp = parseNum(GP)
    const hp = parseNum(HP)
    const sp = parseNum(SP)
    const vp = parseNum(VP)

    if (hp === 0 && gp === 0 && sp === 0 && vp === 0) {
      dispatch(
        qSnack({
          msg: "Bitte mindestens eine Pallette angeben (GP, HP, SP, VP)",
          severity: "error",
        })
      )
      return
    }
    // parse all hints and see if valid
    const reqHints = parseHints(selectedHints)
    if (reqHints) {
      const placePackage = shipmentDetails
        ? shipmentDetails.permitToPlacePackage
        : false
      const mainNote = selectedHints[0] ? selectedHints[0].hint : ""
      const input: RsShipmentInput = {
        GP: gp,
        HP: hp,
        SP: sp,
        VP: vp,
        carrierID: "3",
        orderID: orderID,
        printerName:
          selectedPrinter.printerIP + ":" + selectedPrinter.printerPort,
        mainNote: mainNote,
        notes: reqHints,
        permitToPlacePackage: placePackage,
      }
      setIsLoading(true)
      rsShipmentCreate(input)
        .then((data) => {
          if (data?.createRsShipment) {
            if (data.createRsShipment.Info.length > 0) {
              dispatch(
                qSnack({
                  msg: data.createRsShipment.Info.join(","),
                  severity: "info",
                })
              )
            }
            if (data.createRsShipment.PDFURL !== "") {
              setShipmentDetails((prev) => {
                if (prev) {
                  return {
                    ...prev,
                    pdfURL: data.createRsShipment.PDFURL,
                  }
                }
                return null
              })
            }
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  useEffect(() => {
    if (orderIDref.current) {
      orderIDref.current.focus()
    }
    // load printer from server
    fetchPrinters().then((data) => {
      if (data?.printers) {
        setPrinters(data.printers)
        const sp = localStorage.getItem("selectedPrinter")
        if (sp) {
          const printer = data.printers.find(
            (printer) => printer.printerID === Number(sp)
          )
          if (printer) {
            setSelectedPrinter(printer)
          }
        }
      }
    })
  }, [])

  return (
    <Container maxWidth="xl" sx={{ marginBottom: "2.5%" }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4" component="h1" gutterBottom>
            RS Logistik
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Textfield
            variant="outlined"
            value={orderID}
            onChange={handleOrderID}
            onKeyUp={handleOrderIDCommit}
            inputRef={orderIDref}
            label="Bestell ID"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel id="printer-select-label">Drucker</InputLabel>
            <Select
              labelId="printer-select-label"
              id="printer-select"
              value={selectedPrinter?.printerID || ""}
              label="Drucker"
              onChange={(event) => {
                const printerID = event.target.value as number
                const printer = printers.find(
                  (printer) => printer.printerID === printerID
                )
                setSelectedPrinter(printer || null)
                localStorage.setItem("selectedPrinter", printerID.toString())
              }}
            >
              {printers.map((printer) => (
                <MenuItem key={printer.printerID} value={printer.printerID}>
                  {printer.printerID} - {printer.printerIP}:
                  {printer.printerPort}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <LoadingButton
            variant="contained"
            sx={{ height: "100%" }}
            disabled={shipmentDetails === null}
            onClick={handleOrderCreate}
            loading={isLoading}
          >
            {shipmentDetails?.pdfURL ? "DRUCKEN" : "ERSTELLEN & DRUCKEN"}
          </LoadingButton>
        </Grid>
        <Grid item xs={12}>
          <Alert severity="info">
            Bitte hier einen ZPL Zebra Drucker auswählen andere Drucker werden
            hier nicht funktionieren.
          </Alert>
        </Grid>
        {shipmentDetails && shipmentDetails.pdfURL !== "" && (
          <Grid item xs={12}>
            <Alert severity="info">
              {shipmentDetails?.pdfURL ? (
                <>
                  "Es ist bereits ein Label vorhanden. Dieses wird erneut
                  gedruckt auf Knopfdruck."{" "}
                  <Link href={shipmentDetails?.pdfURL} target="_blank">
                    Anzeigen
                  </Link>
                </>
              ) : (
                ""
              )}
            </Alert>
          </Grid>
        )}
        {shipmentDetails && (
          <Grid item xs={12}>
            <Alert
              severity={
                shipmentDetails?.permitToPlacePackage ? "warning" : "error"
              }
            >
              Abstellgenehmigung:{" "}
              {shipmentDetails?.permitToPlacePackage ? "Ja" : "Nein"}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          {shipmentDetails && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <AddressContainer adr={shipmentDetails.addressDetails} />
              </Grid>
              <Grid item xs={12}>
                <ShipmentDetailsContainer shipmentDetails={shipmentDetails} />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} md={8}>
          {shipmentDetails && (
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Textfield
                  variant="outlined"
                  label="Ganze Palette"
                  value={GP}
                  onChange={handleGP}
                />
              </Grid>
              <Grid item xs={3}>
                <Textfield
                  variant="outlined"
                  label="Halbe Palette"
                  value={HP}
                  onChange={handleHP}
                />
              </Grid>
              <Grid item xs={3}>
                <Textfield
                  variant="outlined"
                  label="Sack"
                  value={SP}
                  onChange={handleSP}
                />
              </Grid>
              <Grid item xs={3}>
                <Textfield
                  variant="outlined"
                  label="Viertel Palette"
                  value={VP}
                  onChange={handleVP}
                />
              </Grid>
              <Grid item xs={12}>
                <Alert severity="info">
                  Bitte mindestens eine Palette oder einen Sack auswählen.
                </Alert>
              </Grid>
              {selectedHints.map((hint, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    {index === 0 && (
                      <Textfield
                        variant="outlined"
                        label="Main Hinweis"
                        fullWidth
                        value={hint ? hint.hint : ""}
                        onChange={(event) => {
                          const newHints = [...selectedHints]
                          if (newHints[0]) {
                            newHints[0].hint = event.target.value
                            setSelectedHints(newHints)
                          }
                        }}
                      />
                    )}

                    {index !== 0 && (
                      <HintSelector
                        hint={hint}
                        hintIndex={index}
                        hintOptions={hintOptions}
                        label={
                          index === 0 ? "Main Hinweis" : "Hinweis " + index
                        }
                        setSelectedHint={handleHintChange}
                      />
                    )}
                  </Grid>
                )
              })}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default RsLogisticPage
