import { fetchGQL } from "app/api"
import { RoundsGetQuery } from "generated/graphql"
import { gql } from "graphql-tag"

const ROUNDS_GET = gql`
  query roundGet {
    roundsGet {
      rounds {
        id
        round
        name
      }
    }
  }
`

export const fetchRounds = async (): Promise<RoundsGetQuery | undefined> => {
  const data = await fetchGQL<RoundsGetQuery, {}>(ROUNDS_GET, {})
  return data
}
