import { fetchGQL } from "app/api"
import {
  CustomerByIdQuery,
  CustomerByIdQueryVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"

const CUSTOMER_BY_ID = gql`
  query customerById($customerID: Int!) {
    customerById(customerID: $customerID) {
      id
      nickName
      firstName
      lastName
      company
      email
      telefon
      handy
      street
      zip
      city
      country
      inbase
      custnote
      rechName
      rechStreet
      rechOrt
      rechPlz
      anrede
    }
  }
`

export const customerById = async (
  input: number
): Promise<CustomerByIdQuery | undefined> => {
  const variables: CustomerByIdQueryVariables = {
    customerID: input,
  }
  const data = await fetchGQL<CustomerByIdQuery, CustomerByIdQueryVariables>(
    CUSTOMER_BY_ID,
    variables
  )
  return data
}
