import { fetchGQL } from "app/api"
import {
  OrderTimelineQuery,
  OrderTimelineQueryVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"

const ORDER_TIMELINE = gql`
  query orderTimeline($orderID: Int!) {
    orderTimeline(orderID: $orderID) {
      id
      zeitpunkt
      action
      username
      computername
      orderID
      flavour
    }
  }
`

export const orderTimeline = async (
  orderID: number
): Promise<OrderTimelineQuery | undefined> => {
  const variables: OrderTimelineQueryVariables = { orderID }
  const data = await fetchGQL<OrderTimelineQuery, OrderTimelineQueryVariables>(
    ORDER_TIMELINE,
    variables
  )
  return data
}
