import { TextField } from "@mui/material"
import { useState } from "react"

type NumericInputProps = {
  label: string
  value: string | number
  minValue?: number
  maxValue?: number
  disabled?: boolean
  setValueCallback: (value: string) => void
  setIsValidCallback: (isValid: boolean) => void
  onFocusLostCallback: (value: string) => void
}

const NumericInput = (props: NumericInputProps): JSX.Element => {
  const {
    label,
    value,
    minValue,
    maxValue,
    disabled = false,
    setValueCallback,
    setIsValidCallback,
    onFocusLostCallback,
  } = props

  const [error, setError] = useState(false)
  return (
    <TextField
      type="text"
      inputMode="numeric"
      label={label}
      value={value}
      onChange={(e) => {
        const numVal = Number(e.target.value)
        if (isNaN(numVal)) {
          return
        }
        if (
          (!minValue || numVal >= minValue) &&
          (!maxValue || numVal <= maxValue)
        ) {
          setError(false)
          setIsValidCallback(true)
          setValueCallback(e.target.value)
        } else {
          setError(true)
          setIsValidCallback(false)
        }
      }}
      onBlur={(e) => {
        onFocusLostCallback(e.target.value)
      }}
      fullWidth
      sx={{ marginBottom: 1 }}
      error={error}
      disabled={disabled}
    />
  )
}

export default NumericInput
