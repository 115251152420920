import {
  CouponsByKeyQuery,
  CouponsByKeyQueryVariables,
  CouponCreateInput,
  CouponCreateMutation,
  CouponCreateMutationVariables,
  CouponDeleteInput,
  CouponDeleteMutation,
  CouponDeleteMutationVariables,
  CouponByIdQuery,
  CouponByIdQueryVariables,
  CouponUpdateInput,
  CouponUpdateMutation,
  CouponUpdateMutationVariables,
  CouponByIdInput,
  CouponsByKeyInput,
  CouponsByActiveDateInput,
  CouponsByActiveDateQuery,
  CouponsByActiveDateQueryVariables,
  CouponKeyInUseQuery,
  CouponKeyInUseQueryVariables,
} from "../generated/graphql"
import { gql } from "graphql-tag"
import { fetchGQL } from "app/api"

const CREATE_COUPON = gql`
  mutation couponCreate($in: CouponCreateInput!) {
    couponCreate(in: $in) {
      id
      key
      title
      description
      value
      discount
      min_sum
      article_id
      product_id
      valid_from
      valid_until
      combinable
      max_uses
      no_delivery_costs
      sale_allowed
      categoryIds
      note_intern
      editable
      who_created
      created_on
    }
  }
`

export const couponCreate = async (
  input: CouponCreateInput
): Promise<CouponCreateMutation | undefined> => {
  const variables: CouponCreateMutationVariables = {
    in: input,
  }
  const data = await fetchGQL<
    CouponCreateMutation,
    CouponCreateMutationVariables
  >(CREATE_COUPON, variables)
  return data
}

const UPDATE_COUPONS = gql`
  mutation couponUpdate($in: CouponUpdateInput!) {
    couponUpdate(in: $in) {
      id
      key
      title
      description
      value
      discount
      min_sum
      article_id
      product_id
      valid_from
      valid_until
      combinable
      max_uses
      no_delivery_costs
      sale_allowed
      categoryIds
      note_intern
      editable
    }
  }
`

export const couponUpdate = async (
  input: CouponUpdateInput
): Promise<CouponUpdateMutation | undefined> => {
  const variables: CouponUpdateMutationVariables = {
    in: input,
  }
  const data = await fetchGQL<
    CouponUpdateMutation,
    CouponUpdateMutationVariables
  >(UPDATE_COUPONS, variables)
  return data
}

const DELETE_COUPONS = gql`
  mutation couponDelete($in: CouponDeleteInput!) {
    couponDelete(in: $in)
  }
`

export const couponDelete = async (
  input: CouponDeleteInput
): Promise<CouponDeleteMutation | undefined> => {
  const variables: CouponDeleteMutationVariables = {
    in: input,
  }
  const data = await fetchGQL<
    CouponDeleteMutation,
    CouponDeleteMutationVariables
  >(DELETE_COUPONS, variables)
  return data
}

const COUPON_KEY_IN_USE = gql`
  query couponKeyInUse($key: String!) {
    couponKeyInUse(key: $key)
  }
`

export const couponKeyInUse = async (
  key: string
): Promise<CouponKeyInUseQuery | undefined> => {
  const variables: CouponKeyInUseQueryVariables = {
    key: key,
  }

  const data = await fetchGQL<
    CouponKeyInUseQuery,
    CouponKeyInUseQueryVariables
  >(COUPON_KEY_IN_USE, variables)
  return data
}

const COUPON_BY_ID = gql`
  query couponById($in: CouponByIdInput) {
    coupon(in: $in) {
      id
      key
      title
      description
      value
      discount
      min_sum
      article_id
      product_id
      valid_from
      valid_until
      combinable
      max_uses
      no_delivery_costs
      sale_allowed
      categoryIds
      note_intern
      editable
      who_created
      created_on
    }
  }
`

export const couponById = async (
  input: CouponByIdInput
): Promise<CouponByIdQuery | undefined> => {
  const variables: CouponByIdQueryVariables = {
    in: input,
  }
  const data = await fetchGQL<CouponByIdQuery, CouponByIdQueryVariables>(
    COUPON_BY_ID,
    variables
  )
  return data
}

const COUPON_BY_KEY = gql`
  query couponsByKey($in: CouponsByKeyInput!) {
    couponsByKey(in: $in) {
      id
      key
      title
      description
      value
      discount
      min_sum
      article_id
      product_id
      valid_from
      valid_until
      combinable
      max_uses
      no_delivery_costs
      sale_allowed
      categoryIds
      note_intern
      editable
      who_created
      created_on
    }
  }
`

export const couponsByKey = async (
  input: CouponsByKeyInput
): Promise<CouponsByKeyQuery | undefined> => {
  const variables: CouponsByKeyQueryVariables = {
    in: input,
  }
  const data = await fetchGQL<CouponsByKeyQuery, CouponsByKeyQueryVariables>(
    COUPON_BY_KEY,
    variables
  )
  return data
}

const COUPONS_BY_ACTIVE_DATE = gql`
  query couponsByActiveDate($in: CouponsByActiveDateInput!) {
    couponsByActiveDate(in: $in) {
      id
      key
      title
      description
      value
      discount
      min_sum
      article_id
      product_id
      valid_from
      valid_until
      combinable
      max_uses
      no_delivery_costs
      sale_allowed
      categoryIds
      note_intern
      editable
      who_created
      created_on
    }
  }
`

export const couponByActiveDate = async (
  input: CouponsByActiveDateInput
): Promise<CouponsByActiveDateQuery | undefined> => {
  const variables: CouponsByActiveDateQueryVariables = {
    in: input,
  }
  const data = await fetchGQL<
    CouponsByActiveDateQuery,
    CouponsByActiveDateQueryVariables
  >(COUPONS_BY_ACTIVE_DATE, variables)
  return data
}
