import { Container } from "@mui/material"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import {
  DataGridPro,
  GridColDef,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro"
import { DateRange, DateRangePicker } from "@mui/x-date-pickers-pro"
import { dataGridDEde } from "constants/dataGridLocale"
import { datePickerLocaleDEde } from "constants/datePickerLocale"
import dayjs, { Dayjs } from "dayjs"
import { SalesStat } from "generated/graphql"
import { fetchSaleStats } from "queries/fetchSalesStats"
import { useEffect, useState } from "react"
import { formatDateFromDate } from "utils/formatDate"
import { shortcutsItems } from "utils/shortcutItems"

const columns: GridColDef[] = [
  {
    field: "artnum",
    headerName: "Artikel Nr.",
    width: 100,
    editable: false,
  },
  {
    field: "pflNR",
    headerName: "PFL Nr.",
    width: 100,
    editable: false,
  },
  {
    field: "gername",
    headerName: "Name",
    width: 250,
    editable: false,
  },
  {
    field: "botname",
    headerName: "Botanischer Name",
    width: 250,
    editable: false,
  },
  {
    field: "from",
    headerName: "Von",
    width: 80,
    editable: false,
  },
  {
    field: "to",
    headerName: "Bis",
    width: 80,
    editable: false,
  },
  {
    field: "price",
    headerName: "Preis",
    width: 150,
    editable: false,
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      params.value.toLocaleString("de", {
        maximumFractionDigits: 2,
        style: "currency",
        currency: "EUR",
      }),
  },
  {
    field: "count",
    headerName: "Anzahl",
    width: 80,
    editable: false,
  },
]

const SalesStatsPage = () => {
  const [loading, setloading] = useState(false)
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([
    dayjs().startOf("month"),
    dayjs(),
  ])

  const [saleStatsData, setSaleStatsData] = useState<SalesStat[]>([])

  useEffect(() => {
    setloading(true)
    const fromDateString = dateRange[0]
      ? formatDateFromDate(dateRange[0].toDate())
      : ""
    const toDateString = dateRange[1]
      ? formatDateFromDate(dateRange[1].toDate())
      : ""
    if (!fromDateString || !toDateString) return
    if (fromDateString === "" || toDateString === "") return
    fetchSaleStats(fromDateString, toDateString).then((res) => {
      if (res) {
        setloading(false)
        // sort by price descending
        setSaleStatsData(res.salesStats.sort((a, b) => b.price - a.price))
      }
    })
  }, [dateRange, setloading, setSaleStatsData])

  return (
    <Container maxWidth="xl" sx={{ mt: "2.5%" }}>
      <Grid container spacing={1} sx={{ marginTop: 1 }}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: 500 }}>
              <DateRangePicker
                slotProps={{
                  shortcuts: {
                    items: shortcutsItems,
                  },
                }}
                localeText={datePickerLocaleDEde}
                value={dateRange}
                onChange={(newValue) => setDateRange(newValue)}
                disableFuture={true}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                height: "calc(100vh - 20vh)",
                width: "100%",
              }}
            >
              <DataGridPro
                rows={saleStatsData}
                columns={columns}
                localeText={dataGridDEde}
                getRowId={(item) => item.artnum}
                loading={loading}
                sortingOrder={["desc", "asc"]}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default SalesStatsPage
