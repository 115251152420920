import { TextField } from "@mui/material"
import { ChangeEvent, KeyboardEvent, useState } from "react"
import { orderByID } from "queries/orderById"
import { enqueueSnackbar } from "notistack"
import { useNavigate } from "react-router-dom"

type OrderIdSearchProps = {
  size: "small" | "medium"
}

export const OrderIdSearch = (props: OrderIdSearchProps) => {
  const { size } = { ...props }
  const [orderId, setOrderId] = useState<string>("")
  const navigate = useNavigate()

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const newValue = event.currentTarget.value
    if (/^\d*$/.test(newValue)) {
      setOrderId(newValue)
    }
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      fetchOrder()
    }
  }

  const fetchOrder = () => {
    orderByID(parseInt(orderId)).then((result) => {
      if (result && result.orderByID) {
        navigate("/orderDetails/" + orderId)
      } else {
        enqueueSnackbar("Die OrderID existiert nicht", {
          variant: "warning",
        })
        setOrderId("")
      }
    })
  }

  return (
    <TextField
      label="OrderID"
      variant="outlined"
      value={orderId}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      fullWidth
      size={size}
    />
  )
}
