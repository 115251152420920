import { fetchGQL } from "app/api"
import {
  EtikettenDataQuery,
  EtikettenDataQueryVariables,
} from "generated/graphql"
import gql from "graphql-tag"

const Q = gql`
  query etikettenData($round: Int!) {
    getEtikettenRound(round: $round) {
      orderID
      bdb
      sh
      quantity
      supplierID
      supplierName
      ewid
      botname
      von
      bis
      sorte
      totalBasketItems
      posten
      etiNum
    }
  }
`

export const etikettenData = async (
  round: number
): Promise<EtikettenDataQuery | undefined> => {
  const variables: EtikettenDataQueryVariables = { round }
  const data = await fetchGQL<EtikettenDataQuery, EtikettenDataQueryVariables>(
    Q,
    variables
  )
  return data
}
