import {
  PackageStatsByOrderIdQuery,
  PackageStatsByOrderIdQueryVariables,
  PackageStatsQuery,
  PackageStatsQueryVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"
import { fetchGQL } from "app/api"

const GET_PACKAGESTATS = gql`
  query packageStats($from: String!, $to: String!) {
    packageStats(from: $from, to: $to) {
      id
      orderID
      carrier
      ps
      packageNumber
      createdAt
      labelURL
      trackingURL
    }
  }
`

export const fetchPackageStats = async (
  from: string,
  to: string
): Promise<PackageStatsQuery | undefined> => {
  const variables: PackageStatsQueryVariables = { from, to }
  const data = await fetchGQL<PackageStatsQuery, PackageStatsQueryVariables>(
    GET_PACKAGESTATS,
    variables
  )
  return data
}

const GET_PACKAGESTATS_BY_ORDERID = gql`
  query packageStatsByOrderID($orderID: String!) {
    packageStatsByOrderID(orderID: $orderID) {
      id
      orderID
      carrier
      ps
      packageNumber
      createdAt
      labelURL
      trackingURL
    }
  }
`

export const fetchPackageStatsByOrderID = async (
  oid: string
): Promise<PackageStatsByOrderIdQuery | undefined> => {
  const variables: PackageStatsByOrderIdQueryVariables = { orderID: oid }
  const data = await fetchGQL<
    PackageStatsByOrderIdQuery,
    PackageStatsByOrderIdQueryVariables
  >(GET_PACKAGESTATS_BY_ORDERID, variables)
  return data
}
