import { fetchGQL } from "app/api"
import {
  RmlLabelPrintQuery,
  RmlLabelPrintQueryVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"

const RML_LABEL_PRINT = gql`
  query rmlLabelPrint($printerIpPort: String!, $rmlOrderID: String!) {
    rmlLabelPrint(printerIpPort: $printerIpPort, rmlOrderID: $rmlOrderID)
  }
`

export const rmlLabelPrint = async (
  printer: string,
  rmlOrderID: string
): Promise<RmlLabelPrintQuery | undefined> => {
  const variables: RmlLabelPrintQueryVariables = {
    printerIpPort: printer,
    rmlOrderID,
  }
  const data = await fetchGQL<RmlLabelPrintQuery, RmlLabelPrintQueryVariables>(
    RML_LABEL_PRINT,
    variables
  )
  return data
}
