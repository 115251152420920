import { gql } from "graphql-tag"
import { fetchGQL } from "app/api"
import {
  UserSaveMutation,
  UserSaveMutationVariables,
  UserUpdateInput,
  UsersQuery,
  UsersQueryVariables,
} from "generated/graphql"

const USERS_GET = gql`
  query users {
    users {
      id
      username
      privileges
    }
  }
`

export const usersGet = async (): Promise<UsersQuery | undefined> => {
  const variables: UsersQueryVariables = {}
  const data = await fetchGQL<UsersQuery, UsersQueryVariables>(
    USERS_GET,
    variables
  )
  return data
}

const USER_SAVE = gql`
  mutation userSave($input: UserUpdateInput!) {
    userSave(in: $input) {
      id
      username
      privileges
    }
  }
`

export const userSave = async (
  input: UserUpdateInput
): Promise<UserSaveMutation | undefined> => {
  const variables: UserSaveMutationVariables = { input }
  const data = await fetchGQL<UserSaveMutation, UserSaveMutationVariables>(
    USER_SAVE,
    variables
  )
  return data
}
