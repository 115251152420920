import { PerformanceQuery, PerformanceQueryVariables } from "generated/graphql"
import { gql } from "graphql-tag"
import { fetchGQL } from "app/api"

const GET_PERFORMANCE = gql`
  query performance($from: String!, $to: String!) {
    performance(from: $from, to: $to) {
      worker
      itemsTagged
      itemsDistributed
      itemsDispatched
    }
  }
`

export const fetchPerformance = async (
  from: string,
  to: string
): Promise<PerformanceQuery | undefined> => {
  const variables: PerformanceQueryVariables = { from, to }
  const data = await fetchGQL<PerformanceQuery, PerformanceQueryVariables>(
    GET_PERFORMANCE,
    variables
  )
  return data
}
