import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material"
import { useState } from "react"
import { SupplierInput } from "generated/graphql"
import { supplierCreate } from "queries/fetchSuppliers"
import { useAppDispatch } from "app/hooks"
import { qSnack } from "app/snackSlice"

export interface DialogAddSupplierProps {
  isOpen: boolean
  onClose: () => void
  getNewID: () => number
}

const DialogAddSupplier = ({
  isOpen,
  onClose,
  getNewID,
}: DialogAddSupplierProps): JSX.Element => {
  const [supplier, setSupplier] = useState<SupplierInput>({
    id: 0,
    name: "",
    street: "",
    city: "",
    zip: "",
    phone: "",
    fax: "",
    email: "",
    active: false,
    flavour: 0,
    G24: false,
    inStorage: false,
    GBF: false,
    sendEmail: false,
    sendFax: false,
  })

  const dispatch = useAppDispatch()

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = event.target
    setSupplier((prev) => ({
      ...prev,
      [name]:
        name === "active" ||
        name === "G24" ||
        name === "inStorage" ||
        name === "sendEmail" ||
        name === "sendFax"
          ? checked
          : value,
    }))
  }

  const handleAddSupplier = () => {
    const updatedSupplier = { ...supplier, id: getNewID() }
    supplierCreate(updatedSupplier).then((res) => {
      if (res?.supplierCreate) {
        dispatch(qSnack({ msg: "Lieferant hinzugefügt", severity: "success" }))
        setSupplier((prev) => ({
          ...prev,
          id: 0,
          name: "",
          street: "",
          city: "",
          zip: "",
          phone: "",
          fax: "",
          email: "",
          active: false,
          flavour: 0,
          G24: false,
          inStorage: false,
          sendEmail: false,
          sendFax: false,
        }))
        onClose()
      } else {
        dispatch(
          qSnack({
            msg: "Fehler beim Hinzufügen des Lieferanten",
            severity: "error",
          })
        )
      }
    })
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={onClose}>
      <DialogTitle>Lieferant hinzufügen</DialogTitle>
      <DialogContent dividers>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-between",
            marginTop: 2,
          }}
        >
          <TextField
            id="standard-basic"
            label="Name"
            variant="outlined"
            name="name"
            sx={{ marginTop: 2 }}
            value={supplier.name}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            id="standard-basic"
            label="Straße"
            variant="outlined"
            sx={{ marginTop: 2 }}
            name="street"
            value={supplier.street}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            id="standard-basic"
            label="PLZ"
            variant="outlined"
            sx={{ marginTop: 2 }}
            name="zip"
            value={supplier.zip}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            id="standard-basic"
            label="Ort"
            sx={{ marginTop: 2 }}
            variant="outlined"
            name="city"
            value={supplier.city}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            id="standard-basic"
            sx={{ marginTop: 2 }}
            label="Telefon"
            variant="outlined"
            name="phone"
            value={supplier.phone}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            id="standard-basic"
            sx={{ marginTop: 2 }}
            label="Fax"
            variant="outlined"
            name="fax"
            value={supplier.fax}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            id="standard-basic"
            label="Email"
            variant="outlined"
            sx={{ marginTop: 2 }}
            name="email"
            value={supplier.email}
            onChange={handleInputChange}
            fullWidth
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={supplier.active}
                onChange={handleInputChange}
                name="active"
                color="primary"
              />
            }
            label="Aktiv"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={supplier.G24}
                onChange={handleInputChange}
                name="G24"
                color="primary"
              />
            }
            label="G24"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={supplier.inStorage}
                onChange={handleInputChange}
                name="inStorage"
                color="primary"
              />
            }
            label="Lager Baumschule"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={supplier.sendEmail}
                onChange={handleInputChange}
                name="sendEmail"
                color="primary"
              />
            }
            label="Email senden"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={supplier.sendFax}
                onChange={handleInputChange}
                name="sendFax"
                color="primary"
              />
            }
            label="Fax senden"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Abbrechen</Button>
        <Button onClick={handleAddSupplier}>Hinzufügen</Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogAddSupplier
