import { fetchGQL } from "app/api"
import { gql } from "graphql-tag"
import {
  ArticleSupplierSaveMutation,
  ArticleSupplierSaveMutationVariables,
} from "../generated/graphql"

const ARTICLE_SUPPLIER_SAVE = gql`
  mutation articleSupplierSave($in: ArticleSupplierInput!) {
    articleSupplierSave(in: $in) {
      id
      articleID
      supplierID
      supplierName
      price
      createdAt
      updatedAt
      createdBy
      note
      quality
      priority
    }
  }
`

export const articleSupplierSave = async (
  vars: ArticleSupplierSaveMutationVariables
): Promise<ArticleSupplierSaveMutation | undefined> => {
  const data = await fetchGQL<
    ArticleSupplierSaveMutation,
    ArticleSupplierSaveMutationVariables
  >(ARTICLE_SUPPLIER_SAVE, vars)
  return data
}
