export const base64ToBlob = (base64: string, contentType: string): Blob => {
  // Decode the Base64 string
  const byteCharacters = atob(base64)

  // Convert decoded data to a byte array
  const buf = new Uint8Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    buf[i] = byteCharacters.charCodeAt(i)
  }
  // Create a blob with the byte array and the content type
  return new Blob([buf], { type: contentType })
}
