import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { Autocomplete, Grid, IconButton, TextField } from "@mui/material"
import Tile from "components/Tile/Tile"
import { Round } from "generated/graphql"
import { sortBy, uniqBy } from "lodash"

interface RoundSelectorProps {
  rounds: Round[]
  selectedRound: Round
  handleRoundSelectionChange: (r: Round) => void
}

const RoundSelector = (props: RoundSelectorProps): JSX.Element => {
  const { rounds, handleRoundSelectionChange, selectedRound } = props

  const renderInput = (params: any) => {
    return <TextField label="Runde" variant="outlined" {...params} />
  }

  const handleNextItem = (direction: number) => {
    if (direction === 0) return
    if (direction > 1 || direction < -1) return
    const currentIndex = rounds.findIndex(
      (r) => r.round === selectedRound.round
    )
    const nextIndex = currentIndex + direction
    // handle underflow (start from end)
    if (nextIndex === -1 && rounds.length > 0) {
      handleRoundSelectionChange(rounds[rounds.length - 1])
      return
    }
    if (nextIndex < rounds.length) {
      handleRoundSelectionChange(rounds[nextIndex])
      return
    }
    // handle overflow (start from beginning)
    if (nextIndex === rounds.length && rounds.length > 0) {
      handleRoundSelectionChange(rounds[0])
      return
    }
  }

  const handleClickNext = () => {
    handleNextItem(1)
  }

  const handleClickPrevious = () => {
    handleNextItem(-1)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Autocomplete
          disablePortal
          options={sortBy(uniqBy(rounds, "round"))}
          getOptionLabel={(round) => round.round.toString()}
          isOptionEqualToValue={(option, value) => option.round === value.round}
          renderInput={renderInput}
          value={selectedRound}
          onChange={(_, newValue) => {
            if (newValue) {
              handleRoundSelectionChange(newValue)
            }
          }}
          size="small"
        />
      </Grid>
      <Grid
        item
        xs={1}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <IconButton onClick={handleClickPrevious}>
          <ChevronLeftIcon />
        </IconButton>
      </Grid>

      <Grid
        item
        xs={1}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <IconButton onClick={handleClickNext}>
          <ChevronRightIcon />
        </IconButton>
      </Grid>
      {selectedRound && (
        <Grid item xs={6}>
          <Tile label="Name" value={selectedRound.name} />
        </Grid>
      )}
    </Grid>
  )
}

export default RoundSelector
