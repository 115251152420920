import { Autocomplete, Box, Chip, Grid, TextField } from "@mui/material"
import { OrderStatus } from "generated/graphql"
import { orderStatus } from "queries/orderStatus"
import { useEffect, useState } from "react"
import DoneIcon from "@mui/icons-material/Done"
import CloseIcon from "@mui/icons-material/Close"

type OrderStatusAutocompleteProps = {
  status: Number
  handleOrderStatusChange: (newStatus: number) => void
}

export const OrderStatusAutocomplete = (
  props: OrderStatusAutocompleteProps
) => {
  const { status, handleOrderStatusChange } = props
  const [stati, setStati] = useState<OrderStatus[]>()
  const [value, setValue] = useState<OrderStatus>()
  const [inputValue, setInputValue] = useState("")

  useEffect(() => {
    orderStatus().then((data) => {
      if (data?.orderStatus && data) {
        const itemMap = new Map<number, OrderStatus>()

        data.orderStatus.forEach((item) => itemMap.set(item.status, item))

        const result: OrderStatus[] = []
        for (let i = 1; i <= 1000; i++) {
          if (itemMap.has(i)) {
            result.push(itemMap.get(i)!)
          } else {
            result.push({
              status: i,
              etiStop: false,
              verteilenStop: false,
              packStop: false,
              remark: "",
            })
          }
        }
        setStati(result)
        const orderStatus = result.find((item) => item.status === status)
        setValue(orderStatus)
      }
    })
  }, [status])

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      const newValue = stati?.find(
        (item) => item.status === parseInt(inputValue)
      )
      if (newValue) {
        setValue(newValue)
        handleOrderStatusChange(newValue.status)
      }
    }
  }

  return (
    <>
      {stati && (
        <>
          <Autocomplete
            fullWidth
            value={value}
            inputValue={inputValue}
            options={stati}
            getOptionLabel={(option) =>
              option.status.toString() + ": " + option.remark
            }
            onInputChange={(_, newInputValue) => {
              setInputValue(newInputValue)
            }}
            onChange={(_, newValue) => {
              if (newValue) {
                setValue(newValue)
                handleOrderStatusChange(newValue.status)
              }
            }}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.status}: {option.remark}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Status"
                inputProps={{
                  ...params.inputProps,
                  onKeyDown: handleKeyDown,
                }}
              />
            )}
          />
          {value && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    margin: 1,
                    gap: 0.5,
                  }}
                >
                  <Chip
                    label={"Etikettieren"}
                    color={value.etiStop ? "error" : "primary"}
                    icon={value.etiStop ? <CloseIcon /> : <DoneIcon />}
                  />

                  <Chip
                    label={"Verteilen"}
                    color={value.verteilenStop ? "error" : "primary"}
                    icon={value.verteilenStop ? <CloseIcon /> : <DoneIcon />}
                  />

                  <Chip
                    label={"Packen"}
                    color={value.packStop ? "error" : "primary"}
                    icon={value.packStop ? <CloseIcon /> : <DoneIcon />}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  )
}
