import { Grid, Typography } from "@mui/material"
import { Address } from "generated/graphql"

export interface AdressContainerProps {
  adr: Address
}

const AdressContainer = (props: AdressContainerProps): JSX.Element => {
  const { adr } = props
  return (
    <Grid
      container
      spacing={1}
      sx={{
        border: "1px solid black",
        borderColor: "grey.500",
        borderRadius: 5,
        paddingBottom: 2,
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Grid item xs={12}>
        <Typography variant="h6">Addresse</Typography>
      </Grid>
      <Grid item xs={12}>
        {adr.company}
      </Grid>
      <Grid item xs={12}>
        {adr.fullName}
      </Grid>
      <Grid item xs={12}>
        {adr.street}
      </Grid>
      <Grid item xs={12}>
        {adr.zipCode} {adr.city}
      </Grid>
      <Grid item xs={12}>
        {adr.countryCode.toUpperCase()} {adr.country}
      </Grid>
    </Grid>
  )
}

export default AdressContainer
