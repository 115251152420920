import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Grid,
  Alert,
} from "@mui/material"
import { Printer } from "generated/graphql"
import { useEffect, useState } from "react"

export interface DialogRoundSupplierProps {
  isOpen: boolean
  onClose: () => void
  pdfB64: string
  pl: Printer[]
  onPrint: (printerIpPort: string) => void
}

const DialogPDF = (props: DialogRoundSupplierProps): JSX.Element => {
  const { isOpen, onClose, pdfB64, pl, onPrint } = props
  const [selectedPrinter, setSelectedPrinter] = useState(0)

  const handleChange = (event: SelectChangeEvent<number>) => {
    setSelectedPrinter(event.target.value as number)
    localStorage.setItem("selectedPrinter", event.target.value as string)
  }

  const handlePrint = () => {
    const printer = pl.find((p) => p.printerID === selectedPrinter)
    if (printer) {
      onPrint(printer.printerIP + ":" + printer.printerPort)
    }
  }

  useEffect(() => {
    const printerID = localStorage.getItem("selectedPrinter")
    if (printerID) {
      const printer = pl.find((p) => p.printerID === parseInt(printerID))
      if (printer) {
        setSelectedPrinter(printer.printerID)
      }
    }
  }, [pl])

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>PDF</DialogTitle>
      <DialogContent dividers>
        <Box sx={{ width: 500, height: 700 }}>
          <embed
            src={`data:application/pdf;base64,${pdfB64}`}
            style={{ width: "100%", height: "100%" }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="printer-label">Drucker</InputLabel>
              <Select
                labelId="printer-label"
                id="printer-select"
                value={selectedPrinter === 0 ? undefined : selectedPrinter}
                label="Drucker"
                onChange={handleChange}
              >
                {pl.map((p) => (
                  <MenuItem key={p.printerID} value={p.printerID}>
                    {p.printerID}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Alert severity="info">
              ZPL Zebra Drucker werden unterstützt. Bitte die ID des Druckers
              (sollte auf dem Drucker stehen) auswählen.
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              onClick={handlePrint}
              disabled={selectedPrinter === 0}
            >
              Drucken
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default DialogPDF
