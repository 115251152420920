import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "app/store"

export interface loadingState {
  isLoading: boolean
}

const initialState: loadingState = {
  isLoading: false,
}

export const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
  },
})

export const { setLoading } = loadingSlice.actions

export const selectIsLoading = (state: RootState) =>
  state.loadingState.isLoading

export default loadingSlice.reducer
