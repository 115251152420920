import LoadingButton from "@mui/lab/LoadingButton"
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material"
import Alert from "@mui/material/Alert"
import Container from "@mui/material/Container"
import FormControl from "@mui/material/FormControl"
import Grid from "@mui/material/Grid"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import Textfield from "@mui/material/TextField"
import { useAppDispatch } from "app/hooks"
import { qSnack } from "app/snackSlice"
import AddressContainer from "components/AddressContainer/AddressContainer"
import ShipmentDetailsContainer from "components/ShipmentDetailsContainer/ShipmentDetailsContainer"
import { Printer, RabenShipmentInput, ShipmentDetails } from "generated/graphql"
import { fetchPrinters } from "queries/fetchPrinters"
import { rabenLabelPrint, rabenShipmentCreate } from "queries/fetchRaben"
import { fetchShipmentDetails } from "queries/fetchShipmentDetails"
import React, { useEffect, useRef, useState } from "react"

const numRegex = /^[0-9]*$/
const ipPortRegex = /^[0-9]*\.[0-9]*\.[0-9]*\.[0-9]*:[0-9]*$/
const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

type PaletteInfo = {
  label: string
  KG: number // max weight in KG for this palette is a default value but can be adjusted
  code: string
  length: number // in m
  depth: number // in m
  height: number // in m height is always 1m but not really it takes one cargo slot in the container which has more than 1m height
}

const paletteInfo: PaletteInfo[] = [
  {
    label: "Euro Palette",
    KG: 150,
    code: "ep",
    length: 1.2,
    depth: 0.8,
    height: 1.0,
  },
  {
    label: "Halbe Palette",
    KG: 75,
    code: "ds",
    length: 0.6,
    depth: 0.8,
    height: 1.0,
  },
  {
    label: "Viertel Palette",
    KG: 50,
    code: "cp",
    length: 0.4,
    depth: 0.6,
    height: 1.0,
  },
]

const RabenPage = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const [orderID, setOrderID] = useState("")
  const [printers, setPrinters] = useState<Printer[]>([])
  const [logisticService, setSpecialService] = useState("none")
  const [avd, setAvd] = useState(false)
  const [myd, setMyd] = useState(false)
  const [selectedPrinter, setSelectedPrinter] = useState<Printer | null>(null)
  const [email, setEmail] = useState("")
  const [docID, setDocID] = useState("")
  const [phone, setPhone] = useState("")
  const [palletCount, setPalletCount] = useState(1)
  const [shipmentDetails, setShipmentDetails] =
    useState<ShipmentDetails | null>(null)

  const orderIDref = useRef<HTMLInputElement | null>(null)
  const [selectedPalette, setSelectedPalette] = useState<PaletteInfo>(
    paletteInfo[0]
  )

  const handleOrderID = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrderID(event.target.value)
  }

  const handleOrderIDCommit = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      //check if orderID is a number
      if (!numRegex.test(orderID)) {
        dispatch(
          qSnack({
            msg: "Bitte eine gültige Auftragsnummer eingeben nur Zahlen erlaubt",
            severity: "error",
          })
        )
        return
      }

      //reset current shipment details
      setPalletCount(1)
      setShipmentDetails(null)
      setSelectedPalette(paletteInfo[0])
      setDocID("")
      setEmail("")
      setPhone("")
      setAvd(false)
      setMyd(false)
      setSpecialService("none")
      fetchShipmentDetails(orderID).then((data) => {
        if (data?.shipmentDetails) {
          if (data.shipmentDetails.msg.length > 0) {
            dispatch(
              qSnack({
                msg: data.shipmentDetails.msg.join(","),
                severity: "warning",
              })
            )
          }
          if (data.shipmentDetails.orderDetails.orderID > 0) {
            //update hintsMap first entry
            setShipmentDetails(data.shipmentDetails)
            setEmail(data.shipmentDetails.custEmail)
            setPhone(data.shipmentDetails.custMobile)
          }
        }
      })
    }
  }

  const [isLoading, setIsLoading] = useState(false)
  const handleOrderCreate = async () => {
    // check if selectedPrinter is set
    if (!selectedPrinter) {
      dispatch(
        qSnack({
          msg: "Bitte Drucker auswählen",
          severity: "info",
        })
      )
      return
    }
    if (!shipmentDetails) {
      dispatch(
        qSnack({
          msg: "Bitte Auftragsnummer eingeben",
          severity: "info",
        })
      )
      return
    }

    if (docID !== "") {
      // try to print docID
      rabenLabelPrint(
        selectedPrinter.printerIP + ":" + selectedPrinter.printerPort,
        docID
      ).then((data) => {
        if (data?.rabenLabelPrint) {
          dispatch(
            qSnack({
              msg: "Label an den Drucker gesendet, wenn nichts passiert bitte Drucker überprüfen.",
              severity: "success",
            })
          )
        }
      })
      return
    }

    setIsLoading(true)
    // send shipment data gql to server
    // if success show snackbar
    const name =
      shipmentDetails.addressDetails.fullName === ""
        ? shipmentDetails.addressDetails.company
        : shipmentDetails.addressDetails.fullName
    if (name === "") {
      dispatch(
        qSnack({
          msg: "Bitte Empfänger Name eintragen",
          severity: "info",
        })
      )
      return
    }
    const printer =
      selectedPrinter.printerIP + ":" + selectedPrinter.printerPort
    if (!ipPortRegex.test(printer)) {
      dispatch(
        qSnack({
          msg: "Bitte Drucker auswählen",
          severity: "info",
        })
      )
      return
    }
    if (
      shipmentDetails.addressDetails.countryCode === "" ||
      shipmentDetails.addressDetails.countryCode.length !== 2
    ) {
      dispatch(
        qSnack({
          msg: "Bitte Empfänger Land - CountryCode - Alpha 2 Code eintragen (DE, AT, CH, ...)",
          severity: "info",
        })
      )
      return
    }
    const input: RabenShipmentInput = {
      orderID: shipmentDetails.orderDetails.orderID,
      name: shipmentDetails.addressDetails.fullName,
      CountryCode: shipmentDetails.addressDetails.countryCode.toUpperCase(),
      city: shipmentDetails.addressDetails.city,
      street: shipmentDetails.addressDetails.street,
      postalCode: shipmentDetails.addressDetails.zipCode,
      email: email,
      phone: phone,
      pallet: {
        KG: selectedPalette.KG,
        Height: selectedPalette.height,
        Width: selectedPalette.length,
        Depth: selectedPalette.depth,
        TypeCode: selectedPalette.code,
      },
      printerIpPort: printer,
      avd: avd,
      myd: myd,
      logisticService: logisticService,
    }
    for (let i = 0; i < palletCount; i++) {
      const data = await rabenShipmentCreate(input)
      if (data?.rabenShipmentCreate) {
        dispatch(
          qSnack({
            msg: `Auftrag erstellt ${i + 1}/${palletCount}`,
            severity: "success",
          })
        )
        // remove -doc from docID
        // awesome job matthias
        // thanks bro
        // you are welcome
        // i am so happy
        // i am so happy too
        // we are happy
        // this is so cool
        // i am so happy
        const trimmed = data.rabenShipmentCreate.DocID.replace("-doc", "")
        // still happy
        setDocID(trimmed)
        // matthias is happy
      }
    }
    setIsLoading(false)
  }

  const isValidEmail = emailRegex.test(email)

  useEffect(() => {
    if (orderIDref.current) {
      orderIDref.current.focus()
    }
    // load printer from server
    fetchPrinters().then((data) => {
      if (data?.printers) {
        setPrinters(data.printers)
        const sp = localStorage.getItem("selectedPrinter")
        if (sp) {
          const printer = data.printers.find(
            (printer) => printer.printerID === Number(sp)
          )
          if (printer) {
            setSelectedPrinter(printer)
          }
        }
      }
    })
  }, [])

  return (
    <Container maxWidth="xl" sx={{ marginBottom: "2.5%" }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4" component="h1" gutterBottom>
            Raben Group Logistik
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Textfield
            variant="outlined"
            value={orderID}
            onChange={handleOrderID}
            onKeyUp={handleOrderIDCommit}
            inputRef={orderIDref}
            label="Bestell ID"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Textfield
            variant="outlined"
            value={palletCount}
            type="number"
            onChange={(event) => {
              const value = event.target.value
              if (numRegex.test(value) && Number(value) > 0) {
                setPalletCount(Number(value))
              }
            }}
            label="Anzahl Paletten"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <InputLabel id="printer-select-label">Drucker</InputLabel>
            <Select
              labelId="printer-select-label"
              id="printer-select"
              value={selectedPrinter?.printerID || ""}
              label="Drucker"
              onChange={(event) => {
                const printerID = event.target.value as number
                const printer = printers.find(
                  (printer) => printer.printerID === printerID
                )
                setSelectedPrinter(printer || null)
                localStorage.setItem("selectedPrinter", printerID.toString())
              }}
            >
              {printers.map((printer) => (
                <MenuItem key={printer.printerID} value={printer.printerID}>
                  {printer.printerID} - {printer.printerIP}:
                  {printer.printerPort}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <LoadingButton
            variant="contained"
            sx={{ height: "100%" }}
            fullWidth
            disabled={
              palletCount < 1 ||
              shipmentDetails === null ||
              (avd && phone === "") ||
              !isValidEmail ||
              shipmentDetails?.permitToPlacePackage === false
            }
            onClick={handleOrderCreate}
            loading={isLoading}
          >
            {docID !== "" ? "DRUCKEN" : "ERSTELLEN & DRUCKEN"}
          </LoadingButton>
        </Grid>
        <Grid item xs={12}>
          <Alert severity="info">
            Bitte hier einen ZPL Zebra Drucker auswählen andere Drucker werden
            hier nicht funktionieren.
          </Alert>
        </Grid>
        {shipmentDetails && (
          <Grid item xs={12}>
            <Alert
              severity={
                shipmentDetails?.permitToPlacePackage ? "warning" : "error"
              }
            >
              Abstellgenehmigung:{" "}
              {shipmentDetails?.permitToPlacePackage ? "Ja" : "Nein"}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          {shipmentDetails && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <AddressContainer adr={shipmentDetails.addressDetails} />
              </Grid>
              <Grid item xs={12}>
                <ShipmentDetailsContainer shipmentDetails={shipmentDetails} />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          {shipmentDetails && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  value={selectedPalette}
                  onChange={(_, newValue) => {
                    if (newValue) setSelectedPalette(newValue)
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.code === value.code
                  }
                  disablePortal
                  id="combo-box-demo"
                  fullWidth
                  options={paletteInfo}
                  renderInput={(params) => (
                    <TextField {...params} label="Palette" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Textfield
                  fullWidth
                  variant="outlined"
                  label="Gewicht"
                  InputProps={{
                    endAdornment: "KG",
                  }}
                  value={selectedPalette.KG}
                  onChange={(event) => {
                    const kgFloatRegex = /^[0-9]*\.?[0-9]*$/
                    const value = event.target.value
                    if (kgFloatRegex.test(value))
                      setSelectedPalette({
                        ...selectedPalette,
                        KG: Number(value),
                      })
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Alert severity="info">
                  {selectedPalette.label} {selectedPalette.length * 100}x
                  {selectedPalette.depth * 100}x{selectedPalette.height * 100}{" "}
                  (in CM)
                </Alert>
              </Grid>

              <Grid item xs={12}>
                <Textfield
                  fullWidth
                  variant="outlined"
                  label="Kunde Email"
                  error={!isValidEmail}
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value)
                  }}
                />
                {!isValidEmail && (
                  <Alert severity="error">Bitte Kunde-Email eintragen.</Alert>
                )}
              </Grid>
              <Grid item xs={12}>
                <Textfield
                  fullWidth
                  variant="outlined"
                  label="Kunde Telefon"
                  error={avd && phone === ""}
                  value={phone}
                  onChange={(event) => {
                    setPhone(event.target.value)
                  }}
                />
                {avd && phone === "" && (
                  <Alert severity="error">
                    Bitte Telefonnummer eintragen wenn Avisierung gewählt wurde.
                  </Alert>
                )}
              </Grid>
              <Grid item xs={12}>
                <Alert severity="info">
                  Eine Palette hat immer eine Höhe von 1m. Ist aber nicht auf 1m
                  begrenzt. Die Palette nimmt immer einen ganzen Slot im
                  Container ein. Die Palette kann nicht gestapelt werden.
                </Alert>
              </Grid>
            </Grid>
          )}
        </Grid>
        {shipmentDetails && (
          <Grid item xs={12} md={4}>
            <FormControl>
              <FormLabel id="">Lieferservice</FormLabel>
              <RadioGroup
                value={logisticService}
                name="radio-buttons-group-deliveryservice"
              >
                <FormControlLabel
                  value="none"
                  control={<Radio />}
                  label="Keiner"
                  onChange={() => {
                    setSpecialService("none")
                  }}
                />
                <FormControlLabel
                  value="ND10"
                  control={<Radio />}
                  label="Next Day 6-10 Uhr"
                  onChange={() => {
                    setSpecialService("ND10")
                  }}
                />
                <FormControlLabel
                  value="ND12"
                  control={<Radio />}
                  label="Next Day 6-12 Uhr"
                  onChange={() => {
                    setSpecialService("ND12")
                  }}
                />
                <FormControlLabel
                  value="ND16"
                  control={<Radio />}
                  label="Next Day 6-16 Uhr"
                  onChange={() => {
                    setSpecialService("ND16")
                  }}
                />
              </RadioGroup>
            </FormControl>
            <FormGroup>
              <FormLabel id="">Avisierung</FormLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    value={avd}
                    onChange={(e) => setAvd(e.target.checked)}
                  />
                }
                label="Telefonisch avisieren"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={myd}
                    onChange={(e) => setMyd(e.target.checked)}
                  />
                }
                label="E-Mail avisieren"
              />
            </FormGroup>
          </Grid>
        )}
      </Grid>
    </Container>
  )
}

export default RabenPage
