import { fetchGQL } from "app/api"
import {
  CustomerOrderCreateMutation,
  CustomerOrderCreateMutationVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"

const CUSTOMER_ORDER_CREATE = gql`
  mutation customerOrderCreate($in: CustomerOrderInput!) {
    customerOrderCreate(in: $in) {
      orderID
    }
  }
`

export const customerOrderCreate = async (
  vars: CustomerOrderCreateMutationVariables
): Promise<CustomerOrderCreateMutation | undefined> => {
  const data = await fetchGQL<
    CustomerOrderCreateMutation,
    CustomerOrderCreateMutationVariables
  >(CUSTOMER_ORDER_CREATE, vars)
  return data
}
